import { Directive, ElementRef, OnDestroy } from '@angular/core';

interface Position {
  top: number,
  left: number,
  x: number,
  y: number
}

@Directive({
    selector: '[appMouseDragScroll]',
    standalone: false
})
export class MouseDragScrollDirective implements OnDestroy{
  private pos: Position = { top: 0, left: 0, x: 0, y: 0 };
  private preventClick: boolean;

  constructor(private elRef: ElementRef<HTMLElement>) {
    elRef.nativeElement.addEventListener('mousedown', this.mouseDownHandler, true);
    elRef.nativeElement.addEventListener('click', this.mouseClickHandler, true);
    elRef.nativeElement.classList.add('cursor-grab');
  }

  ngOnDestroy(): void {
    this.removeListeners()
  }

  private mouseClickHandler = (e: MouseEvent) => {
    if (this.preventClick) {
      e.stopPropagation();
      e.stopImmediatePropagation();
      e.preventDefault();
      this.preventClick = false;
    }
  }

  private mouseDownHandler = (e: MouseEvent) => {
    const target = e.target as HTMLElement;
    this.pos = {
      // The current scroll
      left: this.elRef.nativeElement.scrollLeft,
      top: this.elRef.nativeElement.scrollTop,
      // Get the current mouse position
      x: e.clientX,
      y: e.clientY,
    };

    this.elRef.nativeElement.addEventListener('mousemove', this.mouseMoveHandler);
    document.addEventListener('mouseup', this.mouseUpHandler);

    target.classList.add('none-draggable-selectable');
    target.classList.add('cursor-grabbing');
    this.elRef.nativeElement.classList.add('cursor-grabbing');
  };


  private mouseMoveHandler = (e: MouseEvent) => {
    // How far the mouse has been moved
    const dx = e.clientX - this.pos.x;
    const dy = e.clientY - this.pos.y;

    // Scroll the element
    this.elRef.nativeElement.scrollTop = this.pos.top - dy;
    this.elRef.nativeElement.scrollLeft = this.pos.left - dx;

    this.preventClick = true;
  };

  private mouseUpHandler = (e: MouseEvent) => {
    const target = e.target as HTMLElement;

    target.classList.remove('none-draggable-selectable');
    target.classList.remove('cursor-grabbing');
    this.elRef.nativeElement.classList.remove('cursor-grabbing');
    this.removeListeners();
  };


  private removeListeners(): void {
    this.elRef.nativeElement.removeEventListener('mousemove', this.mouseMoveHandler);
    document.removeEventListener('mouseup', this.mouseUpHandler);
  }
}
