import { Component, OnInit } from '@angular/core';
import { ProductRequestService } from '../product-request.service';
import { PaymentsService } from 'src/app/shared/payments.service';
import { UtilsService } from 'src/app/shared/utils.service';
import { BroadcasterNotificationType } from 'src/app/communication/broadcaster-notifications';
import { AuthService } from 'src/app/auth/auth.service';
import { RolesHelperService } from 'src/app/auth/roles-helper.service';

@Component({
    selector: 'app-product-quote',
    templateUrl: './product-quote.component.html',
    styleUrls: ['./product-quote.component.scss'],
    standalone: false
})
export class ProductQuoteComponent implements OnInit {
  public price: number;
  public budget: number;
  public showBudget: boolean;
  public invalidAddress: boolean;
  public isSU: boolean;
  constructor(
    public prService: ProductRequestService,
    private paymentsService: PaymentsService,
    private utils: UtilsService,
    public auth: AuthService,
    private roles: RolesHelperService
  ) {
    this.showBudget = false;
    this.isSU = this.roles.isSuperUserLoggedIn();
  }

  ngOnInit() {
  }

  fund() {
    this.createProduct();
  }

  getQuote() {
    this.createProduct();
  }

  createProduct() {
    // this.broadcaster.broadcast('createAssetRequest');
    this.prService.save();
  }

  async addBudget() {
    if (this.budget) {
      try {
        await this.paymentsService.addBudget(this.prService.request.retailers[this.prService.retailerIndex].id, this.budget);
        this.utils.notifyUser({
          type: BroadcasterNotificationType.Success,
          text: 'Funds successfully accumulated'
        });
        this.prService.setBudget();
        setTimeout(() => {
          this.prService.setBudget();
          setTimeout(() => {
            this.prService.setBudget();
            setTimeout(() => {
              this.prService.setBudget();
              setTimeout(() => {
                this.prService.setBudget();
              }, 1000);
            }, 1000);
          }, 1000);
        }, 1000);
      }
      catch (e) {
        if (e === 'invalid address')
          this.invalidAddress = true;
        else
          this.utils.notifyUser({
            type: BroadcasterNotificationType.Error,
            text: 'Unable to submit payment'
          });
      }
    }
    else
      this.utils.notifyUser({
        type: BroadcasterNotificationType.Error,
        text: 'State budget first'
      });
  }
}
