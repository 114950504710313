<div class="wrap scrollbar">
    <div class="router-outlet-wrap" [ngClass]="{'on': productService.editItem}">
        <router-outlet></router-outlet>
    </div>
    <div class="products-audit">
        <div>
            <div *ngIf="isSU" class="retailer-dropdown">
                <app-retailers-autocomplete [placeholder]="'search for retailers'" class="disp-block"
                    (retailer-change)="onRetailerChange($event)"></app-retailers-autocomplete>
            </div>
            <div class="grid pos-rel">
                <div fxLayout="row" fxLayoutAlign="center start"
                    [ngClass]="{'with-retailer': isSU, 'without-retailer': !isSU}"
                    class="flex-wrap-wrap scrollbar padd-sides list-wrap" id="scroll" #scroll>
                    @for (p of list; track p) {
                        <div class="prod-audit-list pos-rel float-dir w-100">
                            <div class="date-wrap">
                                <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="5px">
                                    <mat-divider style="width: 30%; display: inline-block;"></mat-divider>
                                    <!-- <span class="date-text" style="padding: 20px; font-weight: 500;"> {{p.created_at |
                                        moment :
                                        'format' : 'dddd, MMMM Do' }}</span> -->
                                    <span class="date-text" style="padding: 20px; font-weight: 500;"> {{p.created_at |
                                        moment :
                                        'format' : 'EEEE, MMMM do yyyy' }}</span>
                                    <mat-divider style="width: 30%; display: inline-block;"></mat-divider>
                                </div>
                            </div>
                            <div fxLayout="row" class="product-item" fxLayoutAlign="center stretch"
                                *ngFor="let item of p.items">
                                <div class="product-item-wrap">
                                    <div class="image" fxFlex="0 1 calc(15.1%)">
                                        <a [routerLink]="['/product/' + p.product_id]">
                                            <img *ngIf="item.products[0].products_data[0]" [src]="item.products[0].products_data[0].url" /></a>
                                    </div>
                                    <div class="time" fxFlex="0 1 calc(8%)">{{item.created_at | moment : 'format' : 'h:mm a' }}
                                    </div>
                                    <div fxFlex="0 1 calc(30.1%)">
                                        <div>
                                            <a mat-icon-button class="product-name link v-sub"
                                                [routerLink]="['/product/' + item.products[0].id]">
                                                {{item.products[0].name}}
                                            </a>
                                            <div *ngIf="isSU" class="retailer_link">
                                                <a *ngIf="isSU" class="link v-sub"
                                                    [routerLink]="['/products', { retailer_id: item.products[0].retailer_id   }]">
                                                    ({{item.products[0].retailers[0].name}})
                                                    <span class="material-icons">open_in_new</span>
                                                </a>
                                            </div>
                                        </div>
                                        <div>
                                            <b class="text2 capitalize">{{item.fullName}}</b>
                                        </div>
                                        <div>
                                            {{item.action}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    </div>
</div>