import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { MediaTag, ProductResource } from '../../product/product';
import { EnumsService } from '../enums.service';
import { FormatsType } from '../enums';

const VIEWER_RESOURCE_TYPE = {
  [FormatsType.OBJ]: 'OBJ',
  [FormatsType.FBX]: 'FBX',
  [FormatsType.GLB]: 'GLB',
  [FormatsType.glTF]: 'GLTF',
  [FormatsType.DAE]: 'DAE',
  [FormatsType.MP4]: 'MP4',
  [FormatsType.JPG]: 'JPG',
  [FormatsType.PNG]: 'PNG',
  [FormatsType.TIFF]: 'TIFF',
  [FormatsType.USDZ]: 'USDZ'
}

@Component({
    selector: 'app-resource-dropdown',
    templateUrl: './resource-dropdown.component.html',
    styleUrls: ['./resource-dropdown.component.scss'],
    standalone: false
})
export class ResourceDropdownComponent implements OnInit {
  @Input() resources: ProductResource[] = [];
  @Input() selectedResource: ProductResource | ProductResource[];
  @Input() multiple: boolean;
  @Output() selected = new EventEmitter<ProductResource | ProductResource[]>();
  public polyDictionary = this.enums.getPolyTypesDictionary();
  public mediatagModel;
  constructor(public enums: EnumsService) {
    this.mediatagModel = MediaTag.MODEL;
  }

  ngOnInit(): void {
  }

  public onSelectionChange(change: MatSelectChange): void {
    const resource: ProductResource = change.value;
    this.selected.emit(resource);
  }

  public get viewerResourceType() {
    return VIEWER_RESOURCE_TYPE
  }

  openedChange(isOpen: boolean) {
    if (isOpen)
      document.body.classList.add(`resource-dropdown-open${this.multiple ? '-m' : ''}`);
    else {
      document.body.classList.remove('resource-dropdown-open');
      document.body.classList.remove('resource-dropdown-open-m');
    }
  }
}
