import { Component, OnInit, OnDestroy } from '@angular/core';
import { BroadcasterService } from 'ng-broadcaster';
import { User, UsersFilterOptions } from '../user';
import { AuthService } from '../auth.service';
import { GraphqlService } from '../../communication/graphql.service';
import { InviteDialog } from './invite-dialog';
import { UsersListDialog } from './users-list-dialog';
import { UtilsService } from '../../shared/utils.service';
import { Subscription } from 'rxjs';
import { GlobalsService } from 'src/app/shared/globals.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'app-users-summary',
    templateUrl: './users-summary.component.html',
    styleUrls: ['./users-summary.component.scss'],
    standalone: false
})
export class UsersSummaryComponent implements OnInit, OnDestroy {
  public users: Array<User>;
  public showSelfProfile: boolean;
  public usersCount: number;
  private subs: Array<Subscription>;
  constructor(
    private gql: GraphqlService,
    private broadcaster: BroadcasterService,
    public auth: AuthService,
    private dialog: MatDialog,
    private utils: UtilsService,
    private globals: GlobalsService
  ) {
    this.subs = [];
    this.showSelfProfile = false;
    this.users = [] as Array<User>;
    this.refreshRetailerUsers(this.auth.user);
    this.subs.push(this.broadcaster.on('onLogin').subscribe((user: User) => {
      this.refreshRetailerUsers(user);
    }));
    this.subs.push(this.broadcaster.on('onUsersChange').subscribe((user: User) => {
      this.refreshRetailerUsers(this.auth.user);
    }));
    this.broadcaster.on('onclick').subscribe(
      (elm: Element) => {
        if (this.showSelfProfile) {
          if (!this.utils.closest(elm, '#users-summary'))
            this.toggleSelfProfile(false);
        }
        else// if (!this.utils.closest(elm, '#users-summary-self-profile'))
          this.toggleSelfProfile(false);
      }
    );
    this.broadcaster.on('onNavigationSnapshotChange').subscribe(
      () => this.toggleSelfProfile(false)
    )
  }

  ngOnInit() {
  }

  onNavToProfile() {
    this.toggleSelfProfile(false);
  }

  refreshRetailerUsers(user: User) {
    if (!user) return;
    if (user.retailers_users instanceof Array && user.retailers_users.length > 0) {
      if (!this.auth.user.retailers_users[this.auth.retailerIndex]) {
        this.auth.refreshUserDate();
        return;
      }
      const options = {
        limit: 4,
        offset: 0,
        retailer_id: [this.auth.user.retailers_users[this.auth.retailerIndex].retailer_id]
      } as UsersFilterOptions;
      this.gql.users(options).subscribe(users => {
        if (users.data && users.data.allUsers) {
          this.usersCount = users.data.allUsers.count;
          this.users = this.utils.deepCopyByValue(users.data.allUsers.rows);
          let isFound = false;
          for (let i = 0; i < this.users.length; i++) {
            if (this.users[i].id == this.auth.user.id) {
              this.users.splice(i, 1);
              isFound = true;
              break;
            }
          }
          if (!isFound && this.users.length > 3)
            this.users.splice(1, 1);
        }
      });
    }
  }

  getAcronyms(user: User): string {
    let res = '';
    if (user.firstname)
      res += user.firstname[0];
    if (user.lastname)
      res += user.lastname[0];
    return res;
  }

  toggleSelfProfile(state?: boolean) {
    if (typeof state === 'boolean')
      this.showSelfProfile = state;
    else
      this.showSelfProfile = !this.showSelfProfile;
  }

  openAllUsersDialog() {
    this.toggleSelfProfile(false);
    // const dialogRef = 
    this.dialog.open(UsersListDialog, {
      width: '550px'
    });
    // dialogRef.afterClosed().subscribe((result: User) => {
    //   // console.log('The dialog was closed');
    //   // this.animal = result;
    // });
  }

  openInviteUser() {
    this.toggleSelfProfile(false);
    this.dialog.open(InviteDialog, {
      width: '550px'
    });
  }

  ngOnDestroy() {
    if (this.subs.length)
      this.subs.forEach(s => s.unsubscribe());
  }
}
