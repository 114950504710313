import { PreviewOptions } from '../product/product';
import {
    // ViewerCommunicator,
    IAdjustmentsPresetJson,
} from 'hexa-viewer-communicator';
import {
    ViewerCommunicator
} from 'hexa-viewer-communicator/dist/viewer-communicator';
import { UtilsService } from './utils.service';
import { ScriptsFetcherType } from './utils';
import { environment } from 'src/environments/environment';

export class ResourceService {
    public _onPreviewReady: Array<Function>;
    constructor(private utlls: UtilsService) {
        this.fetchScript();
    }

    get hexaViewerSrc() {
        switch (environment.environmentName) {
            case 'DEV': {
                return 'https://vdev.hexa3d.io/hexa-viewer.bundle.js';
            }
            case 'QA':
            case 'STG': {
                return 'https://vqa.hexa3d.io/hexa-viewer.bundle.js';
            }
        }
        return 'https://v.hexa3d.io/hexa-viewer.bundle.js';
    }

    private async fetchScript() {
        await this.utlls.fetchScript(
            this.hexaViewerSrc,
            ScriptsFetcherType.HEXA_VIEWER
        );
    }

    private createInstance(resource: string): Promise<ViewerCommunicator> {
        return new Promise(async (resolve, reject) => {
            await this.fetchScript();
            const comm = new ViewerCommunicator();
            comm.hexaViewer = await comm.createInstanceFromUrl(resource);
            resolve(comm);
        });
    }

    private addToDom(hexaViewer: HTMLElement, parent: HTMLElement) {
        parent.appendChild(hexaViewer);
    }

    public async createPreview(
        resource: string,
        options: PreviewOptions
    ): Promise<string> {
        return new Promise(async (resolve, reject) => {
            resource += `&igreferrer=${encodeURIComponent(
                window.location.origin
            )}&create_images_by_tour=true`;
            const comm = await this.createInstance(resource);
            comm.hexaViewer.id = comm.hexaViewer.getAttribute('frame-id');
            comm.hexaViewer.classList.add('viewport-threshold');
            let height = options.height ? options.height : 1000;
            comm.hexaViewer.style.width = options.width
                ? options.width + 'px'
                : '1000px';
            comm.hexaViewer.style.height = options.height
                ? options.height + 'px'
                : '1000px';
            comm.hexaViewer.style.bottom = -1 * (height - 2) + 'px';
            this.addToDom(comm.hexaViewer, document.body);

            // timeout
            const promise1 = new Promise((resolve, reject) => {
                setTimeout(resolve, 60000, null);
            });

            const promise2 = comm.onCreateImagesByTour();

            Promise.race([promise1, promise2]).then((data: any) => {
                comm.hexaViewer.remove();
                console.log(data);
                if (data)
                    resolve(data.images[0]);
                else
                    resolve(data);
            });

            // const data = await comm.onCreateImagesByTour();
            // comm.hexaViewer.remove();
            // resolve(data.images[0]);
        });
    }

    async applyPreset(resource: string, json: IAdjustmentsPresetJson) {
        const comm = await this.createInstance(resource);
        comm.hexaViewer.id = comm.hexaViewer.getAttribute('frame-id');
        comm.hexaViewer.classList.add('viewport-threshold');
        const height = 250;
        comm.hexaViewer.style.width = height + 'px';
        comm.hexaViewer.style.height = height + 'px';
        comm.hexaViewer.style.bottom = -1 * (height - 2) + 'px';
        this.addToDom(comm.hexaViewer, document.body);
        await comm.applyPreset(json);
        return await comm.broadcastSceneSummary({ addResourceJson: true });
    }

    async adjustScene(resource: string) {
        const comm = await this.createInstance(resource);
        comm.hexaViewer.id = comm.hexaViewer.getAttribute('frame-id');
        comm.hexaViewer.classList.add('viewport-threshold');
        const height = 250;
        comm.hexaViewer.style.width = height + 'px';
        comm.hexaViewer.style.height = height + 'px';
        comm.hexaViewer.style.bottom = -1 * (height - 2) + 'px';
        this.addToDom(comm.hexaViewer, document.body);
        await comm.adjustScene();
        return await comm.broadcastSceneSummary({
            autoAdjustScene: true,
            addResourceJson: true,
        });
    }

    getEnvViewerServer(): string {
        switch (environment.environmentName) {
            case 'QA':
            case 'STG': {
                return '2';
            }
            case 'PROD': {
                return '1';
            }
            case 'DEV': {
                return '3';
            }
        }
        return '1';
    }
}
