import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FeedbackQuery, ProductsResourcesFeedback, Product } from '../product';
import { EnumsService } from '../../shared/enums.service';
import { KeyValuePair } from '../../shared/enums';
import { RestService } from '../../communication/rest.service';
import { BroadcasterNotification, BroadcasterNotificationType } from '../../communication/broadcaster-notifications';
import { BroadcasterService } from 'ng-broadcaster';
import { GraphqlService } from '../../communication/graphql.service';
import { Subject, Subscription } from 'rxjs';
import { ProductRequestService } from '../product-request.service';
import { ProductService } from '../product.service';
import { RolesHelperService } from 'src/app/auth/roles-helper.service';
import { UtilsService } from 'src/app/shared/utils.service';
import { FeedbackService } from '../feedback.service';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
    selector: 'app-feedback-edit',
    templateUrl: './feedback-edit.component.html',
    styleUrls: ['./feedback-edit.component.scss'],
    standalone: false
})
export class FeedbackEditComponent implements OnInit, OnDestroy {
  public feedback: ProductsResourcesFeedback;
  public commentText: string;
  public textValue: Subject<string>;
  private sub: Subscription;
  public isSU: boolean;
  public isPauseDueDate: boolean;
  public isShowSubType: boolean;
  constructor(
    private activatedRoute: ActivatedRoute,
    private enums: EnumsService,
    private rest: RestService,
    private broadcaster: BroadcasterService,
    private gql: GraphqlService,
    private router: Router,
    private prService: ProductRequestService,
    private productService: ProductService,
    private roles: RolesHelperService,
    public feedbackService: FeedbackService,
    public auth: AuthService,
    private utils: UtilsService
  ) {
    let f = this.activatedRoute.snapshot.data['feedback'] as FeedbackQuery;
    this.feedback = this.utils.deepCopyByValue(f.data.products_resources_feedback);



    this.textValue = new Subject<string>();
    this.sub = this.broadcaster.on('esc').subscribe(this.close.bind(this));
    this.isSU = this.roles.isSuperUserOrObserverLoggedIn();
    this.isPauseDueDate = true;
    this.isShowSubType = this.roles.doesUserHasPermission('feedback sub-type');
  }

  ngOnInit() {
    if (this.feedback.feedback_type) {
      this.feedbackService.init(null, null, this.feedback.feedback_type);
    }
  }

  onTextChange(notes: string) {
    this.commentText = notes;
  }

  addComment() {
    if (!this.commentText) return;
    let payload = {
      feedback_id: this.feedback.id,
      comment: this.commentText,
      pause_due_date: false
    };

    if (this.isSU) {
      payload.pause_due_date = this.isPauseDueDate;
    }
    this.rest.feedbackComment('post', payload).subscribe(
      data => this.refresh(),
      err => this.utils.httpErrorResponseHandler(err, 'failure adding comment')
    )
  }

  refresh() {
    this.gql.feedback(this.feedback.id).subscribe(
      (f: any) => {
        this.feedback = f.data.products_resources_feedback;
        this.textValue.next('');
      },
      err => this.utils.httpErrorResponseHandler(err, 'failure refreshing feedback')
    );
  }

  close() {
    this.router.navigate(['/product', this.productService.editItem.id]);
    delete this.prService.currentFeedbackEditId;
  }

  onFixChange(fixed: boolean) {
    // this.feedback.fixed = fixed;
    this.rest.productFeedbackFix('post',
      {
        feedback_id: this.feedback.id,
        fixed
      }
    ).subscribe(
      () => {
        let data: BroadcasterNotification = {
          text: `feedback ${this.feedback.fixed ? 'solved' : 'unsolved'} successfully`,
          type: BroadcasterNotificationType.Success,
          action: 'OK'
        };
        this.broadcaster.broadcast('notifyUser', data);
        this.prService.refresh();
      },
      err => this.utils.httpErrorResponseHandler(err, 'failure editing feedback')
    );
  }

  saveType(type, subType?) {
    if (!type)
      return;
    if (!this.feedbackService.filteredSubTypes?.length) {
      subType = null;
      this.feedback.feedback_sub_type = null;
      this.feedback.feedback_sub_types = null;
    }
    // if (this.roles.doesUserHasPermission("feedback sub-type") && !subType && this.feedbackService.types.some(t => t.id === type && t.feedback_sub_types.length)) {
    //   alert('Please select a sub type');
    //   return;
    // }
    this.feedback.feedback_type = type;
    if (subType)
      this.feedback.feedback_sub_type = subType;
    this.feedback.resource_id = this.prService.enabledResources[this.prService.currentResourceIndex].id;
    this.rest.feedback('put', this.feedback, "?fid=" + this.feedback.id + '&rid=' + this.auth.user.retailers_users[this.auth.retailerIndex].retailer_id).subscribe(
      () => {
        this.prService.refreshAudit();
       },
      err => this.utils.httpErrorResponseHandler(err, 'failure feedback type changed')
    );
  }

  showSolved() {
    return this.isSU || this.prService.request.external_provider;
  }

  ngOnDestroy() {
    delete this.prService.currentFeedbackEditId;
    this.sub.unsubscribe();
  }
}
