import { Component, OnInit, OnDestroy } from '@angular/core';
import { RolesConfig, RolesManagerService, RolePermission } from 'ng-role-based-access-control';
import { RolesHelperService } from '../roles-helper.service';
import { Subscription } from 'rxjs';
import { GlobalsService } from '../../shared/globals.service';

@Component({
    selector: 'app-roles-management',
    templateUrl: './roles-management.component.html',
    styleUrls: ['./roles-management.component.scss'],
    standalone: false
})
export class RolesManagementComponent implements OnInit, OnDestroy {
  public config: RolesConfig;
  addRolePermissionMsg: string;
  newRoleName: string;
  newPermissionId: number;
  newRoleId: number;
  public onRolesFetched: Subscription;
  constructor(
    public roles: RolesManagerService,
    private rolesHelper: RolesHelperService,
    private globals: GlobalsService) {
    this.onRolesFetched = this.rolesHelper.onRolesFetched.subscribe(this.setConfig.bind(this));
  }

  ngOnInit() {
    this.setConfig();
    setTimeout(() => {
      this.globals.setHeaer('Manage Roles & Permissions', '');
      this.globals.setBreadcrumbs();
    });
  }

  setConfig() {
    this.config = this.rolesHelper.getConfig();
  }

  addRole(roleName: string) {
    this.roles.addRoleToConfig(roleName);
    delete this.newRoleName;
  }

  deleteRole(roleName: string) {
    for (let i = 0; i < this.config.roles.length; i++ ) {
      if (!this.config.roles[i].id && this.config.roles[i].name == roleName)
        return this.config.roles.splice(i, 1);
    }
  }

  // getRoleByName(roleName: string) {
  //   return this.roles.getRoleByName(roleName);
  // }

  // getPermissionById(permissionId) {
  //   return this.roles.getPermissionById(permissionId);
  // }

  addRolePermission(roleName, permissionDescription) {
    this.addRolePermissionMsg = this.roles.addRolePermission(roleName, permissionDescription) == true ? 'already exist' : '';
  }

  deleteRolePermission(roleId, permissionId) {
    this.roles.deleteRolePermission(roleId, permissionId);
  }

  removeRolePermission(rp: RolePermission) {
    this.config.roles_permissions.splice(this.config.roles_permissions.indexOf(rp), 1);
  }

  save() {
    if (!confirm('are you sure you want to save this roles config?')) return;
    this.rolesHelper.save(this.config);
  }

  ngOnDestroy() {
    this.onRolesFetched.unsubscribe();
  }
}
