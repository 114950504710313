import { Component, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { ProductResource, ImageDialog } from '../product';
import { RolesHelperService } from '../../auth/roles-helper.service';
import { UtilsService } from '../../shared/utils.service';
import { EnumsService } from 'src/app/shared/enums.service';
import { ProductService } from '../product.service';
import { ProductRequestService } from '../product-request.service';
import { ImageDialogComponent } from 'src/app/shared/image-dialog/image-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'app-resource-embedded',
    templateUrl: './resource-embedded.component.html',
    styleUrls: ['./resource-embedded.component.scss'],
    standalone: false
})
export class ResourceEmbeddedComponent implements OnChanges {
  @Input() resource: ProductResource;
  @Output('on-change') onChange: EventEmitter<ProductResource>;
  public isPreview: boolean;
  public themeColor: string;
  // public arKitUrl: string;
  // public arCoreUrl: string;
  public placeholdersValues: { [id: number]: { [id: string]: string } };
  public isSU: boolean;
  constructor(
    public roles: RolesHelperService,
    public utils: UtilsService,
    private enums: EnumsService,
    public productService: ProductService,
    public prService: ProductRequestService,
    private dialog: MatDialog
  ) {
    this.placeholdersValues = this.enums.getProductPlaceholdersValues();
    this.onChange = new EventEmitter<ProductResource>();
    this.isSU = this.roles.isSuperUserOrObserverLoggedIn()
  }

  ngOnChanges() {
    if (this.resource.resource_default) {
      this.isPreview = !!this.utils.getUrlParam(this.resource.resource_default, 'pv');
      this.themeColor = this.utils.getUrlParam(this.resource.resource_default, 'theme-color');
      // this.arKitUrl = this.resource.resource_ar_url ? 'https://v.hexa3d.io/arkit.html?load=' + this.resource.resource_ar_url : null;
      // this.arCoreUrl = this.resource.resource_ar_url ? 'https://v.hexa3d.io/arcore.html?load=' + this.resource.resource_arcore_url : null;
    }
  }

  dataChanged() {
    this.onChange.emit(this.resource);
  }

  onPreviewChange(state: boolean) {
    this.resource.resource_default = this.utils.setUrlParam(this.resource.resource_default, 'pv', state ? this.resource.resource_big : null);
    this.dataChanged()
  }

  viewImageURL(url: string, copyLink?: string) {
    const m = {
      title: 'Scan the QR with your mobile device for AR experience',
      url: url,
      copyLink
    } as ImageDialog;
    this.dialog.open(ImageDialogComponent, {
      data: m
    });
  }

  onThemeChange() {
    const val = this.themeColor.trim();
    this.resource.resource_default = this.utils.setUrlParam(this.resource.resource_default, 'theme-color', val ? val : null);
    this.dataChanged()
  }
}
