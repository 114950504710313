import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PreviewResourceItem, PreviewResourceItemConfig } from './preview-resources.model';
import { AssetCommunicationComponent } from '../../shared/asset-communication/asset-communication.component';
import { Subject, Subscription } from 'rxjs';
import { AssetAdjustmentsHelperService } from 'src/app/shared/asset-adjustments-helper.service';
import { DEFAULT_3D_ICON, DEFAULT_VIDEO_ICON } from 'src/app/shared/enums';
import { BodyService } from 'src/app/shared/body.service';
import { MediaTag } from '../product';
import { BroadcasterService } from 'ng-broadcaster';

@Component({
    selector: 'app-preview-files',
    templateUrl: './preview-resources.component.html',
    styleUrls: ['./preview-resources.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
    standalone: false
})
export class PreviewResourcesComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(AssetCommunicationComponent) set iframeModel(assetCommunication: AssetCommunicationComponent) {
    if (assetCommunication) {
      this.assetAdjustmentsHelperService.setIFrameModel(assetCommunication.iframeModel);
    }
  };
  public resources: PreviewResourceItem[];
  public currentResource: PreviewResourceItem;
  private _destroy$ = new Subject<void>();
  private subs: Array<Subscription>;

  constructor(private dialogRef: MatDialogRef<PreviewResourcesComponent>,
              @Inject(MAT_DIALOG_DATA) private data: PreviewResourceItemConfig,
              private assetAdjustmentsHelperService: AssetAdjustmentsHelperService,
              private body: BodyService,
              private broadcaster: BroadcasterService) {
                this.subs = [];
              }

  ngOnInit(): void {
    this.resources = this.data.resources;
    this.currentResource = this.data.currentResource;
    this.subs.push(this.broadcaster.on('onPreviewResourceItemChange').subscribe(this.onPreviewResourceItemChange.bind(this)));
  }

  ngAfterViewInit() {
    setTimeout(() => {
        this.scrollToCurrent();
    }, 1000);
  }

  scrollToCurrent() {
    this.body.scrollToElement(new ElementRef(document.querySelector('.slider-item--selected')), 'start', 'center');
  }

  public get resourceType(): typeof MediaTag {
    return MediaTag;
  }

  public get videoIcon(): string {
    return DEFAULT_VIDEO_ICON;
  }

  public modelIcon(resource: PreviewResourceItem): string {
    return resource.big || DEFAULT_3D_ICON;
  }

  public onResourceSelect(selectedItem: PreviewResourceItem): void {
    this.currentResource = selectedItem;
    setTimeout(() => {
        this.scrollToCurrent();
    });
  }

  private onPreviewResourceItemChange(item: PreviewResourceItem) {
    this.resources.forEach(r => {
      if (r.uuid === item.uuid)
        Object.assign(r, item);
    });
    if (item.uuid === this.currentResource.uuid)
      Object.assign(this.currentResource, item);
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this.subs.forEach(s => s.unsubscribe());
  }
}
