import { Component, OnInit, Input } from '@angular/core';
import { RetailersDomain, RetailersPath, RetailersDomainsPaths } from '../retailer';
import { RestService } from '../../communication/rest.service';
import { BroadcasterNotificationType, BroadcasterNotification } from '../../communication/broadcaster-notifications';
import { BroadcasterService } from 'ng-broadcaster';
import { UtilsService } from '../../shared/utils.service';

@Component({
    selector: 'app-retailer-domains',
    templateUrl: './retailer-domains.component.html',
    styleUrls: ['./retailer-domains.component.scss'],
    standalone: false
})
export class RetailerDomainsComponent implements OnInit {
  @Input() domains: Array<RetailersDomain>;
  @Input() paths: Array<RetailersPath>;
  @Input('retailer-id') retailerId: number;
  public newDomain: string;
  public newPath: string;
  constructor(
    private rest: RestService,
    private broadcaster: BroadcasterService,
    private utils: UtilsService
  ) { }

  ngOnInit() {
    if (this.domains)
      this.domains = this.utils.deepCopyByValue(this.domains);
    if (this.paths)
      this.paths = this.utils.deepCopyByValue(this.paths);
  }

  removeDomain(domain: RetailersDomain) {
    for (let i = 0; i < this.domains.length; i++) {
      if (typeof domain.id === 'number') {
        if (this.domains[i].id == domain.id) {
          this.domains.splice(i, 1);
          return;
        }
      }
      else {
        if (this.domains[i].retailer_domain == domain.retailer_domain) {
          this.domains.splice(i, 1);
          return;
        }
      }
    }
  }

  addDomain() {
    this.domains.push({
      retailer_domain: this.newDomain,
      retailer_id: this.retailerId
    } as RetailersDomain);
    delete this.newDomain;
  }

  removePath(path: RetailersPath) {
    for (let i = 0; i < this.paths.length; i++) {
      if (typeof path.id === 'number') {
        if (this.paths[i].id == path.id) {
          this.paths.splice(i, 1);
          return;
        }
      }
      else {
        if (this.paths[i].path == path.path) {
          this.domains.splice(i, 1);
          return;
        }
      }
    }
  }

  addPath() {
    this.paths.push({
      path: this.newPath,
      retailer_id: this.retailerId
    } as RetailersPath);
  }

  save() {
    let payload = {
      domains: this.domains,
      paths: this.paths
    } as RetailersDomainsPaths;
    const query = '?rid=' + this.retailerId;
    this.rest.retailerDomains('put', payload, query).subscribe(
      (res: RetailersDomainsPaths) => {
        this.domains = res.domains;
        this.paths = res.paths;
        let data: BroadcasterNotification = {
          text: 'domains uploaded successfully',
          type: BroadcasterNotificationType.Success,
          action: 'OK'
        }
        this.broadcaster.broadcast('notifyUser', data);
      },
      err => this.utils.httpErrorResponseHandler(err, 'failure updating domains')
    );
  }
}
