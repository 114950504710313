"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.InjectStyleOptions = exports.TutorialOptions = void 0;
function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}
function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }
  return obj;
}
var TutorialOptions = function TutorialOptions() {
  _classCallCheck(this, TutorialOptions);
  _defineProperty(this, "identifier", void 0);
  _defineProperty(this, "maxIdentifier", void 0);
  this.maxIdentifier = 1;
};
exports.TutorialOptions = TutorialOptions;
var InjectStyleOptions = function InjectStyleOptions() {
  _classCallCheck(this, InjectStyleOptions);
  _defineProperty(this, "className", void 0);
};
exports.InjectStyleOptions = InjectStyleOptions;
