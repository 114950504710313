import { Component, OnInit, Input } from '@angular/core';
import { RetailersConfig, RetailersSettings } from '../retailer';
import { RestService } from '../../communication/rest.service';
import { UtilsService } from '../../shared/utils.service';
import { BroadcasterService } from 'ng-broadcaster';
import { BroadcasterNotification, BroadcasterNotificationType } from '../../communication/broadcaster-notifications';

@Component({
    selector: 'app-retailer-config',
    templateUrl: './retailer-config.component.html',
    styleUrls: ['./retailer-config.component.scss'],
    standalone: false
})
export class RetailerConfigComponent implements OnInit {
  @Input() config: Array<RetailersConfig>;
  @Input('retailer-id') retailerId: number;
  public container: { [id: string] : string; };
  constructor(
    private rest: RestService,
    private utils: UtilsService,
    private broadcaster: BroadcasterService
  ) {
    this.container = {}
  }

  ngOnInit() {
    this.setUI(this.config);
  }

  setUI(config: Array<RetailersConfig>) {
    this.container = {};
    if (config) {
      let code = this.utils.deepCopyByValue(config) as Array<RetailersConfig>;
      for (let i = 0; i < code.length; i++) {
        this.container[code[i].type] = code[i].config;
      }
    }
  }

  save() {
    let query = '?rid=' + this.retailerId;
    let payload = [] as Array<RetailersConfig>;
    // let hasDesctop = false;
    for (let i in this.container) {
      // if (i == 'desktop' && this.container[i])
      //   hasDesctop = true;
      if (this.container[i].trim()) {
        payload.push({
          config: this.container[i],
          retailer_id: this.retailerId,
          type: i
        } as RetailersConfig);
      }
    }
    // if (!hasDesctop) {
    //   let data: BroadcasterNotification = {
    //     text: 'enter desktop code first',
    //     type: BroadcasterNotificationType.Error,
    //     action: 'OK'
    //   }
    //   this.broadcaster.broadcast('notifyUser', data);
    //   return;
    // }
    
    this.rest.retailerConfig('put', payload, query).subscribe(
      (date: Array<RetailersConfig>) => {
        this.setUI(date);
        let data: BroadcasterNotification = {
          text: 'code uploaded successfully',
          type: BroadcasterNotificationType.Success,
          action: 'OK'
        }
        this.broadcaster.broadcast('notifyUser', data);
      },
      err => this.utils.httpErrorResponseHandler(err, 'failure updating code')
    );
  }
}
