import { Component, Input } from '@angular/core';
import { AssetAdjustmentsService, AssetAdjustmentType, AssetAdjustments } from 'asset-adjustments';

@Component({
    selector: 'app-light-manager',
    templateUrl: './light-manager.component.html',
    styleUrls: ['./light-manager.component.scss'],
    standalone: false
})
export class LightManagerComponent  {
  @Input('light') light: AssetAdjustments;
  public SLIDER: AssetAdjustmentType;
  public SELECT: AssetAdjustmentType;
  public TOGGLE: AssetAdjustmentType;
  public CUSTOM: AssetAdjustmentType;
  public JOYSTICK: AssetAdjustmentType;
  public VECTOR3: AssetAdjustmentType;
  public IMAGE_MENU: AssetAdjustmentType;
  constructor(
    public assetAdjustmentsService: AssetAdjustmentsService
  ) {
    this.SLIDER = AssetAdjustmentType.SLIDER;
    this.SELECT = AssetAdjustmentType.SELECT;
    this.TOGGLE = AssetAdjustmentType.TOGGLE;
    this.CUSTOM = AssetAdjustmentType.CUSTOM;
    this.JOYSTICK = AssetAdjustmentType.JOYSTICK;
    this.VECTOR3 = AssetAdjustmentType.VECTOR3;
    this.IMAGE_MENU = AssetAdjustmentType.IMAGE_MENU;
  }

  soloChanged() {
    if (this.assetAdjustmentsService.soloLights[this.light.options.type][this.light.index])
      this.assetAdjustmentsService.muteLights(this.light.options.type, this.light.index);
    else
      this.assetAdjustmentsService.recoverMuteLights();
  }
}
