import { Component, OnInit, Input } from '@angular/core';
import { UtilsService } from '../../shared/utils.service';
import { AuthService } from 'src/app/auth/auth.service';
import {EndpointsService} from 'src/app/communication/endpoints.service';
import { Retailer } from 'src/app/retailer/retailer';
import { CORS_TOKEN } from 'src/app/shared/utils';
import { RestService } from 'src/app/communication/rest.service';

declare var paypal: any;

@Component({
    selector: 'app-pay',
    templateUrl: './pay.component.html',
    styleUrls: ['./pay.component.scss'],
    standalone: false
})
export class PayComponent implements OnInit {
  @Input() amount: number;
  @Input() retailer: Retailer;
  constructor(
    private utils: UtilsService,
    private auth: AuthService,
    private endpoints: EndpointsService,
    private rest: RestService
  ) { }

  ngOnInit() {
    if (this.amount)
      this.utils.loadScript('https://www.paypalobjects.com/api/checkout.js', this.onPaypalScriptReady.bind(this));
    else
      console.error('No amount was provided for payment!');
  }

  onPaypalScriptReady() {
    let isProd = window.location.host == 'cms.hexa3d.io';
    paypal.Button.render({
      env: isProd ? 'production' : 'sandbox',
      // Set up the payment:
      // 1. Add a payment callback
      payment: (data: any, actions: any) => {
        // 2. Make a request to your server        
        let url = `?${this.endpoints.isLocalhost() ? `cors_override_key=${CORS_TOKEN}` : ''}`
        
        let p = {
          retailer_id: this.auth.user.retailers_users[this.auth.retailerIndex].retailer_id,
          amount: this.utils.getVATByCountryCode(this.retailer.country_code) * this.amount
        };
        this.rest.retailerInvoice('POST', p, url).subscribe((res: any) => {
          window.location.href = res.approval_url;
        }, err => {
          console.error(err);
        })
         
      },
      // Execute the payment:
      // 1. Add an onAuthorize callback
      onAuthorize: (data: any, actions: any) => {
        // 2. Make a request to your server
        let url = `/approve${this.endpoints.isLocalhost() ? `?cors_override_key=${CORS_TOKEN}` : ''}`
        let p = {
          paymentID: data.paymentID,
          payerID: data.payerID
        };
        this.rest.retailerInvoice('POST', p, url).subscribe((res: any) => {
          // 3. Show the buyer a confirmation message.
        }, err => {
          console.error(err);
        })     
      }
    }, '#paypal-button');
  }
}
