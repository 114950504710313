import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { GlobalsService } from 'src/app/shared/globals.service';
import { User, UsersMailsSubscription, UserQueryData } from '../user';
import { Retailer } from 'src/app/retailer/retailer';
import { RestService } from '../../communication/rest.service';
import { BroadcasterNotification, BroadcasterNotificationType } from 'src/app/communication/broadcaster-notifications';
import { BroadcasterService } from 'ng-broadcaster';
import { ApolloQueryResult } from '@apollo/client/core';
import { UtilsService } from 'src/app/shared/utils.service';
import { ValidState } from 'src/app/shared/utils';
import { RolesHelperService } from '../roles-helper.service';
import { AuthService } from '../auth.service';
import { GraphqlService } from 'src/app/communication/graphql.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-user-settings',
    templateUrl: './user-settings.component.html',
    styleUrls: ['./user-settings.component.scss'],
    standalone: false
})
export class UserSettingsComponent implements OnInit, OnDestroy {
  public user: User;
  public isUserValid: boolean;
  public isRetailerValid: boolean;
  public fundsValidState: ValidState;
  public isSU: boolean;
  public isSelfProfile: boolean;
  private subs: Array<Subscription>;
  private ignoreRetailerChanges: boolean;
  constructor(
    private activatedRoute: ActivatedRoute,
    private globals: GlobalsService,
    private rest: RestService,
    private broadcaster: BroadcasterService,
    private utils: UtilsService,
    private roles: RolesHelperService,
    public auth: AuthService,
    private gql: GraphqlService
  ) {
    this.ignoreRetailerChanges = false;
    this.isUserValid = true;
    this.isRetailerValid = true;
    this.isSU = this.roles.isSuperUserOrObserverLoggedIn();
    this.subs = [];
    setTimeout(() => {
      delete this.globals.playgroundClass;
      this.globals.setHeaer('Account Details', '');
      this.globals.setBreadcrumbs();
    }, 0);
    this.subs.push(this.broadcaster.on('onNavigationSnapshotChange').subscribe(
      (snapshot: ActivatedRouteSnapshot) => {
        this.setUser(snapshot);
      }
    ));
  }

  ngOnInit() {
    // this.user = this.activatedRoute.snapshot.data['user'] as User;
    this.setUser(this.activatedRoute.snapshot);
  }

  setUser(snapshot: ActivatedRouteSnapshot) {
    const u = snapshot.data['user'] as ApolloQueryResult<UserQueryData>;
    this.user = this.utils.deepCopyByValue(u.data.users);
    this.isSelfProfile = this.user.id == this.auth.user.id;
    // this.user = snapshot.data['user'] as User;
    // this.cdr.detectChanges();
  }

  onProfileChange(user: User) {
    this.user.avatar_url = user.avatar_url;
    this.user.firstname = user.firstname;
    this.user.lastname = user.lastname;
    this.user.email = user.email;
    this.user.users_roles = user.users_roles;
  }

  onIgnoreRetailerChanges(ignore: boolean) {
    this.ignoreRetailerChanges = ignore;
  }

  onRetailerChange(retailer: Retailer) {
    if (this.ignoreRetailerChanges) return;
    console.log(this.user.retailers_users[this.auth.retailerIndex].retailers[0]);
    Object.assign(this.user.retailers_users[this.auth.retailerIndex].retailers[0], retailer);
    console.log(this.user.retailers_users[this.auth.retailerIndex].retailers[0]);
    this.fundsValidState = {
      state: false,
      message: 'first save your company\'s settings'
    };
    // this.user.retailers_users[this.auth.retailerIndex].retailers[0] = retailer;
  }

  save() {
    if (!this.isRetailerValid || !this.isUserValid) {
      let data: BroadcasterNotification = {
        text: 'first fill out all mandatory fields',
        type: BroadcasterNotificationType.Info,
        action: 'OK'
      }
      this.broadcaster.broadcast('notifyUser', data);
      return;
    }
    
    const saveUser = () => {
      this.rest.userProfile('put', this.user, '/' + this.user.id).subscribe(
        data => {
          this.refresh();
          // Object.assign(this.user, data);
          let msg: BroadcasterNotification = {
            text: 'profiles successfully saved',
            type: BroadcasterNotificationType.Success,
            action: 'OK'
          }
          this.broadcaster.broadcast('notifyUser', msg);
        },
        err => this.utils.httpErrorResponseHandler(err, 'failure saving profile')
      );
    }

    if (this.user.retailers_users[this.auth.retailerIndex]) {
      const retailer = this.user.retailers_users[this.auth.retailerIndex].retailers[0];
      this.rest.retailer('put', retailer, '/' + retailer.id).subscribe(
        () => {
          delete this.fundsValidState;
          if (this.isSU || this.isSelfProfile)
            saveUser();
          else {
            let data: BroadcasterNotification = {
              text: 'retailer profile successfully saved',
              type: BroadcasterNotificationType.Success,
              action: 'OK'
            }
            this.broadcaster.broadcast('notifyUser', data);
          }
        },
        err => this.utils.httpErrorResponseHandler(err, 'failure saving retailer profile')
      );
    }
    else {
      delete this.fundsValidState;
      saveUser();
    }
  }

  refresh() {
    this.gql.user(this.user.id).subscribe(
      user => this.user = this.utils.deepCopyByValue(user.data.users),
      err => this.utils.httpErrorResponseHandler(err, 'failure refreshing profile')
    );
  }

  onUserValid(isValid: boolean) {
    this.isUserValid = isValid;
  }

  onRetailerValid(isValid: boolean) {
    this.isRetailerValid = isValid;
  }

  onEmailNotificationsChange(usersMailsSubscription: Array<UsersMailsSubscription>) {
    this.user.users_mails_subscriptions = usersMailsSubscription;
  }

  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe());
  }
}
