import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'spaceUpper',
    standalone: false
})
export class SpaceUpperPipe implements PipeTransform {

  transform(value: string): any {
    try {
      return value.replace(/([A-Z])/g, ' $1').trim();
    } catch (e) {
      return value;
    }
  }

}
