import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { User, MailCategory, UsersMailsSubscription, EmailInterval } from '../user';

@Component({
    selector: 'app-email-notifications',
    templateUrl: './email-notifications.component.html',
    styleUrls: ['./email-notifications.component.scss'],
    standalone: false
})
export class EmailNotificationsComponent implements OnInit {
  @Input() user: User;
  @Output('on-change') onNotificationChange: EventEmitter<Array<UsersMailsSubscription>>;
  public currentIndex: number;
  public emailCategoryDictionary: { [id: string]: string };
  public emailIntervalDictionary: { [id: string]: string };
  public emailIntervalDictionaryIcons: { [id: string]: string };
  constructor() {
    this.onNotificationChange = new EventEmitter<Array<UsersMailsSubscription>>();
  }

  ngOnInit() {
    this.emailCategoryDictionary = {};
    this.emailIntervalDictionary = {};
    this.emailIntervalDictionaryIcons = {};
    this.user.users_mails_subscriptions.forEach(
      s => this.emailCategoryDictionary[s.mail_category_id] = this.getMailCategoryType(s.mail_category_id)
    );
    this.user.users_mails_subscriptions_intervals.forEach(
      i => {
        this.emailIntervalDictionary[i.interval_id] = this.getInterval(i.interval_id);
        this.emailIntervalDictionaryIcons[i.interval_id] = this.getIntervalIcon(i.interval_id);
      }
    );
  }

  private getMailCategoryType(t: MailCategory) {
    switch (t) {
      case MailCategory.ASSET_CREATION_UPDATES: {
        return 'asset creation updates'
      }
      case MailCategory.ANALYTICS: {
        return 'analytics'
      }
      case MailCategory.BILLING: {
        return 'billing'
      }
      case MailCategory.MARKETING: {
        return 'marketing'
      }
      case MailCategory.ESSENTIAL: {
        return 'essential'
      }
      case MailCategory.FEEDBACK_UPDATES: {
        return 'Feedback Comments Updates'
      }
    }
  }

  private getInterval(t: EmailInterval) {
    switch (t) {
      case EmailInterval.PUSH: {
        return 'immediate'
      }
      case EmailInterval.DAILY: {
        return 'daily'
      }
      case EmailInterval.WEEKLY: {
        return 'weekly'
      }
      case EmailInterval.MONTHLY: {
        return 'monthly'
      }
    }
  }

  private getIntervalIcon(t: EmailInterval) {
    switch (t) {
      case EmailInterval.PUSH: {
        return 'access_time'
      }
      case EmailInterval.DAILY: {
        return 'today'
      }
      case EmailInterval.WEEKLY: {
        return 'view_week'
      }
      case EmailInterval.MONTHLY: {
        return 'brightness_3'
      }
    }
  }

  onChange() {
    this.onNotificationChange.next(this.user.users_mails_subscriptions);
  }

  setIndex(i: number) {
    this.currentIndex = i;
  }
}
