import { Component, Inject } from "@angular/core";
import { User } from "../user";
import { RestService } from "../../communication/rest.service";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { UtilsService } from "src/app/shared/utils.service";

@Component({
    selector: 'app-user-dialog',
    templateUrl: 'user-dialog.html',
    standalone: false
})
export class UserDialog {
    public isUserValid: boolean;
    constructor(
        public dialogRef: MatDialogRef<UserDialog>,
        @Inject(MAT_DIALOG_DATA) public user: User,
        private rest: RestService,
        private utils: UtilsService
    ) {
        this.isUserValid = true;
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    onProfileChange(user: User) {
        this.user.avatar_url = user.avatar_url;
        this.user.firstname = user.firstname;
        this.user.lastname = user.lastname;
        this.user.email = user.email;
        this.user.users_roles = user.users_roles;
    }

    save() {
        this.rest.userProfile('put', this.user, '/' + this.user.id).subscribe(
            data => this.dialogRef.close(data),
            err => this.utils.httpErrorResponseHandler(err, 'failure saving profile')
        );
    }

    onUserValid(isValid: boolean) {
        this.isUserValid = isValid;
    }
}