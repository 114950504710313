import { Directive, Input } from '@angular/core';

@Directive({
    selector: '[prevent-keys]',
    host: {
        '(keydown)': 'onKeyUp($event)'
    },
    standalone: false
})
export class PreventKeysDirective {
  @Input('prevent-keys') preventKeys;

  constructor() { }

  onKeyUp ( $event ) {
      if ( this.preventKeys && this.preventKeys.includes( $event. keyCode ) ) {
          $event.preventDefault();
      }
  }
}
