import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-users-list-item',
    templateUrl: './users-list-item.component.html',
    styleUrls: ['./users-list-item.component.scss'],
    standalone: false
})
export class UsersListItemComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
