import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-sso-options',
    templateUrl: './sso-options.component.html',
    styleUrl: './sso-options.component.scss',
    standalone: false
})
export class SsoOptionsComponent {
    constructor(private router: Router) {}

    public getGoogleLoginLink(): string {
        return `https://accounts.google.com/o/oauth2/v2/auth?client_id=${
            environment.googleData.client_id
        }&response_type=code&state=${encodeURIComponent(
            environment.googleData.state
        )}&scope=openid email profile&redirect_uri=${encodeURIComponent(
            environment.googleData.redirect_uri
        )}&prompt=consent&include_granted_scopes=true&access_type=offline`;
    }

    public toSignIn(): void {
        // this.pixels.sendPixel({
        //     event: 'click',
        //     button_name: 'continue',
        //     sign_up_step: 'sign up',
        //     button_position: 'page',
        // });
        this.router.navigate(['/login']);
    }
}
