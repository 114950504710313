import { Injectable, ElementRef } from '@angular/core';
import { Product, ProductAuditQueryData, ProductResource, ProductIdentifier, RenderState, ProductsRendersRequestStatus, ProductsRendersRequest, RenderType, ProductRenderType, ProductsPolygonSpecifications, MediaTag, ResourceDetails, FILE_USAGE_TYPE, Dimensions, ProductRequestState, ImageDialog, ProductsStatus, ExternalResourceDetails, ProductResourceOriginator, ProductDB } from './product';
import { UtilsService } from '../shared/utils.service';
import { ProductService } from './product.service';
import { GraphqlService } from '../communication/graphql.service';
import { BroadcasterNotification, BroadcasterNotificationType } from '../communication/broadcaster-notifications';
import { BroadcasterService } from '../../../node_modules/ng-broadcaster';
import { ApolloQueryResult } from '@apollo/client/core';
import { RolesHelperService } from '../auth/roles-helper.service';
import { GlobalsService } from '../shared/globals.service';
import { NavigationLink } from '../shared/globals';
import { ApiCallOptions, DimensionsUnits } from '../shared/utils';
import { ResourceTypesService } from '../shared/resource-types.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { InsufficientFundsComponent } from './insufficient-funds/insufficient-funds.component';
import { RestService } from '../communication/rest.service';
import { DEFAULT_3D_ICON, DEFAULT_PREVIEW_DIM, FormatsType, KeyValuePair, KeyValuePoly, RenderStatus } from '../shared/enums';
import { EnumsService } from '../shared/enums.service';
import { RendersAngles, Retailer, Category, RetailerBatch, RetailersUISettings, AdjustmentsPreset } from '../retailer/retailer';
import { StorageService } from 'ng-storage-service';
import { AuthService } from '../auth/auth.service';
import { ResourceDimensions, AdjustmentsSourceChanges } from 'asset-adjustments';
import { ImageDialogComponent } from '../shared/image-dialog/image-dialog.component';
import { RetailerBatchesService } from '../retailer/retailer-batches.service';
import { Observable } from 'rxjs';
import { AdjustmentsSourceChangesState } from 'asset-adjustments';
import { AssetAdjustmentsService } from 'asset-adjustments';
import { AssetAdjustmentsHelperService } from '../shared/asset-adjustments-helper.service';
import { PreviewResourceItem } from './preview-files/preview-resources.model';
import { ResumableUploadService } from '../shared/resumable-upload.service';
import { ResumableState, ResumableSubject } from '../shared/resumable';
import { tap } from 'rxjs/operators';
import { openDB, IDBPDatabase } from '@tempfix/idb';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProductRequestService {
  static LAST_NEW_PRODUCT = 'lastNewProduct';
  private _iframeModel: ElementRef;
  private onDimensionsResolve: Function;
  // private _iframeSrc: string;
  public request: Product;
  public retailerIndex: number;
  public currentResourceIndex: number;
  public currentFeedbackEditId: number;
  public currentRejectId: number;
  public gifgen: boolean;
  public gifgenForce: boolean;
  public initEnabledResourceIndex: number;
  public canEditItems: boolean;
  public canEditRenders: boolean;
  public requiredFormats: Array<number>;
  public uploadingCount: number;
  public saveAfterUpload: boolean;
  public availableResourceTypes: { [id: number]: ProductResource };
  public counter: number;
  public price: number;
  public budget: number;
  public isCovertSupported: boolean;

  public renderState: number;
  public CANNOT_CREATE: RenderState;
  public CAN_CREATE: RenderState;
  public CREATING: RenderState;
  public CREATED: RenderState;
  public positions: Array<KeyValuePair>;
  public renderTypes: Array<ProductRenderType>;
  public IMAGES_RENDER: RenderType;
  public VIDEO_RENDER: RenderType;
  public IMAGES_AND_VIDEO_RENDER: RenderType;
  public requestRenderDirty: boolean;
  public currentRenderState: RenderType;
  public enabledResources: Array<ProductResource>;
  public isSU: boolean;
  public canViewAllRetailers: boolean;
  public selectedTabIndex: number;
  public polyTypes: Array<KeyValuePair>;
  public polyShapeTypes: Array<KeyValuePair>;
  public polyDictionary: { [id: number]: KeyValuePoly };
  public adjustmentsMode: boolean;
  public adjustmentsModeAvailable: boolean;
  public renderMode: boolean;
  public renderModeAvailable: boolean;
  public renderPrice: number;
  public allowDownloads: boolean;
  public finalGlbSize: number;
  public totalTexturesSize: number;
  public resourceDimensions: Dimensions;
  public dimUnitsDictionary: KeyValuePair;
  public batches: Array<RetailerBatch>;
  public isDueDateEnabled: boolean;
  public renderStatus: RenderStatus;
  public isUploadExternalResourcesDirty: boolean;
  public externalResourcesUploadsIDs = [];
  public externalResourcesUploadsInProgress = 0;
  public externalResources: ExternalResourceDetails[] = [];
  public previewResourceItems: PreviewResourceItem[] = [];
  public externalResourcesCounter: number;
  public preservedModelResource: number;
  public selectedResource: ProductResource;
  public uploadedToClientStorage: boolean;
  public uploadedSourceFileToClientStorage: boolean;
  private db: IDBPDatabase<ProductDB>;
  constructor(
    public utils: UtilsService,
    private productService: ProductService,
    private gql: GraphqlService,
    public broadcaster: BroadcasterService,
    private roles: RolesHelperService,
    private globals: GlobalsService,
    private resourceTypesService: ResourceTypesService,
    private router: Router,
    private dialog: MatDialog,
    public rest: RestService,
    private enums: EnumsService,
    private storage: StorageService,
    private auth: AuthService,
    private retailerBatchesService: RetailerBatchesService,
    private assetAdjustmentsService: AssetAdjustmentsService,
    private assetAdjustmentsHelperService: AssetAdjustmentsHelperService,
    public resumableUpload: ResumableUploadService
  ) {
    this.counter = 0;
    this.CANNOT_CREATE = RenderState.CANNOT_CREATE;
    this.CAN_CREATE = RenderState.CAN_CREATE;
    this.CREATING = RenderState.CREATING;
    this.CREATED = RenderState.CREATED;
    this.isSU = this.roles.isSuperUserOrObserverLoggedIn();
    this.canViewAllRetailers = this.roles.doesUserHasPermission('view all retailers');
    this.positions = this.enums.getProductPositions();
    this.renderTypes = this.enums.getRenderTypes();
    this.polyTypes = this.enums.getPolyTypes();
    this.polyShapeTypes = this.enums.getPolyShapeTypes();
    this.IMAGES_RENDER = RenderType.IMAGES;
    this.VIDEO_RENDER = RenderType.VIDEO;
    this.IMAGES_AND_VIDEO_RENDER = RenderType.IMAGES_AND_VIDEO;
    this.enabledResources = [];
    this.batches = [];
    this.polyDictionary = this.enums.getPolyTypesDictionary();
    this.dimUnitsDictionary = this.enums.getDimensionsUnitsDictionary();
    this.broadcaster.on('onLogout').subscribe(() => { this.deleteDBValue(ProductRequestService.LAST_NEW_PRODUCT) });

  }

  // get iframeSrc() {
  //   return this._iframeSrc;
  // }

  get iframeModel(): ElementRef {
    return this._iframeModel;
  }

  set iframeModel(iframeModel: ElementRef) {
    this._iframeModel = iframeModel;
  }

  setCanEditItems(statusId) {
    this.canEditItems = this.isSU || statusId == 1 || statusId == 8 || !statusId;
  }

  setDueDateEnabled() {
    return this.request.ia || isNaN(this.request.id);
  }

  public resetRequest() {
    this.request = new Product();
    this.request.UI = this.productService.getProdutUI(this.request, { improviseImageSet: false });
    this.retailerIndex = 0;
    this.initEnabledResourceIndex = 0;

    this.selectedTabIndex = 0;
    this.canEditItems = true;
    this.canEditRenders = true;
    this.availableResourceTypes = {};
    this.preservedModelResource = null;
    // this.initIdentifiers();
    // this.initPolygons();
    // this.setBreadcrumbs();
    // this.initResources();
    // this.initRendersRequests();
    this.setBatches();
    this.isCovertSupported = false;
    this.allowDownloads = false;
    // this.renderState = this.CANNOT_CREATE;
    this.renderState = this.CAN_CREATE;
  }

  public initRenderStatus() {
    if (this?.request?.products_renders_requests?.length) {
      const rStatus = this?.request?.products_renders_requests[this.renderIndex]?.status_id;
      if (rStatus)
        this.renderStatus = this.enums.getRenderStatus(rStatus);
    }
  }

  public async onResourceIndexChange() {
    this.setCovertSupported();
    this.setAdjustmentsAvailable();
    this.setRenderModeAvailable();
    this.setDimensionsUnits();
    this.calcFilesSizes();
    // if (this.isSU)
    //   this.resourceDimensions = await this.getDimensions();
  }

  public async getDimensions(): Promise<Dimensions> {
    return new Promise((resolve, reject) => {
      this.onDimensionsResolve = resolve;
      this.router.navigate(['/product/' + this.request.id, { state: 'adjustments' }]);
      this.adjustmentsMode = true;
    });
  }

  private calcFilesSizes() {
    delete this.finalGlbSize;
    delete this.totalTexturesSize;
    const resourceFiles = this.enabledResources && this.enabledResources[this.currentResourceIndex] && this.enabledResources[this.currentResourceIndex].products_resources_files_details;
    if (resourceFiles) {
      let resourceDetails = {
        original_files: {},
        viewer_files: {}
      } as ResourceDetails;
      resourceFiles.filter(f => f.file_usage_type == FILE_USAGE_TYPE.VIEWER_FILES).forEach(f => resourceDetails.viewer_files[f.file_name] = f.file_size);
      resourceFiles.filter(f => f.file_usage_type == FILE_USAGE_TYPE.ORIGINAL_FILES).forEach(f => resourceDetails.original_files[f.file_name] = f.file_size);
      this.finalGlbSize = this.getGlbSize(resourceDetails);
      this.totalTexturesSize = this.getTotalTexturesSize(this.finalGlbSize, resourceDetails);
    }
  }

  getGlbSize(resourceDetails: ResourceDetails): number {
    let glbRaw = this.utils.getTotalImagesSize(resourceDetails.viewer_files, ['_raw.glb']);
    if (glbRaw)
      return glbRaw;
    let gltPlusArcore = this.utils.getTotalImagesSize(resourceDetails.viewer_files, ['glb'], ['_arcore_webp.glb', '_webp_arcore.glb', '_webp.glb', '_glb.zip']);
    let gltWithoutArcore = this.utils.getTotalImagesSize(resourceDetails.viewer_files, ['glb'], ['_arcore.glb', '_arcore_webp.glb', '_webp_arcore.glb', '_webp.glb', '_glb.zip']);
    return gltPlusArcore - gltWithoutArcore;
  }

  private getTotalTexturesSize(finalGlbSize: number, resourceDetails: ResourceDetails): number {
    if (resourceDetails) {
      let totalGeometry = this.utils.getTotalImagesSize(resourceDetails.original_files, ['.bin', '.gltf']);
      // texture size is GLB size reduced by the geometry size
      return finalGlbSize - totalGeometry;
    }
  }

  public setCovertSupported() {
    this.isCovertSupported = this.enabledResources && this.enabledResources[this.currentResourceIndex] && (this.enabledResources[this.currentResourceIndex].viewer_resource_type == FormatsType.GLB || this.enabledResources[this.currentResourceIndex].viewer_resource_type == FormatsType.glTF);
  }

  public deleteResource(index: number) {
    if (!confirm('Are you sure you want to delete this resource?')) return;
    const r = this.enabledResources[index];
    if (r) {
      for (let i = 0; i < this.request.products_resources.length; i++) {
        if (this.request.products_resources[i].id == r.id) {
          this.request.products_resources.splice(i, 1);
          break;
        }
      }
    }
    this.save();
  }

  setAdjustmentsAvailable() {
    this.adjustmentsModeAvailable = this.roles.doesUserHasPermission('adjust assets') || this.request.retailers[0]?.expose_adjustments;
    if (this.adjustmentsModeAvailable)
      this.adjustmentsModeAvailable = this.enabledResources.length && this.enabledResources[this.currentResourceIndex].resource_type == MediaTag.MODEL;
    if (this.adjustmentsMode) {
      this.navigateToState(ProductRequestState.ADJUSTMENTS);
    }
  }

  setRenderModeAvailable() {
    this.renderModeAvailable = false;
    this.renderPrice = 0;
    if (this.request.retailers && this.request.retailers[this.retailerIndex]) {
      if (typeof this.request.retailers[this.retailerIndex].realtime_image_render_price === 'number') {
        this.renderPrice = this.request.retailers[this.retailerIndex].realtime_image_render_price;
        this.renderModeAvailable = true;
      }
    }
    if (this.isSU)
      this.renderModeAvailable = true;
    if (this.renderModeAvailable)
      this.renderModeAvailable = this.enabledResources.length && this.enabledResources[this.currentResourceIndex].resource_type == MediaTag.MODEL;
  }

  setDimensionsUnits() {
    if (this.enabledResources && this.enabledResources[this.currentResourceIndex] &&
      this.enabledResources[this.currentResourceIndex].assets_details &&
      this.enabledResources[this.currentResourceIndex].assets_details[0] &&
      this.enabledResources[this.currentResourceIndex].assets_details[0].units) {
      if (this.request.units != this.enabledResources[this.currentResourceIndex].assets_details[0].units) {
        const ratio = 2.54;
        if (this.request.units == DimensionsUnits.INCH) {
          if (this.enabledResources[this.currentResourceIndex].assets_details[0].width)
            this.enabledResources[this.currentResourceIndex].assets_details[0].width /= ratio;
          if (this.enabledResources[this.currentResourceIndex].assets_details[0].height)
            this.enabledResources[this.currentResourceIndex].assets_details[0].height /= ratio;
          if (this.enabledResources[this.currentResourceIndex].assets_details[0].length)
            this.enabledResources[this.currentResourceIndex].assets_details[0].length /= ratio;
        }
        else {
          if (this.enabledResources[this.currentResourceIndex].assets_details[0].width)
            this.enabledResources[this.currentResourceIndex].assets_details[0].width *= ratio;
          if (this.enabledResources[this.currentResourceIndex].assets_details[0].height)
            this.enabledResources[this.currentResourceIndex].assets_details[0].height *= ratio;
          if (this.enabledResources[this.currentResourceIndex].assets_details[0].length)
            this.enabledResources[this.currentResourceIndex].assets_details[0].length *= ratio;
        }
        this.enabledResources[this.currentResourceIndex].assets_details[0].units = this.request.units;
      }
    }
  }

  navigateToState(index: ProductRequestState): void {
    this.adjustmentsMode = false;
    this.renderMode = false;
    switch (index) {
      case ProductRequestState.SETTINGS: {//settings
        this.router.navigate(['/product/' + this.request.id]);
        break;
      }
      case ProductRequestState.FEEDBACK: {
        this.router.navigate(['/product/' + this.request.id, { state: 'feedback' }]);
        break;
      }
      case ProductRequestState.ADJUSTMENTS: {
        this.router.navigate(['/product/' + this.request.id, { state: 'adjustments' }]);
        this.adjustmentsMode = true;
        break;
      }
      case ProductRequestState.RENDERER: {
        this.router.navigate(['/product/' + this.request.id, { state: 'renderer' }]);
        this.renderMode = true;
        break;
      }
    }
  }

  public mapFromProduct(product: Product) {
    this.resetRequest();
    this.request = this.utils.deepCopyByValue(product);
    this.request.UI = this.productService.getProdutUI(this.request, { improviseImageSet: false });
    if (this.request.products_resources) {
      for (let i = 0; i < this.request.products_resources.length; i++) {
        if (this.request.products_resources[i].resource_enabled == 1) {
          this.initEnabledResourceIndex = i;
          this.request.products_resources[i].artists_jobs_resources = 
          product.products_resources[0]?.artists_jobs_resources;
          break;
        }
       
      }
    }
    this.setCanEditItems(this.request.status_id);
    this.initIdentifiers();
    this.initPolygons();
    this.setBreadcrumbs();
    this.initResources();
    this.setCovertSupported();
    this.calcFilesSizes();
    this.initRenderSettings();
    this.initAutoTextureOffers();
    this.setAllowDownloads();
    // this.renderState = this.request.id ? this.CAN_CREATE : this.CANNOT_CREATE;
    this.renderState = this.CAN_CREATE;
    if (this.request.status_id == ProductsStatus.OFFLINE || this.request.status_id == ProductsStatus.ONLINE || (this.request.products_renders_requests && this.request.products_renders_requests.length)) {
      this.renderState = this.CAN_CREATE;
      if (this.request.products_renders_requests && this.request.products_renders_requests.length) {
        if (this.request.products_renders_requests[this.renderIndex].status_id == ProductsRendersRequestStatus.DELIVERED) {
          this.renderState = this.CREATED;

        }
        else if (this.request.products_renders_requests[this.renderIndex].status_id == ProductsRendersRequestStatus.IN_PROGRESS) {
          this.renderState = this.CREATING;

        }
        else if (this.request.products_renders_requests[this.renderIndex].status_id == ProductsRendersRequestStatus.REQUESTED) {
          this.renderState = this.CREATING;

        }
      }
    }

    this.isDueDateEnabled = this.setDueDateEnabled();
  }

  public get renderIndex() {
    if (this?.request?.id && this?.request?.products_renders_requests?.length > 0)
      return this.request.products_renders_requests.length - 1;
    return 0;
  }

  public setAllowDownloads() {
    this.allowDownloads = false;
    if (this.request.retailers && this.request.retailers[this.retailerIndex])
      this.allowDownloads = this.request.retailers[this.retailerIndex].allow_downloads;
    if (!this.allowDownloads)
      this.allowDownloads = this.request.allow_downloads;
  }


  public setCurrentRenderState() {
    delete this.currentRenderState;

    if (this.request.products_renders_requests[this.renderIndex].products_renders_requests_types && this.request.products_renders_requests[this.renderIndex].products_renders_requests_types.find(srt => srt.type_id == RenderType.IMAGES)) {
      if (this.request.products_renders_requests[this.renderIndex].products_renders_requests_types.find(srt => srt.type_id == RenderType.VIDEO)) {
        this.currentRenderState = RenderType.IMAGES_AND_VIDEO;
      }
      else {
        this.currentRenderState = RenderType.IMAGES;
      }
    }
    else if (this.request.products_renders_requests[this.renderIndex].products_renders_requests_types && this.request.products_renders_requests[this.renderIndex].products_renders_requests_types.find(srt => srt.type_id == RenderType.VIDEO)) {
      this.currentRenderState = RenderType.VIDEO;
    }
    this.setPrice();
  }

  public initRenderSettings(overrideDefault = false, isCategoryUpdated = false) {
    /*
   let setDefault = () => {
     Object.assign(this.request.products_renders_requests[this.renderIndex], this.request.retailers[this.retailerIndex].retailers_renders_settings[0]);
     Object.assign(this.request.products_renders_requests[this.renderIndex].products_renders_requests_angles, this.request.retailers[this.retailerIndex].retailers_renders_angles);
     if (this.request.retailer_category_id) {
       const cat = this.request.retailers[this.retailerIndex].retailers_categories.find(rc => rc.id == this.request.retailer_category_id);
       if (cat && cat.retailers_categories_renders_angles && cat.retailers_categories_renders_angles.length)
         this.request.products_renders_requests[this.renderIndex].products_renders_requests_angles = this.utils.deepCopyByValue(cat.retailers_categories_renders_angles);
     }
   }
   this.renderTypes = this.enums.getRenderTypes();
   if (this.request.products_renders_requests.length && this.request.products_renders_requests[this.renderIndex].products_renders_requests_types && this.request.products_renders_requests[this.renderIndex].products_renders_requests_types.length) {
     this.request.products_renders_requests[this.renderIndex].products_renders_requests_types = this.request.products_renders_requests[this.renderIndex].products_renders_requests_types;
     this.request.products_renders_requests[this.renderIndex].products_renders_requests_types.forEach(srt => {
       const relevant = this.renderTypes.find(rt => rt.type_id == srt.type_id);
       if (relevant) {
         srt.name = relevant.name;
         if (this.renderTypes.findIndex(rt => rt.type_id == srt.type_id) > -1)
           this.renderTypes.splice(this.renderTypes.findIndex(rt => rt.type_id == srt.type_id), 1);
         this.renderTypes.push(srt);
       }
     })
   }
   */
    //  if (!this.request.products_renders_requests.length) {
    this.initRendersRequests(false, isCategoryUpdated);
    //    setDefault();
    //  }
    //  else if (overrideDefault)
    //    setDefault();

    this.setCurrentRenderState();
  }

  public initIdentifiers() {
    if (!this.request.products_identifiers)
      this.request.products_identifiers = [];
    if (!this.request.products_identifiers.length)
      this.request.products_identifiers[0] = {} as ProductIdentifier;
  }

  public initPolygons() {
    if (!this.request.products_polygon_specifications)
      this.request.products_polygon_specifications = [];
    if (!this.request.products_polygon_specifications.length || !Object.keys(this.request.products_polygon_specifications[0]).length) {
      this.request.products_polygon_specifications[0] = {} as ProductsPolygonSpecifications;
      if (!this.request?.id && this.request.retailers && this.request.retailers[this.retailerIndex] && this.request.retailers[this.retailerIndex].retailers_polygon_specifications && this.request.retailers[this.retailerIndex].retailers_polygon_specifications.length) {
        this.request.retailers[this.retailerIndex].retailers_polygon_specifications.forEach(rps => {
          let obj = this.utils.deepCopyByValue(rps);
          delete obj.retailer_id;
          obj.product_id = this.request.id;
          if (!Object.keys(this.request.products_polygon_specifications[0]).length)
            Object.assign(this.request.products_polygon_specifications[0], obj);
          else
            this.request.products_polygon_specifications.push(obj);
        });
      }
    }
  }

  public onRetailersUpdate() {
    if (this.request.retailers[this.retailerIndex] &&
      this.request.retailers[this.retailerIndex].retailers_resources_types &&
      this.request.retailers[this.retailerIndex].retailers_resources_types.length)
      this.requiredFormats = this.resourceTypesService.mapRetailerResourceTypeToUi(this.request.retailers[this.retailerIndex].retailers_resources_types);
    this.setBudget();
    this.initRenderSettings(true);
    this.initAutoTextureOffers();
    this.initRendersRequests(true);
    this.setBatches();
    this.initPolygons();
    this.setPrice();
    // this.request.external_provider = false;
    this.request.external_provider = this.request.retailers[this.retailerIndex]?.external_provider || false;
    if (this.request.retailers[this.retailerIndex] && this.isRetailersExternalProviderWithoutIA(this.request.retailers[this.retailerIndex].id))
      this.request.external_provider = false;
  }

  isRetailersExternalProviderWithoutIA(retailerId: number) {
    return environment.allowRetailersExternalProviderWithoutIA.find(rid => rid === retailerId);
  }

  public initRendersRequests(clear = false, isCategoryUpdated = false) {
    this.initRenderStatus();

    if (this.request?.products_renders_requests.length && this.request?.products_renders_requests[this.renderIndex].id) {
      return;
    }

    if (!this.request.products_renders_requests || clear)
      this.request.products_renders_requests = [];
    if (!this.request.products_renders_requests.length || clear) {
      this.request.products_renders_requests[this.renderIndex] = {
        product_id: this.request.id,
        products_renders_requests_types: [],
        products_renders_requests_angles: [{
          angle_x: null,
          angle_y: null,
          screenshot: null
        }]
      } as ProductsRendersRequest;
    }
    let currentCategory = null as Category;
    if (this.request?.retailers && this.request?.retailers[this.retailerIndex]?.retailers_categories && this.request.retailer_category_id) {
      currentCategory = this.request.retailers[this.retailerIndex].retailers_categories.find(rc => rc.id == this.request.retailer_category_id);
    }
    if (isCategoryUpdated && currentCategory) {
      this.request.process_type = currentCategory.process_type;
    }
    if (this.request.products_renders_requests.length)
      this.request.products_renders_requests.forEach(prr => {
        if (!prr.product_id)
          prr.product_id = this.request.id;

        if (!this.request?.products_renders_requests[this.renderIndex]?.products_renders_requests_types?.length && currentCategory) {
          let rts = this.enums.getRenderTypes();
          prr.products_renders_requests_types = [];
          if (currentCategory.image_render_price)
            prr.products_renders_requests_types.push(rts[0]);
          if (currentCategory.video_render_price)
            prr.products_renders_requests_types.push(rts[1]);
        }
        let rrs = this.request.retailers?.length && this.request.retailers[this.retailerIndex].retailers_renders_settings;
        if (rrs?.length &&
          (currentCategory?.image_render_price ||
            currentCategory?.video_render_price)) {
          prr.background_color = prr.background_color ? prr.background_color : rrs[0].background_color;
          prr.duration = prr.duration ? prr.duration : rrs[0].duration;
          prr.resolution_height = prr.resolution_height ? prr.resolution_height : rrs[0].resolution_height;
          prr.resolution_width = prr.resolution_width ? prr.resolution_width : rrs[0].resolution_width;
          prr.frames_per_second = prr.frames_per_second ? prr.frames_per_second : rrs[0].frames_per_second;
          prr.product_position = prr.product_position ? prr.product_position : rrs[0].product_position;
          prr.product_reflection = prr.product_reflection ? prr.product_reflection : rrs[0].product_reflection;
          prr.product_shadow = prr.product_shadow ? prr.product_shadow : rrs[0].product_shadow;
        } else {
          prr.background_color = null;
          prr.duration = null;
          prr.resolution_height = null;
          prr.resolution_width = null;
          prr.frames_per_second = null;
          prr.product_position = null;
          prr.product_reflection = null;
          prr.product_shadow = null;
        }

        if (!prr.products_renders_requests_angles || clear) {
          prr.products_renders_requests_angles = [];
          let retailerRendersAngles = null;
          if (currentCategory) {
            retailerRendersAngles = currentCategory.retailers_categories_renders_angles;
          }
          else if (this.request.retailers[this.retailerIndex] &&
            this.request.retailers[this.retailerIndex].retailers_renders_angles &&
            this.request.retailers[this.retailerIndex].retailers_renders_angles.length) {
            retailerRendersAngles = this.request.retailers[this.retailerIndex].retailers_renders_angles;
          }
          if (retailerRendersAngles && retailerRendersAngles.length) {
            retailerRendersAngles.forEach((angle: RendersAngles) => {
              prr.products_renders_requests_angles.push({
                angle_x: angle.angle_x,
                angle_y: angle.angle_y,
                screenshot: angle.screenshot
              });
            })
          }
          else {
            prr.products_renders_requests_angles = [{
              angle_x: null,
              angle_y: null,
              screenshot: null
            }];
          }
        }
        if (!prr.products_renders_requests_types || clear)
          prr.products_renders_requests_types = [];
      })
  }

  isResourceRender(resource: ProductResource) {
    return this.productService.isResourceRender(resource);
  }

  private initResources() {
    this.currentResourceIndex = 0;
    this.availableResourceTypes = {};
    this.enabledResources = [];
    // let counter = 0;
    if (this.request && this.request.products_resources && this.request.products_resources.length > 0) {
      for (let i = 0; i < this.request.products_resources.length; i++) {
        this.availableResourceTypes[this.request.products_resources[i].viewer_resource_type] = this.request.products_resources[i];
        if (this.request.products_resources[i].resource_enabled || this.isSU || (this.isResourceRender(this.request.products_resources[i]) && !this.request.external_provider)) {
          this.enabledResources.push(this.request.products_resources[i]);
          // if (this.request.products_resources[i].resource_enabled)
          //   this.currentResourceIndex = counter++;
        }
      }
    }
    // this.currentResourceIndex = 0;
    this.enabledResources.sort(function (a: ProductResource, b: ProductResource) {
      var c = new Date(a.created_at).getTime();
      var d = new Date(b.created_at).getTime();
      return d - c;
    });
    this.enabledResources.sort(function (a: ProductResource, b: ProductResource) {
      var c = a.resource_enabled ? 1 : 0;
      var d = b.resource_enabled ? 1 : 0;
      return d - c;
    });
    if (this.preservedModelResource) {
      let rsrs = this.enabledResources.find(r => r.id === this.preservedModelResource)
      let indx = this.enabledResources.indexOf(rsrs);
      this.currentResourceIndex = indx;
      this.selectedResource = rsrs;
    }
    this.counter++;
  }

  setHeader() {
    this.globals.playgroundClass = null;
    this.globals.setHeaer(this.request.name_org_lang || 'Create New Asset', '');
  }

  setBreadcrumbs() {
    let breadcrumbs = [] as Array<NavigationLink>;
    if (this.request.id) {
      const currentStatusIds = this.productService.getStatusByStatusId(this.request.status_id);
      let idsMap = currentStatusIds.map(sl => sl);
      if (idsMap.length == 2 && idsMap[0] == 5 && idsMap[1] == 6) {
        idsMap = [this.request.status_id];
      }
      let pBreadcrumb = {
        link: idsMap.length ? '/products' : '',
        nativeLink: idsMap.length ? '/products;status_id=' + idsMap : '',
        text: this.productService.getStatusById(this.request.status_id).value
      } as NavigationLink;
      if (idsMap.length)
        pBreadcrumb.params = {
          status_id: idsMap
        }
      breadcrumbs.push(pBreadcrumb);
      breadcrumbs.push({
        link: '/products',
        text: this.request.retailers[this.retailerIndex].name,
        nativeLink: '/products;retailer_id=' + this.request.retailers[this.retailerIndex].id,
        params: {
          retailer_id: this.request.retailers[this.retailerIndex].id
        }
      });
    }
    else {
      breadcrumbs.push({
        link: '/products',
        // value: 'Back to My Assets'
        text: 'My Assets'
      });
    }
    this.globals.setBreadcrumbs(breadcrumbs);
  }

  public hasEnabledReource(): boolean {
    if (this.request.products_resources.length == 0) return true;
    return this.request.products_resources.filter(r => r.resource_enabled == 1).length > 0;
  }

  public removeArrayValue(controlName: string, fieldName: string, fieldValue: any): boolean {
    let success = false;
    let current = this.request[controlName];
    current = current || [];
    const index = current.findIndex(x => x[fieldName] == fieldValue);
    if (index > -1) {
      current.splice(index, 1);
      success = true;
    }
    return success;
  }

  public editArrayValue(controlName: string, fieldName: string, oldValue: any, newValue: any): boolean {
    let success = false;
    let current = this.request[controlName];
    if (!current) current = [];
    let value = current.find(x => x[fieldName] == oldValue);
    if (value) {
      value[fieldName] = newValue;
      success = true;
    }
    return success;
  }

  public refreshAudit() {
    if (!this.request.id) return;
    this.gql.productAudit(this.request.id).subscribe(
      (pa: ApolloQueryResult<ProductAuditQueryData>) => {
        this.request.products_audit = pa.data.products_audit;
      },
      err => this.utils.httpErrorResponseHandler(err, 'failure refreshing product history')
    );
  }

  fixNewCategories() {
    if (this.request.category) {
      if (this.request.retailer_category_id &&
        this.request.retailer_category_id > 0)
        delete this.request.category;
      else
        delete this.request.retailer_category_id;
    }
    if (this.request.sub_category) {
      if (this.request.retailer_sub_category_id &&
        this.request.retailer_sub_category_id > 0)
        delete this.request.sub_category;
      else
        delete this.request.retailer_sub_category_id;
    }
  }

  openInsufficientFundsDialog(): void {
    this.setBudget();
    this.setPrice();
    const dialogRef = this.dialog.open(InsufficientFundsComponent, {
      width: '500px',
      data: {
        retailer: this.request.retailers[this.retailerIndex],
        price: this.price,
        budget: this.budget
      }
    });
  }

  refresh() {
    this.saveCB(this.request);
  }

  save() {
    if (!this.request.retailers || !this.request.retailers[this.retailerIndex] || !this.request.retailers[this.retailerIndex].retailers_budget || !this.request.retailers[this.retailerIndex].retailers_budget[0]) {
      alert('Please select retailer first');
      return;
    }
    if (!this.request.id && this.price > this.request.retailers[this.retailerIndex].retailers_budget[0].amount) {
      this.openInsufficientFundsDialog();
      return;
    }
    if (!this.request.name_org_lang) {
      let data: BroadcasterNotification = {
        text: 'first insert product name (GENERAL DETAILS tab)',
        type: BroadcasterNotificationType.Error,
        action: 'OK'
      }
      this.broadcaster.broadcast('notifyUser', data);
      return;
    }
    if ((!this.request.id && this.request.products_data.length < ProductService.minImages) && !this.request.external_provider) {
      let data: BroadcasterNotification = {
        text: `first upload at least ${ProductService.minImages} more image${ProductService.minImages > 1 ? 's' : ''}`,
        type: BroadcasterNotificationType.Error,
        action: 'OK'
      }
      this.broadcaster.broadcast('notifyUser', data);
      return;
    }
    if ((!this.request.units && (this.request.width || this.request.height || this.request.length)) && !this.request.external_provider) {
      let data: BroadcasterNotification = {
        text: 'first state the dimensions units (Inches or Centimeters)',
        type: BroadcasterNotificationType.Error,
        action: 'OK'
      }
      this.broadcaster.broadcast('notifyUser', data);
      return;
    }
    if (this.uploadingCount > 0) {
      this.saveAfterUpload = true;
      return;
    }
    this.request.retailer_id = this.request.retailers[this.retailerIndex].id;
    this.fixNewCategories();
    let options = new ApiCallOptions();
    options.callback = this.saveCB.bind(this);
    this.request.category_name_org_lang = this.request.category;
    this.request.sub_category_name_org_lang = this.request.sub_category;

    this.saveProduct(options);
    this.isDueDateEnabled = this.setDueDateEnabled();


  }

  public saveProduct(options, isRenderApprove?) {
    let r: Product;
    r = this.utils.deepCopyByValue(this.request);
    if (isRenderApprove) {
      let rendersResources = r.products_resources.filter(r => r.resource_type !== MediaTag.MODEL);
      if (rendersResources && rendersResources.length) {
        rendersResources[rendersResources.length - 1].resource_enabled = 1;
        //TODO can't link specific render_request to one of sources from render_resource
      }
      else {
        let data: BroadcasterNotification = {
          text: 'You are trying to approve not a render resource',
          type: BroadcasterNotificationType.Error,
          action: 'OK'
        }
        this.broadcaster.broadcast('notifyUser', data);
      }
      r.status_id =
        (r.enabled || r.mobile_enabled) ? ProductsRendersRequestStatus.ONLINE : ProductsRendersRequestStatus.OFFLINE;
    } else if (r.products_resources?.length) {
      const er = this.enabledResources[this.currentResourceIndex];
      const erId = er.id;
      const pr = r.products_resources.filter(r => r.id === erId);
      if (pr && pr.length) {
        pr[0].resource_enabled = er.resource_enabled;
      }
    }
    this.productService.save(r, options);
  }

  saveCB(item: Product) {
    if (item && item.id) {
      this.gql.product(item.id).subscribe(
        res => {
          this.request = this.utils.deepCopyByValue(res.data.products);
          // this.initRendersRequests();
          this.initRenderSettings();
          this.initAutoTextureOffers();
          this.setAllowDownloads();
          this.initIdentifiers();
          this.initPolygons();
          this.requiredFormats = this.resourceTypesService.mapProductResourceTypeToUi(this.request.products_resources_types);
          this.globals.setHeaer(this.request.name_org_lang, '');
          this.setHeader();
          this.setBreadcrumbs();
          this.setPrice();
          this.setBatches();
          this.auth.refreshUserDate();
          this.setAdjustmentsAvailable();
          this.setRenderModeAvailable();
          this.setDimensionsUnits();
          this.isDueDateEnabled = this.setDueDateEnabled();
          this.initResources();
        },
        err => this.utils.httpErrorResponseHandler(err, 'failure getting product')
      )
      this.refreshAudit();
      this.router.navigate(['/product/' + item.id]);
      if (this.gifgen) {
        let div = document.createElement('div');
        div.id = 'autoGifDone';
        document.body.appendChild(div);
      }
    }
    else { // error?

    }
  }

  public onRejectFixed() {
    delete this.request.products_reject_reasons;
    this.request.status_id = 1;
    this.request.cs_id = 1;
    this.request.status = 'Requested';
    this.save();
  }

  public onApprove() {
    if (this.request.enabled == 1 || this.request.mobile_enabled == 1) {
      this.request.status_id = 6;
      this.request.status = 'Online';
    }
    else {
      this.request.status_id = 5;
      this.request.status = 'Offline';
    }
    this.save();
  }

  public setResourceIndexEnabled(index: number, disableRest: boolean) {
    if (disableRest) {
      for (let i = 0; i < this.request.products_resources.length; i++) {
        this.request.products_resources[i].resource_enabled = (index == i ? 1 : 0);
      }
    }
    else {
      this.request.products_resources[index].resource_enabled = 1;
    }
  }

  public setBudget(): void {
    if (this.request.retailers[this.retailerIndex]?.retailers_budget[0]?.amount)
      this.budget = this.request.retailers[this.retailerIndex].retailers_budget[0].amount;
  }

  public setPrice(): void {
    let multiplier = 1, addToFinal = 0;
    if (this.request) {
      if (this.request.products_polygon_specifications && this.request.products_polygon_specifications.length > 1)
        multiplier += this.request.products_polygon_specifications.length - 1;
      if (this.request.retailer_category_id
        && this.request.retailers &&
        this.request.retailers[this.retailerIndex].retailers_categories) {
        let c = this.request.retailers[this.retailerIndex].retailers_categories.find(c => c.id == this.request.retailer_category_id);
        if (c) {
          if (this.request.products_renders_requests && this.request.products_renders_requests[this.renderIndex] && this.request.products_renders_requests[this.renderIndex].products_renders_requests_types) {
            this.request.products_renders_requests[this.renderIndex].products_renders_requests_types.forEach(prrt => {
              if (prrt.type_id == RenderType.IMAGES && c.image_render_price && this.request.products_renders_requests[this.renderIndex].products_renders_requests_angles)
                addToFinal += c.image_render_price * this.request.products_renders_requests[this.renderIndex].products_renders_requests_angles.length;
              if (prrt.type_id == RenderType.VIDEO && c.video_render_price)
                addToFinal += c.video_render_price;
            });
          }
          if (typeof c.price === 'number') {
            this.price = (this.utils.floatinPrecision(
              c.price * this.utils.getVATByCountryCode(
                this.request.retailers[this.retailerIndex].country_code), 2) *
              multiplier) +
              addToFinal;
            return;
          }
        }
      }
    }
    if (this.request.retailers)
      this.price = (this.getDefaultPrice(this.request.retailers[0]) * multiplier) + addToFinal;
  }

  private initAutoTextureOffers(): void {
    if (this.request.auto_create_texture_offers == undefined) {
      this.setAutoTextureOffersFromRetailer();
    }
  }

  private setAutoTextureOffersFromRetailer(): void {
    this.request.auto_create_texture_offers = this.request.retailers[this.retailerIndex]?.auto_create_texture_offers;
  }

  public getDefaultPrice(retailer: Retailer): number {
    let res = 100 * this.utils.getVATByCountryCode(retailer ? retailer.country_code : null);
    return this.utils.floatinPrecision(res, 2);
  }

  public requestRender() {
    this.requestRenderDirty = true;
    if (!this.request.products_renders_requests[this.renderIndex].products_renders_requests_types.length) {
      let data: BroadcasterNotification = {
        text: 'render type is required',
        type: BroadcasterNotificationType.Error,
        action: 'OK'
      };
      this.broadcaster.broadcast('notifyUser', data);
      return;
    }



    this.createRender();
  }

  public createRender() {
    delete this.request.products_renders_requests[this.renderIndex].id;
    if (this.request?.id && this.request?.products_renders_requests?.length > 0)
      this.request.products_renders_requests[this.renderIndex].product_id = this.request.id;
    this.rest.productRender('post', this.request.products_renders_requests).subscribe(
      res => {
        let data: BroadcasterNotification = {
          text: 'render request created',
          type: BroadcasterNotificationType.Success,
          action: 'OK'
        };
        this.broadcaster.broadcast('notifyUser', data);
        this.renderState = RenderState.CREATING;
        this.refresh();
      },
      err => this.utils.httpErrorResponseHandler(err, 'failure requesting render')
    );

  }

  removeAngle(i: number) {
    this.request.products_renders_requests[this.renderIndex].products_renders_requests_angles.splice(i, 1);
    this.setPrice();
  }

  addAngle() {
    this.request.products_renders_requests[this.renderIndex].products_renders_requests_angles.push({} as RendersAngles);
    this.setPrice();
  }

  removePoly(i: number) {
    this.request.products_polygon_specifications.splice(i, 1);
    this.setPrice();
  }

  addPoly() {
    this.request.products_polygon_specifications.push({} as ProductsPolygonSpecifications);
    this.setPrice();
  }

  selectedIndexChange(index: number) {
    this.selectedTabIndex = index;
  }

  onDimensions(dim: ResourceDimensions) {
    if (this.onDimensionsResolve) {
      this.onDimensionsResolve(dim);
      delete this.onDimensionsResolve;
      history.back();
    }
    // if (dim && dim.units && this.resourceDetails) {
    //   this.resourceDetails.units = dim.units;
    //   this.resourceDetails.width = dim.width;
    //   this.resourceDetails.height = dim.height;
    //   this.resourceDetails.length = dim.length;
    // }
    // this.setWarnings();
  }

  async changeSrc(changes: AdjustmentsSourceChanges) {
    if (changes.state === AdjustmentsSourceChangesState.discard) {
      this.assetAdjustmentsService.init(changes.src);
      this.refresh();
    } else {
      const r = this.enabledResources[this.currentResourceIndex];
      // const viewerUrl = r.resource_default;
      r.resource_default = changes.src;
      r.resource_default = this.utils.setUrlParam(r.resource_default, 'json-data', new Date().getTime().toString());
      r.json_params = this.utils.getResourceJsonParams(changes.json_params, r.viewer_resource_type);
      r.json_params = await this.assetAdjustmentsHelperService.onBeforeSave(r.json_params);
      r.update_json_params = true;
      await this.putResource(r);
      this.assetAdjustmentsService.init(r.resource_default);
      this.broadcaster.broadcast('notifyUser', {
        text: 'scene saved',
        type: BroadcasterNotificationType.Success,
        action: 'OK'
      });
      // this.refresh();
    }
  }

  hasDimModel(): boolean {
    return this.enabledResources[this.currentResourceIndex] && this.enabledResources[this.currentResourceIndex].resource_type == MediaTag.MODEL;
  }

  showDimModel() {
    let iframeSrc = this.utils.setUrlParam(this.enabledResources[this.currentResourceIndex].resource_default, 'dimensions', '');
    // if (this.enabledResources[this.currentResourceIndex].assets_details &&
    //   this.enabledResources[this.currentResourceIndex].assets_details[0].units &&
    //   this.enabledResources[this.currentResourceIndex].assets_details[0].width &&
    //   this.enabledResources[this.currentResourceIndex].assets_details[0].height &&
    //   this.enabledResources[this.currentResourceIndex].assets_details[0].length) {
    //   const units = this.dimUnitsDictionary[this.enabledResources[this.currentResourceIndex].assets_details[0].units]
    //     .replace(this.dimUnitsDictionary[DimensionsUnits.CM], 'CM')
    //     .replace(this.dimUnitsDictionary[DimensionsUnits.INCH], 'INCH')
    //   iframeSrc = this.utils.setUrlParam(this.enabledResources[this.currentResourceIndex].resource_default, 'dimensions',
    //     `${this.utils.round(this.enabledResources[this.currentResourceIndex].assets_details[0].width)} ${units},${this.utils.round(this.enabledResources[this.currentResourceIndex].assets_details[0].height)} ${units},${this.utils.round(this.enabledResources[this.currentResourceIndex].assets_details[0].length)} ${units}`);
    // }
    // // delete the dimensions param if exist:
    // iframeSrc = this.utils.setUrlParam(this.enabledResources[this.currentResourceIndex].resource_default, 'dimensions', '');
    // // add the dimensions param:
    iframeSrc += '&dimensions=';
    if (this.enabledResources[this.currentResourceIndex].assets_details &&
      this.enabledResources[this.currentResourceIndex].assets_details[0].units) {
      iframeSrc = this.utils.setUrlParam(iframeSrc, 'dimensions-u', this.enabledResources[this.currentResourceIndex].assets_details[0].units.toString());
    }
    // else {
    //   // delete the dimensions param if exist:
    //   iframeSrc = this.utils.setUrlParam(this.enabledResources[this.currentResourceIndex].resource_default, 'dimensions', '');
    //   // add the dimensions param:
    //   iframeSrc += '&dimensions=';
    // }
    const m = {
      title: this.request.name_org_lang + ' (dimensions)',
      url: iframeSrc,
      isIframe: true
    } as ImageDialog;
    this.dialog.open(ImageDialogComponent, {
      width: `${window.innerWidth - 20}px`,
      height: `${Math.min(768, window.innerHeight - 20)}px`,
      data: m
    });
  }

  setBatches() {
    if (!this.request.retailers || !this.request.retailers[this.retailerIndex] || !this.request.retailers[this.retailerIndex].retailers_batches) return;
    let batches = this.request.retailers[0].retailers_batches;
    batches.forEach(b => {
      b.displayName = this.retailerBatchesService.getDisplayName(b, this.request.retailers[0].retailers_contracts);
      this.batches.push(b);
      if (new Date(b.end_at) < this.request.due_date) {
        this.request.due_date = b.end_at;
      }
    });
  }

  onRetailerChange(retailer: Retailer): Promise<void> {
    return new Promise((resolve, reject) => {
      if (retailer) {
        if (this.request.retailers[0].id !== retailer.id)
          this.request.products_batches = [];
        this.request.retailers[0] = retailer;
        // remove older retailer's polygon_specifications:
        this.request.products_polygon_specifications = [];
        this.gql.retailerForProduct({
          id: this.request.retailers[0].id
        }).subscribe(
          res => {
            this.request.retailers[0] = this.utils.deepCopyByValue(res.data.retailers);
            this.onRetailersUpdate();
            resolve()
          }
        );
      } else {
        reject()
      }
      this.setPrice();
      this.initRenderSettings();
      this.setAutoTextureOffersFromRetailer();
    });
  }

  public submitExternalFiles(): Observable<Object> {
    const product = this.productService.preRequest(this.request);
    product.products_resources_submit = true;
    return this.rest.product('put', product);
  }

  getRetailerSettings() {
    let settings = null;
    if (this.request.retailers[this.retailerIndex].retailers_settings[0])
      settings = this.utils.tryParse(this.request.retailers[this.retailerIndex].retailers_settings[0].settings) as RetailersUISettings;
    const defRes = DEFAULT_PREVIEW_DIM;
    const defObj = {
      width: defRes,
      height: defRes
    };
    if (!settings)
      settings = {
        preload: defObj,
        gif: defObj
      };
    if (!settings.preload)
      settings.preload = defObj;
    if (!settings.gif)
      settings.gif = defObj;
    return settings;
  }

  public async putResource(resource: ProductResource) {
    resource.resource_default = this.utils.setUrlParam(resource.resource_default, 'engine', null);
    resource.resource_default = this.utils.setUrlParam(resource.resource_default, 'theme-color', null);
    await this.rest.productResourceUpload('put', resource, `?rid=${resource.id}`).toPromise();
    for (let i = 0; i < this.request.products_resources.length; i++) {
      if (this.request.products_resources[i].id === resource.id) {
        this.request.products_resources[i] = resource;
      }
    }
  }

  getPreset(): AdjustmentsPreset | undefined {
    let preset: AdjustmentsPreset;
    const subCategory = this.request.retailers[this.retailerIndex].retailers_sub_categories?.find(subCategory => subCategory.id === this.request.retailer_sub_category_id);

    if (subCategory)
      preset = subCategory.artists_users_presets.sort((a: AdjustmentsPreset, b: AdjustmentsPreset) => a.sort_index - b.sort_index)[0];
    if (!preset)
      preset = this.request.retailers[this.retailerIndex].artists_users_presets?.sort((a: AdjustmentsPreset, b: AdjustmentsPreset) => a.sort_index - b.sort_index)[0];

    return preset;
  }

  getPreviewResourceType(extension: string): MediaTag {
    extension = extension.replace('.', '').toLowerCase();
    return ['jpg', 'jpeg', 'png', 'webp'].some(type => type === extension) ? MediaTag.IMAGE : extension === 'mp4' ? MediaTag.VIDEO : MediaTag.MODEL;
  }

  uploadExternalResource(file: File, onSuccess: (res: any) => Promise<void>): void {
    const sub = this.resumableUpload.sourceFiles(file);
    sub.pipe(tap(() => this.externalResourcesUploadsInProgress++))
      .subscribe((res: ResumableSubject) => {
        switch (res.state) {
          case ResumableState.ERROR: {
            console.warn(res);
            console.warn('Something went wrong during upload of a specific file, ERROR . . .');
            const data: BroadcasterNotification = {
              text: 'Connection was interrupted, please try again.',
              type: BroadcasterNotificationType.Error,
              action: 'OK'
            };
            this.broadcaster.broadcast('notifyUser', data);
            break;
          }
          case ResumableState.COMPLETE: {
            const payload = {
              uploaded_file_name: file.name,
              uploaded_file_url: res.object.message
            };
            this.rest.afterResource('post', payload, '?upload-only=true').subscribe({ next: onSuccess });
          }
        }
      });
  }

  getDisplayExtName(ext: string) {
    if (ext) {
      ext = ext.toLowerCase();
      if (ext === 'glb' || ext === 'gltf')
        ext = 'glTF'
      else if (ext === '3df')
        ext = 'FBX';
      else {
        ext = ext.toUpperCase();
      }
    }
    return ext;
  }

  initExternalResources(): void {
    this.externalResources = [];
    this.previewResourceItems = [];
    this.request.products_resources.filter(productResource => (productResource.resource_enabled || productResource.uploadedThisSession) && productResource.resource_originator === ProductResourceOriginator.CMS).forEach(productResource => {
      const name = this.utils.getFileName(productResource.resource_default);
      let extension = this.utils.getFileNameExtension(productResource.resource_type === MediaTag.MODEL ? this.utils.getUrlParam(productResource.resource_default, 'load') : productResource.resource_default).toLowerCase();
      this.externalResources.push({
        id: productResource.id,
        base64: productResource.resource_type === MediaTag.IMAGE ? productResource.resource_default : productResource.resource_big || DEFAULT_3D_ICON,
        file: undefined,
        type: productResource.viewer_resource_type,
        name: name.substring(name.lastIndexOf('/') + 1),
        extension: this.getDisplayExtName(extension),
        submitted: productResource.resource_enabled === 1,
        loading: false,
        isInFolder: false
      });
      this.previewResourceItems.push({
        src: productResource.resource_default,
        resourceType: this.getPreviewResourceType(extension),
        big: productResource.resource_big,
        loading: false,
        uuid: this.utils.generateUUID()
      });
    });
  }

  uploadToClientStorage(resourceId) {
    if (!resourceId)
      return;
    let payload = {
      "resource_id": resourceId
    };
    const query = '?rid=' + this.request.retailers[0].id;
    this.rest.uploadToClientStorage('post', payload, query).subscribe(
      () => {
        let obj: BroadcasterNotification = {
          text: "Successed Upload To Client Storage",
          type: BroadcasterNotificationType.Success,
          action: 'OK'
        };
        this.broadcaster.broadcast('notifyUser', obj);
        this.uploadedToClientStorage = true;
      },
      err => this.utils.httpErrorResponseHandler(err, 'failure Upload To Client Storage')
    )
  }

  uploadSourceFileToClientStorage(resourceId) {
    if (!resourceId)
      return;
    let payload = {
      "resource_id": resourceId
    };
    const query = '?rid=' + this.request.retailers[0].id;
    this.rest.uploadSourceFileToClientStorage('post', payload, query).subscribe(
      () => {
        let obj: BroadcasterNotification = {
          text: "Source file uploaded to client storage successfully",
          type: BroadcasterNotificationType.Success,
          action: 'OK'
        };
        this.broadcaster.broadcast('notifyUser', obj);
        this.uploadedSourceFileToClientStorage = true;
      },
      err => this.utils.httpErrorResponseHandler(err, 'failed to upload source file to client storage')
    )
  }

  async initIdb() {
    if (!this.db) {
      this.db = await openDB<ProductDB>('product-request-db', 1, {
        upgrade(db) {
          const store = db.createObjectStore('product-request-table');
          store.createIndex('key', ['itemid'], { unique: false });
        },
      });
    }
  }

  async getDBValue(itemid): Promise<Product> {
    await this.initIdb();
    let row = await this.db.get("product-request-table", itemid);
    return row;
  }

  async deleteDBValue(itemid) {
    await this.initIdb();
    await this.db.delete("product-request-table", itemid);
  }

  async setDBValue(itemid, value) {
    await this.initIdb();
    await this.db.put('product-request-table', value, itemid);
  }

  clear() {
    this.deleteDBValue(ProductRequestService.LAST_NEW_PRODUCT);
    let retailers = this.request.retailers;
    this.resetRequest();
    this.request.retailers = retailers;
    this.productService.editItem = this.request;
    this.setPrice();
    this.setBudget();
    this.initRendersRequests(true);
    this.setBatches();
    this.initRenderSettings();
    this.setAllowDownloads();
    this.initIdentifiers();
    this.initPolygons();
    this.requiredFormats = this.resourceTypesService.mapProductResourceTypeToUi(this.request.products_resources_types);
    this.globals.setHeaer(this.request.name_org_lang, '');
    this.setHeader();
    this.setBreadcrumbs();
    this.isUploadExternalResourcesDirty = false;
    this.externalResourcesUploadsInProgress = 0;
    this.externalResourcesCounter = 0;
    this.externalResources = [];
    this.previewResourceItems = [];
  }
}
