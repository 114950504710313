import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Retailer } from 'src/app/retailer/retailer';
import { BudgetService } from 'src/app/auth/budget.service';
import { ValidState } from 'src/app/shared/utils';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
    selector: 'app-insufficient-funds',
    templateUrl: './insufficient-funds.component.html',
    styleUrls: ['./insufficient-funds.component.scss'],
    standalone: false
})
export class InsufficientFundsComponent implements OnInit {
  public retailer: Retailer;
  public budget: number;
  public price: number;
  public validRetailer: ValidState;
  constructor(
    public dialogRef: MatDialogRef<InsufficientFundsComponent>,
    @Inject(MAT_DIALOG_DATA) public obj: any,
    private budgetService: BudgetService,
    public auth: AuthService
  ) { }

  ngOnInit() {
    this.retailer = this.obj.retailer;
    this.budget = this.obj.budget;
    this.price = this.obj.price;
    this.validRetailer = this.budgetService.canPay(this.retailer);
  }

}
