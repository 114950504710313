import * as i0 from '@angular/core';
import { Injectable, NgModule, EventEmitter } from '@angular/core';
import * as i1 from 'messages-handler';
import { PostToChildOptions as PostToChildOptions$1, MessagesHandlerService } from 'messages-handler';
import { Subject } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
var ImagesFileTypes;
(function (ImagesFileTypes) {
  ImagesFileTypes[ImagesFileTypes["ANY"] = 0] = "ANY";
  ImagesFileTypes[ImagesFileTypes["PNG"] = 1] = "PNG";
  ImagesFileTypes[ImagesFileTypes["JPG"] = 2] = "JPG";
})(ImagesFileTypes || (ImagesFileTypes = {}));
var AssetAdjustmentType;
(function (AssetAdjustmentType) {
  AssetAdjustmentType[AssetAdjustmentType["SLIDER"] = 1] = "SLIDER";
  AssetAdjustmentType[AssetAdjustmentType["SELECT"] = 2] = "SELECT";
  AssetAdjustmentType[AssetAdjustmentType["TOGGLE"] = 3] = "TOGGLE";
  AssetAdjustmentType[AssetAdjustmentType["CUSTOM"] = 4] = "CUSTOM";
  AssetAdjustmentType[AssetAdjustmentType["JOYSTICK"] = 5] = "JOYSTICK";
  AssetAdjustmentType[AssetAdjustmentType["VECTOR3"] = 6] = "VECTOR3";
  AssetAdjustmentType[AssetAdjustmentType["IMAGE_MENU"] = 7] = "IMAGE_MENU";
})(AssetAdjustmentType || (AssetAdjustmentType = {}));
var AssetAdjustmentSector;
(function (AssetAdjustmentSector) {
  AssetAdjustmentSector[AssetAdjustmentSector["SCENE"] = 1] = "SCENE";
  AssetAdjustmentSector[AssetAdjustmentSector["LIGHTING"] = 2] = "LIGHTING";
  AssetAdjustmentSector[AssetAdjustmentSector["MATERIALS"] = 3] = "MATERIALS";
  AssetAdjustmentSector[AssetAdjustmentSector["SHADOW"] = 4] = "SHADOW";
  AssetAdjustmentSector[AssetAdjustmentSector["MESHES"] = 5] = "MESHES";
  AssetAdjustmentSector[AssetAdjustmentSector["ANNOTATIONS"] = 6] = "ANNOTATIONS";
  AssetAdjustmentSector[AssetAdjustmentSector["OPTIMIZATIONS"] = 7] = "OPTIMIZATIONS";
  AssetAdjustmentSector[AssetAdjustmentSector["MESH_ANIMATIONS"] = 8] = "MESH_ANIMATIONS";
  AssetAdjustmentSector[AssetAdjustmentSector["HIERARCHY_TREE"] = 9] = "HIERARCHY_TREE";
  AssetAdjustmentSector[AssetAdjustmentSector["ALIGNMENTS"] = 10] = "ALIGNMENTS";
  AssetAdjustmentSector[AssetAdjustmentSector["POSTPROCESSING"] = 11] = "POSTPROCESSING";
})(AssetAdjustmentSector || (AssetAdjustmentSector = {}));
var AssetAdjustmentPosition;
(function (AssetAdjustmentPosition) {
  AssetAdjustmentPosition[AssetAdjustmentPosition["BEFORE"] = 1] = "BEFORE";
  AssetAdjustmentPosition[AssetAdjustmentPosition["CENTER"] = 2] = "CENTER";
  AssetAdjustmentPosition[AssetAdjustmentPosition["AFTER"] = 3] = "AFTER";
  AssetAdjustmentPosition[AssetAdjustmentPosition["TOP"] = 4] = "TOP";
  AssetAdjustmentPosition[AssetAdjustmentPosition["BOTTOM"] = 5] = "BOTTOM";
})(AssetAdjustmentPosition || (AssetAdjustmentPosition = {}));
class MeshBloomOptions {
  luminanceThreshold;
  luminanceSmoothing;
  resolutionScale;
  width;
  height;
  intensity;
  // kernelSize: number;
  constructor() {
    this.luminanceThreshold = 0.1;
    this.luminanceSmoothing = 0.025;
    this.resolutionScale = 0.5;
    this.width = 360;
    this.height = 360;
    this.intensity = 1;
  }
}
class PlaneOptions {
  color;
  // transparent: boolean;
  opacity;
  reflector;
  side;
  physical;
  active;
  physicalOptions;
  // scene?: any;
  geoSize;
}
var TransparentSort;
(function (TransparentSort) {
  TransparentSort[TransparentSort["Z_INDEX"] = 1] = "Z_INDEX";
  TransparentSort[TransparentSort["REVERSE_PAINTRE_SORT_STABLE"] = 2] = "REVERSE_PAINTRE_SORT_STABLE";
  TransparentSort[TransparentSort["PAINTER_SORT_STABLE"] = 3] = "PAINTER_SORT_STABLE";
})(TransparentSort || (TransparentSort = {}));
class SVGPostprocessingOptions {
  contrast; // 0 - 200
  brightness; // 50 - 250
  saturate; // 0 - 200
  sepia; // 0 - 100
  sharpen; // 0 - 3
  gamma; // 0.5 - 1.5 (deprecated)
  hue; // 0 - 360
  gammaR; // 0.5 - 1.5
  gammaRAmplitude; // 0.5 - 1.5
  gammaROffset; // -0.5 - 0.5
  gammaG; // 0.5 - 1.5
  gammaGAmplitude; // 0.5 - 1.5
  gammaGOffset; // -0.5 - 0.5
  gammaB; // 0.5 - 1.5
  gammaBAmplitude; // 0.5 - 1.5
  gammaBOffset; // -0.5 - 0.5
  gammaA; // -1 - 1
  gammaASlope; // 0.5 - 1.5
  constructor() {
    this.contrast = 100;
    this.brightness = 100;
    this.saturate = 100;
    this.sepia = 0;
    this.sharpen = 0;
    // this.gamma = 1;
    this.hue = 0;
    this.gammaR = 1;
    this.gammaRAmplitude = 1;
    this.gammaROffset = 0;
    this.gammaG = 1;
    this.gammaGAmplitude = 1;
    this.gammaGOffset = 0;
    this.gammaB = 1;
    this.gammaBAmplitude = 1;
    this.gammaBOffset = 0;
    this.gammaA = 0;
    this.gammaASlope = 1;
  }
}
var ThreeTextureWrap;
(function (ThreeTextureWrap) {
  ThreeTextureWrap[ThreeTextureWrap["RepeatWrapping"] = 1000] = "RepeatWrapping";
  ThreeTextureWrap[ThreeTextureWrap["ClampToEdgeWrapping"] = 1001] = "ClampToEdgeWrapping";
  ThreeTextureWrap[ThreeTextureWrap["MirroredRepeatWrapping"] = 1002] = "MirroredRepeatWrapping";
})(ThreeTextureWrap || (ThreeTextureWrap = {}));
var DimensionsUnits;
(function (DimensionsUnits) {
  DimensionsUnits[DimensionsUnits["INCH"] = 1] = "INCH";
  DimensionsUnits[DimensionsUnits["CM"] = 2] = "CM";
})(DimensionsUnits || (DimensionsUnits = {}));
var NotificationType;
(function (NotificationType) {
  NotificationType[NotificationType["Error"] = 0] = "Error";
  NotificationType[NotificationType["Success"] = 1] = "Success";
  NotificationType[NotificationType["Info"] = 2] = "Info";
})(NotificationType || (NotificationType = {}));
class PostToChildOptions {
  stringify;
  constructor(stringify) {
    this.stringify = stringify;
  }
}
var AdjustmentsSourceChangesState;
(function (AdjustmentsSourceChangesState) {
  AdjustmentsSourceChangesState[AdjustmentsSourceChangesState["save"] = 1] = "save";
  AdjustmentsSourceChangesState[AdjustmentsSourceChangesState["discard"] = 2] = "discard";
})(AdjustmentsSourceChangesState || (AdjustmentsSourceChangesState = {}));
var AnnotationType;
(function (AnnotationType) {
  AnnotationType[AnnotationType["HOT_SPOT"] = 1] = "HOT_SPOT";
  AnnotationType[AnnotationType["MESH_TAP"] = 2] = "MESH_TAP";
})(AnnotationType || (AnnotationType = {}));
class BindSphereLightOptions {
  type;
  index;
  options;
}
var RaycasterType;
(function (RaycasterType) {
  RaycasterType[RaycasterType["HIGHLIGHT"] = 1] = "HIGHLIGHT";
  RaycasterType[RaycasterType["SPHERE_CURSOR"] = 2] = "SPHERE_CURSOR";
  RaycasterType[RaycasterType["MESH_ACTIONS"] = 3] = "MESH_ACTIONS";
})(RaycasterType || (RaycasterType = {}));
var GltfValidationSeverity;
(function (GltfValidationSeverity) {
  GltfValidationSeverity[GltfValidationSeverity["Error"] = 0] = "Error";
  GltfValidationSeverity[GltfValidationSeverity["Warning"] = 1] = "Warning";
  GltfValidationSeverity[GltfValidationSeverity["Information"] = 2] = "Information";
  GltfValidationSeverity[GltfValidationSeverity["Hint"] = 3] = "Hint";
})(GltfValidationSeverity || (GltfValidationSeverity = {}));
var RenderSide;
(function (RenderSide) {
  RenderSide[RenderSide["FrontSide"] = 0] = "FrontSide";
  RenderSide[RenderSide["BackSide"] = 1] = "BackSide";
  RenderSide[RenderSide["DoubleSide"] = 2] = "DoubleSide";
})(RenderSide || (RenderSide = {}));
class CompressedFilesState {
  started;
  ended;
  totalBytesStart;
  totalBytesEnd;
  totalBytesSaved;
  totalPercentage;
  constructor() {
    this.started = 0;
    this.ended = 0;
    this.totalBytesStart = 0;
    this.totalBytesEnd = 0;
    this.totalBytesSaved = 0;
    this.totalPercentage = 0;
  }
}
var ConsoleMessageType;
(function (ConsoleMessageType) {
  ConsoleMessageType[ConsoleMessageType["ERROR"] = 1] = "ERROR";
  ConsoleMessageType[ConsoleMessageType["WARNING"] = 2] = "WARNING";
})(ConsoleMessageType || (ConsoleMessageType = {}));
var ThreeMinificationFilters;
(function (ThreeMinificationFilters) {
  ThreeMinificationFilters[ThreeMinificationFilters["NearestFilter"] = 1003] = "NearestFilter";
  ThreeMinificationFilters[ThreeMinificationFilters["NearestMipmapNearestFilter"] = 1004] = "NearestMipmapNearestFilter";
  ThreeMinificationFilters[ThreeMinificationFilters["NearestMipmapLinearFilter"] = 1005] = "NearestMipmapLinearFilter";
  ThreeMinificationFilters[ThreeMinificationFilters["LinearFilter"] = 1006] = "LinearFilter";
  ThreeMinificationFilters[ThreeMinificationFilters["LinearMipmapNearestFilter"] = 1007] = "LinearMipmapNearestFilter";
  ThreeMinificationFilters[ThreeMinificationFilters["LinearMipmapLinearFilter"] = 1008] = "LinearMipmapLinearFilter";
})(ThreeMinificationFilters || (ThreeMinificationFilters = {}));
var RenderingEngine;
(function (RenderingEngine) {
  RenderingEngine[RenderingEngine["THREE"] = 1] = "THREE";
  RenderingEngine[RenderingEngine["BABYLON"] = 2] = "BABYLON";
  RenderingEngine[RenderingEngine["PLAYCANVAS"] = 3] = "PLAYCANVAS";
})(RenderingEngine || (RenderingEngine = {}));
var RenderTechnology;
(function (RenderTechnology) {
  RenderTechnology[RenderTechnology["WebGL"] = 1] = "WebGL";
  RenderTechnology[RenderTechnology["WebGPU"] = 2] = "WebGPU";
})(RenderTechnology || (RenderTechnology = {}));
var AmazonPresets;
(function (AmazonPresets) {
  AmazonPresets[AmazonPresets["DIRECTIONAL"] = 1] = "DIRECTIONAL";
  AmazonPresets[AmazonPresets["CHROMATIC"] = 2] = "CHROMATIC";
  AmazonPresets[AmazonPresets["NEUTRAL"] = 3] = "NEUTRAL";
  AmazonPresets[AmazonPresets["AMBIENT"] = 4] = "AMBIENT";
})(AmazonPresets || (AmazonPresets = {}));
var CLAMPING_MODE;
(function (CLAMPING_MODE) {
  CLAMPING_MODE[CLAMPING_MODE["GRADUAL"] = 0] = "GRADUAL";
  CLAMPING_MODE[CLAMPING_MODE["DISTINCT"] = 1] = "DISTINCT";
})(CLAMPING_MODE || (CLAMPING_MODE = {}));
class MeshFurOptions {
  furLength;
  furAngle;
  furColor;
  diffuseTexture;
  furSpacing;
  furDensity;
  furSpeed;
  // furDisplacement: number;
  furGravity;
  furOffset;
  furOcclusion;
  quality;
  opacity;
  constructor() {
    this.furLength = .005;
    this.furAngle = 0;
    this.furColor = '#ffffff';
    this.diffuseTexture = '';
    this.furSpacing = .005;
    this.furDensity = 10;
    this.furSpeed = 0.2;
    // this.furDisplacement = 3;
    this.furGravity = 1;
    this.furOffset = 0.01;
    this.furOcclusion = 0.5;
    this.quality = 30;
    this.opacity = 1;
  }
}
var MAPS_DICTIONARY = {
  aoMap: {
    defaultIntensity: 1,
    maxIntensity: 1,
    restore: true,
    imageManipulation: true,
    defaultManipulationValue: 1,
    maxManipulationValue: 2,
    upscaleTexture: true
  },
  emissiveMap: {
    defaultIntensity: 1,
    maxIntensity: 3,
    hasColor: true,
    imageManipulation: true,
    defaultManipulationValue: 1,
    maxManipulationValue: 2,
    upscaleTexture: true
  },
  thicknessMap: {
    defaultIntensity: 0,
    maxIntensity: 3,
    hasColor: false,
    imageManipulation: true,
    defaultManipulationValue: 1,
    maxManipulationValue: 2,
    upscaleTexture: true
  },
  transmissionMap: {
    defaultIntensity: 0,
    maxIntensity: 1,
    hasColor: false,
    imageManipulation: true,
    defaultManipulationValue: 1,
    maxManipulationValue: 2,
    upscaleTexture: true
  },
  sheenColorMap: {
    defaultIntensity: 0,
    maxIntensity: 1,
    hasColor: true,
    imageManipulation: true,
    defaultManipulationValue: 1,
    maxManipulationValue: 2,
    upscaleTexture: true
  },
  sheenRoughnessMap: {
    defaultIntensity: 1,
    maxIntensity: 1,
    hasColor: false,
    imageManipulation: true,
    defaultManipulationValue: 1,
    maxManipulationValue: 2,
    upscaleTexture: true
  },
  envMap: {
    maxIntensity: 5
  },
  lightMap: {
    maxIntensity: 1,
    upscaleTexture: true
  },
  specularMap: {
    maxIntensity: 100,
    upscaleTexture: true
  },
  roughnessMap: {
    defaultValue: 1,
    maxIntensity: 3,
    restore: true,
    imageManipulation: true,
    defaultManipulationValue: 1,
    maxManipulationValue: 2,
    upscaleTexture: true
  },
  metalnessMap: {
    defaultValue: 1,
    maxIntensity: 3,
    restore: true,
    imageManipulation: true,
    defaultManipulationValue: 1,
    maxManipulationValue: 2,
    upscaleTexture: true
  },
  map: {
    // defaultValue: 1,
    // maxIntensity: 1,
    restore: true,
    imageManipulation: true,
    defaultManipulationValue: 1,
    maxManipulationValue: 2,
    // videoEnabled: true,
    minFilter: true,
    sprite: true,
    // hue: true,
    // defaultHueValue: 0
    alias: 'base color map',
    upscaleTexture: true
  },
  normalMap: {
    // defaultValue: 1,
    maxIntensity: 3,
    restore: true,
    // rgb: true,
    imageManipulation: true,
    defaultManipulationValue: 1,
    maxManipulationValue: 2,
    upscaleTexture: true
  }
};
var MAX_PREVIEW_SIZE = 30;
const THREE_LATEST_VERSION = '172';
const BABYLON_LATEST_VERSION = '7.8.2';
class EnumsService {
  getMaterialTypes() {
    return [{
      key: 'MeshPhysicalMaterial',
      value: 'Mesh Physical Material'
    }, {
      key: 'MeshStandardMaterial',
      value: 'Mesh Standard Material'
    }, {
      key: 'MeshPhongMaterial',
      value: 'Mesh Phong Material'
    }, {
      key: 'MeshBasicMaterial',
      value: 'Mesh Basic Material'
    }
    // { key: 'MeshToonMaterial', value: 'Mesh Toon Material' },
    // { key: 'PointsMaterial', value: 'Points Material' },
    // { key: 'RawShaderMaterial', value: 'Raw Shader Material' },
    // { key: 'ShaderMaterial', value: 'Shader Material' },
    // { key: 'ShadowMaterial', value: 'Shadow Material' },
    // { key: 'SpriteMaterial', value: 'Sprite Material' },
    // { key: 'LineBasicMaterial', value: 'Line Basic Material' },
    // { key: 'LineDashedMaterial', value: 'Line Dashed Material' },
    // { key: 'Material', value: 'Material' }
    ];
  }
  getTexturesSizes() {
    return [{
      value: '512',
      key: 512
    }, {
      value: '1K',
      key: 1024
    }, {
      value: '2K',
      key: 2048
    }, {
      value: '4K',
      key: 4096
    }];
  }
  getHDRIFormats() {
    return [{
      value: 'JPEG',
      key: 'jpg'
    }, {
      value: 'HDR',
      key: 'hdr'
    }, {
      value: 'Ultra-HDR',
      key: 'ultra-hdr'
    }];
  }
  getToneMappings(engine) {
    switch (engine) {
      case RenderingEngine.THREE:
        {
          return [
          // { value: 'No Tone Mapping', key: 0 },
          {
            value: 'Linear Tone Mapping',
            key: 1
          }, {
            value: 'Reinhard Tone Mapping',
            key: 2
          }, {
            value: 'Cineon Tone Mapping',
            key: 3
          }, {
            value: 'ACES Filmic Tone Mapping',
            key: 4
          }, {
            value: 'AgX Tone Mapping',
            key: 6
          }, {
            value: 'Neutral Tone Mapping',
            key: 7
          }, {
            value: 'Custom Tone Mapping',
            key: 5
          }];
        }
      case RenderingEngine.BABYLON:
        {
          return [{
            value: 'Standard Tone Mapping',
            key: 0
          }, {
            value: 'ACES Filmic Tone Mapping',
            key: 1
          }, {
            value: 'Neutral Tone Mapping',
            key: 2
          }];
        }
      case RenderingEngine.PLAYCANVAS:
        {
          return [{
            value: 'Linear Tone Mapping',
            key: 0
          }, {
            value: 'Filmic Tone Mapping',
            key: 1
          }, {
            value: 'HEJL Tone Mapping',
            key: 2
          }, {
            value: 'ACES Tone Mapping',
            key: 3
          }, {
            value: 'ACES 2 Tone Mapping',
            key: 4
          }, {
            value: 'Neutral Tone Mapping',
            key: 5
          }];
        }
    }
    return [];
  }
  getHDRIPrev() {
    return {
      18: 'https://img-cdn.azureedge.net/hotlink-ok/hdri/18/preview_small.jpg',
      19: 'https://img-cdn.azureedge.net/hotlink-ok/hdri/19/preview_small.jpg',
      20: 'https://img-cdn.azureedge.net/hotlink-ok/hdri/20/preview_small.jpg',
      21: 'https://img-cdn.azureedge.net/hotlink-ok/hdri/21/preview_small.jpg',
      5: 'https://img-cdn.azureedge.net/hotlink-ok/hdri/5/preview_small.jpg',
      6: 'https://img-cdn.azureedge.net/hotlink-ok/hdri/6/preview_small.jpg',
      7: 'https://img-cdn.azureedge.net/hotlink-ok/hdri/7/preview_small.jpg',
      8: 'https://img-cdn.azureedge.net/hotlink-ok/hdri/8/preview_small.jpg',
      9: 'https://img-cdn.azureedge.net/hotlink-ok/hdri/9/preview_small.jpg',
      10: 'https://img-cdn.azureedge.net/hotlink-ok/hdri/10/preview_small.jpg',
      11: 'https://img-cdn.azureedge.net/hotlink-ok/hdri/11/preview_small.jpg',
      14: 'https://img-cdn.azureedge.net/hotlink-ok/hdri/14/preview_small.jpg',
      15: 'https://img-cdn.azureedge.net/hotlink-ok/hdri/15/preview_small.jpg',
      16: 'https://img-cdn.azureedge.net/hotlink-ok/hdri/16/preview_small.jpg',
      22: 'https://img-cdn.azureedge.net/hotlink-ok/hdri/22/preview_small.jpg',
      23: 'https://img-cdn.azureedge.net/hotlink-ok/hdri/23/preview_small.jpg',
      24: 'https://img-cdn.azureedge.net/hotlink-ok/hdri/24/preview_small.jpg',
      25: 'https://img-cdn.azureedge.net/hotlink-ok/hdri/25/preview_small.jpg',
      26: 'https://img-cdn.azureedge.net/hotlink-ok/hdri/26/preview_small.jpg',
      27: 'https://img-cdn.azureedge.net/hotlink-ok/hdri/27/preview_small.jpg',
      28: 'https://img-cdn.azureedge.net/hotlink-ok/hdri/28/preview_small.jpg',
      29: 'https://img-cdn.azureedge.net/hotlink-ok/hdri/29/preview_small.jpg',
      30: 'https://img-cdn.azureedge.net/hotlink-ok/hdri/30/preview_small.jpg',
      31: 'https://img-cdn.azureedge.net/hotlink-ok/hdri/31/preview_small.jpg',
      32: 'https://img-cdn.azureedge.net/hotlink-ok/hdri/32/preview_small.jpg'
    };
  }
  getHDRIFull() {
    return {
      18: 'https://img-cdn.azureedge.net/hotlink-ok/hdri/18/preview.jpg',
      19: 'https://img-cdn.azureedge.net/hotlink-ok/hdri/19/preview.png',
      20: 'https://img-cdn.azureedge.net/hotlink-ok/hdri/20/preview.jpg',
      21: 'https://img-cdn.azureedge.net/hotlink-ok/hdri/21/preview.jpg',
      5: 'https://img-cdn.azureedge.net/hotlink-ok/hdri/5/preview.jpg',
      6: 'https://img-cdn.azureedge.net/hotlink-ok/hdri/6/preview.jpg',
      7: 'https://img-cdn.azureedge.net/hotlink-ok/hdri/7/preview.jpg',
      8: 'https://img-cdn.azureedge.net/hotlink-ok/hdri/8/preview.jpg',
      9: 'https://img-cdn.azureedge.net/hotlink-ok/hdri/9/preview.jpg',
      10: 'https://img-cdn.azureedge.net/hotlink-ok/hdri/10/preview.jpg',
      11: 'https://img-cdn.azureedge.net/hotlink-ok/hdri/11/preview.jpg',
      14: 'https://img-cdn.azureedge.net/hotlink-ok/hdri/14/preview.jpg',
      15: 'https://img-cdn.azureedge.net/hotlink-ok/hdri/15/preview.jpg',
      16: 'https://img-cdn.azureedge.net/hotlink-ok/hdri/16/preview.jpg',
      22: 'https://img-cdn.azureedge.net/hotlink-ok/hdri/22/preview.jpg',
      23: 'https://img-cdn.azureedge.net/hotlink-ok/hdri/23/preview.jpg',
      24: 'https://img-cdn.azureedge.net/hotlink-ok/hdri/24/preview.jpg',
      25: 'https://img-cdn.azureedge.net/hotlink-ok/hdri/25/preview.jpg',
      26: 'https://img-cdn.azureedge.net/hotlink-ok/hdri/26/preview.jpg',
      27: 'https://img-cdn.azureedge.net/hotlink-ok/hdri/27/preview.jpg',
      28: 'https://img-cdn.azureedge.net/hotlink-ok/hdri/28/preview.jpg',
      29: 'https://img-cdn.azureedge.net/hotlink-ok/hdri/29/preview.jpg',
      30: 'https://img-cdn.azureedge.net/hotlink-ok/hdri/30/preview.jpg',
      31: 'https://img-cdn.azureedge.net/hotlink-ok/hdri/31/preview.jpg',
      32: 'https://img-cdn.azureedge.net/hotlink-ok/hdri/32/preview.jpg'
    };
  }
  getHDRs() {
    return [{
      value: 'yellow',
      key: 5
    }, {
      value: 'blue',
      key: 6
    },
    // { value: 'blue green', key: 7 },
    {
      value: 'white',
      key: 8
    }, {
      value: 'wood',
      key: 9
    }, {
      value: 'black',
      key: 10
    }, {
      value: 'off-white',
      key: 11
    },
    // { value: 'space-blue', key: 12 },
    {
      value: 'bronze',
      key: 14
    }, {
      value: 'silver',
      key: 15
    }, {
      value: 'gold',
      key: 16
    }, {
      value: '2019 white',
      key: 18
    }, {
      value: '2019 silver',
      key: 19
    }, {
      value: '2019 black',
      key: 20
    }, {
      value: '2019 yellow',
      key: 21
    }, {
      value: 'white to black',
      key: 22
    }, {
      value: 'black white & blue',
      key: 23
    }, {
      value: 'neutral',
      key: 24
    }, {
      value: 'amazon chromatic',
      key: 25
    }, {
      value: 'amazon directional',
      key: 26
    }, {
      value: 'amazon neutral',
      key: 27
    }, {
      value: 'wannaby',
      key: 28
    }, {
      value: 'wannaby bags',
      key: 29
    }, {
      value: 'zound dark',
      key: 30
    }, {
      value: 'street',
      key: 31
    }, {
      value: 'apple neutral',
      key: 32
    }];
  }
  getSidesRender(keyAsText) {
    return [{
      value: 'Front Side',
      key: keyAsText ? 'FrontSide' : RenderSide.FrontSide
    }, {
      value: 'Back Side',
      key: keyAsText ? 'BackSide' : RenderSide.BackSide
    }, {
      value: 'Double Side',
      key: keyAsText ? 'DoubleSide' : RenderSide.DoubleSide
    }];
  }
  getTransparentRenderOrder() {
    return [{
      value: 'Z Index',
      key: 1
    }, {
      value: 'Reverse Camera Proximity',
      key: 2
    }, {
      value: 'Camera Proximity',
      key: 3
    }];
  }
  getTextureWrapTypes() {
    return [{
      value: 'Clamp To Edge Wrapping',
      key: ThreeTextureWrap.ClampToEdgeWrapping
    }, {
      value: 'Mirrored Repeat Wrapping',
      key: ThreeTextureWrap.MirroredRepeatWrapping
    }, {
      value: 'Repeat Wrapping',
      key: ThreeTextureWrap.RepeatWrapping
    }];
  }
  getMinificationFilters() {
    return [{
      value: 'Nearest',
      key: ThreeMinificationFilters.NearestFilter
    }, {
      value: 'Nearest Mipmap',
      key: ThreeMinificationFilters.NearestMipmapNearestFilter
    }, {
      value: 'Nearest Mipmap Linear',
      key: ThreeMinificationFilters.NearestMipmapLinearFilter
    }, {
      value: 'Linear',
      key: ThreeMinificationFilters.LinearFilter
    }, {
      value: 'Linear Mipmap Nearest',
      key: ThreeMinificationFilters.LinearMipmapNearestFilter
    }, {
      value: 'Linear Mipmap',
      key: ThreeMinificationFilters.LinearMipmapLinearFilter
    }];
  }
  getLightsTypes() {
    return [{
      value: 'point',
      key: 'PointLight'
    }, {
      value: 'hemisphere',
      key: 'HemisphereLight'
    }, {
      value: 'directional',
      key: 'DirectionalLight'
    }, {
      value: 'ambient',
      key: 'AmbientLight'
    }, {
      value: 'spot',
      key: 'SpotLight'
    }, {
      value: 'rectarea',
      key: 'RectAreaLight'
    }];
  }
  getThreeJsVersions() {
    return [{
      value: '110',
      key: 110
    }, {
      value: '112',
      key: 112
    }, {
      value: '120',
      key: 120
    }, {
      value: '126',
      key: 126
    }, {
      value: '129',
      key: 129
    }, {
      value: '131',
      key: 131
    },
    // { value: '134', key: 134 },
    {
      value: '135',
      key: 135
    }, {
      value: '147',
      key: 147
    }, {
      value: '149',
      key: 149
    }, {
      value: '154',
      key: 154
    }, {
      value: '155',
      key: 155
    }, {
      value: '161',
      key: 161
    }, {
      value: '162',
      key: 162
    }, {
      value: '163',
      key: 163
    }, {
      value: '164',
      key: 164
    }, {
      value: '165',
      key: 165
    }, {
      value: '168',
      key: 168
    }, {
      value: '170',
      key: 170
    }, {
      value: '172',
      key: 172
    }];
  }
  getBabylonJsVersions() {
    return ['3.3.0', '4.2.1', '5.13.0', '5.29.0', '6.6.0', '6.12.0', '7.4.0', '7.8.2'];
  }
  getEngines() {
    return [{
      value: 'three.js',
      key: RenderingEngine.THREE
    }, {
      value: 'babylon.js',
      key: RenderingEngine.BABYLON
    }, {
      value: 'playcanvas',
      key: RenderingEngine.PLAYCANVAS
    }];
  }
  getRenderTechnologies() {
    return [{
      value: 'WebGL',
      key: RenderTechnology.WebGL
    }, {
      value: 'WebGPU',
      key: RenderTechnology.WebGPU
    }];
  }
  static ɵfac = function EnumsService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || EnumsService)();
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: EnumsService,
    factory: EnumsService.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(EnumsService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();
class UtilsService {
  safeParse(val) {
    try {
      return JSON.parse(val);
    } catch (e) {
      return val;
    }
  }
  getParamsFromUrl(url) {
    var params = {};
    var parser = document.createElement('a');
    parser.href = url;
    var query = parser.search.substring(1);
    var vars = query.split('&');
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split('=');
      params[pair[0]] = decodeURIComponent(pair[1]);
    }
    return params;
  }
  getUrlParam(url, name) {
    if (!url || url.indexOf('?') == -1) return url;
    let paramsString = url.substring(url.indexOf('?') + 1);
    let searchParams = new URLSearchParams(paramsString);
    return searchParams.get(name);
  }
  setUrlParam(url, name, value) {
    if (!url || url.indexOf('?') == -1) return url;
    let paramsString = url.substring(url.indexOf('?') + 1);
    let searchParams = new URLSearchParams(paramsString);
    // if (isNaN(parseInt(value)))
    //   value = null;
    if (typeof value === 'number' || typeof value === 'string') searchParams.set(name, value);else searchParams.delete(name);
    return url.substring(0, url.indexOf('?') + 1) + searchParams.toString();
  }
  parseToArr(val, returnDefault = false) {
    if (val instanceof Array) return val;
    let res = [];
    if (typeof val === 'string' && val) {
      try {
        res = JSON.parse(val);
        if (!returnDefault && res.length == 0) res = null;
      } catch (e) {
        res = val.split(',');
      }
    }
    if (!(res instanceof Array)) {
      res = [res];
    }
    return res;
  }
  getFileTypeBy(t) {
    switch (t) {
      case ImagesFileTypes.ANY:
        {
          return null;
        }
      case ImagesFileTypes.JPG:
        {
          return 'image/jpeg';
        }
      case ImagesFileTypes.PNG:
        {
          return 'image/png';
        }
      default:
        {
          return null;
        }
    }
  }
  getColor(color) {
    let getP = obtained => {
      let res = Math.round(obtained * 255).toString(16);
      if (res.length < 2) res = '0' + res;
      return res;
    };
    if (color) {
      return '#' + getP(color.r) + getP(color.g) + getP(color.b);
    }
    return '#ffffff';
  }
  isFloat(n) {
    return Number(n) === n && n % 1 !== 0;
  }
  trimIfFloat(float, trim = 4) {
    if (this.isFloat(float)) return parseFloat(float.toFixed(trim));
    return float;
  }
  deepCopyByValue(src, onlyInextensible = false) {
    try {
      if (onlyInextensible && Object.isExtensible(src)) return src;
      return JSON.parse(JSON.stringify(src));
    } catch (e) {
      return null;
    }
  }
  getAllActions() {
    return ['viewerFullyLoaded', 'onModelLoaded', 'setMaterials', 'setDistance', 'setExposure', 'setBoundingBox', 'setTextures', 'onMapTextureToMaterialDone', 'setMeshesData', 'setModel', 'setUVsOverlap', 'subdivisionDone', 'simplifyDone', 'setMainMesh', 'setCameraInfo', 'setSceneSummary', 'onMaterialManipulations', 'viewerLoaded', 'setSelectedMesh', 'setMaterialManipulations', 'onGltfValidation', 'onModelLoadError', 'setViewerFullyLoaded', 'onConsole', 'setCurrentScreenshot', 'onPngCompress', 'onLightsSummary', 'setTexturesAnimations', 'setPolyCount', 'setPolyTypeDistribution', 'onPosition', 'setMeshAnimations', 'setShadowPlane', 'setSceneHierarchy', 'setNumOfTransparentMaterials', 'onMultipleUV', 'setOverlapMeshesNames', 'setBloomEffectOptions', 'setFurEffectOptions', 'setAnnotations', 'notifyUser', 'viewerListening', 'onAnimateEnterEnd', 'setDiffuseThreshold', 'setFixImageThreshold', 'setCollisions', 'setAdjustImageRGB', 'setRevertImageRGB', 'onControlsPositionEnd', 'setSVGPostprocessing', 'setMeshActions']; //, 'setMeshManipulations'
  }
  base64MimeType(encoded) {
    let result = null;
    if (typeof encoded !== 'string') return result;
    var mime = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);
    if (mime && mime.length) result = mime[1];
    return result;
  }
  resizeImage(src, dim) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = event => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.width = dim;
        canvas.height = dim;
        ctx.drawImage(img, 0, 0, dim, dim);
        resolve(canvas.toDataURL(this.base64MimeType(src)));
      };
      img.src = src;
    });
  }
  cancelEvent(e) {
    if (!e) e = window.event;
    try {
      e.preventDefault();
    } catch (e) {}
    try {
      e.stopPropagation();
    } catch (e) {}
    try {
      e.stopImmediatePropagation();
    } catch (e) {}
    return false;
  }
  static ɵfac = function UtilsService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || UtilsService)();
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: UtilsService,
    factory: UtilsService.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(UtilsService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();
class AssetAdjustmentsService {
  mhService;
  enums;
  utils;
  static MESH_MANIPULATIONS_RELEVANT_FIELDS = ['ignoreBB', 'castShadow', 'receiveShadow', 'bloom', 'fur'];
  static LEGACY_PARAMS = ['hdr', 'hdrbg', 'hdr-blur', 'hdr-format', 'hdr-intensity', 'le-probe', 'wireframe', 'shaded-wireframe', 'xray', 'phcl', 'mesh-id', 'grid', 'tsl', 'exp', 'fov', 'dimensions'];
  parameters = [];
  materials;
  materialMaps;
  materialTypes;
  texturesSizes;
  materialManipulations;
  _meshManipulations;
  meshAnimations;
  _shadowPlaneOptions;
  _hdri;
  _params;
  _annotations;
  _meshActions;
  _collisions;
  _collisionsData;
  _svgPostprocessing;
  hasMeshAnimations;
  sceneLights;
  texturesAnimations;
  materialManipulationsRevived;
  texturedMaterials;
  activeTextures; // textures with images or intensity
  activeTexturesBackup;
  texturesWithImages;
  textures;
  currentMaterialIndex;
  currentTextureIndex;
  boundingBox;
  mainMesh;
  selectedMesh;
  selectedLight;
  mapping;
  counter;
  LightingCounter;
  cameraPosition;
  modelPivot;
  modelRotation;
  loading;
  _meshesData;
  materialAccordion;
  currentTabIndex;
  showOutline;
  sceneUnits;
  imagesCompressionFactor;
  imagesType;
  normalImagesType;
  mroImagesType;
  ignoreChanges;
  frameEventsAttached;
  lastFrameSrc;
  mapsArr;
  texturesSrcs;
  annotationImgSrcs;
  videoTexturesSrcs;
  iframeModel;
  embed;
  viewerType;
  resourceType;
  jsonParams;
  changeSrc;
  createdAt;
  changeBB;
  UVsOverlapCount;
  subdivisionProgress;
  simplifyProgress;
  subdivisionFactor;
  simplifyFactor;
  polyTypeProgress;
  quantizeNormalBits;
  quantizeTexcoordBits;
  dracoCompressionLevel;
  scaleFactor;
  meshCompress;
  imageCompress;
  maxTexturesSize;
  maxDiffuseTexturesSize;
  maxNormalTexturesSize;
  maxMroTexturesSize;
  useOptipng;
  exportedQuery;
  refreshMaterials;
  refreshTextures;
  texturedMaterialsMap;
  binary;
  broadcaster;
  pixel;
  targetResourceType;
  animatedState;
  simplifyGltf;
  deleteUV2Gltf;
  meshBloomOptions;
  meshFurOptions;
  _mappingChanged;
  _meshChanged;
  _materialChanged;
  _textureImageChanged;
  _hasSoloLights;
  _soloLights;
  _childrenParentDictionary;
  _compressedFiles;
  _gltfValidationResponse;
  _modelLoadError;
  materialManipulationsChanges;
  meshManipulationsChanges;
  lightsChanges;
  _initEmbeddedLights;
  // _isViewerReady means a model has been fully loaded
  _isViewerReady;
  // _isViewerLoaded means the viewer is ready but no model has been necessarily loaded
  _isViewerLoaded;
  _textureToMaterialTasks;
  lightsSummarySent;
  resolveAfterSetLights;
  resolveAfterSetMaterialManipulations;
  resolveAfterSetSceneSummary;
  resolveAfterSetSVGPostprocessing;
  _lastLights;
  _polyCount;
  _polyTypeDistribution;
  onPosition;
  onViewerListening;
  onSelecteMeshByName;
  onGetMeshesData;
  _onSetMaterials;
  _sceneHierarchy;
  onNumOfTransparentMaterials;
  onFixImageThreshold;
  onAdjustImageRGB;
  onRevertImageRGB;
  onControlsPositionEnd;
  onSetDiffuseThreshold;
  _onViewerFullyLoaded;
  _onAutoAdjustScene;
  _onTextures;
  _numOfBloomMeshes;
  _numOfFurMeshes;
  _orgSrc;
  _threeVersion;
  _babylonVersion;
  _engine;
  _tech;
  _imageThresholdFixed;
  constructor(mhService, enums, utils) {
    this.mhService = mhService;
    this.enums = enums;
    this.utils = utils;
    this._childrenParentDictionary = {};
    this.animatedState = {};
    this.texturedMaterialsMap = {};
    this.onPosition = [];
    this.onViewerListening = [];
    this.onSelecteMeshByName = [];
    this.onGetMeshesData = [];
    this._onSetMaterials = [];
    this.refreshMaterials = true;
    this.refreshTextures = true;
    this.binary = true;
    this._isViewerReady = false;
    this._isViewerLoaded = false;
    this.lightsSummarySent = false;
    this._textureToMaterialTasks = 0;
    this.simplifyFactor = 0.1;
    this.subdivisionFactor = 1;
    this.quantizeNormalBits = 15;
    this.quantizeTexcoordBits = 12;
    this.dracoCompressionLevel = 7;
    this.scaleFactor = 1.0;
    this.soloLights = {};
    this.resolveAfterSetLights = [];
    this.resolveAfterSetMaterialManipulations = [];
    this.resolveAfterSetSceneSummary = [];
    this.resolveAfterSetSVGPostprocessing = [];
    this.onNumOfTransparentMaterials = [];
    this.onFixImageThreshold = [];
    this.onAdjustImageRGB = [];
    this.onRevertImageRGB = [];
    this.onControlsPositionEnd = [];
    this.onSetDiffuseThreshold = [];
    this._onViewerFullyLoaded = [];
    this._onAutoAdjustScene = [];
    this._onTextures = [];
    this._numOfBloomMeshes = 0;
    this._numOfFurMeshes = 0;
    this._imageThresholdFixed = false;
    // delete this.maxTexturesSize;
    // delete this.maxDiffuseTexturesSize;
    // delete this.maxNormalTexturesSize;
    // delete this.maxMroTexturesSize;
    // delete this.imagesType;
    // delete this.normalImagesType;
    // delete this.mroImagesType;
    // delete this.useOptipng;
    // delete this.simplifyGltf;
    // delete this.deleteUV2Gltf;
    // delete this._polyCount;
    // delete this._polyTypeDistribution;
    // delete this._sceneHierarchy;
    this.texturesAnimations = {};
    this.meshCompress = false;
    this.imageCompress = false;
    this._compressedFiles = new CompressedFilesState();
    this.meshBloomOptions = new MeshBloomOptions();
    this.meshFurOptions = new MeshFurOptions();
    this.modelPivot = {
      value: {
        x: null,
        y: null,
        z: null
      },
      name: 'pivot position',
      action: 'fixModelPivot',
      urlParam: 'model-pivot',
      type: AssetAdjustmentType.CUSTOM,
      sector: AssetAdjustmentSector.SCENE
    };
    this.modelRotation = {
      value: {
        x: null,
        y: null,
        z: null
      },
      degree: {
        x: null,
        y: null,
        z: null
      },
      name: 'pivot rotation',
      action: 'fixModelRotation',
      urlParam: 'model-rotation',
      min: 0,
      max: 360,
      step: 1,
      type: AssetAdjustmentType.JOYSTICK,
      sector: AssetAdjustmentSector.SCENE
    };
    this.materialTypes = this.enums.getMaterialTypes();
    this.texturesSizes = this.enums.getTexturesSizes();
    this.broadcaster = new Subject();
    this.pixel = new Subject();
  }
  get orgSrc() {
    if (!this._orgSrc && this.iframeModel && this.iframeModel.nativeElement) this._orgSrc = this.iframeModel.nativeElement.src;
    return this._orgSrc;
  }
  get numOfBloomMeshes() {
    return this._numOfBloomMeshes;
  }
  set numOfBloomMeshes(value) {
    this._numOfBloomMeshes = value;
  }
  get numOfFurMeshes() {
    return this._numOfFurMeshes;
  }
  set numOfFurMeshes(value) {
    this._numOfFurMeshes = value;
  }
  get meshesData() {
    return this._meshesData;
  }
  set meshesData(value) {
    this._meshesData = value;
    if (this._meshesData) {
      setTimeout(() => {
        if (this._meshesData) {
          Object.values(this._meshesData).forEach(md => {
            if (md.rotation) {
              if (!md.rotationDegree) md.rotationDegree = {};
              md.rotationDegree.x = parseFloat(md.rotation.x.toString()) / (Math.PI / 180);
              md.rotationDegree.y = parseFloat(md.rotation.y.toString()) / (Math.PI / 180);
              md.rotationDegree.z = parseFloat(md.rotation.z.toString()) / (Math.PI / 180);
            }
          });
        }
      });
    }
  }
  get meshManipulations() {
    return this._meshManipulations;
  }
  set meshManipulations(value) {
    this._meshManipulations = value;
  }
  get sceneHierarchy() {
    return this._sceneHierarchy;
  }
  get mappingChanged() {
    return this._mappingChanged;
  }
  set mappingChanged(value) {
    this._mappingChanged = value;
  }
  get meshChanged() {
    return this._meshChanged;
  }
  get materialChanged() {
    return this._materialChanged;
  }
  get textureImageChanged() {
    return this._textureImageChanged;
  }
  get polyCount() {
    return this._polyCount;
  }
  get polyTypeDistribution() {
    return this._polyTypeDistribution;
  }
  get childrenParentDictionary() {
    return this._childrenParentDictionary;
  }
  get compressedFiles() {
    return this._compressedFiles;
  }
  get isViewerReady() {
    return this._isViewerReady;
  }
  get isViewerLoaded() {
    return this._isViewerLoaded;
  }
  get textureToMaterialTasks() {
    return this._textureToMaterialTasks;
  }
  get soloLights() {
    return this._soloLights;
  }
  set soloLights(value) {
    this._soloLights = value;
  }
  get threeVersion() {
    return this._threeVersion;
  }
  set threeVersion(value) {
    this._threeVersion = value;
    this.iframeModel.nativeElement.src = this.utils.setUrlParam(this.iframeModel.nativeElement.src, 'tv', String(this._threeVersion));
  }
  get babylonVersion() {
    return this._babylonVersion;
  }
  set babylonVersion(value) {
    this._babylonVersion = value;
    this.iframeModel.nativeElement.src = this.utils.setUrlParam(this.iframeModel.nativeElement.src, 'bv', this._babylonVersion);
  }
  get engine() {
    return this._engine;
  }
  set engine(value) {
    this._engine = value;
    this.iframeModel.nativeElement.src = this.utils.setUrlParam(this.iframeModel.nativeElement.src, 'engine', String(this._engine));
  }
  get tech() {
    return this._tech;
  }
  set tech(value) {
    this._tech = value;
    this.iframeModel.nativeElement.src = this.utils.setUrlParam(this.iframeModel.nativeElement.src, 'rt', String(this._tech));
  }
  getParent(name, childrenParentDictionary = this._childrenParentDictionary) {
    for (let i in childrenParentDictionary) {
      let child = childrenParentDictionary[i].find(p => p.name == name);
      if (child) return child;
    }
    return null;
  }
  refreshTexturedMaterialsMap() {
    this.texturedMaterialsMap = {};
    let getTexturedMaterialsByMap = (material, mapKey) => {
      if (material[mapKey] && this.texturedMaterials && this.texturedMaterials[material[mapKey].name] && this.texturedMaterials[material[mapKey].name][mapKey]) return this.texturedMaterials[material[mapKey].name][mapKey];
      return null;
    };
    if (this.materials[this.currentMaterialIndex]) {
      let maps = this.noIntensity(this.keyValuePure(this.mapping), this.getMaterialType(this.materials[this.currentMaterialIndex]));
      this.materialMaps = [];
      maps.forEach(map => {
        this.texturedMaterialsMap[map.key] = getTexturedMaterialsByMap(this.materials[this.currentMaterialIndex], map.key);
        this.materialMaps.push(map.key);
      });
    }
  }
  keyValuePure(value) {
    let keys = [];
    for (let key in value) {
      keys.push({
        key: key,
        value: value[key]
      });
    }
    return keys;
  }
  noIntensity(value, materialType) {
    let supportedArray = [],
      final = [];
    if (value) {
      switch (materialType) {
        case 'MeshStandardMaterial':
          {
            supportedArray = ['alphaMap', 'bumpMap', 'aoMap', 'emissiveMap', 'envMap', 'map', 'metalnessMap', 'normalMap', 'roughnessMap'];
            break;
          }
        case 'MeshBasicMaterial':
          {
            supportedArray = ['alphaMap', 'aoMap', 'envMap', 'specularMap', 'map'];
            break;
          }
        case 'MeshDepthMaterial':
          {
            supportedArray = ['alphaMap'];
            break;
          }
        case 'MeshLambertMaterial':
          {
            supportedArray = ['alphaMap', 'aoMap', 'emissiveMap', 'envMap', 'specularMap', 'map'];
            break;
          }
        case 'MeshPhongMaterial':
          {
            supportedArray = ['alphaMap', 'aoMap', 'bumpMap', 'emissiveMap', 'envMap', 'normalMap', 'specularMap', 'map'];
            break;
          }
        case 'MeshPhysicalMaterial':
          {
            supportedArray = ['alphaMap', 'aoMap', 'bumpMap', 'emissiveMap', 'envMap', 'map', 'metalnessMap', 'normalMap', 'roughnessMap', 'specularColorMap', 'specularMap', 'thicknessMap', 'transmissionMap', 'sheenColorMap', 'sheenRoughnessMap'];
            break;
          }
        case 'MeshToonMaterial':
          {
            supportedArray = ['gradientMap'];
            break;
          }
        case 'PointsMaterial':
          {
            supportedArray = ['map'];
            break;
          }
        case 'SpriteMaterial':
          {
            supportedArray = ['map'];
            break;
          }
        // case 'ShaderMaterial': {
        //   supportedArray = [];
        //   break;
        // }
        default:
          {
            return value.filter(v => v.key.indexOf('Intensity') === -1);
          }
      }
      value.forEach(map => {
        if (supportedArray.find(s => s == map.key)) final.push(map);
      });
    }
    return final;
  }
  recoverIframeConnection() {
    if (this.iframeModel && this.iframeModel.nativeElement) this.iframeModel.nativeElement = document.querySelector(`iframe[src="${this.iframeModel.nativeElement.src}"]`);
  }
  postToChild(action, value, options = new PostToChildOptions$1(true)) {
    if (this.iframeModel) {
      let obj = {
        action: action,
        value: value
      };
      if (!this.mhService.postToChild(obj, this.iframeModel.nativeElement, options)) {
        this.recoverIframeConnection();
        this.mhService.postToChild(obj, this.iframeModel.nativeElement, options);
      }
    }
  }
  getCurrentScreenshot() {
    if (!this.mhService.postToChild({
      action: 'getCurrentScreenshot'
    }, this.iframeModel.nativeElement)) {
      this.recoverIframeConnection();
      this.mhService.postToChild({
        action: 'getCurrentScreenshot'
      }, this.iframeModel.nativeElement);
    }
  }
  async onMaterialsTextures() {
    try {
      if (this.textures && this.materials) {
        const materialsAny = this.materials;
        this.texturedMaterials = {};
        this.activeTextures = {};
        this.texturesWithImages = {};
        let urlsToAdd = [];
        // , emptyTextures = {} as { [id: string]: { [id: string]: TexturedMaterial; }; };
        for (var i = 0; i < this.materials.length; i++) {
          let backupArray = Object.keys(this.activeTexturesBackup).map(t => this.activeTexturesBackup[t]);
          if (!this.animatedState[this.materials[i].name]) this.animatedState[this.materials[i].name] = {};
          for (let j in this.materials[i]) {
            if (j == 'map' || this.mapsArr.find(mt => mt == j.replace('Map', ''))) {
              if (materialsAny[i][j] && materialsAny[i][j].name) {
                if (!this.materialAccordion[this.materials[i].name + '_' + j]) this.materialAccordion[this.materials[i].name + '_' + j] = false;
                this.texturedMaterials[materialsAny[i][j].name] = {};
                let materialTextures = this.getTexturesByName(materialsAny[i][j].name);
                if (materialTextures.length) {
                  materialTextures.forEach(textureRef => {
                    let texturedMaterial = {
                      uuid: textureRef.value.uuid,
                      name: textureRef.value.name,
                      type: textureRef.key,
                      map: textureRef.value,
                      material: this.materials[i],
                      intensity: this.getIntensity(this.materials[i], textureRef.key),
                      flipY: textureRef.value.flipY,
                      normalScale: this.materials[i].normalScale
                      // aoManipulation: this.materials[i].aoManipulation,
                      // roughnessManipulation: this.materials[i].roughnessManipulation,
                      // metalnessManipulation: this.materials[i].metalnessManipulation,
                      // normalManipulation: this.materials[i].normalManipulation,
                      // mapManipulation: this.materials[i].mapManipulation
                      // aoManipulation: textureRef.value.aoManipulation,// this.materials[i].aoManipulation,
                      // roughnessManipulation: textureRef.value.roughnessManipulation,// this.materials[i].roughnessManipulation,
                      // metalnessManipulation: textureRef.value.metalnessManipulation,// this.materials[i].metalnessManipulation,
                      // normalManipulation: textureRef.value.normalManipulation,// this.materials[i].normalManipulation,
                      // mapManipulation: textureRef.value.mapManipulation// this.materials[i].mapManipulation,
                      // mapIntensity: this.materials[i].mapIntensity,
                      // normalIntensity: this.materials[i].normalIntensity
                    };
                    if (textureRef.value.videoSrc && !this.videoTexturesSrcs.find(s => s == textureRef.value.videoSrc)) this.videoTexturesSrcs.push(textureRef.value.videoSrc);
                    if (textureRef.value.imageSrc && !backupArray.find(b => b.src == textureRef.value.imageSrc)) urlsToAdd.push(textureRef.value.imageSrc);
                    this.texturedMaterials[materialsAny[i][j].name][texturedMaterial.type] = texturedMaterial;
                    if (texturedMaterial.map.imageSrc) {
                      texturedMaterial.src = texturedMaterial.map.imageSrc;
                      this.activeTextures[texturedMaterial.name] = texturedMaterial;
                      this.texturesWithImages[texturedMaterial.name] = texturedMaterial;
                    }
                  });
                }
              } else {
                let intensity = this.materials[i][j];
                if (typeof intensity !== 'number') intensity = this.getIntensity(this.materials[i], j);
                // if (!emptyTextures[materialsAny[i].name])
                //   emptyTextures[materialsAny[i].name] = {};
                const emptyTexture = {
                  material: this.materials[i],
                  intensity,
                  normalScale: this.materials[i].normalScale,
                  type: this.getMapType(j)
                };
                // emptyTextures[materialsAny[i].name][this.getMapType(j)] =
                if (!this.texturedMaterials[materialsAny[i].name]) this.texturedMaterials[materialsAny[i].name] = {};
                this.texturedMaterials[materialsAny[i].name][emptyTexture.type] = emptyTexture;
              }
            }
            // if (emptyTextures[materialsAny[i].name]) {
            //   for (let k in emptyTextures[materialsAny[i][j]]) {
            //     if (!this.texturedMaterials[materialsAny[i]][k]) {
            //       // this.texturedMaterials[materialsAny[i]][k] = emptyTextures[materialsAny[i][j]];
            //     }
            //   }
            // }
          }
          const map = this.materials[i].map;
          if (typeof this.materials[i].rotation !== 'number') this.materials[i].rotation = map ? map.rotation : 0;
          if (!this.materials[i].repeat) this.materials[i].repeat = map ? map.repeat : {
            x: 1,
            y: 1
          };
          if (!this.materials[i].offset) this.materials[i].offset = map ? map.offset : {
            x: 0,
            y: 0
          };
          if (!this.materials[i].center) this.materials[i].center = map ? map.center : {
            x: 0,
            y: 0
          };
          if (!this.materials[i].wrapS) this.materials[i].wrapS = map ? map.wrapS : ThreeTextureWrap.ClampToEdgeWrapping;
          if (!this.materials[i].wrapT) this.materials[i].wrapT = map ? map.wrapT : ThreeTextureWrap.ClampToEdgeWrapping;
        }
      }
    } catch (e) {
      debugger;
    }
    this.refreshTexturedMaterialsMap();
    this.onMaterialManipulations(this.materialManipulations);
    await this.createPreview();
    this.counter++;
  }
  async createPreview() {
    if (this.texturesWithImages) {
      for (let i in this.texturesWithImages) {
        if (!this.texturesWithImages[i].preview) this.texturesWithImages[i].preview = await this.utils.resizeImage(this.texturesWithImages[i].src, MAX_PREVIEW_SIZE);
      }
    }
  }
  getTexturesByName(name) {
    let res = [];
    if (this.textures) {
      for (let i = 0; i < this.textures.length; i++) {
        let t = this.textures[i];
        for (let map in t) {
          if (typeof t[map] === 'object') {
            if (t[map] && t[map]['name'] == name) res.push({
              key: map,
              value: t[map]
            });
          }
        }
      }
    }
    return res;
  }
  getIntensity(material, map) {
    switch (map) {
      case 'roughnessMap':
        {
          return material.roughness;
        }
      case 'metalnessMap':
        {
          return material.metalness;
        }
      case 'emissiveMap':
        {
          return material.emissiveIntensity;
        }
      case 'specularMap':
        {
          return material.shininess;
        }
      case 'thicknessMap':
        {
          return material.thickness;
        }
      case 'transmissionMap':
        {
          return material.transmission;
        }
      case 'sheenRoughnessMap':
        {
          return material.sheenRoughness;
        }
      case 'sheenColorMap':
        {
          return material.sheen;
        }
      case 'specularIntensityMap':
        {
          return material.specularIntensity;
        }
      default:
        {
          return material[map + 'Intensity'];
        }
    }
  }
  getMapType(map) {
    switch (map) {
      case 'roughness':
        {
          return 'roughnessMap';
        }
      case 'metalness':
        {
          return 'metalnessMap';
        }
      case 'emissive':
        {
          return 'emissiveMap';
        }
      case 'specular':
        {
          return 'specularMap';
        }
      case 'thickness':
        {
          return 'thicknessMap';
        }
      case 'transmission':
        {
          return 'transmissionMap';
        }
      case 'sheenRoughness':
        {
          return 'sheenRoughnessMap';
        }
      case 'sheenColor':
        {
          return 'sheenColorMap';
        }
      case 'specularIntensity':
        {
          return 'specularIntensityMap';
        }
      default:
        {
          return map.replace('Intensity', '');
        }
    }
  }
  firstInit() {
    this._isViewerReady = false;
    this._isViewerLoaded = false;
    this.lightsSummarySent = false;
    this._textureToMaterialTasks = 0;
    this.UVsOverlapCount = 0;
    this.frameEventsAttached = false;
    this.activeTexturesBackup = {};
    this.texturesSrcs = [];
    this.annotationImgSrcs = [];
    this.videoTexturesSrcs = [];
    this.materialAccordion = {};
    // this.controlsPosition = {
    //   value: {
    //     x: 0,
    //     y: 0,
    //     z: 0
    //   },
    //   name: 'pivot',
    //   action: 'setPivot',
    //   urlParam: 'pivot',
    //   min: -Math.PI,
    //   max: Math.PI,
    //   step: Math.PI / 18,
    //   type: AssetAdjustmentType.CUSTOM,
    //   sector: AssetAdjustmentSector.SCENE
    // };
    this.cameraPosition = {
      value: {
        x: 0,
        y: 0,
        z: 0
      },
      default: 0,
      name: 'camera position',
      action: 'setCameraPosition',
      urlParam: 'cam-pos',
      min: -90,
      max: 90,
      type: AssetAdjustmentType.CUSTOM,
      sector: AssetAdjustmentSector.SCENE
    };
    // this.cameraRotation = {
    //   value: {
    //     x: 0,
    //     y: 0,
    //     z: 0
    //   },
    //   default: 0,
    //   name: 'camera rotation',
    //   action: 'setCameraRotation',
    //   urlParam: 'cam-rot',
    //   min: -Math.PI / 2,
    //   max: Math.PI / 2,
    //   type: AssetAdjustmentType.CUSTOM,
    //   sector: AssetAdjustmentSector.SCENE
    // };
    // this.mapping = this.formBuilder.group({});
    this.mapping = {};
    this.mapsArr = ['metalness', 'normal', 'roughness', 'ao', 'alpha', 'bump', 'displacement', 'emissive', 'env', 'light', 'specular', 'thickness', 'transmission', 'sheen', 'sheenRoughness', 'specularIntensity', 'specularColor'];
    for (let m of this.mapsArr) {
      this.mapping[m + 'Map'] = null;
      switch (m) {
        case 'roughness':
          {
            this.mapping['roughness'] = null;
            break;
          }
        case 'metalness':
          {
            this.mapping['metalness'] = null;
            break;
          }
        case 'emissive':
          {
            this.mapping['emissiveIntensity'] = null;
            break;
          }
        case 'sheenColor':
          {
            this.mapping['sheenColor'] = null;
            break;
          }
        case 'sheenRoughness':
          {
            this.mapping['sheenRoughness'] = null;
            break;
          }
        case 'thickness':
          {
            this.mapping['thickness'] = null;
            break;
          }
        case 'transmission':
          {
            this.mapping['transmission'] = null;
            break;
          }
        // case 'reflectivity': {
        //   this.mapping.addControl('reflectivity', this.formBuilder.control(null));
        //   break;
        // }
        // case 'specular': {
        //   this.mapping.addControl('shininess', this.formBuilder.control(null));
        //   break;
        // }
        default:
          {
            this.mapping[m + 'MapIntensity'] = null;
          }
      }
    }
    this.mapping['map'] = null;
    this.counter = 0;
    this.LightingCounter = 0;
  }
  async init(srcToRemove) {
    this.materials = null;
    this.textures = null;
    this.boundingBox = null;
    this.mainMesh = null;
    this.meshesData = null;
    this.materialManipulationsRevived = null;
    this._orgSrc = null;
    this.loading = true;
    this.materialManipulationsChanges = false;
    this.meshManipulationsChanges = false;
    this.meshAnimations = null;
    this._shadowPlaneOptions = null;
    this.hasMeshAnimations = false;
    this.lightsChanges = false;
    this.currentMaterialIndex = null;
    this._gltfValidationResponse = null;
    this._modelLoadError = null;
    this.simplifyGltf = null;
    this.deleteUV2Gltf = null;
    this._polyCount = null;
    this._polyTypeDistribution = null;
    this._sceneHierarchy = null;
    this.soloLights = {};
    this.texturesAnimations = {};
    this._childrenParentDictionary = {};
    this.animatedState = {};
    this.onPosition = [];
    this._isViewerReady = false;
    this._isViewerLoaded = false;
    this.lightsSummarySent = false;
    this._textureToMaterialTasks = 0;
    // this.lights = [];
    if (this.viewerType == '3JS') {
      this.parameters = [
      // {
      //   value: null,
      //   name: 'hemisphere',
      //   action: 'setCurrentLight',
      //   urlParam: 'le-hem',
      //   min: 0,
      //   max: 5,
      //   step: 0.01,
      //   options: {
      //     type: 'HemisphereLight'
      //   },
      //   type: AssetAdjustmentType.SLIDER,
      //   sector: AssetAdjustmentSector.LIGHTING,
      //   hasColor: true,
      //   hasHSL: true,
      //   hsl: { x: null, y: null, z: null }
      // },
      // {
      //   value: null,
      //   name: 'directional',
      //   action: 'setCurrentLight',
      //   urlParam: 'le-dir',
      //   min: 0,
      //   max: 5,
      //   step: 0.01,
      //   options: {
      //     type: 'DirectionalLight'
      //   },
      //   type: AssetAdjustmentType.SLIDER,
      //   sector: AssetAdjustmentSector.LIGHTING,
      //   hasColor: true,
      //   hasHSL: true,
      //   hsl: { x: null, y: null, z: null }
      // },
      // {
      //   value: null,
      //   name: 'point',
      //   action: 'setCurrentLight',
      //   urlParam: 'le-pnt',
      //   min: 0,
      //   max: 5,
      //   step: 0.01,
      //   options: {
      //     type: 'PointLight'
      //   },
      //   type: AssetAdjustmentType.SLIDER,
      //   sector: AssetAdjustmentSector.LIGHTING,
      //   hasColor: true,
      //   hasHSL: true,
      //   hsl: { x: null, y: null, z: null }
      // },
      // {
      //   value: null,
      //   name: 'ambient',
      //   action: 'setCurrentLight',
      //   urlParam: 'le-amb',
      //   min: 0,
      //   max: 5,
      //   step: 0.01,
      //   options: {
      //     type: 'AmbientLight'
      //   },
      //   type: AssetAdjustmentType.SLIDER,
      //   sector: AssetAdjustmentSector.LIGHTING,
      //   hasColor: true,
      //   hasHSL: true,
      //   hsl: { x: null, y: null, z: null }
      // },
      // {
      //   value: null,
      //   name: 'spot',
      //   action: 'setCurrentLight',
      //   urlParam: 'le-spt',
      //   min: 0,
      //   max: 5,
      //   step: 0.01,
      //   options: {
      //     type: 'SpotLight'
      //   },
      //   type: AssetAdjustmentType.SLIDER,
      //   sector: AssetAdjustmentSector.LIGHTING,
      //   hasColor: true,
      //   hasHSL: true,
      //   hsl: { x: null, y: null, z: null }
      // },
      this.modelPivot,
      // {
      //   value: null,
      //   name: 'min distance',
      //   action: 'setDistance',
      //   urlParam: 'min-distance',
      //   min: 0,
      //   max: 15,
      //   step: 0.0001,
      //   options: {
      //     type: 'min'
      //   },
      //   type: AssetAdjustmentType.SLIDER,
      //   sector: AssetAdjustmentSector.SCENE,
      //   round: 16
      // },
      // {
      //   value: null,
      //   name: 'max distance',
      //   action: 'setDistance',
      //   urlParam: 'max-distance',
      //   min: 0,
      //   max: 15,
      //   step: 0.0001,
      //   options: {
      //     type: 'max'
      //   },
      //   type: AssetAdjustmentType.SLIDER,
      //   sector: AssetAdjustmentSector.SCENE,
      //   round: 16
      // },
      {
        value: null,
        default: 45,
        name: 'field of view',
        action: 'setFOV',
        // urlParam: 'fov',
        id: 'fov',
        min: 5,
        max: 90,
        step: 1,
        // options: {
        //   ignoreDistance: true
        // },
        type: AssetAdjustmentType.SLIDER,
        sector: AssetAdjustmentSector.SCENE
      }, {
        value: null,
        name: 'exposure',
        action: 'setExposure',
        default: 1,
        // urlParam: 'exp',
        id: 'exp',
        min: 0,
        max: 5,
        step: 0.01,
        type: AssetAdjustmentType.SLIDER,
        sector: AssetAdjustmentSector.SCENE
      },
      // {
      //   value: null,
      //   name: 'focal length',
      //   action: 'setFocalLength',
      //   urlParam: 'focal-length',
      //   min: 1,
      //   max: 180,
      //   step: 1,
      //   // options: {
      //   //   type: 'min'
      //   // },
      //   type: AssetAdjustmentType.SLIDER,
      //   sector: AssetAdjustmentSector.SCENE
      // },
      {
        value: 1,
        name: 'HDRI Intensity',
        action: 'setHDR',
        // urlParam: 'hdr-intensity',
        id: 'hdr-intensity',
        type: AssetAdjustmentType.SLIDER,
        min: 0,
        max: 3,
        default: 1,
        step: 0.01,
        disabled: true,
        sector: AssetAdjustmentSector.SCENE
      }, {
        value: 0,
        name: 'Light Probe',
        action: 'setLightProbe',
        // urlParam: 'le-probe',
        id: 'le-probe',
        type: AssetAdjustmentType.SLIDER,
        min: 0,
        max: 5,
        default: 0,
        step: 0.01,
        disabled: true,
        sector: AssetAdjustmentSector.SCENE
      },
      // {
      //   value: 0,
      //   name: 'Refraction',
      //   action: 'setRefraction',
      //   // urlParam: 'refraction',
      //   id: 'refraction',
      //   type: AssetAdjustmentType.SLIDER,
      //   min: 0,
      //   max: 1,
      //   default: 0.98,
      //   step: 0.01,
      //   disabled: true,
      //   sector: AssetAdjustmentSector.SCENE
      // },
      {
        value: null,
        name: 'transparent render order',
        action: 'setTransparentSort',
        // urlParam: 'tsl',
        id: 'tsl',
        type: AssetAdjustmentType.SELECT,
        selectOptions: this.getTransparentRenderOrder(),
        sector: AssetAdjustmentSector.SCENE
      }, {
        value: false,
        name: 'tone mapping',
        action: 'setToneMapping',
        // urlParam: 'phcl',
        id: 'tone-mapping',
        type: AssetAdjustmentType.SELECT,
        selectOptions: this.enums.getToneMappings(this.engine),
        sector: AssetAdjustmentSector.SCENE
      }, {
        value: false,
        name: 'HDRI Format',
        action: 'setHDR',
        id: 'hdr-format',
        type: AssetAdjustmentType.SELECT,
        selectOptions: this.enums.getHDRIFormats(),
        disabled: true,
        sector: AssetAdjustmentSector.SCENE
      },
      // {
      //   value: null,
      //   name: 'framework version',
      //   action: 'changeThreeVersion',
      //   urlParam: 'tv',
      //   id: 'tv',
      //   type: AssetAdjustmentType.SELECT,
      //   selectOptions: this.enums.getThreeJsVersions(),
      //   sector: AssetAdjustmentSector.SCENE,
      //   cssClass: 'w-100'
      // },
      {
        value: null,
        name: 'HDRI',
        action: 'setHDR',
        // urlParam: 'hdr',
        id: 'hdr',
        type: AssetAdjustmentType.IMAGE_MENU,
        optionsImages: this.enums.getHDRIPrev(),
        sector: AssetAdjustmentSector.SCENE
      }, {
        value: {
          x: null,
          y: null,
          z: null
        },
        name: 'HDRI rotation',
        action: 'setHDR',
        id: 'hdr-rotation',
        type: AssetAdjustmentType.VECTOR3,
        sector: AssetAdjustmentSector.SCENE,
        min: -Math.PI,
        max: Math.PI,
        step: 0.01,
        default: {
          x: 0,
          y: 0,
          z: 0
        },
        disabled: true
      },
      // {
      //   value: null,
      //   name: 'anisotropy',
      //   action: 'setAnisotropy',
      //   urlParam: 'anisotropy',
      //   type: AssetAdjustmentType.SELECT,
      //   selectOptions: this.getAnisotropies(),
      //   sector: AssetAdjustmentSector.SCENE,
      //   cssClass: 'w-100'
      // },
      {
        value: false,
        name: 'HDRI background',
        action: 'toggleHDRbg',
        // urlParam: 'hdrbg',
        id: 'hdrbg',
        type: AssetAdjustmentType.TOGGLE,
        sector: AssetAdjustmentSector.SCENE
      }, {
        value: false,
        name: 'HDRI Blurriness',
        action: 'setHDR',
        // urlParam: 'hdr-blur',
        id: 'hdr-blur',
        type: AssetAdjustmentType.TOGGLE,
        disabled: true,
        sector: AssetAdjustmentSector.SCENE
      }, {
        value: false,
        name: 'HDRI skybox',
        action: 'setHDR',
        id: 'hdr-skybox',
        type: AssetAdjustmentType.TOGGLE,
        sector: AssetAdjustmentSector.SCENE
      }, {
        value: false,
        name: 'grid',
        action: 'toggleGrid',
        // urlParam: 'grid',
        id: 'grid',
        type: AssetAdjustmentType.TOGGLE,
        sector: AssetAdjustmentSector.SCENE
      }, {
        value: false,
        name: 'mesh ID',
        action: 'toggleMeshID',
        // urlParam: 'mesh-id',
        id: 'mesh-id',
        type: AssetAdjustmentType.TOGGLE,
        sector: AssetAdjustmentSector.MESHES
      }, {
        value: false,
        name: 'physical lights',
        action: 'togglePhysicallyCorrectLights',
        // urlParam: 'phcl',
        id: 'phcl',
        type: AssetAdjustmentType.TOGGLE,
        sector: AssetAdjustmentSector.SCENE
      }, {
        value: false,
        name: 'x-ray mode',
        action: 'toggleXrayMode',
        // urlParam: 'xray',
        id: 'xray',
        type: AssetAdjustmentType.TOGGLE,
        sector: AssetAdjustmentSector.SCENE
      }, {
        value: false,
        name: 'UV mode',
        action: 'toggleUvMode',
        id: 'uv',
        type: AssetAdjustmentType.TOGGLE,
        sector: AssetAdjustmentSector.SCENE
      }, {
        value: false,
        name: 'wireframe',
        action: 'toggleWireframe',
        // urlParam: 'wireframe',
        id: 'wireframe',
        type: AssetAdjustmentType.TOGGLE,
        sector: AssetAdjustmentSector.SCENE
      }, {
        value: false,
        name: 'shaded wireframe',
        action: 'toggleShadedWireframe',
        // urlParam: 'shaded-wireframe',
        id: 'shaded-wireframe',
        type: AssetAdjustmentType.TOGGLE,
        sector: AssetAdjustmentSector.SCENE,
        hasColor: true,
        colorChanged: false,
        color: '#ffffff'
      }, {
        value: 1,
        name: 'opacity',
        action: 'togglePlane',
        // urlParam: 'plane',
        id: 'plane',
        min: 0,
        max: 1,
        step: 0.01,
        default: 1,
        type: AssetAdjustmentType.SLIDER,
        sector: AssetAdjustmentSector.SHADOW,
        hasColor: true,
        hasToggle: true
      }, {
        value: false,
        name: 'render bottom',
        action: 'togglePlane',
        id: 'plane-s',
        type: AssetAdjustmentType.TOGGLE,
        sector: AssetAdjustmentSector.SHADOW
      }, {
        value: 1,
        name: 'mirror effect',
        action: 'togglePlane',
        // urlParam: 'plane-r',
        id: 'plane-r',
        type: AssetAdjustmentType.TOGGLE,
        sector: AssetAdjustmentSector.SHADOW
      }, {
        value: 1,
        name: 'physical shadow',
        action: 'togglePlane',
        // urlParam: 'plane-p',
        id: 'plane-p',
        type: AssetAdjustmentType.TOGGLE,
        sector: AssetAdjustmentSector.SHADOW,
        children: [{
          name: 'blur',
          action: 'togglePlane',
          // urlParam: 'plane-p-b',
          id: 'plane-p-b',
          type: AssetAdjustmentType.SLIDER,
          sector: AssetAdjustmentSector.SHADOW,
          min: 0,
          max: 15,
          value: 3.5,
          round: 1,
          step: 0.1
        },
        // {
        //   name: 'darkness',
        //   action: 'togglePlane',
        //   // urlParam: 'plane-p-d',
        //   id: 'plane-p-d',
        //   type: AssetAdjustmentType.SLIDER,
        //   sector: AssetAdjustmentSector.SHADOW,
        //   min: 0,
        //   max: 1,
        //   value: 0.85,
        //   round: 1,
        //   step: 0.05
        // },
        {
          name: 'height',
          action: 'togglePlane',
          // urlParam: 'plane-p-h',
          id: 'plane-p-h',
          type: AssetAdjustmentType.SLIDER,
          sector: AssetAdjustmentSector.SHADOW,
          min: 0,
          max: 10,
          value: 1,
          round: 0.01,
          step: 0.05
        }
        // {
        //   name: 'opacity',
        //   action: 'togglePlane',
        //   // urlParam: 'plane-p-o',
        //   id: 'plane-p-o',
        //   type: AssetAdjustmentType.SLIDER,
        //   sector: AssetAdjustmentSector.SHADOW,
        //   min: 0,
        //   max: 1,
        //   value: 0,
        //   round: 1,
        //   step: 0.05
        // },
        // {
        //   name: 'map size',
        //   action: 'togglePlane',
        //   // urlParam: 'plane-p-dim',
        //   id: 'plane-p-dim',
        //   // default: 512,
        //   value: 512,
        //   type: AssetAdjustmentType.SELECT,
        //   selectOptions: this.enums.getTexturesSizes(),
        //   sector: AssetAdjustmentSector.SHADOW
        // }
        ]
      }, {
        value: {
          x: null,
          y: null,
          z: null
        },
        name: 'dimensions',
        action: 'toggleDimensionsAnnotations',
        // urlParam: 'dimensions',
        id: 'dimensions',
        type: AssetAdjustmentType.VECTOR3,
        sector: AssetAdjustmentSector.SCENE,
        hasToggle: true,
        toggleState: false,
        hasColor: true,
        colorChanged: false
      }, {
        value: false,
        name: 'close up',
        action: 'toggleCloseup',
        id: 'closeup',
        type: AssetAdjustmentType.TOGGLE,
        sector: AssetAdjustmentSector.SCENE
      }, {
        value: false,
        name: 'matcap',
        action: 'toggleMatcapMode',
        id: 'matcap',
        type: AssetAdjustmentType.TOGGLE,
        sector: AssetAdjustmentSector.SCENE
      }, {
        value: false,
        name: 'hide bottom',
        action: 'toggleHideBottom',
        id: 'hide-bottom',
        type: AssetAdjustmentType.TOGGLE,
        sector: AssetAdjustmentSector.SCENE
      },
      // {
      //   value: false,
      //   name: 'glasses virtual try-on',
      //   action: 'toggleArGlassesButton',
      //   urlParam: 'ar-glasses',
      //   type: AssetAdjustmentType.TOGGLE,
      //   sector: AssetAdjustmentSector.ANNOTATIONS
      // },
      {
        value: false,
        name: 'augmented reality',
        action: 'toggleAr',
        urlParam: 'ar',
        type: AssetAdjustmentType.TOGGLE,
        sector: AssetAdjustmentSector.ANNOTATIONS
      }, {
        value: false,
        name: 'snowflakes effect',
        action: 'toggleSnow',
        urlParam: 'snow',
        type: AssetAdjustmentType.TOGGLE,
        sector: AssetAdjustmentSector.ANNOTATIONS
      }, {
        value: 100,
        name: 'contrast',
        action: 'applySVGPostprocessing',
        id: 'pp-contrast',
        type: AssetAdjustmentType.SLIDER,
        min: 0,
        max: 200,
        round: 1,
        step: 1,
        sector: AssetAdjustmentSector.POSTPROCESSING
      }, {
        value: 100,
        name: 'brightness',
        action: 'applySVGPostprocessing',
        id: 'pp-brightness',
        type: AssetAdjustmentType.SLIDER,
        min: 50,
        max: 250,
        round: 1,
        step: 1,
        sector: AssetAdjustmentSector.POSTPROCESSING
      }, {
        value: 100,
        name: 'saturate',
        action: 'applySVGPostprocessing',
        id: 'pp-saturate',
        type: AssetAdjustmentType.SLIDER,
        min: 0,
        max: 200,
        round: 1,
        step: 1,
        sector: AssetAdjustmentSector.POSTPROCESSING
      }, {
        value: 0,
        name: 'sepia',
        action: 'applySVGPostprocessing',
        id: 'pp-sepia',
        type: AssetAdjustmentType.SLIDER,
        min: 0,
        max: 100,
        round: 1,
        step: 1,
        sector: AssetAdjustmentSector.POSTPROCESSING
      }, {
        value: 1,
        name: 'sharpen',
        action: 'applySVGPostprocessing',
        id: 'pp-sharpen',
        type: AssetAdjustmentType.SLIDER,
        min: 0,
        max: 3,
        round: 1,
        step: 1,
        sector: AssetAdjustmentSector.POSTPROCESSING
      },
      // {
      //     value: 1,
      //     name: 'gamma',
      //     action: 'applySVGPostprocessing',
      //     id: 'pp-gamma',
      //     type: AssetAdjustmentType.SLIDER,
      //     min: 0.5,
      //     max: 1.5,
      //     round: 0.05,
      //     step: 0.05,
      //     sector: AssetAdjustmentSector.POSTPROCESSING,
      //     cssClass: 'disp-none'
      // },
      {
        value: 1,
        name: 'hue',
        action: 'applySVGPostprocessing',
        id: 'pp-hue',
        type: AssetAdjustmentType.SLIDER,
        min: 0,
        max: 360,
        round: 1,
        step: 1,
        sector: AssetAdjustmentSector.POSTPROCESSING
      }, {
        cssClass: 'separate gamma',
        value: 1,
        name: 'red gamma exponent',
        action: 'applySVGPostprocessing',
        id: 'pp-gamma-r',
        type: AssetAdjustmentType.SLIDER,
        min: 0.5,
        max: 1.5,
        round: 0.05,
        step: 0.05,
        sector: AssetAdjustmentSector.POSTPROCESSING
      }, {
        value: 1,
        name: 'red gamma amplitude',
        action: 'applySVGPostprocessing',
        id: 'pp-gamma-r-amplitude',
        type: AssetAdjustmentType.SLIDER,
        min: 0.5,
        max: 1.5,
        round: 0.05,
        step: 0.05,
        sector: AssetAdjustmentSector.POSTPROCESSING
      }, {
        value: 0,
        name: 'red gamma offset',
        action: 'applySVGPostprocessing',
        id: 'pp-gamma-r-offset',
        type: AssetAdjustmentType.SLIDER,
        min: -0.5,
        max: 0.5,
        round: 0.01,
        step: 0.01,
        sector: AssetAdjustmentSector.POSTPROCESSING
      }, {
        value: 1,
        name: 'green gamma exponent',
        action: 'applySVGPostprocessing',
        id: 'pp-gamma-g',
        type: AssetAdjustmentType.SLIDER,
        min: 0.5,
        max: 1.5,
        round: 0.05,
        step: 0.05,
        sector: AssetAdjustmentSector.POSTPROCESSING
      }, {
        value: 1,
        name: 'green gamma amplitude',
        action: 'applySVGPostprocessing',
        id: 'pp-gamma-g-amplitude',
        type: AssetAdjustmentType.SLIDER,
        min: 0.5,
        max: 1.5,
        round: 0.05,
        step: 0.05,
        sector: AssetAdjustmentSector.POSTPROCESSING
      }, {
        value: 0,
        name: 'green gamma offset',
        action: 'applySVGPostprocessing',
        id: 'pp-gamma-g-offset',
        type: AssetAdjustmentType.SLIDER,
        min: -0.5,
        max: 0.5,
        round: 0.01,
        step: 0.01,
        sector: AssetAdjustmentSector.POSTPROCESSING
      }, {
        value: 1,
        name: 'blue gamma exponent',
        action: 'applySVGPostprocessing',
        id: 'pp-gamma-b',
        type: AssetAdjustmentType.SLIDER,
        min: 0.5,
        max: 1.5,
        round: 0.05,
        step: 0.05,
        sector: AssetAdjustmentSector.POSTPROCESSING
      }, {
        value: 1,
        name: 'blue gamma amplitude',
        action: 'applySVGPostprocessing',
        id: 'pp-gamma-b-amplitude',
        type: AssetAdjustmentType.SLIDER,
        min: 0.5,
        max: 1.5,
        round: 0.05,
        step: 0.05,
        sector: AssetAdjustmentSector.POSTPROCESSING
      }, {
        value: 0,
        name: 'blue gamma offset',
        action: 'applySVGPostprocessing',
        id: 'pp-gamma-b-offset',
        type: AssetAdjustmentType.SLIDER,
        min: -0.5,
        max: 0.5,
        round: 0.01,
        step: 0.01,
        sector: AssetAdjustmentSector.POSTPROCESSING
      }, {
        value: 0,
        name: 'alpha gamma intercept',
        action: 'applySVGPostprocessing',
        id: 'pp-gamma-a',
        type: AssetAdjustmentType.SLIDER,
        min: -1,
        max: 1,
        round: 0.05,
        step: 0.05,
        sector: AssetAdjustmentSector.POSTPROCESSING
      }, {
        value: 1,
        name: 'alpha gamma slope',
        action: 'applySVGPostprocessing',
        id: 'pp-gamma-a-slope',
        type: AssetAdjustmentType.SLIDER,
        min: 0.5,
        max: 1.5,
        round: 0.05,
        step: 0.05,
        sector: AssetAdjustmentSector.POSTPROCESSING
      }
      // {
      //   value: {},
      //   name: 'material type',
      //   action: 'swapMaterialType',
      //   urlParam: 'swap-material-type',
      //   type: AssetAdjustmentType.CUSTOM,
      //   sector: AssetAdjustmentSector.MATERIALS
      // },
      // {
      //   value: {},
      //   name: 'material type',
      //   action: 'setMaterialProps',
      //   urlParam: 'material-props',
      //   type: AssetAdjustmentType.CUSTOM,
      //   sector: AssetAdjustmentSector.MATERIALS
      // }
      // ,
      // this.controlsPosition
      ];
      // this.postToChild('broadcastLightsSummary');
    } else if (this.viewerType == 'b4w') {
      this.parameters = [{
        value: null,
        name: 'light',
        action: 'setCurrentLightEnergy',
        urlParam: 'le',
        min: -5,
        max: 15,
        step: 0.1,
        type: AssetAdjustmentType.SLIDER,
        sector: AssetAdjustmentSector.LIGHTING
      }
      // ,
      // {
      //   value: null,
      //   name: 'minimum zoom',
      //   action: 'setDistance',
      //   urlParam: 'distance_delta',
      //   min: 0,
      //   max: 15,
      //   step: 0.1,
      //   type: AssetAdjustmentType.SLIDER,
      //   sector: AssetAdjustmentSector.SCENE
      // },
      // {
      //   value: null,
      //   name: 'maximum zoom',
      //   action: 'setDistance',
      //   urlParam: 'distance',
      //   min: 0,
      //   max: 15,
      //   step: 0.1,
      //   type: AssetAdjustmentType.SLIDER,
      //   sector: AssetAdjustmentSector.SCENE
      // }
      ];
      if (this.createdAt) {
        let created = new Date(this.createdAt).getTime();
        let from = new Date('2018-01-29T13:09:31Z').getTime();
        if (created > from) {
          this.parameters.push({
            value: null,
            name: 'brightness',
            action: 'setColorCorrectionParams',
            urlParam: 'bri',
            min: 0,
            max: 0.99,
            step: 0.01,
            type: AssetAdjustmentType.SLIDER,
            sector: AssetAdjustmentSector.LIGHTING
          });
          this.parameters.push({
            value: null,
            name: 'contrast',
            action: 'setColorCorrectionParams',
            urlParam: 'con',
            min: 0,
            max: 0.99,
            step: 0.01,
            type: AssetAdjustmentType.SLIDER,
            sector: AssetAdjustmentSector.LIGHTING
          });
          this.parameters.push({
            value: null,
            name: 'exposure',
            action: 'setColorCorrectionParams',
            urlParam: 'exp',
            min: 0,
            max: 0.99,
            step: 0.01,
            type: AssetAdjustmentType.SLIDER,
            sector: AssetAdjustmentSector.LIGHTING
          });
          this.parameters.push({
            value: null,
            name: 'saturation',
            action: 'setColorCorrectionParams',
            urlParam: 'sat',
            min: 0,
            max: 0.99,
            step: 0.01,
            type: AssetAdjustmentType.SLIDER,
            sector: AssetAdjustmentSector.LIGHTING
          });
        }
      }
    }
    if (this.iframeModel && this.iframeModel.nativeElement) {
      this.ignoreChanges = true;
      this._engine = parseInt(this.utils.getUrlParam(this.iframeModel.nativeElement.src, 'engine'));
      if (isNaN(this._engine)) this._engine = RenderingEngine.THREE;
      this._tech = parseInt(this.utils.getUrlParam(this.iframeModel.nativeElement.src, 'rt'));
      if (isNaN(this._tech)) this._tech = RenderTechnology.WebGL;
      this._threeVersion = parseInt(this.utils.getUrlParam(this.iframeModel.nativeElement.src, 'tv'));
      if (isNaN(this._threeVersion)) this._threeVersion = 110;
      this._babylonVersion = this.utils.getUrlParam(this.iframeModel.nativeElement.src, 'bv');
      if (!this._babylonVersion) {
        const allBV = this.enums.getBabylonJsVersions();
        this._babylonVersion = allBV[allBV.length - 1];
      }
      this.materialManipulations = {};
      // this.sceneLights = {};
      if (this.jsonParams) {
        const jsonParams = this.utils.safeParse(this.jsonParams);
        if (jsonParams) {
          if (jsonParams.scene) {
            if (jsonParams.scene.materialManipulations) this.materialManipulations = jsonParams.scene.materialManipulations;
            if (jsonParams.scene.meshManipulations) this.meshManipulations = jsonParams.scene.meshManipulations;
            if (jsonParams.scene.lights) this.sceneLights = jsonParams.scene.lights;
            if (jsonParams.scene.texturesAnimations) {
              this.texturesAnimations = jsonParams.scene.texturesAnimations;
              this.setAnimatedState();
            }
            if (jsonParams.scene.meshAnimations) this.setMeshAnimations(jsonParams.scene.meshAnimations);
            if (jsonParams.scene.shadowPlane) this.shadowPlane = jsonParams.scene.shadowPlane;
            if (jsonParams.scene.meshBloomOptions) this.meshBloomOptions = jsonParams.scene.meshBloomOptions;
            if (jsonParams.scene.meshFurOptions) this.meshFurOptions = jsonParams.scene.meshFurOptions;
            if (jsonParams.scene.hdri) this.hdri = jsonParams.scene.hdri;
            if (jsonParams.scene.svgPostprocessingOptions) this.svgPostprocessing = jsonParams.scene.svgPostprocessingOptions;
          } else {
            const jsonParamsAny = jsonParams;
            // legacy support for when the 'materialsManipulations' was in the root of the JSON instead of the 'scene'
            if (jsonParamsAny.materialManipulations) this.materialManipulations = jsonParamsAny.materialManipulations;
            // set true so we could move lights configuration to JSON instead of the legacy structure
            this.lightsChanges = true;
          }
        }
      } else {
        // legacy support for when the materials-manipulations was part of the URL params
        let materialManipulations = this.utils.safeParse(decodeURIComponent(this.utils.getUrlParam(this.iframeModel.nativeElement.src, 'materials-manipulations')));
        if (materialManipulations) {
          this.materialManipulationsChanges = true;
          this.materialManipulations = materialManipulations;
        }
      }
      if (this.parameters) {
        this._params = null;
        this._hdri = null;
        if (!this._annotations) this._annotations = [];
        if (!this._meshActions) this._meshActions = [];
        this.parameters.forEach(param => {
          let val = this.getValue(param);
          switch (param.type) {
            case AssetAdjustmentType.CUSTOM:
              {
                // switch (param.urlParam) {
                //   case 'pivot': {
                //     param.value = { x: 0, y: 0, z: 0 };
                //     try {
                //       let pivot = val.split(',');
                //       param.value = { x: pivot[0], y: pivot[1], z: pivot[2] };
                //     } catch (e) { }
                //     this.controlsPosition.value = param.value;
                //     break;
                //   }
                // }
                break;
              }
            case AssetAdjustmentType.VECTOR3:
              {
                if (param.hasToggle) param.toggleState = typeof val === 'string';
                if (!param.value || !this.isToggleRelevant(param)) param.value = {
                  x: null,
                  y: null,
                  z: null
                };
                try {
                  if (this.isToggleRelevant(param)) {
                    let vec = val.split(',');
                    param.value = {
                      x: vec[0],
                      y: vec[1],
                      z: vec[2]
                    };
                  }
                } catch (e) {}
                if (param.hasColor) {
                  const color = this.getValue(param, '-c');
                  if (color) param.color = color;
                }
                break;
              }
            case AssetAdjustmentType.JOYSTICK:
              {
                param.value = {
                  x: null,
                  y: null,
                  z: null
                };
                try {
                  let p = val.split(',');
                  param.value = {
                    x: p[0],
                    y: p[1],
                    z: p[2]
                  };
                } catch (e) {}
                break;
              }
            case AssetAdjustmentType.TOGGLE:
              {
                param.value = !!val;
                break;
              }
            default:
              {
                if (val) {
                  param.value = parseFloat(val);
                  if (isNaN(param.value) && val) param.value = val;
                  if (param.value && param.hasToggle) param.toggleState = true;
                  if (param.hasColor) {
                    const color = this.getValue(param, '-c');
                    if (color) param.color = color;
                  }
                  if (param.hasHSL) {
                    const hsl = this.getValue(param, '-hsl');
                    if (hsl) {
                      const hslArr = hsl.split(',');
                      param.hsl = {
                        x: parseFloat(hslArr[0]),
                        y: parseFloat(hslArr[1]),
                        z: parseFloat(hslArr[2])
                      };
                    }
                  }
                  if (param.urlParam == 'shadow-type') {
                    param.value = this.utils.parseToArr(val);
                  }
                  if (param.id == 'hdr') {
                    let intensity = this.parameters.find(x => x.id == 'hdr-intensity');
                    intensity.disabled = false;
                    let blur = this.parameters.find(x => x.id == 'hdr-blur');
                    blur.disabled = false;
                    let format = this.parameters.find(x => x.id == 'hdr-format');
                    format.disabled = false;
                    let probe = this.parameters.find(x => x.id == 'le-probe');
                    probe.disabled = false;
                    let rotation = this.parameters.find(x => x.id == 'hdr-rotation');
                    if (this.engine === RenderingEngine.THREE && this.threeVersion >= 162 || this.engine === RenderingEngine.BABYLON) rotation.disabled = false;
                    let refraction = this.parameters.find(x => x.id == 'refraction');
                    if (refraction) refraction.disabled = false;
                  } else if (param.id == 'hdr-intensity') {
                    let intensity = this.parameters.find(x => x.id == 'hdr-intensity');
                    intensity.default = param.value;
                  } else if (param.id == 'hdr-blur') {
                    let blur = this.parameters.find(x => x.id == 'hdr-blur');
                    blur.default = param.value;
                  } else if (param.id == 'hdr-format') {
                    let format = this.parameters.find(x => x.id == 'hdr-format');
                    format.default = param.value;
                  } else if (param.id == 'le-probe') {
                    let probe = this.parameters.find(x => x.id == 'le-probe');
                    probe.default = param.value;
                  } else if (param.id == 'hdr-rotation') {
                    let rotation = this.parameters.find(x => x.id == 'hdr-rotation');
                    rotation.default = param.value;
                  }
                  // else if (param.id == 'refraction') {
                  //   let refraction = this.parameters.find(x => x.urlParam == 'refraction');
                  //   if (refraction)
                  //     refraction.disabled = param.value;
                  // }
                }
              }
          }
        });
      }
      this.ignoreChanges = false;
      if (!this.frameEventsAttached) {
        this.UVsOverlapCount = 0;
        this.frameEventsAttached = true;
        this.lastFrameSrc = this.iframeModel.nativeElement.src;
        // let obj = {
        //   actions: this.utils.getAllActions(),
        //   invoke: 'onMessage',
        //   scope: this,
        //   options: {
        //     apply: true
        //   }
        // } as MessageRequest;
        // if (srcToRemove)
        //   this.mhService.removePostMessageEvent(this.mhService.getReferrerByUrl(srcToRemove), this);
        // this.mhService.onPostMessageEvent(obj, this.mhService.getReferrerByUrl(this.iframeModel.nativeElement.src));
        this.attachMessages(srcToRemove);
        // await this.waitForViewerListening();
        // this.postToChild('broadcastViewerFullyLoaded');
        // let subAndBroad = () => {
        //   this.mhService.onPostMessageEvent(obj, this.mhService.getReferrerByUrl(this.iframeModel.nativeElement.src));
        //   this.postToChild('broadcastViewerFullyLoaded');
        // };
        // if (this.embed)
        //   setTimeout(() => { subAndBroad(); }, 2000);
        // else
        //   subAndBroad();
        // if (window['ResizeObserver']) {
        //   new window['ResizeObserver'](() => {
        //     setTimeout(() => {
        //       this.toggleDistance();
        //     }, 2000);
        //   }).observe(this.iframeModel.nativeElement);
        // }
        // this.iframeModel.nativeElement.addEventListener('resize', () => {
        //   setTimeout(() => {
        //     this.toggleDistance();
        //   }, 2000);
        // }, false);
      }
    }
    this.sceneUnits = this.getSceneUnits();
    // this.takeSnapshot(SnapshotType.PARAMETERS_WITH_PARENT, null, true);
    // this.cdr.detectChanges();
  }
  attachMessages(srcToRemove) {
    let obj = {
      actions: this.utils.getAllActions(),
      invoke: 'onMessage',
      scope: this,
      options: {
        apply: true
      }
    };
    if (srcToRemove) this.mhService.removePostMessageEvent(this.mhService.getReferrerByUrl(srcToRemove), this);
    this.mhService.onPostMessageEvent(obj, this.mhService.getReferrerByUrl(this.iframeModel.nativeElement.src));
  }
  async waitForViewerListening() {
    return new Promise((resolve, reject) => {
      this.onViewerListening.push(() => {
        resolve();
      });
    });
  }
  // setShadowLightType(type: string) {
  //   let params = this.parameters.filter(i => i.sector == AssetAdjustmentSector.SHADOW);
  //   params.forEach(p => {
  //     p.
  //   });
  // }
  getSHadows() {
    let res = [{
      key: 'spt',
      value: 'spot light'
    }, {
      key: 'dir',
      value: 'directional light'
    }];
    return res;
  }
  getSceneUnits() {
    switch (this.resourceType) {
      case 6:
        {
          return 'meters';
        }
      case 7:
        {
          return 'meters';
        }
      case 1:
        {
          return 'meters';
        }
    }
    return 'scene units';
  }
  getValue(param, suffix) {
    const lp = AssetAdjustmentsService.LEGACY_PARAMS.find(i => i == param.id);
    if (lp) {
      const mapper = {
        hdr: 'type',
        exp: 'exposure',
        phcl: 'physicallyCorrectLights',
        'mesh-id': 'meshId',
        'shaded-wireframe': 'shadedWireframe',
        'hdr-intensity': 'intensity',
        'hdr-blur': 'blur',
        'hdr-format': 'format',
        'le-probe': 'lightProbe',
        hdrbg: 'background'
      };
      const id = mapper[param.id] || param.id;
      if (this.params && this.params[id]) return this.params[id];
      if (this.hdri && this.hdri[id]) return this.hdri[id];
    }
    let val = this.utils.getUrlParam(this.iframeModel.nativeElement.src, param.urlParam + (suffix ? suffix : ''));
    return val;
    // this.iframeModel.nativeElement.src = this.setUrlParam(this.iframeModel.nativeElement.src, param.urlParam, String(param.value));
  }
  restoreAll() {
    if (this.loading && !this.embed) return;
    this.loading = true;
    this.emitChanges(this.iframeModel.nativeElement.src, AdjustmentsSourceChangesState.discard);
    // let src = this.iframeModel.nativeElement.src;
    // this.parameters.forEach(param => {
    //   delete param.value;
    //   // this.controlsPosition.value = { x: 0, y: 0, z: 0 };
    //   this.cameraPosition.value = { x: 0, y: 0, z: 0 };
    //   // this.cameraRotation.value = { x: 0, y: 0, z: 0 };
    //   src = this.utils.setUrlParam(src, param.urlParam, null);
    //   if (param.hasColor) {
    //     src = this.utils.setUrlParam(src, param.urlParam + '-c', null);
    //     param.colorChanged = false;
    //   }
    //   if (param.hasHSL) {
    //     src = this.utils.setUrlParam(src, param.urlParam + '-hsl', null);
    //     param.HSLChanged = false;
    //     param.hsl = { x: null, y: null, z: null };
    //   }
    //   // this.changeSrc.emit(src);
    //   // if (param.urlParam == 'le-dir-p')
    //   //   param.value = { x: null, y: null, z: null };
    //   // if (param.urlParam == 'le-spt-p')
    //   //   param.value = { x: null, y: null, z: null };
    //   // if (param.urlParam == 'shadow-type')
    //   //   param.value = AssetAdjustmentsService.DEFAULT_SHADOW_TYPES;
    //   if (param.urlParam == 'dimensions')
    //     param.value = { x: null, y: null, z: null };
    // });
    // src = this.syncParams(src);
    // this.iframeModel.nativeElement.src = src;
    // this.emitChanges();
  }
  async emitChanges(src, state = AdjustmentsSourceChangesState.save) {
    src = src || this.iframeModel.nativeElement.src;
    // delete the legacy URL param
    src = this.utils.setUrlParam(src, 'materials-manipulations', null);
    let changes = {
      src: src,
      json_params: await this.getJsonParamsAsync(),
      targetResourceType: this.targetResourceType,
      state
    };
    this.changeSrc.emit(changes);
  }
  syncParams(src) {
    if (this.viewerType == 'b4w' && (this.utils.getUrlParam(src, 'distance_delta') || this.utils.getUrlParam(src, 'distance'))) src = this.utils.setUrlParam(src, 'preset', 'custom');else src = this.utils.setUrlParam(src, 'preset', null);
    if (!this.utils.getUrlParam(src, 'shaded-wireframe')) src = this.utils.setUrlParam(src, 'shaded-wireframe-c', null);
    if (!this.utils.getUrlParam(src, 'hdr')) {
      src = this.utils.setUrlParam(src, 'hdr-intensity', null);
      src = this.utils.setUrlParam(src, 'le-probe', null);
    }
    return src;
  }
  // saveValue(param: AssetAdjustments) {
  //   var src: string;
  //   if (param.hasToggle && !param.toggleState)
  //     src = this.utils.setUrlParam(this.iframeModel.nativeElement.src, param.urlParam, null);
  //   else {
  //     src = this.utils.setUrlParam(this.iframeModel.nativeElement.src, param.urlParam, String(param.value));
  //     if (param.hasColor && param.colorChanged)
  //       src = this.utils.setUrlParam(src, param.urlParam + '-c', String(param.color));
  //   }
  //   src = this.syncParams(src);
  //   this.changeSrc.emit(src);
  // }
  restoreValue(param) {
    delete param.value;
    let src = this.setUrlParam(this.iframeModel.nativeElement.src, param.urlParam, null);
    if (param.hasColor) {
      if (param.urlParam) src = this.setUrlParam(src, param.urlParam + '-c', null);
      param.colorChanged = false;
    }
    if (param.hasHSL) {
      if (param.urlParam) src = this.setUrlParam(src, param.urlParam + '-hsl', null);
      param.HSLChanged = false;
      param.hsl = {
        x: null,
        y: null,
        z: null
      };
    }
    src = this.syncParams(src);
    this.emitChanges(src);
    // this.changeSrc.emit(src);
  }
  setUrlParam(url, name, value) {
    if (name) return this.utils.setUrlParam(url, name, value);
    return url;
  }
  getCurrentSrc() {
    let src = this.iframeModel.nativeElement.src,
      isSetDistance = false;
    if (this._threeVersion) src = this.utils.setUrlParam(src, 'tv', String(this._threeVersion));
    if (this._engine && this._engine !== RenderingEngine.THREE) {
      src = this.utils.setUrlParam(src, 'engine', String(this._engine));
      if (this._babylonVersion) src = this.utils.setUrlParam(src, 'bv', String(this._babylonVersion));
    }
    if (this._tech && this._tech !== RenderTechnology.WebGL) src = this.utils.setUrlParam(src, 'rt', String(this._tech));
    for (let param of this.parameters) {
      let handled = false;
      if (param.hasColor && param.colorChanged && this.isToggleRelevant(param)) {
        handled = true;
        if (param.urlParam) src = this.setUrlParam(src, param.urlParam + '-c', param.color);
      }
      if (param.hasHSL && param.HSLChanged && this.isToggleRelevant(param) && param.hsl) {
        handled = true;
        src = this.setUrlParam(src, param.urlParam + '-hsl', param.hsl.x + ',' + param.hsl.y + ',' + param.hsl.z);
      }
      if (param.hasToggle) {
        handled = true;
        if (param.value && this.isToggleRelevant(param)) {
          if (param.type == AssetAdjustmentType.VECTOR3) {
            if (param.value && (param.value.x || param.value.y || param.value.z))
              // if (param.value)
              src = this.setUrlParam(src, param.urlParam, (param.value.x ? this.utils.trimIfFloat(param.value.x, param.round) : '') + ',' + (param.value.y ? this.utils.trimIfFloat(param.value.y, param.round) : '') + ',' + (param.value.z ? this.utils.trimIfFloat(param.value.z, param.round) : ''));else src = this.setUrlParam(src, param.urlParam, '');
          } else src = this.setUrlParam(src, param.urlParam, String(param.value));
        } else src = this.setUrlParam(src, param.urlParam, null);
      }
      if (param.type == AssetAdjustmentType.TOGGLE) {
        handled = true;
        if (param.value && this.isToggleRelevant(param)) src = this.setUrlParam(src, param.urlParam, String(param.options && param.options.value ? param.options.value : param.value));else if (!String(param.options && param.options.value)) src = this.setUrlParam(src, param.urlParam, null);else if (!param.value && this.isToggleRelevant(param)) src = this.setUrlParam(src, param.urlParam, null);
      } else if (typeof param.value === 'number' && !isNaN(param.value) && this.isToggleRelevant(param)) {
        handled = true;
        src = this.setUrlParam(src, param.urlParam, String(param.value));
        if (param.action == 'setDistance') isSetDistance = true;
      } else if (param.type == AssetAdjustmentType.JOYSTICK) {
        handled = true;
        if (param.value && param.value && typeof param.value.x === 'number' && typeof param.value.y === 'number' && typeof param.value.z === 'number' && this.isToggleRelevant(param)) {
          src = this.setUrlParam(src, param.urlParam, this.utils.trimIfFloat(param.value.x, param.round) + ',' + this.utils.trimIfFloat(param.value.y, param.round) + ',' + this.utils.trimIfFloat(param.value.z, param.round));
        }
      } else if (param.type == AssetAdjustmentType.CUSTOM) {
        handled = true;
        // switch (param.name) {
        //   case 'pivot': {
        //     if (param.value && Object.keys(param.value).length > 0 && (param.value.x !== 0 && param.value.y !== 0 && param.value.z !== 0)) {
        //       src = this.utils.setUrlParam(src, param.urlParam, (String(param.value.x) || '0') + ',' + (String(param.value.y) || '0') + ',' + (String(param.value.z) || '0'));
        //     }
        //     break;
        //   }
        // }
      } else if (param.multiple) {
        handled = true;
        src = this.setUrlParam(src, param.urlParam, String(this.utils.trimIfFloat(param.value, param.round)));
      }
      if (!handled) {
        if (param.value || param.value === 0) {
          src = this.setUrlParam(src, param.urlParam, String(this.utils.trimIfFloat(param.value, param.round)));
        } else if (!param.value && param.value !== 0 && this.utils.getUrlParam(src, param.urlParam)) {
          src = this.setUrlParam(src, param.urlParam, null);
        }
      }
    }
    if (isSetDistance) src = this.setUrlParam(src, 'preset', 'custom');
    const remove = ['min-distance', 'max-distance', 'embed-loader', 'plane', 'plane-r', 'plane-p', 'plane-p-o', 'plane-p-dim', 'plane-p-h', 'plane-p-d', 'plane-p-b', 'refraction', 'shadow-type', 'le-spt', 'le-amb', 'le-dir', 'le-pnt', 'le-hem', 'le-spt-c', 'le-amb-c', 'le-dir-c', 'le-pnt-c', 'le-hem-c', 'undefined'];
    remove.forEach(p => src = this.setUrlParam(src, p, null));
    src = src.replace('&=undefined', '');
    // src = this.setUrlParam(src, 'hdr', null);
    // src = this.setUrlParam(src, 'hdr-intensity', null);
    // src = this.setUrlParam(src, 'hdr-blur', null);
    // src = this.setUrlParam(src, 'le-probe', null);
    AssetAdjustmentsService.LEGACY_PARAMS.forEach(lp => src = this.setUrlParam(src, lp, null));
    // if (this.utils.getUrlParam(src, 'le-probe') == '0')
    //   src = this.setUrlParam(src, 'le-probe', null);
    src = this.syncParams(src);
    return src;
  }
  async saveAll() {
    if (this.loading) return;
    this.recoverMuteLights();
    let src = this.getCurrentSrc();
    await this.refreshLightsSummary();
    if (this._meshChanged || this._materialChanged || this._textureImageChanged || this.mappingChanged || this.meshCompress || this.imageCompress || this.scaleFactor && this.scaleFactor !== 1.0 || JSON.stringify(this._initEmbeddedLights) !== JSON.stringify(this.getEmbeddedLights())) {
      this.mappingChanged = true;
      this.export({
        useOptipng: this.hasImagesChanged() ? true : this.useOptipng
      });
      this.loading = true;
      this.exportedQuery = this.utils.getParamsFromUrl(src);
      await this.refreshLightsSummary();
      return;
    }
    this.loading = true;
    // else if (src != this.iframeModel.nativeElement.src || this.materialManipulationsChanges || this.mappingChanged || this.lightsChanges || this.meshManipulationsChanges) {
    // if lights hasn't changed we can save
    await this.refreshLightsSummary();
    this.emitChanges(src);
    // }
    // else {
    //   let data: Notification = {
    //     text: 'no changes have been made, nothing to save',
    //     type: NotificationType.Info,
    //     action: 'OK'
    //   }
    //   this.broadcaster.next({ key: 'notifyUser', data });
    // }
  }
  // clearMaterialManipulations() {
  //   if (this.materialManipulations) {
  //     for (let i in this.materialManipulations) {
  //       for (let j in this.materialManipulations[i]) {
  //         // color, alphaTest and transparent are being saved directly to the glTF
  //         if (j !== 'color' && j !== 'alphaTest' && j !== 'transparent')
  //           delete this.materialManipulations[i][j];
  //       }
  //     }
  //   }
  // }
  isToggleRelevant(param) {
    if (!param.hasToggle) return true;
    return !!param.toggleState;
  }
  changeColor(param, event) {
    param.colorChanged = true;
    // this._materialChanged = true;
    this.changeValue(param, event);
  }
  changeHSL(param, event, field) {
    param.HSLChanged = true;
    this.changeValue(param, event, {
      hslField: field
    });
  }
  // private initSnapshot() {
  //   this.snapshots = [];
  //   // this.actionsLog = [];
  //   // this.actionsLogIndex = 0;
  //   this.snapshotIndex = 0;
  //   this._hasUndo = false;
  //   this._hasRedo = false;
  // }
  onTextureChange(texture) {
    if (texture && this.texturedMaterials[this.materials[this.currentMaterialIndex].name] && this.texturedMaterials[this.materials[this.currentMaterialIndex].name][texture.type] && this.texturedMaterials[this.materials[this.currentMaterialIndex].name][texture.type].src && !this.texturesSrcs.find(s => s == this.texturedMaterials[this.materials[this.currentMaterialIndex].name][texture.type].src)) {
      this.texturesSrcs.push(this.texturedMaterials[this.materials[this.currentMaterialIndex].name][texture.type].src);
    }
    let intensity = null;
    if (typeof texture.channel === 'number') intensity = texture.intensity;else if (texture) {
      if (texture.name && this.activeTextures[texture.name]) intensity = this.activeTextures[texture.name].intensity;else intensity = texture.intensity;
    }
    let options = {
      map: texture ? texture.relatedType ? texture.relatedType : texture.type : null,
      intensity: intensity,
      textureSrc: texture && this.activeTextures[texture.name] ? this.activeTextures[texture.name].src : null,
      textureName: texture ? texture.name : null,
      srcChange: texture ? texture.srcChange : false,
      channel: texture.channel,
      aoManipulation: texture.aoManipulation,
      roughnessManipulation: texture.roughnessManipulation,
      metalnessManipulation: texture.metalnessManipulation,
      mapManipulation: texture.mapManipulation,
      normalManipulation: texture.normalManipulation,
      emissiveManipulation: texture.emissiveManipulation,
      // 'hueFactor': texture.hueFactor,
      color: texture['color'],
      videoSrc: texture.videoSrc,
      toggleFlipY: texture.toggleFlipY,
      animatedTexture: texture.animatedTexture
    };
    if (texture.animatedTexture) {
      if (!this.texturesAnimations[this.materials[this.currentMaterialIndex].name]) this.texturesAnimations[this.materials[this.currentMaterialIndex].name] = {};
      this.texturesAnimations[this.materials[this.currentMaterialIndex].name][options.map] = texture.animatedTexture;
      // this.texturesAnimations[this.materials[this.currentMaterialIndex].name] = texture.animatedTexture;
    } else if (texture.type !== 'envMap') {
      this._textureImageChanged = true;
    } else {
      if (!this.materialManipulations[this.materials[this.currentMaterialIndex].name]) this.materialManipulations[this.materials[this.currentMaterialIndex].name] = {};
      this.materialManipulations[this.materials[this.currentMaterialIndex].name].envMap = {
        envMapIntensity: options.intensity,
        imageSrc: options.textureSrc
      };
    }
    if (texture && typeof texture.flipY === 'boolean') options.flipY = texture.flipY;
    this.postToChild('mapTextureToMaterial', {
      texture: texture ? texture.src : null,
      material: this.materials[this.currentMaterialIndex].name,
      options: options
    });
    if (texture.videoSrc) this.materialManipulations[this.materials[this.currentMaterialIndex].name].video = {
      mapType: options.map,
      src: texture.videoSrc
    };
    // this.counter++;
    // if (!ignoreSnapshot) {
    //   let data = new SnapshotData();
    //   data.texture = {
    //     texture: this.utils.deepCopyByValue(texture),
    //     texturedMaterials: this.utils.deepCopyByValue(this.texturedMaterials)
    //   } as TextureSnapshot;
    //   this.takeSnapshot(SnapshotType.TEXTURE, data);
    // }
    // else {
    //   this.counter++;
    // }
  }
  // private takeSnapshot(type: SnapshotType, data: SnapshotData, replaceLast = false) {
  //   data = data || new SnapshotData();
  //   if (!this.parameters || !this.parameters.length) return;
  //   // let base = this.actionsLog.length - 1;
  //   // if (!replaceLast)
  //   //   base++;
  //   // let pop = base - this.actionsLogIndex;
  //   let pop = (this.snapshots.length - 1) - this.snapshotIndex;
  //   for (let i = 0; i < pop; i++) {
  //     this.snapshots.pop();
  //     // this.actionsLogIndex--;
  //   }
  //   let nextParams = this.utils.deepCopyByValue(this.parameters) as Array<AssetAdjustments>;
  //   let nextChildrenParentDictionary = this.utils.deepCopyByValue(this._childrenParentDictionary) as { [id: string]: Array<AssetAdjustments> };
  //   let nextMesh = this.utils.deepCopyByValue(this.meshesData) as { [id: string]: MeshData; };
  //   let nextMaterial = this.utils.deepCopyByValue(this.materialManipulations) as { [id: string]: ThreeMaterialManipulation; };
  //   if (replaceLast && this.snapshots.length) {
  //     let obj = new SnapshotData();
  //     obj.fieldName = this.snapshots[this.snapshots.length - 1].data.fieldName;
  //     obj.parentName = data.parentName;
  //     this.snapshots[this.snapshots.length - 1] = { data: obj, parameters: nextParams, childrenParentDictionary: nextChildrenParentDictionary, type, meshesData: nextMesh, materialManipulations: nextMaterial };
  //     // this.snapshots[this.snapshots.length - 1] = { fieldName: this.snapshots[this.snapshots.length - 1].fieldName, parameters: nextParams, childrenParentDictionary: nextChildrenParentDictionary, parentName, type };
  //   }
  //   else {
  //     let lastSnapshot = this.snapshots[this.snapshots.length - 1];
  //     // let lastParam = lastSnapshot ? lastSnapshot.parameters.find(p => p.name == fieldName) : null;
  //     let lastParam = lastSnapshot ? lastSnapshot.parameters.find(p => p.name == data.fieldName) : null;
  //     // let nextParam = nextParams.find(p => p.name == fieldName);
  //     let nextParam = nextParams.find(p => p.name == data.fieldName);
  //     if (lastParam && lastParam.value == nextParam.value) {
  //       // do not add duplicate!
  //     }
  //     else {
  //       let obj = new SnapshotData();
  //       obj.fieldName = data.fieldName;
  //       obj.parentName = data.parentName;
  //       obj.textureProps = data.textureProps;
  //       obj.material = data.material;
  //       obj.mesh = data.mesh;
  //       obj.texture = data.texture;
  //       this.snapshots.push({ parameters: nextParams, childrenParentDictionary: nextChildrenParentDictionary, data: obj, type, meshesData: nextMesh, materialManipulations: nextMaterial });
  //       // this.snapshots.push({ fieldName: fieldName, parameters: nextParams, childrenParentDictionary: nextChildrenParentDictionary, parentName, type });
  //     }
  //   }
  //   this.snapshotIndex = this.snapshots.length - 1;
  //   this._hasRedo = false;
  // }
  changeValue(param, event, data) {
    if (this.ignoreChanges) return;
    // if (param.urlParam && param.urlParam.indexOf('plane-p-') > -1)
    //   param = this.parameters.find(p => p.urlParam == 'plane');
    // if (param.isLight || (param.parent && param.parent.isLight))
    if (this.orgSrc && param.id && AssetAdjustmentsService.LEGACY_PARAMS.find(lp => lp === param.id)) {
      this._orgSrc = this.utils.setUrlParam(this.orgSrc, param.id, null);
      this._orgSrc = this.utils.setUrlParam(this.orgSrc, param.id + '-c', null);
      this._params = null;
      this._hdri = null;
      if (!this._annotations) this._annotations = [];
      if (!this._meshActions) this._meshActions = [];
    }
    if (param.isLight || this._childrenParentDictionary[param.name] && this._childrenParentDictionary[param.name].find(p => p.isLight)) this.lightsChanges = true;
    if (param.type == AssetAdjustmentType.SLIDER && param.step) {
      if (data && data.hslField && param.hasHSL) {
        param.hsl = param.hsl || {
          x: null,
          y: null,
          z: null
        };
        param.hsl[data.hslField] = this.round(typeof param.round === 'number' ? param.round : param.step, param.hsl[data.hslField]);
        if (event && typeof event.value === 'number') param.hsl[data.hslField] = this.round(typeof param.round === 'number' ? param.round : param.step, event.value);
      } else {
        param.value = this.round(typeof param.round === 'number' ? param.round : param.step, param.value);
        if (event && typeof event.value === 'number') param.value = this.round(typeof param.round === 'number' ? param.round : param.step, event.value);
      }
    } else if (param.type == AssetAdjustmentType.CUSTOM) {
      // switch (param.name) {
      //   case 'pivot': {
      //     param.value.x = this.round(param.step, param.value.x * 1);
      //     param.value.y = this.round(param.step, param.value.y * 1);
      //     param.value.z = this.round(param.step, param.value.z * 1);
      //     if (event && typeof event.value === 'number' && data) {
      //       param.value[data] = this.round(param.step, event.value * 1);
      //     }
      //     break;
      //   }
      // }
    }
    let obj = {
      action: param.action,
      options: param.options || {}
    };
    if (!obj.options.index) obj.options.index = obj.index;
    if (param.sector === AssetAdjustmentSector.POSTPROCESSING) {
      delete this._svgPostprocessing;
      obj.value = this.svgPostprocessing;
    } else if (param.id == 'hdr' || param.id == 'hdr-intensity' || param.id == 'hdr-blur' || param.id == 'hdr-blur' || param.id == 'hdr-format' || param.id == 'hdr-skybox' || param.id == 'hdr-rotation') {
      let intensity = this.parameters.find(x => x.id == 'hdr-intensity');
      let blur = this.parameters.find(x => x.id == 'hdr-blur');
      let format = this.parameters.find(x => x.id == 'hdr-format');
      let probe = this.parameters.find(x => x.id == 'le-probe');
      let rotation = this.parameters.find(x => x.id == 'hdr-rotation');
      let refraction = this.parameters.find(x => x.id == 'refraction');
      let skybox = this.parameters.find(x => x.id == 'hdr-skybox');
      obj.value = this.parameters.find(x => x.id == 'hdr').value;
      this.ignoreChanges = true;
      if (obj.value) {
        intensity.disabled = false;
        blur.disabled = false;
        format.disabled = false;
        probe.disabled = false;
        if (this.engine === RenderingEngine.THREE && this.threeVersion >= 162 || this.engine === RenderingEngine.BABYLON) rotation.disabled = false;
        skybox.disabled = false;
        if (refraction) refraction.disabled = false;
        obj.options = {
          intensity: intensity.value,
          blur: blur.value,
          format: format.value,
          skybox: skybox.value,
          rotation: rotation.value
        };
      } else {
        intensity.disabled = true;
        probe.disabled = true;
        rotation.disabled = true;
        skybox.disabled = true;
      }
      this.ignoreChanges = false;
    } else {
      if (param.hasColor && param.colorChanged) obj.options.color = param.color;
      if (param.hasHSL && param.HSLChanged) obj.options.hsl = param.hsl;
      if (param.hasToggle) obj.options.active = param.toggleState;
      obj.options.index = param.index;
      if (this.viewerType == '3JS') {
        switch (param.type) {
          // case AssetAdjustmentType.CUSTOM: {
          //   switch (param.name) {
          //     case 'pivot': {
          //       obj.value = param.value;
          //       this.parameters.find(p => p.name == param.name).value = obj.value;
          //       break;
          //     }
          //   }
          //   break;
          // }
          case AssetAdjustmentType.JOYSTICK:
            {
              if (param.value) {
                if (param.value.x) param.value.x = this.utils.trimIfFloat(param.value.x, param.round);
                if (param.value.y) param.value.y = this.utils.trimIfFloat(param.value.y, param.round);
                if (param.value.z) param.value.z = this.utils.trimIfFloat(param.value.z, param.round);
              }
              obj.options['position'] = param.value;
              obj.value = param.value;
              break;
            }
          default:
            {
              obj.value = param.value;
              if (obj.value && obj.options.value && param.type == AssetAdjustmentType.TOGGLE) obj.value = obj.options.value;
              break;
            }
        }
        // if (param.action == 'setFOV') {
        //   if (this.isDistanceFree) {
        //     this.isDistanceFree = false;
        //     this.toggleDistance();
        //   }
        // }
        // obj.value = param.value;
      } else {
        if (param.action == 'setDistance') {
          let distParams = this.parameters.filter(p => p.action == 'setDistance');
          for (let p of distParams) {
            if (typeof p.value === 'number' && !isNaN(p.value)) obj.options[p.urlParam] = this.utils.trimIfFloat(p.value, param.round);
          }
        } else {
          switch (param.name) {
            case 'light':
              {
                obj.value = param.value;
                obj.options = {
                  fixed_value: true
                };
                break;
              }
            default:
              {
                for (let p of this.parameters) {
                  if (p.name != 'light' && typeof p.value === 'number' && !isNaN(p.value)) obj.options[p.name] = this.utils.trimIfFloat(p.value, param.round);
                }
                obj.options[param.name] = this.utils.trimIfFloat(param.value, param.round);
              }
          }
        }
      }
      if (param.action == 'togglePlane') {
        let planeParam, planeMirrorParam, planePhysicalParam, planeSideParam;
        if (param.id != 'plane-r') planeMirrorParam = this.parameters.find(p => p.id == 'plane-r');else planeMirrorParam = param;
        if (param.id != 'plane-s') planeSideParam = this.parameters.find(p => p.id == 'plane-s');else planeSideParam = param;
        if (param.id != 'plane-p') planePhysicalParam = this.parameters.find(p => p.id == 'plane-p');else planePhysicalParam = param;
        if (param.id != 'plane') planeParam = this.parameters.find(p => p.id == 'plane');else planeParam = param;
        // if (param.urlParam == 'plane-r') {
        //   planeMirrorParam = param;
        //   planeParam = this.parameters.find(p => p.urlParam == 'plane');
        // }
        // else {
        //   planeParam = param;
        //   planeMirrorParam = this.parameters.find(p => p.urlParam == 'plane-r');
        // }
        obj = {
          action: planeParam.action,
          options: planeParam.options || {}
        };
        obj.options.active = planeParam.toggleState;
        obj.options.color = planeParam.color;
        obj.value = planeParam.value;
        obj.options.opacity = planeParam.value;
        obj.options.reflector = planeMirrorParam.value;
        obj.options.side = planeSideParam.value;
        obj.options.physical = planePhysicalParam.value;
        let opacity = planePhysicalParam.children.find(p => p.id == 'plane-p-o'),
          dim = planePhysicalParam.children.find(p => p.id == 'plane-p-dim'),
          height = planePhysicalParam.children.find(p => p.id == 'plane-p-h'),
          darkness = planePhysicalParam.children.find(p => p.id == 'plane-p-d');
        obj.options.physicalOptions = {
          blur: planePhysicalParam.children.find(p => p.id == 'plane-p-b').value,
          darkness: darkness ? darkness.value : null,
          opacity: opacity ? opacity.value : 1,
          dim: dim ? dim.value : 512,
          height: height ? height.value : null
        };
        this.shadowPlane = obj.options;
        // obj.options.physicalOptions = ;
      }
    }
    // if (param.action == 'setShadowType') {
    //   let dim = this.parameters.find(p => p.urlParam == 'shadow-map-dim');
    //   let type = this.parameters.find(p => p.urlParam == 'shadow-type');
    //   obj.value = type.value;
    //   obj.options = {
    //     dim: dim.value
    //   };
    // }
    if (!this.mhService.postToChild(obj, this.iframeModel.nativeElement)) {
      this.recoverIframeConnection();
      this.mhService.postToChild(obj, this.iframeModel.nativeElement);
    }
    // if (!ignoreLog && !this._ignoreLog.find(a => a.name == param.name)) {
    //   let p = this.getParent(param.name);
    //   let data = new SnapshotData();
    //   data.fieldName = param.name;
    //   data.parentName = p ? p.name : null;
    //   this.takeSnapshot(this.getParent(param.name) ? SnapshotType.PARAMETERS_WITH_PARENT : SnapshotType.PARAMETERS, data);
    //   this.setHasUndo();
    // }
  }
  // undo() {
  //   if (this.hasUndo) {
  //     this.exeLog(false);
  //     this._hasRedo = true;
  //     this.setHasUndo();
  //   }
  // }
  // redo() {
  //   if (this.hasRedo) {
  //     this.exeLog(true);
  //     this.setHasRedo();
  //     this.setHasUndo();
  //   }
  // }
  // private setHasUndo() {
  //   this._hasUndo = !!this.snapshotIndex;
  // }
  // private setHasRedo() {
  //   if (this.snapshotIndex < this.snapshots.length - 1)
  //     this._hasRedo = true;
  //   else
  //     this._hasRedo = false;
  // }
  // private exeLog(isRedo: boolean): boolean {
  //   let sourceSnapshot: Snapshot, fieldName: string, parentName: string, meshData: MeshSnapshot, materialSnapshot: MaterialSnapshot, type: SnapshotType;
  //   if (isRedo) {
  //     this.snapshotIndex++;
  //     sourceSnapshot = this.snapshots[this.snapshotIndex];
  //     fieldName = this.snapshots[this.snapshotIndex].data.fieldName;
  //     parentName = this.snapshots[this.snapshotIndex].data.parentName;
  //     meshData = this.snapshots[this.snapshotIndex].data.mesh;
  //     materialSnapshot = this.snapshots[this.snapshotIndex].data.material;
  //     type = this.snapshots[this.snapshotIndex].type;
  //   }
  //   else {
  //     sourceSnapshot = this.snapshots[this.snapshotIndex - 1];
  //     fieldName = this.snapshots[this.snapshotIndex].data.fieldName;
  //     parentName = this.snapshots[this.snapshotIndex].data.parentName;
  //     meshData = this.snapshots[this.snapshotIndex].data.mesh;
  //     materialSnapshot = this.snapshots[this.snapshotIndex].data.material;
  //     type = this.snapshots[this.snapshotIndex].type;
  //     this.snapshotIndex--;
  //   }
  //   if (sourceSnapshot) {
  //     let paramToUpdate, sourceParam;
  //     switch (type) {
  //       case SnapshotType.PARAMETERS:
  //       case SnapshotType.PARAMETERS_WITH_PARENT: {
  //         if (parentName) {
  //           paramToUpdate = this.getParent(parentName).children.find(p => p.name == fieldName);
  //           sourceParam = this.getParent(parentName, sourceSnapshot.childrenParentDictionary).children.find(p => p.name == fieldName);
  //         }
  //         else {
  //           paramToUpdate = this.parameters.find(p => p.name == fieldName);
  //           sourceParam = sourceSnapshot.parameters.find(p => p.name == fieldName);
  //         }
  //         let value;
  //         if (sourceParam.value === null && sourceParam.default !== null)
  //           value = this.utils.deepCopyByValue(sourceParam.default);
  //         else
  //           value = this.utils.deepCopyByValue(sourceParam.value);
  //         paramToUpdate.value = value;
  //         this.changeValue(paramToUpdate, null, null, true);
  //         break;
  //       }
  //       case SnapshotType.MESH: {
  //         // this.mainMesh = meshData.mainMesh;
  //         // this.selectedMesh = meshData.selectedMesh;
  //         this.setMeshProps(meshData.mesh, meshData.key, meshData.value, meshData.mappingChanged, meshData.refreshMaterials, true);
  //         break;
  //       }
  //       case SnapshotType.MATERIAL_MANIPULATION:
  //       case SnapshotType.MATERIAL: {
  //         if (materialSnapshot.materialManipulations) {
  //           this.materialManipulations = materialSnapshot.materialManipulations;
  //           this.onMaterialPropChange(materialSnapshot.mappingChanged, true);
  //         }
  //         if (materialSnapshot.materials) {
  //           this.materials = materialSnapshot.materials;
  //           this.setMaterialProps(materialSnapshot.materialProps.material, materialSnapshot.materialProps.key, materialSnapshot.materialProps.value, materialSnapshot.materialProps.mappingChanged, true);
  //         }
  //         break;
  //       }
  //       case SnapshotType.TEXTURE_PROPS: {
  //         if (materialSnapshot.materialManipulations) {
  //           this.materialManipulations = materialSnapshot.materialManipulations;
  //           this.onMaterialPropChange(materialSnapshot.mappingChanged, true);
  //         }
  //         if (materialSnapshot.materials) {
  //           this.materials = materialSnapshot.materials;
  //           this.setMaterialProps(materialSnapshot.materialProps.material, materialSnapshot.materialProps.key, materialSnapshot.materialProps.value, materialSnapshot.materialProps.mappingChanged, true);
  //         }
  //         break;
  //       }
  //       case SnapshotType.TEXTURE: {
  //         if (sourceSnapshot.data && sourceSnapshot.data.texture) {
  //           this.texturedMaterials = this.utils.deepCopyByValue(sourceSnapshot.data.texture.texturedMaterials);
  //           this.onTextureChange(sourceSnapshot.data.texture.texture, true);
  //         }
  //         break;
  //       }
  //       default: {
  //         return false;
  //       }
  //     }
  //   }
  //   return true;
  // }
  // getSidesRender(): Array<KeyValuePair> {
  //   let res = [{
  //     key: null,
  //     value: 'Default'
  //   }] as Array<KeyValuePair>;
  //   return res.concat(this.enums.getSidesRender());
  // }
  getTransparentRenderOrder() {
    let res = [{
      key: null,
      value: 'Default'
    }];
    return res.concat(this.enums.getTransparentRenderOrder());
  }
  // getAnisotropies(): Array<KeyValuePair> {
  //   let res = [{
  //     key: null,
  //     value: 'Default'
  //   },
  //   {
  //     key: 1,
  //     value: '1 sample per frame'
  //   },
  //   {
  //     key: 2,
  //     value: '2 samples per frame'
  //   },
  //   {
  //     key: 4,
  //     value: '4 samples per frame'
  //   },
  //   {
  //     key: 8,
  //     value: '8 samples per frame'
  //   },
  //   {
  //     key: 16,
  //     value: '16 samples per frame'
  //   }] as Array<KeyValuePair>;
  //   return res;
  // }
  round(stepNum, value) {
    if (typeof value !== 'number') return null;
    let step = String(stepNum);
    if (step.indexOf('.') > -1) {
      let round = 100 * step.substring(step.indexOf('.') + 1).length;
      value = Math.round(value * round) / round;
    }
    return value;
  }
  onConsole(type, args) {
    this.broadcaster.next({
      key: 'onConsole',
      data: {
        type,
        args
      }
    });
  }
  onPngCompress(data) {
    if (data.status === 'start') this._compressedFiles.started++;else {
      this._compressedFiles.ended++;
      this._compressedFiles.totalBytesStart = data.beforeBytes;
      this._compressedFiles.totalBytesEnd = data.afterBytes;
      this._compressedFiles.totalBytesSaved = this._compressedFiles.totalBytesStart - this._compressedFiles.totalBytesEnd;
      this._compressedFiles.totalPercentage = parseFloat((this._compressedFiles.totalBytesEnd / this._compressedFiles.totalBytesStart).toFixed(2));
    }
    this.broadcaster.next({
      key: 'onPngCompress',
      data
    });
  }
  setCurrentScreenshot(data) {
    this.broadcaster.next({
      key: 'setCurrentScreenshot',
      data
    });
  }
  onViewerFullyLoaded() {
    // this.initSnapshot();
    // this.takeSnapshot(SnapshotType.PARAMETERS_WITH_PARENT, null, true);
    this._meshChanged = false;
    this._materialChanged = false;
    this._textureImageChanged = false;
    if (this.mappingChanged) {
      this.mappingChanged = false;
      this.init();
      this.loading = false;
      this.currentMaterialIndex = null;
    }
    this.materials = null;
    this.textures = null;
    this.boundingBox = null;
    this.meshesData = null;
    this.soloLights = {};
    this._compressedFiles = new CompressedFilesState();
    this._initEmbeddedLights = null;
    this.postToChild('broadcastDistance');
    // this.postToChild('broadcastLights');
    this.postToChild('broadcastExposure');
    this.postToChild('broadcastBoundingBox');
    this.postToChild('broadcastCameraInfo');
    this.postToChild('broadcastMainMesh');
    // this.postToChild('getMeshesData');
    this.postToChild('broadcastUVsOverlap');
    this.postToChild('broadcastTexturesAnimations');
    this.postToChild('broadcastPolyCount');
    this.postToChild('broadcastMeshAnimations');
    // this.postToChild('broadcastMeshManipulations');
    this.postToChild('broadcastOverlapMeshesNames');
    this.postToChild('broadcastBloomEffectOptions');
    this.postToChild('broadcastFurEffectOptions');
    this.postToChild('broadcastSceneSummary');
    this.postToChild('broadcastAnnotations');
    this.postToChild('broadcastMeshActions');
    // this.postToChild('broadcastLightsSummary');
    if (this.currentTabIndex == AssetAdjustmentSector.MATERIALS || this.currentTabIndex == AssetAdjustmentSector.MESHES) {
      this.postToChild('broadcastMaterials');
      this.postToChild('broadcastTextures');
      this.getMeshesData();
    }
    if (this.currentTabIndex == AssetAdjustmentSector.HIERARCHY_TREE) {
      this.postToChild('broadcastSceneHierarchy');
    }
    this.loading = false;
    if (this.embed) {
      this.pixel.next({
        event_type: 'embeddedViewer'
      });
      this.onMaterialChange();
    }
    this._isViewerReady = true;
    this._isViewerLoaded = true;
    this._textureToMaterialTasks = 0;
    this.broadcaster.next({
      key: 'viewerFullyLoaded'
    });
    this._onViewerFullyLoaded.forEach(f => f());
    this._onViewerFullyLoaded = [];
  }
  broadcastViewerFullyLoaded() {
    return new Promise((resolve, reject) => {
      this._onViewerFullyLoaded.push(resolve);
    });
  }
  onMessage(obj) {
    switch (obj.action) {
      case 'viewerListening':
        {
          this.onViewerListening.forEach(f => f());
          break;
        }
      case 'viewerFullyLoaded':
        {
          this.onViewerFullyLoaded();
          break;
        }
      case 'onModelLoaded':
        {
          // Not sure we need this
          // this.onViewerFullyLoaded();
          break;
        }
      case 'setViewerFullyLoaded':
        {
          if (obj.data && this.loading) {
            this.onViewerFullyLoaded();
          }
          break;
        }
      case 'onPngCompress':
        {
          this.onPngCompress(obj.data);
          break;
        }
      case 'onConsole':
        {
          this.onConsole(obj.data.type, obj.data.args);
          break;
        }
      case 'setCurrentScreenshot':
        {
          this.setCurrentScreenshot(obj.data);
          break;
        }
      case 'viewerLoaded':
        {
          this._isViewerLoaded = true;
          this._textureToMaterialTasks = 0;
          this.broadcaster.next({
            key: 'viewerLoaded'
          });
          break;
        }
      case 'setMaterials':
        {
          this.setMaterials(obj.data);
          break;
        }
      case 'setTexturesAnimations':
        {
          this.setTexturesAnimations(obj.data);
          break;
        }
      case 'setPolyCount':
        {
          this._polyCount = obj.data[0];
          break;
        }
      case 'setPolyTypeDistribution':
        {
          this.setPolyTypeDistribution(obj.data);
          break;
        }
      case 'setDistance':
        {
          this.setDistance(obj.data);
          break;
        }
      // case 'setLights': {
      //   this.setLights(obj.data);
      //   break;
      // }
      case 'setExposure':
        {
          this.setExposure(obj.data);
          break;
        }
      case 'setBoundingBox':
        {
          this.setBoundingBox(obj.data);
          break;
        }
      case 'setCameraInfo':
        {
          this.setCameraInfo(obj.data);
          break;
        }
      case 'setMainMesh':
        {
          this.setMainMesh(obj.data);
          break;
        }
      case 'setTextures':
        {
          this.setTextures(obj.data);
          break;
        }
      case 'onMapTextureToMaterialDone':
        {
          if (this.activeTextures) this.activeTexturesBackup = Object.assign(this.activeTextures);
          if (obj.data) {
            if (obj.data.newTexture) {
              this.refreshMaterials = true;
              this.refreshTextures = true;
            }
            if (obj.data.numOfTasks) {
              this._textureToMaterialTasks = obj.data.numOfTasks;
            }
          }
          if (this.refreshMaterials) this.postToChild('broadcastMaterials');
          if (this.refreshTextures) this.postToChild('broadcastTextures');
          break;
        }
      case 'setMeshesData':
        {
          this.setMeshesData(obj.data);
          break;
        }
      case 'setModel':
        {
          this.setModel(obj.data);
          break;
        }
      case 'setUVsOverlap':
        {
          this.setUVsOverlap(obj.data);
          break;
        }
      case 'subdivisionDone':
        {
          this.subdivisionDone();
          break;
        }
      case 'simplifyDone':
        {
          this.simplifyDone(obj.data);
          break;
        }
      case 'setSceneSummary':
        {
          this.setSceneSummary(obj);
          break;
        }
      case 'setSVGPostprocessing':
        {
          this.setSVGPostprocessing(obj);
          break;
        }
      case 'onLightsSummary':
        {
          this.onLightsSummary(obj.data.lights, obj.data.reBuild, obj.data.initEmbedded);
          break;
        }
      case 'onPosition':
        {
          this.onPosition.forEach(r => r(obj.data));
          this.onPosition = [];
          break;
        }
      case 'setMeshAnimations':
        {
          this.setMeshAnimations(obj.data);
          break;
        }
      // case 'setMeshManipulations': {
      //   break;
      // }
      case 'setShadowPlane':
        {
          this.shadowPlane = obj.data;
          break;
        }
      case 'setSceneHierarchy':
        {
          this._sceneHierarchy = obj.data;
          break;
        }
      case 'onMaterialManipulations':
        {
          this.onMaterialManipulations(obj.data);
          break;
        }
      case 'setSelectedMesh':
        {
          this.setSelectedMesh(obj.data);
          break;
        }
      case 'setMaterialManipulations':
        {
          this.setMaterialManipulations(obj.data);
          break;
        }
      case 'onGltfValidation':
        {
          this.onGltfValidation(obj.data);
          break;
        }
      case 'onModelLoadError':
        {
          this.onModelLoadError(obj.data);
          break;
        }
      case 'setNumOfTransparentMaterials':
        {
          this.onNumOfTransparentMaterials.forEach(f => f(obj.data));
          this.onNumOfTransparentMaterials = [];
          break;
        }
      case 'setDiffuseThreshold':
        {
          this.onSetDiffuseThreshold.forEach(f => f(obj.data));
          this.onSetDiffuseThreshold = [];
          break;
        }
      case 'setFixImageThreshold':
        {
          this.onFixImageThreshold.forEach(f => f(obj.data));
          this.onFixImageThreshold = [];
          break;
        }
      case 'setAdjustImageRGB':
        {
          this.onAdjustImageRGB.forEach(f => f(obj.data));
          this.onAdjustImageRGB = [];
          break;
        }
      case 'setRevertImageRGB':
        {
          this.onRevertImageRGB.forEach(f => f(obj.data));
          this.onRevertImageRGB = [];
          break;
        }
      case 'onControlsPositionEnd':
        {
          this.onControlsPositionEnd.forEach(f => f(obj.data));
          this.onControlsPositionEnd = [];
          break;
        }
      case 'onMultipleUV':
        {
          this.broadcaster.next({
            key: 'onMultipleUV',
            data: obj.data
          });
          break;
        }
      case 'notifyUser':
        {
          this.broadcaster.next({
            key: 'notifyUser',
            data: obj.data
          });
          break;
        }
      case 'setOverlapMeshesNames':
        {
          this.setOverlapMeshesNames(obj.data);
          break;
        }
      case 'setBloomEffectOptions':
        {
          if (obj.data) this.meshBloomOptions = obj.data;
          break;
        }
      case 'setFurEffectOptions':
        {
          if (obj.data) this.meshFurOptions = obj.data;
          break;
        }
      case 'setAnnotations':
        {
          this._annotations = obj.data;
          break;
        }
      case 'setMeshActions':
        {
          this._meshActions = obj.data;
          break;
        }
      case 'setCollisions':
        {
          if (obj.data) {
            // Support old format for viewer cache issues
            if (obj.data instanceof Array) {
              this._collisions = obj.data;
            }
            // This is the new format
            else {
              this._collisions = obj.data.collisions;
              this._collisionsData = {
                boundigBox: obj.data.boundigBox,
                maxDistance: obj.data.maxDistance,
                meanDistance: obj.data.meanDistance,
                xLength: obj.data.xLength,
                yLength: obj.data.yLength,
                zLength: obj.data.zLength,
                maxDistanceX: obj.data.maxDistanceX,
                maxDistanceY: obj.data.maxDistanceY,
                maxDistanceZ: obj.data.maxDistanceZ
              };
            }
          }
          break;
        }
    }
  }
  async getNumOfTransparentMaterials() {
    return new Promise((resolve, reject) => {
      this.onNumOfTransparentMaterials.push(resolve);
      this.postToChild('broadcastNumOfTransparentMaterials');
    });
  }
  updateMeshAnimations() {
    this.postToChild('updateMeshAnimations', {
      meshAnimations: this.meshAnimations
    });
  }
  updateShadowPlane() {
    this.postToChild('togglePlane', {
      options: this.shadowPlane
    });
  }
  applyShadowPlane() {
    const planeR = this.parameters.find(p => p.id == 'plane-r');
    if (planeR) this.changeValue(planeR);
    const planeP = this.parameters.find(p => p.id == 'plane-p');
    if (planeP) this.changeValue(planeP);
    const plane = this.parameters.find(p => p.id == 'plane');
    if (plane) this.changeValue(plane);
  }
  displayFiles(files) {
    this.postToChild('displayFiles', {
      files: files
    }, new PostToChildOptions$1(false));
  }
  onModelLoadError(obj) {
    this._modelLoadError = obj.error;
    this.broadcaster.next({
      key: 'onModelLoadError',
      data: obj.error
    });
  }
  get modelLoadError() {
    return this._modelLoadError;
  }
  onGltfValidation(obj) {
    this._gltfValidationResponse = obj;
    this.broadcaster.next({
      key: 'GltfValidation',
      data: this.gltfValidationResponse
    });
  }
  get gltfValidationResponse() {
    return this._gltfValidationResponse;
  }
  setMaterialManipulations(obj) {
    this.materials.forEach(m => {
      if (obj[m.name]) {
        this.materialManipulations[m.name] = obj[m.name];
        if (obj[m.name].video && m[obj[m.name].video.mapType]) {
          m[obj[m.name].video.mapType].videoSrc = obj[m.name].video.src;
        }
      }
    });
    this.resolveAfterSetMaterialManipulations.forEach(f => f());
    this.resolveAfterSetMaterialManipulations = [];
    // this.materialManipulations = obj;
  }
  setSelectedMesh(obj) {
    if (this.meshesData) {
      if (!obj.name) this.selectedMesh = null;else {
        for (let i in this.meshesData) {
          if (i == obj.name) {
            this.selectedMesh = this.meshesData[i];
          }
        }
      }
    }
    // if (this.selectedMesh && this.meshManipulations && this.meshManipulations[this.selectedMesh.name]) {
    //   for (let key in this.meshManipulations[this.selectedMesh.name]) {
    //     if (AssetAdjustmentsService.MESH_MANIPULATIONS_RELEVANT_FIELDS.find(i => i === key)) {
    //       this.selectedMesh[key] = this.meshManipulations[this.selectedMesh.name][key];
    //     }
    //   }
    // }
    this.onSelecteMeshByName.forEach(f => f());
    this.onSelecteMeshByName = [];
  }
  async selecteMeshByName(name) {
    return new Promise((resolve, reject) => {
      this.onSelecteMeshByName.push(resolve);
      this.postToChild('selecteMeshByName', {
        meshName: name
      });
    });
  }
  async getMeshesData() {
    return new Promise((resolve, reject) => {
      this.onGetMeshesData.push(resolve);
      this.postToChild('getMeshesData');
    });
  }
  async getMaterials() {
    return new Promise((resolve, reject) => {
      this._onSetMaterials.push(resolve);
      this.postToChild('broadcastMaterials');
    });
  }
  setMeshAnimations(obj) {
    this.meshAnimations = obj;
    this.hasMeshAnimations = !!Object.keys(obj).length;
  }
  // broadcastMeshManipulations(obj: { [id: string]: MeshData }) {
  //   this.meshManipulations = obj;
  // }
  setOverlapMeshesNames(obj) {
    let dictionary = [];
    for (let i in obj.dictionary) {
      if (obj.dictionary[i] > 1) dictionary.push({
        key: i,
        value: obj.dictionary[i]
      });
    }
    this.broadcaster.next({
      key: 'onOverlapMeshesNames',
      data: {
        dictionary: dictionary,
        negativeScaleCount: obj.negativeScaleCount
      }
    });
  }
  fixOverlapMeshesNames() {
    this.postToChild('fixOverlapMeshesNames');
    this._meshChanged = true;
    this.saveAll();
  }
  // fixNegativeScaleMeshes() {
  //   this.postToChild('fixNegativeScaleMeshes');
  //   this._meshChanged = true;
  //   this.saveAll();
  // }
  set params(options) {
    this._params = null;
    let phcl = this.parameters.find(p => p.id == 'phcl'),
      exposure = this.parameters.find(p => p.id == 'exp'),
      fov = this.parameters.find(p => p.id == 'fov'),
      wireframe = this.parameters.find(p => p.id == 'wireframe'),
      shadedWireframe = this.parameters.find(p => p.id == 'shaded-wireframe'),
      meshId = this.parameters.find(p => p.id == 'mesh-id'),
      xray = this.parameters.find(p => p.id == 'xray'),
      uv = this.parameters.find(p => p.id == 'uv'),
      grid = this.parameters.find(p => p.id == 'grid'),
      transparentRenderOrder = this.parameters.find(p => p.id == 'tsl'),
      dimensions = this.parameters.find(p => p.id == 'dimensions'),
      closeup = this.parameters.find(p => p.id == 'closeup'),
      matcap = this.parameters.find(p => p.id == 'matcap'),
      hideBottom = this.parameters.find(p => p.id == 'hide-bottom'),
      toneMapping = this.parameters.find(p => p.id == 'tone-mapping');
    phcl.value = options.physicallyCorrectLights;
    exposure.value = options.exposure;
    fov.value = options.fov;
    meshId.value = options.meshId;
    xray.value = options.xray;
    uv.value = options.uv;
    grid.value = options.grid;
    transparentRenderOrder.value = options.transparentRenderOrder;
    dimensions.toggleState = options.dimensions;
    dimensions.value = options.dimensionsValues;
    dimensions.color = options.dimensionsColor;
    closeup.value = options.closeup;
    matcap.value = options.matcap;
    hideBottom.value = options.hideBottom;
    toneMapping.value = options.toneMapping;
    wireframe.toggleState = options.wireframe;
    wireframe.color = options.wireframeColor;
    shadedWireframe.toggleState = options.shadedWireframe;
    shadedWireframe.color = options.shadedWireframeColor;
  }
  get params() {
    if (this._params) return this._params;
    let phcl = this.parameters.find(p => p.id == 'phcl'),
      exposure = this.parameters.find(p => p.id == 'exp'),
      fov = this.parameters.find(p => p.id == 'fov'),
      wireframe = this.parameters.find(p => p.id == 'wireframe'),
      shadedWireframe = this.parameters.find(p => p.id == 'shaded-wireframe'),
      meshId = this.parameters.find(p => p.id == 'mesh-id'),
      xray = this.parameters.find(p => p.id == 'xray'),
      uv = this.parameters.find(p => p.id == 'uv'),
      closeup = this.parameters.find(p => p.id == 'closeup'),
      matcap = this.parameters.find(p => p.id == 'matcap'),
      hideBottom = this.parameters.find(p => p.id == 'hide-bottom'),
      grid = this.parameters.find(p => p.id == 'grid'),
      transparentRenderOrder = this.parameters.find(p => p.id == 'tsl'),
      toneMapping = this.parameters.find(p => p.id == 'tone-mapping'),
      dimensions = this.parameters.find(p => p.id == 'dimensions');
    // Support legacy:
    const vPhcl = this.utils.getUrlParam(this.orgSrc, 'phcl');
    if (vPhcl) phcl.value = !!vPhcl;
    const vExposure = this.utils.getUrlParam(this.orgSrc, 'exp');
    if (vExposure) exposure.value = parseFloat(vExposure);
    const vFov = this.utils.getUrlParam(this.orgSrc, 'fov');
    if (vFov) fov.value = parseInt(vFov);
    const vWireframe = this.utils.getUrlParam(this.orgSrc, 'wireframe');
    if (vWireframe) wireframe.toggleState = vWireframe === 'true';
    const vShadedWireframe = this.utils.getUrlParam(this.orgSrc, 'shaded-wireframe');
    if (vShadedWireframe) shadedWireframe.toggleState = !!vShadedWireframe;
    const vMeshId = this.utils.getUrlParam(this.orgSrc, 'mesh-id');
    if (vMeshId) meshId.value = vMeshId === 'true';
    const vXray = this.utils.getUrlParam(this.orgSrc, 'xray');
    if (vXray) xray.value = vXray === 'true';
    const Uv = this.utils.getUrlParam(this.orgSrc, 'uv');
    if (Uv) uv.value = Uv === 'true';
    const vGrid = this.utils.getUrlParam(this.orgSrc, 'grid');
    if (vGrid) grid.value = vGrid === 'true';
    const vCloseup = this.utils.getUrlParam(this.orgSrc, 'closeup');
    if (vCloseup) closeup.value = vCloseup === 'true';
    const vMatcap = this.utils.getUrlParam(this.orgSrc, 'matcap');
    if (vMatcap) matcap.value = vMatcap === 'true';
    const vTsl = this.utils.getUrlParam(this.orgSrc, 'tsl');
    if (vTsl) transparentRenderOrder.value = parseInt(vTsl);
    const tm = this.utils.getUrlParam(this.orgSrc, 'tone-mapping');
    if (tm) toneMapping.value = parseInt(tm);
    const vDimensions = this.utils.getUrlParam(this.orgSrc, 'dimensions');
    if (vDimensions) dimensions.toggleState = vDimensions === 'true';
    const vDimensionsColor = this.utils.getUrlParam(this.orgSrc, 'dimensions-c');
    if (vDimensionsColor) dimensions.color = vDimensionsColor;
    // TODO fix this as it should always be string
    if (typeof dimensions.color === 'number') delete dimensions.color;
    if (typeof exposure.value === 'number') exposure.value *= 1;
    this._params = {
      physicallyCorrectLights: phcl.value,
      dimensions: dimensions.toggleState,
      dimensionsValues: dimensions.value,
      dimensionsColor: dimensions.color,
      transparentRenderOrder: transparentRenderOrder.value,
      toneMapping: toneMapping.value,
      exposure: exposure.value,
      fov: fov.value,
      wireframe: wireframe.toggleState,
      wireframeColor: wireframe.color,
      grid: grid.value,
      meshId: meshId.value,
      shadedWireframe: shadedWireframe.toggleState,
      shadedWireframeColor: shadedWireframe.color,
      xray: xray.value,
      uv: uv.value,
      closeup: closeup.value,
      matcap: matcap.value,
      hideBottom: hideBottom.value
    };
    return this._params;
  }
  applyParams() {
    let arr = this.parameters.filter(p => p.id == 'phcl' || p.id == 'exp' || p.id == 'fov' || p.id == 'wireframe' || p.id == 'shaded-wireframe' || p.id == 'mesh-id' || p.id == 'xray' || p.id == 'uv' || p.id == 'grid' || p.id == 'tsl' || p.id == 'dimensions' || p.id == 'tone-mapping' || p.id == 'hide-bottom' || p.id == 'closeup' || p.id == 'matcap');
    arr.forEach(p => {
      this.changeValue(p);
    });
  }
  set svgPostprocessing(options) {
    const defaults = new SVGPostprocessingOptions();
    if (!options) options = defaults;else {
      for (let i in options) {
        if (typeof options[i] !== 'number') options[i] = defaults[i];
      }
    }
    this._svgPostprocessing = null;
    const contrast = this.parameters.find(p => p.id == 'pp-contrast'),
      brightness = this.parameters.find(p => p.id == 'pp-brightness'),
      saturate = this.parameters.find(p => p.id == 'pp-saturate'),
      sepia = this.parameters.find(p => p.id == 'pp-sepia'),
      sharpen = this.parameters.find(p => p.id == 'pp-sharpen'),
      // gamma = this.parameters.find(
      //     (p) => p.id == 'pp-gamma'
      // ) as AssetAdjustments,
      hue = this.parameters.find(p => p.id == 'pp-hue'),
      gammaR = this.parameters.find(p => p.id == 'pp-gamma-r'),
      gammaRAmplitude = this.parameters.find(p => p.id == 'pp-gamma-r-amplitude'),
      gammaROffset = this.parameters.find(p => p.id == 'pp-gamma-r-offset'),
      gammaG = this.parameters.find(p => p.id == 'pp-gamma-g'),
      gammaGAmplitude = this.parameters.find(p => p.id == 'pp-gamma-g-amplitude'),
      gammaGOffset = this.parameters.find(p => p.id == 'pp-gamma-g-offset'),
      gammaB = this.parameters.find(p => p.id == 'pp-gamma-b'),
      gammaBAmplitude = this.parameters.find(p => p.id == 'pp-gamma-b-amplitude'),
      gammaBOffset = this.parameters.find(p => p.id == 'pp-gamma-b-offset'),
      gammaA = this.parameters.find(p => p.id == 'pp-gamma-a'),
      gammaASlope = this.parameters.find(p => p.id == 'pp-gamma-a-slope');
    contrast.value = options.contrast;
    brightness.value = options.brightness;
    saturate.value = options.saturate;
    sepia.value = options.sepia;
    sharpen.value = options.sharpen;
    // gamma.value = options.gamma;
    hue.value = options.hue;
    gammaRAmplitude.value = options.gammaRAmplitude;
    gammaROffset.value = options.gammaROffset;
    gammaGAmplitude.value = options.gammaGAmplitude;
    gammaGOffset.value = options.gammaGOffset;
    gammaBAmplitude.value = options.gammaBAmplitude;
    gammaBOffset.value = options.gammaBOffset;
    if (typeof options.gammaR === 'undefined') gammaR.value = options.gamma;else gammaR.value = options.gammaR;
    if (typeof options.gammaG === 'undefined') gammaG.value = options.gamma;else gammaG.value = options.gammaG;
    if (typeof options.gammaB === 'undefined') gammaB.value = options.gamma;else gammaB.value = options.gammaB;
    gammaA.value = options.gammaA || 0;
    gammaASlope.value = options.gammaASlope;
  }
  set hdri(options) {
    this._hdri = null;
    let hdr = this.parameters.find(p => p.id == 'hdr'),
      hdrIntensity = this.parameters.find(p => p.id == 'hdr-intensity'),
      hdrBlur = this.parameters.find(p => p.id == 'hdr-blur'),
      hdrFormat = this.parameters.find(p => p.id == 'hdr-format'),
      probe = this.parameters.find(p => p.id == 'le-probe'),
      hdrbg = this.parameters.find(p => p.id == 'hdrbg'),
      hdrSkybox = this.parameters.find(p => p.id == 'hdr-skybox'),
      hdrRotation = this.parameters.find(p => p.id == 'hdr-rotation');
    // refraction = this.parameters.find(x => x.id == 'refraction');
    hdr.value = options.type;
    if (hdr.value) hdr.value = parseInt(hdr.value);
    hdrIntensity.value = options.intensity;
    hdrBlur.value = options.blur;
    hdrFormat.value = options.format;
    probe.value = options.lightProbe;
    hdrbg.value = options.background;
    hdrSkybox.value = options.skybox;
    hdrRotation.value = options.rotation || {
      x: 0,
      y: 0,
      z: 0
    };
    if (hdr.value) {
      hdrIntensity.disabled = false;
      probe.disabled = false;
    } else {
      hdrIntensity.disabled = true;
      probe.disabled = true;
    }
    // if (typeof options.refraction === 'number') {
    //   let refraction = this.parameters.find(x => x.id == 'refraction');
    //   if (refraction)
    //     refraction.value = obj.data.hdr.refraction;
    // }
  }
  applyHDRI() {
    let arr = this.parameters.filter(p => p.id === 'hdr' || p.id === 'hdr-intensity' || p.id === 'hdr-blur' || p.id === 'le-probe' || p.id === 'hdrbg' || p.id === 'plane-r' || p.id === 'hdr-format' || p.id === 'hdr-skybox' || p.id === 'hdr-rotation');
    arr.forEach(p => {
      this.changeValue(p);
    });
  }
  get annotations() {
    // if (this._annotation)
    return this._annotations;
    // this._annotation = {
    //   // type: hdr.value,
    //   // background: hdrbg.value,
    //   // blur: hdrBlur.value,
    //   // intensity: hdrIntensity.value,
    //   // lightProbe: probe.value
    // };
    // return this._annotation;
  }
  set annotations(annotations) {
    this._annotations = annotations;
    // this.updateAnnotations();
  }
  get meshActions() {
    return this._meshActions;
  }
  set meshActions(meshActions) {
    this._meshActions = meshActions;
  }
  get collisions() {
    return this._collisions;
  }
  get collisionsData() {
    return this._collisionsData;
  }
  set collisions(collisions) {
    this._collisions = collisions;
    this.broadcaster.next({
      key: 'onCollisions',
      data: {
        collisions: this._collisions,
        data: this._collisionsData
      }
    });
  }
  updateAnnotations() {
    this.postToChild('updateAnnotations', {
      annotations: this._annotations
    });
  }
  updateMeshActions() {
    this.postToChild('updateMeshActions', {
      meshActions: this._meshActions
    });
  }
  get svgPostprocessing() {
    if (this._svgPostprocessing) return this._svgPostprocessing;
    const contrast = this.parameters.find(p => p.id == 'pp-contrast'),
      brightness = this.parameters.find(p => p.id == 'pp-brightness'),
      saturate = this.parameters.find(p => p.id == 'pp-saturate'),
      sepia = this.parameters.find(p => p.id == 'pp-sepia'),
      sharpen = this.parameters.find(p => p.id == 'pp-sharpen'),
      // gamma = this.parameters.find(
      //     (p) => p.id == 'pp-gamma'
      // ) as AssetAdjustments,
      hue = this.parameters.find(p => p.id == 'pp-hue'),
      gammaR = this.parameters.find(p => p.id == 'pp-gamma-r'),
      gammaRAmplitude = this.parameters.find(p => p.id == 'pp-gamma-r-amplitude'),
      gammaROffset = this.parameters.find(p => p.id == 'pp-gamma-r-offset'),
      gammaG = this.parameters.find(p => p.id == 'pp-gamma-g'),
      gammaGAmplitude = this.parameters.find(p => p.id == 'pp-gamma-g-amplitude'),
      gammaGOffset = this.parameters.find(p => p.id == 'pp-gamma-g-offset'),
      gammaB = this.parameters.find(p => p.id == 'pp-gamma-b'),
      gammaBAmplitude = this.parameters.find(p => p.id == 'pp-gamma-b-amplitude'),
      gammaBOffset = this.parameters.find(p => p.id == 'pp-gamma-b-offset'),
      gammaA = this.parameters.find(p => p.id == 'pp-gamma-a'),
      gammaASlope = this.parameters.find(p => p.id == 'pp-gamma-a-slope');
    this._svgPostprocessing = {
      contrast: contrast.value,
      brightness: brightness.value,
      saturate: saturate.value,
      sepia: sepia.value,
      sharpen: sharpen.value,
      // gamma: gamma.value,
      gamma: undefined,
      hue: hue.value,
      // gammaR: typeof gammaR.value === 'undefined' ? gamma.value : gammaR.value,
      // gammaG: typeof gammaG.value === 'undefined' ? gamma.value : gammaG.value,
      // gammaB: typeof gammaB.value === 'undefined' ? gamma.value : gammaB.value,
      gammaR: gammaR.value,
      gammaRAmplitude: gammaRAmplitude.value,
      gammaROffset: gammaROffset.value,
      gammaG: gammaG.value,
      gammaGAmplitude: gammaGAmplitude.value,
      gammaGOffset: gammaGOffset.value,
      gammaB: gammaB.value,
      gammaBAmplitude: gammaBAmplitude.value,
      gammaBOffset: gammaBOffset.value,
      gammaA: gammaA.value || 0,
      gammaASlope: gammaASlope.value
    };
    return this._svgPostprocessing;
  }
  get hdri() {
    if (this._hdri) return this._hdri;
    let hdr = this.parameters.find(p => p.id == 'hdr'),
      hdrIntensity = this.parameters.find(p => p.id == 'hdr-intensity'),
      hdrBlur = this.parameters.find(p => p.id == 'hdr-blur'),
      hdrFormat = this.parameters.find(p => p.id == 'hdr-format'),
      probe = this.parameters.find(p => p.id == 'le-probe'),
      hdrbg = this.parameters.find(p => p.id == 'hdrbg'),
      hdrSkybox = this.parameters.find(p => p.id == 'hdr-skybox'),
      hdrRotation = this.parameters.find(p => p.id == 'hdr-rotation');
    // refraction = this.parameters.find(x => x.id == 'refraction');
    // Support legacy:
    const vHdr = this.utils.getUrlParam(this.orgSrc, 'hdr');
    if (vHdr) hdr.value = parseInt(vHdr);
    const vIntensity = this.utils.getUrlParam(this.orgSrc, 'hdr-intensity');
    if (vIntensity) hdrIntensity.value = parseFloat(vIntensity);
    const vBlur = this.utils.getUrlParam(this.orgSrc, 'hdr-blur');
    if (vBlur) hdrBlur.value = vBlur === 'true';
    const vFormat = this.utils.getUrlParam(this.orgSrc, 'hdr-format');
    if (vFormat) hdrFormat.value = vFormat;
    const vProbe = this.utils.getUrlParam(this.orgSrc, 'le-probe');
    if (vProbe) probe.value = parseFloat(vProbe);
    const vBg = this.utils.getUrlParam(this.orgSrc, 'hdrbg');
    if (vBg) hdrbg.value = vBg === 'true';
    const vSkybox = this.utils.getUrlParam(this.orgSrc, 'hdr-skybox');
    if (vSkybox) hdrSkybox.value = vSkybox === 'true';
    const vRotation = this.utils.getUrlParam(this.orgSrc, 'hdr-rotation');
    if (vRotation) {
      const v = vRotation.split(',');
      hdrRotation.value = {
        x: v[0],
        y: v[1],
        z: v[2]
      };
    }
    this._hdri = {
      type: hdr.value,
      background: hdrbg.value,
      blur: hdrBlur.value,
      format: hdrFormat.value,
      intensity: hdrIntensity.value,
      lightProbe: probe.value,
      skybox: hdrSkybox.value,
      rotation: hdrRotation.value
    };
    if (this._hdri.type) this._hdri.type = this._hdri.type * 1;
    return this._hdri;
  }
  set shadowPlane(options) {
    this._shadowPlaneOptions = null;
    let planeParam = this.parameters.find(p => p.id == 'plane'),
      planeMirrorParam = this.parameters.find(p => p.id == 'plane-r'),
      planePhysicalParam = this.parameters.find(p => p.id == 'plane-p'),
      planeSideParam = this.parameters.find(p => p.id == 'plane-s');
    planeParam.color = options.color;
    planeParam.value = options.opacity;
    planeParam.toggleState = options.active;
    planeMirrorParam.value = options.reflector;
    planeSideParam.value = options.side;
    planePhysicalParam.value = options.physical;
    if (options.physicalOptions) {
      let opacity = planePhysicalParam.children.find(p => p.id == 'plane-p-o'),
        dim = planePhysicalParam.children.find(p => p.id == 'plane-p-dim'),
        height = planePhysicalParam.children.find(p => p.id == 'plane-p-h'),
        darkness = planePhysicalParam.children.find(p => p.id == 'plane-p-d');
      planePhysicalParam.children.find(p => p.id == 'plane-p-b').value = options.physicalOptions.blur;
      if (darkness) darkness.value = options.physicalOptions.darkness;
      if (opacity) opacity.value = options.physicalOptions.opacity;
      if (dim) dim.value = options.physicalOptions.dim;
      if (height) height.value = options.physicalOptions.height;
    }
  }
  get shadowPlane() {
    if (this._shadowPlaneOptions) return this._shadowPlaneOptions;
    if (this.parameters) {
      let planeParam = this.parameters.find(p => p.id == 'plane'),
        planeMirrorParam = this.parameters.find(p => p.id == 'plane-r'),
        planePhysicalParam = this.parameters.find(p => p.id == 'plane-p'),
        planeSideParam = this.parameters.find(p => p.id == 'plane-s');
      if (planePhysicalParam) {
        let opacity = planePhysicalParam.children.find(p => p.id == 'plane-p-o'),
          dim = planePhysicalParam.children.find(p => p.id == 'plane-p-dim'),
          height = planePhysicalParam.children.find(p => p.id == 'plane-p-h'),
          darkness = planePhysicalParam.children.find(p => p.id == 'plane-p-d');
        this._shadowPlaneOptions = {
          color: planeParam.color,
          opacity: planeParam.value,
          active: planeParam.toggleState,
          physical: planePhysicalParam.value,
          reflector: planeMirrorParam.value,
          side: planeSideParam.value,
          physicalOptions: {
            blur: planePhysicalParam.children.find(p => p.id == 'plane-p-b').value,
            darkness: darkness ? darkness.value : null,
            opacity: opacity ? opacity.value : 0,
            dim: dim ? dim.value : 512,
            height: height ? height.value : null
          }
        };
      }
    }
    return this._shadowPlaneOptions;
  }
  onMaterialManipulations(obj) {
    this.materialManipulations = obj;
    this.updateMaterialManipulations();
  }
  updateMaterialManipulations() {
    if (this.materials) {
      for (let matMame in this.materialManipulations) {
        let material = this.materials.find(m => m.name == matMame);
        if (material) {
          this.materialManipulations[matMame].type = this.getMaterialType(material);
        }
        if (this.materialManipulations[matMame].video && this.materialManipulations[matMame].video.src && !this.videoTexturesSrcs.find(s => s == this.materialManipulations[matMame].video.src)) this.videoTexturesSrcs.push(this.materialManipulations[matMame].video.src);
      }
    }
  }
  broadcastSceneSummary() {
    return new Promise((resolve, reject) => {
      this.resolveAfterSetSceneSummary.push(resolve);
      this.postToChild('broadcastSceneSummary');
    });
  }
  setSceneSummary(obj) {
    if (!obj) return;
    const data = obj.data;
    this.hdri = data.hdr;
    this.svgPostprocessing = data.svgPostprocessingOptions;
    this.params = {
      exposure: data.exposure,
      physicallyCorrectLights: data.renderer.physicallyCorrectLights,
      toneMapping: data.renderer.toneMapping,
      fov: data.camera.fov,
      grid: data.grid,
      dimensions: data.dimensions,
      dimensionsValues: data.dimensionsOptions ? {
        x: data.dimensionsOptions.x,
        y: data.dimensionsOptions.y,
        z: data.dimensionsOptions.z
      } : null,
      dimensionsColor: data.dimensionsOptions ? data.dimensionsOptions.color : null,
      meshId: data.meshId,
      shadedWireframe: data.shadedWireframe,
      shadedWireframeColor: data.shadedWireframeOptions ? data.shadedWireframeOptions.color : null,
      transparentRenderOrder: data.transparentRenderOrder,
      wireframe: data.wireframe,
      xray: data.xray,
      uv: data.uv,
      closeup: data.closeup,
      hideBottom: data.hideBottom,
      matcap: data.matcap
    };
    // if (typeof data.exposure === 'number') {
    //   let exposureP = this.parameters.find(p => p.name == 'exposure');
    //   if (exposureP) {
    //     exposureP.value = data.exposure.toFixed(2);
    //     this._orgSrc = this.utils.setUrlParam(this.orgSrc, 'exp', null);
    //   }
    // }
    if (data.hdr) {
      this.hdri = data.hdr;
      this._orgSrc = this.utils.setUrlParam(this.orgSrc, 'hdr', null);
      this._orgSrc = this.utils.setUrlParam(this.orgSrc, 'hdr-intensity', null);
      this._orgSrc = this.utils.setUrlParam(this.orgSrc, 'hdr-blur', null);
      this._orgSrc = this.utils.setUrlParam(this.orgSrc, 'hdrbg', null);
      this._orgSrc = this.utils.setUrlParam(this.orgSrc, 'le-probe', null);
    }
    if (data.lights) {
      this.onLightsSummary(data.lights, true, false);
    }
    if (data.plane) {
      if (data.plane.state) {
        if (!data.plane.options) data.plane.options = {};
        data.plane.options.active = data.plane.state;
        this.shadowPlane = data.plane.options;
      } else this.parameters.find(p => p.id == 'plane').toggleState = data.plane.state;
    }
    if (data.autoAdjustScene) {
      const cs = this.getCurrentSrc();
      this.broadcaster.next({
        key: 'onAutoAdjustScene',
        data: cs
      });
      const d = {
        url: cs
      };
      this._onAutoAdjustScene.forEach(f => f(d));
      this._onAutoAdjustScene = [];
    }
    this.resolveAfterSetSceneSummary.forEach(f => f());
  }
  broadcastSvgPostprocessing() {
    return new Promise((resolve, reject) => {
      this.resolveAfterSetSVGPostprocessing.push(resolve);
      this.postToChild('broadcastSVGPostprocessing');
    });
  }
  setSVGPostprocessing(obj) {
    this.svgPostprocessing = obj.data;
    this.resolveAfterSetSVGPostprocessing.forEach(f => f(this.svgPostprocessing));
  }
  resetSVGPostprocessing() {
    const defaults = new SVGPostprocessingOptions();
    const contrast = this.parameters.find(p => p.id == 'pp-contrast'),
      brightness = this.parameters.find(p => p.id == 'pp-brightness'),
      saturate = this.parameters.find(p => p.id == 'pp-saturate'),
      sepia = this.parameters.find(p => p.id == 'pp-sepia'),
      sharpen = this.parameters.find(p => p.id == 'pp-sharpen'),
      // gamma = this.parameters.find(
      //     (p) => p.id == 'pp-gamma'
      // ) as AssetAdjustments,
      hue = this.parameters.find(p => p.id == 'pp-hue'),
      gammaR = this.parameters.find(p => p.id == 'pp-gamma-r'),
      gammaRAmplitude = this.parameters.find(p => p.id == 'pp-gamma-r-amplitude'),
      gammaROffset = this.parameters.find(p => p.id == 'pp-gamma-r-offset'),
      gammaG = this.parameters.find(p => p.id == 'pp-gamma-g'),
      gammaGAmplitude = this.parameters.find(p => p.id == 'pp-gamma-g-amplitude'),
      gammaGOffset = this.parameters.find(p => p.id == 'pp-gamma-g-offset'),
      gammaB = this.parameters.find(p => p.id == 'pp-gamma-b'),
      gammaBAmplitude = this.parameters.find(p => p.id == 'pp-gamma-b-amplitude'),
      gammaBOffset = this.parameters.find(p => p.id == 'pp-gamma-b-offset'),
      gammaA = this.parameters.find(p => p.id == 'pp-gamma-a'),
      gammaASlope = this.parameters.find(p => p.id == 'pp-gamma-a-slope');
    contrast.value = defaults.contrast;
    brightness.value = defaults.brightness;
    saturate.value = defaults.saturate;
    sepia.value = defaults.sepia;
    sharpen.value = defaults.sharpen;
    // gamma.value = defaults.gamma;
    hue.value = defaults.hue;
    gammaR.value = defaults.gammaR;
    gammaRAmplitude.value = defaults.gammaRAmplitude;
    gammaROffset.value = defaults.gammaROffset;
    gammaG.value = defaults.gammaG;
    gammaGAmplitude.value = defaults.gammaGAmplitude;
    gammaGOffset.value = defaults.gammaGOffset;
    gammaB.value = defaults.gammaB;
    gammaBAmplitude.value = defaults.gammaBAmplitude;
    gammaBOffset.value = defaults.gammaBOffset;
    gammaA.value = defaults.gammaA;
    gammaASlope.value = defaults.gammaASlope;
    this.svgPostprocessing = null;
    this.changeValue(contrast);
  }
  muteLights(exceptType, exceptIndex) {
    let lights = this.utils.deepCopyByValue(this._lastLights);
    let allLights = this.parameters.filter(p => p.isLight);
    for (let lightType in lights) {
      if (!this.soloLights[lightType]) this.soloLights[lightType] = {};
      let currentTypeLights = allLights.filter(p => p.options.type == lightType);
      for (let i = 0; i < currentTypeLights.length; i++) {
        if (exceptType == currentTypeLights[i].options.type && exceptIndex == currentTypeLights[i].index) {
          // keep current light value
          this.soloLights[lightType][currentTypeLights[i].index] = true;
        } else {
          currentTypeLights[i].value = 0;
          this.changeValue(currentTypeLights[i]);
          this.soloLights[lightType][currentTypeLights[i].index] = false;
        }
      }
    }
    this._hasSoloLights = true;
  }
  recoverMuteLights() {
    if (this._lastLights && this._hasSoloLights) {
      this.onLightsSummary(this._lastLights, true, false);
      let allLights = this.parameters.filter(p => p.isLight);
      allLights.forEach(l => {
        this.changeValue(l);
      });
      this._hasSoloLights = false;
    }
  }
  changeLightIntensity(light) {
    if (this.soloLights && this.soloLights[light.options.type] && this.soloLights[light.options.type][light.index]) {
      if (this._lastLights && this._lastLights[light.options.type]) {
        for (let i = 0; this._lastLights[light.options.type].length; i++) {
          if (this._lastLights[light.options.type][i].index == light.index) {
            this._lastLights[light.options.type][i].intensity = light.value;
            break;
          }
        }
      }
    }
    this.changeValue(light);
  }
  onLightsSummary(lights, reBuild, initEmbedded) {
    if (initEmbedded) this._initEmbeddedLights = null;
    this._lastLights = lights;
    this.lightsChanges = true;
    this.soloLights = {};
    let shadowType = this.parameters.find(p => p.urlParam == 'shadow-type');
    if (shadowType) shadowType.value = [];
    // let allLights = this.parameters.filter(p => p.options && p.options.type && p.urlParam && p.urlParam.indexOf('le-') > -1) as Array<AssetAdjustments>;
    // let allLights = this.parameters.filter(p => p.isLight);
    if (reBuild) {
      for (let i = 0; i < this.parameters.length; i++) {
        if (this.parameters[i].isLight) this.parameters.splice(i--, 1);
      }
    }
    for (let lightType in lights) {
      // const related = allLights.filter(p => p.name.indexOf(lightType.replace('Light', '').toLowerCase()) == 0);
      for (let i = 0; i < lights[lightType].length; i++) {
        let light;
        if (reBuild) light = this.createLight(lightType, lights[lightType][i].index);else light = this.parameters.find(p => p.isLight && p.index == lights[lightType][i].index && p.options && p.options.type == lightType);
        // if (!related[i]) {
        //   related[i] = this.createLight(lightType, i);
        //   this.parameters.push(related[i]);
        // }
        // let l = related.find(p => p.urlParam.length == 6);
        // let p = related.find(p => p.urlParam.length == 8);
        // if (l) {
        // light.index = i;
        light.nickname = lights[lightType][i].nickname;
        light.value = this.round(0.01, lights[lightType][i].intensity);
        if (light.hsl) {
          light.hsl.x = this.round(0.01, lights[lightType][i].hsl.h);
          light.hsl.y = this.round(0.01, lights[lightType][i].hsl.s);
          light.hsl.z = this.round(0.01, lights[lightType][i].hsl.l);
        }
        light.color = lights[lightType][i].color;
        let punctual = light.children.find(c => c.options.isPunctual);
        if (punctual) punctual.value = lights[lightType][i].embedded || false;
        if (lights[lightType][i].shadow) {
          if (lights[lightType][i].shadow.mapSize && lights[lightType][i].shadow.mapSize.width) {
            let dim = this.parameters.find(p => p.urlParam == 'shadow-map-dim');
            if (dim) dim.value = lights[lightType][i].shadow.mapSize.width;
          }
        }
        // }
        let position = light.children.find(c => c.name == 'position');
        if (position) {
          position.value.x = this.utils.trimIfFloat(lights[lightType][i].position.x);
          position.value.y = this.utils.trimIfFloat(lights[lightType][i].position.y);
          position.value.z = this.utils.trimIfFloat(lights[lightType][i].position.z);
        }
        let followCamera = light.children.find(c => c.name == 'follow camera');
        if (followCamera) {
          followCamera.value = lights[lightType][i].followCamera;
          // if (!followCamera.options)
          //   followCamera.options = {};
          // followCamera.options.indentation = lights[lightType][i].indentation; // || { x: 0, y: 0, z: 0 };
        }
        let angle = light.children.find(c => c.name == 'angle');
        if (angle) angle.value = lights[lightType][i].angle;
        let decay = light.children.find(c => c.name == 'decay');
        if (decay) decay.value = lights[lightType][i].decay;
        let penumbra = light.children.find(c => c.name == 'penumbra');
        if (penumbra) penumbra.value = lights[lightType][i].penumbra;
        let distance = light.children.find(c => c.name == 'distance');
        if (distance) distance.value = lights[lightType][i].distance;
        let shadowSize = light.children.find(c => c.name == 'shadow size');
        if (shadowSize) shadowSize.value = lights[lightType][i].shadowMapDim;
        let bias = light.children.find(c => c.name == 'bias');
        if (bias) bias.value = lights[lightType][i].bias;
        let normalBias = light.children.find(c => c.name == 'normal bias');
        if (normalBias) normalBias.value = lights[lightType][i].normalBias;
        let castShadow = light.children.find(c => c.name == 'cast shadow');
        if (castShadow) castShadow.value = lights[lightType][i].castShadow;
        if (typeof lights[lightType][i].width === 'number') light.options.width = lights[lightType][i].width;
        if (typeof lights[lightType][i].height === 'number') light.options.height = lights[lightType][i].height;
        if (this.boundingBox) {
          const minMax = this.boundingBox.size * 4;
          let max = minMax * 2;
          let step = minMax / 30;
          step = this.utils.trimIfFloat(step, step < 10 ? 2 : 1);
          if (position) {
            position.min = -minMax;
            position.max = minMax;
            if (position.value) {
              if (position.value.x) position.value.x = this.round(step, position.value.x);
              if (position.value.y) position.value.y = this.round(step, position.value.y);
              if (position.value.z) position.value.z = this.round(step, position.value.z);
            }
          }
          if (distance) {
            distance.min = 0;
            distance.max = max;
            distance.step = step;
            if (distance.value) distance.value = this.round(distance.step, distance.value);
          }
        }
        if (shadowType && lights[lightType][i].castShadow) shadowType.value.push(lights[lightType][i].alias);
        if (reBuild) this.parameters.push(light);
        if (!this.soloLights[lightType]) this.soloLights[lightType] = {};
      }
    }
    this.LightingCounter++;
    // this.lights = this.parameters.filter(p => p.isLight);
    this.sceneLights = lights;
    this.broadcaster.next({
      key: 'onLightsSummary'
    });
    this.resolveAfterSetLights.forEach(r => r(lights));
    this.resolveAfterSetLights = [];
    if (!this._initEmbeddedLights) this._initEmbeddedLights = this.getEmbeddedLights();
    // if (this.saveAfterSetLights)
    //   this.saveAll(true);
    // this.saveAfterSetLights = false;
  }
  getEmbeddedLights() {
    let res = {};
    for (let i in this.sceneLights) {
      this.sceneLights[i].forEach(l => {
        if (l.embedded) res[`${i}_${l.index}`] = true;
      });
    }
    return res;
  }
  addLight(type) {
    this.postToChild('addLight', {
      type
    });
  }
  duplicateLight(type, index) {
    this.postToChild('duplicateLight', {
      type,
      index
    });
  }
  removeAllLights() {
    this.postToChild('removeAllLights');
  }
  deleteLight(type, index) {
    this.postToChild('deleteLight', {
      type,
      index
    });
  }
  setLightsByJson(lights) {
    return new Promise((resolve, reject) => {
      this.resolveAfterSetLights.push(resolve);
      this.postToChild('setLightsByJson', lights);
      this._lastLights = lights;
    });
  }
  async refreshLightsSummary() {
    return new Promise((resolve, reject) => {
      this.resolveAfterSetLights.push(resolve);
      this.postToChild('broadcastLightsSummary');
    });
  }
  async refreshMaterialManipulations() {
    return new Promise((resolve, reject) => {
      this.resolveAfterSetMaterialManipulations.push(resolve);
      this.postToChild('broadcastMaterialManipulations');
    });
  }
  setAnimatedState() {
    for (let i in this.texturesAnimations) {
      if (!this.animatedState[i]) this.animatedState[i] = {};
      for (let j in this.texturesAnimations[i]) {
        this.animatedState[i][j] = true;
      }
    }
  }
  fixTexturesAnimations(texturesAnimations) {
    if (texturesAnimations && texturesAnimations['texturesAnimations']) return this.fixTexturesAnimations(texturesAnimations['texturesAnimations']);
    return texturesAnimations;
  }
  setTexturesAnimations(texturesAnimations) {
    this.texturesAnimations = this.fixTexturesAnimations(texturesAnimations);
    this.setAnimatedState();
  }
  createLight(type, index) {
    // type = type.replace('HemisphericLight', 'HemisphereLight');
    let getChildren = parent => {
      let children = [];
      let child = {
        value: {
          x: null,
          y: null,
          z: null
        },
        name: 'position',
        action: 'setCurrentLight',
        // urlParam: 'le-dir-p',
        min: 0,
        max: 100,
        options: {
          type,
          options: {
            showHelper: true
          }
        },
        type: AssetAdjustmentType.JOYSTICK,
        sector: AssetAdjustmentSector.LIGHTING,
        index: index
      };
      this._childrenParentDictionary[parent.name].push(child);
      children.push(child);
      if (type == 'PointLight' || type == 'DirectionalLight' || type == 'SpotLight') {
        child = {
          value: null,
          name: 'distance',
          action: 'setCurrentLight',
          // urlParam: 'le-spt-distance',
          options: {
            type,
            distance: true,
            options: {
              showHelper: true
            }
          },
          min: 0,
          max: 1000,
          step: 10,
          default: 0,
          type: AssetAdjustmentType.SLIDER,
          sector: AssetAdjustmentSector.LIGHTING,
          index: index
        };
        this.childrenParentDictionary[parent.name].push(child);
        children.push(child);
        child = {
          value: 2048,
          name: 'shadow size',
          // action: 'setShadowType',
          action: 'setCurrentLight',
          options: {
            type,
            shadowMapDim: true,
            options: {
              showHelper: true
            }
          },
          // urlParam: 'shadow-map-dim',
          default: 2048,
          type: AssetAdjustmentType.SELECT,
          selectOptions: this.enums.getTexturesSizes(),
          sector: AssetAdjustmentSector.SHADOW,
          index: index
        };
        this.childrenParentDictionary[parent.name].push(child);
        children.push(child);
        child = {
          value: 0,
          name: 'normal bias',
          action: 'setCurrentLight',
          options: {
            type,
            normalBias: true,
            options: {
              showHelper: true
            }
          },
          default: 0,
          min: -0.1,
          max: 0.1,
          step: 0.0001,
          round: 4,
          type: AssetAdjustmentType.SLIDER,
          sector: AssetAdjustmentSector.SHADOW,
          index: index
        };
        this.childrenParentDictionary[parent.name].push(child);
        children.push(child);
        child = {
          value: 0,
          name: 'bias',
          action: 'setCurrentLight',
          options: {
            type,
            bias: true,
            options: {
              showHelper: true
            }
          },
          default: 0,
          min: -0.1,
          max: 0.1,
          step: 0.0001,
          round: 4,
          type: AssetAdjustmentType.SLIDER,
          sector: AssetAdjustmentSector.SHADOW,
          index: index
        };
        this.childrenParentDictionary[parent.name].push(child);
        children.push(child);
        if (type == 'SpotLight') {
          child = {
            value: null,
            name: 'angle',
            action: 'setCurrentLight',
            // urlParam: 'le-dir-angle',
            options: {
              type,
              angle: true,
              options: {
                showHelper: true
              }
            },
            min: 0,
            max: Math.PI / 2,
            step: 0.08,
            default: Math.PI / 3,
            type: AssetAdjustmentType.SLIDER,
            sector: AssetAdjustmentSector.LIGHTING,
            index: index
          };
          this.childrenParentDictionary[parent.name].push(child);
          children.push(child);
        }
        // children.push({
        //   value: 2048,
        //   name: 'shadow size',
        //   // action: 'setShadowType',
        //   action: 'setCurrentLight',
        //   options: {
        //     type,
        //     shadowMapDim: true,
        //     options: {
        //       showHelper: true
        //     }
        //   },
        //   // urlParam: 'shadow-map-dim',
        //   default: 2048,
        //   type: AssetAdjustmentType.SELECT,
        //   selectOptions: this.enums.getTexturesSizes(),
        //   sector: AssetAdjustmentSector.SHADOW,
        //   index: index,
        //   parent
        // } as AssetAdjustments);
        // children.push({
        //   value: null,
        //   name: 'decay',
        //   action: 'setCurrentLight',
        //   // urlParam: 'le-spt-decay',
        //   options: {
        //     type,
        //     decay: true,
        //     options: {
        //       showHelper: true
        //     }
        //   },
        //   min: 1,
        //   max: 2,
        //   step: 0.05,
        //   default: 1,
        //   type: AssetAdjustmentType.SLIDER,
        //   sector: AssetAdjustmentSector.LIGHTING,
        //   index: index,
        //   parent
        // } as AssetAdjustments);
        // children.push({
        //   value: null,
        //   name: 'penumbra',
        //   action: 'setCurrentLight',
        //   // urlParam: 'le-spt-penumbra',
        //   options: {
        //     type,
        //     penumbra: true,
        //     options: {
        //       showHelper: true
        //     }
        //   },
        //   min: 0,
        //   max: 1,
        //   step: 0.05,
        //   default: 0,
        //   type: AssetAdjustmentType.SLIDER,
        //   sector: AssetAdjustmentSector.LIGHTING,
        //   index: index,
        //   parent
        // } as AssetAdjustments);
        child = {
          value: false,
          name: 'embed in model',
          action: 'setCurrentLight',
          options: {
            type,
            isPunctual: true,
            options: {
              showHelper: true
            }
          },
          default: false,
          type: AssetAdjustmentType.TOGGLE,
          sector: AssetAdjustmentSector.LIGHTING,
          index: index
        };
        this.childrenParentDictionary[parent.name].push(child);
        children.push(child);
        child = {
          // value: AssetAdjustmentsService.DEFAULT_SHADOW_TYPES,
          value: false,
          name: 'cast shadow',
          // action: 'setShadowType',
          action: 'setCurrentLight',
          options: {
            type,
            castShadow: true,
            options: {
              showHelper: true
            }
          },
          // urlParam: 'shadow-type',
          // type: AssetAdjustmentType.SELECT,
          type: AssetAdjustmentType.TOGGLE,
          selectOptions: this.getSHadows(),
          sector: AssetAdjustmentSector.LIGHTING,
          index: index
          // multiple: true
        };
        this.childrenParentDictionary[parent.name].push(child);
        children.push(child);
      }
      if (type == 'PointLight' || type == 'DirectionalLight' || type == 'SpotLight' || type == 'RectAreaLight') {
        child = {
          value: false,
          name: 'follow camera',
          action: 'toggleCameraLights',
          // urlParam: 'cam-lights',
          type: AssetAdjustmentType.TOGGLE,
          sector: AssetAdjustmentSector.LIGHTING,
          min: -1,
          max: 1,
          options: {
            type,
            followCamera: true,
            options: {
              showHelper: true
            }
            // ,
            // indentation: { x: 0, y: 0, z: 0 }
          },
          index: index
        };
        this.childrenParentDictionary[parent.name].push(child);
        children.push(child);
      }
      return children;
    };
    let dim = null;
    if (type == 'RectAreaLight') {
      dim = 1;
      if (this.boundingBox && this.boundingBox.size) dim = Number((this.boundingBox.size / 10).toFixed(1));
    }
    let res = {
      value: null,
      isLight: true,
      name: type.replace('Light', ''),
      action: 'setCurrentLight',
      // urlParam: 'le-spt',
      min: 0,
      max: 5,
      step: 0.01,
      options: {
        type,
        hasDim: type == 'RectAreaLight',
        width: dim,
        height: dim
      },
      type: AssetAdjustmentType.SLIDER,
      sector: AssetAdjustmentSector.LIGHTING,
      hasColor: true,
      hasHSL: true,
      hsl: {
        x: null,
        y: null,
        z: null
      },
      index: index
    };
    this.childrenParentDictionary[res.name] = [];
    res.children = getChildren(res);
    return res;
  }
  setUVsOverlap(obj) {
    this.UVsOverlapCount = 0;
    if (obj.UVsOverlaps instanceof Array) {
      obj.UVsOverlaps.forEach(o => {
        if (!o.maps.filter(i => i == 'aoMap' || i == 'normalMap').length) this.UVsOverlapCount += o.overlaps;
      });
    }
    this.broadcaster.next({
      key: 'onUVsOverlap'
    });
  }
  adjustScene() {
    return new Promise((resolve, reject) => {
      this._onAutoAdjustScene.push(resolve);
      this.postToChild('adjustScene');
    });
  }
  async getJsonParamsAsync() {
    return new Promise(async (resolve, reject) => {
      // await Promise.all([this.refreshLightsSummary(), this.refreshMaterialManipulations()])
      await this.refreshLightsSummary();
      await this.broadcastSceneSummary();
      await this.broadcastSvgPostprocessing();
      // await this.refreshMaterialManipulations();
      // let lights = this.addIndexToLights(this.sceneLights);
      // let res = {
      //   scene: {
      //     materialManipulations: this.utils.deepCopyByValue(this.materialManipulations),
      //     lights,
      //     texturesAnimations: this.utils.deepCopyByValue(this.texturesAnimations),
      //     meshManipulations: this.utils.deepCopyByValue(this.meshManipulations)
      //   }
      // } as ResourceJsonParams;
      // resolve(res);
      resolve(this.getJsonParams());
    });
  }
  getJsonParams() {
    let lights = this.addIndexToLights(this.sceneLights);
    let materialManipulations = this.utils.deepCopyByValue(this.materialManipulations);
    // if (this.materials) {
    //   this.materials.forEach((material: any) => {
    //     if (material.envMap && material.envMap.image && !(material.envMap.image instanceof Array)) {
    //       if (!materialManipulations[material.name])
    //         materialManipulations[material.name] = {} as ThreeMaterialManipulation;
    //       materialManipulations[material.name].envMap = {
    //         envMapIntensity: material.envMapIntensity,
    //         imageSrc: material.envMap.imageSrc
    //       } as ThreeTexture;
    //     }
    //   });
    // }
    let meshManipulations = this.utils.deepCopyByValue(this.meshManipulations);
    if (this.meshesData && Object.keys(this.meshesData).length) {
      for (let i in meshManipulations) {
        if (!this.meshesData[i]) delete meshManipulations[i];
      }
    }
    for (let i in materialManipulations) {
      materialManipulations[i].color = null;
    }
    let res = {
      scene: {
        materialManipulations,
        lights,
        texturesAnimations: this.utils.deepCopyByValue(this.texturesAnimations),
        meshManipulations,
        meshAnimations: this.utils.deepCopyByValue(this.meshAnimations),
        shadowPlane: this.utils.deepCopyByValue(this.shadowPlane),
        meshBloomOptions: this.utils.deepCopyByValue(this.meshBloomOptions),
        meshFurOptions: this.utils.deepCopyByValue(this.meshFurOptions),
        hdri: this.utils.deepCopyByValue(this.hdri),
        params: this.utils.deepCopyByValue(this.params),
        annotations: this.utils.deepCopyByValue(this.annotations),
        meshActions: this.utils.deepCopyByValue(this.meshActions),
        svgPostprocessingOptions: this.svgPostprocessing
      }
    };
    return res;
  }
  setModel(obj) {
    obj.quantizeTexcoordBits = this.quantizeTexcoordBits;
    obj.quantizeNormalBits = this.quantizeNormalBits;
    obj.dracoCompressionLevel = this.dracoCompressionLevel;
    obj.json_params = this.getJsonParams();
    obj.targetResourceType = this.targetResourceType;
    this.broadcaster.next({
      key: 'onResourceExport',
      data: obj
    });
    // this.jobService.onResourceExport.next(obj);
  }
  setMeshesData(obj) {
    this.meshesData = obj;
    this.counter++;
    this.onGetMeshesData.forEach(f => f());
    this.onGetMeshesData = [];
    if (this.selectedMesh && this.meshesData[this.selectedMesh.name]) this.selectedMesh = this.meshesData[this.selectedMesh.name];
    this.refreshNumOfBloomMeshes();
    this.refreshNumOfFurMeshes();
    this.syncMeshManipulations();
  }
  syncMeshManipulations() {
    if (!this.meshManipulations) this.meshManipulations = {};
    for (let meshName in this.meshesData) {
      for (let key in this.meshesData[meshName]) {
        if (key === 'rotation') {
          this.meshesData[meshName][key + 'Degree'] = {
            x: 0,
            y: 0,
            z: 0
          };
        }
        if (AssetAdjustmentsService.MESH_MANIPULATIONS_RELEVANT_FIELDS.find(i => i === key)) {
          if (!this.meshManipulations[meshName]) this.meshManipulations[meshName] = {};
          this.meshManipulations[meshName][key] = this.meshesData[meshName][key];
        }
      }
    }
  }
  refreshNumOfBloomMeshes() {
    if (this.meshesData) {
      this.numOfBloomMeshes = 0;
      for (let meshName in this.meshesData) {
        if (this.meshesData[meshName].bloom) this.numOfBloomMeshes++;
      }
    }
  }
  refreshNumOfFurMeshes() {
    if (this.meshesData) {
      this.numOfFurMeshes = 0;
      for (let meshName in this.meshesData) {
        if (this.meshesData[meshName].fur) this.numOfFurMeshes++;
      }
    }
  }
  broadcastTextures(useCache = false, addBase64 = true) {
    return new Promise((resolve, reject) => {
      this._onTextures.push(resolve);
      this.postToChild('broadcastTextures', {
        useCache: useCache,
        addBase64: addBase64
      });
    });
  }
  setTextures(obj) {
    this.textures = obj.textures;
    this.addTexturesToSrcs(this.textures);
    this.refreshTextures = false;
    this.onMaterialsTextures();
    this._onTextures.forEach(f => f(this.textures));
    this._onTextures = [];
  }
  addTexturesToSrcs(textures) {
    for (let i = 0; i < textures.length; i++) {
      for (let j in textures[i]) {
        if (textures[i][j].imageSrc && !this.texturesSrcs.find(s => s == textures[i][j].imageSrc)) {
          this.texturesSrcs.push(textures[i][j].imageSrc);
        }
      }
    }
  }
  setMainMesh(obj) {
    this.mainMesh = obj;
  }
  setCameraInfo(obj) {
    let fov = this.parameters.find(p => p.urlParam == 'fov');
    if (fov) fov.default = obj.fov;
    let fl = this.parameters.find(p => p.urlParam == 'focal-length');
    if (fl) fl.default = obj.focalLength;
  }
  setBoundingBox(obj) {
    this.boundingBox = obj;
    let rd = {
      width: this.boundingBox.x,
      height: this.boundingBox.y,
      length: this.boundingBox.z,
      units: this.getSceneUnits() == 'scene units' ? null : DimensionsUnits.CM
    };
    if (rd.units == DimensionsUnits.CM) {
      rd.width *= 100;
      rd.height *= 100;
      rd.length *= 100;
    }
    this.changeBB.next(rd);
    let planeP = this.parameters.find(p => p.id == 'plane-p');
    if (planeP && planeP.children) {
      let planeHeight = planeP.children.find(p => p.id == 'plane-p-h');
      if (planeHeight) {
        const y = this.boundingBox.y * 1.2;
        planeHeight.max = y;
        planeHeight.step = y / 20;
      }
    }
    let minD = this.parameters.find(p => p.urlParam == 'min-distance');
    let maxD = this.parameters.find(p => p.urlParam == 'max-distance');
    if (minD && maxD) {
      minD.max = this.boundingBox.size * 1.5;
      maxD.max = this.boundingBox.size * 2.5;
    }
    // const minMax = this.boundingBox.size * 200;
    const minMax = this.boundingBox.size * 4;
    let lights = this.parameters.filter(p => p.isLight);
    lights.forEach(l => {
      if (l.children) l.children.forEach(c => {
        if (c.name == 'position') {
          c.min = -minMax;
          c.max = minMax;
        }
        if (c.name == 'distance') {
          c.min = 0;
          c.max = minMax * 2;
          c.step = minMax / 15;
        }
        if (c.options && c.options.followCamera) {
          c.min = -minMax * 10;
          c.max = minMax * 10;
        }
      });
    });
    // let dirLight = this.parameters.find(p => p.urlParam == 'le-dir-p');
    // if (dirLight) {
    //   dirLight.min = -minMax;
    //   dirLight.max = minMax;
    // }
    // let dirLightDistance = this.parameters.find(p => p.urlParam == 'le-dir-distance');
    // if (dirLightDistance) {
    //   dirLightDistance.min = 0;
    //   dirLightDistance.max = minMax * 2;
    //   dirLightDistance.step = dirLightDistance.max / 30;
    // }
    // let spotLight = this.parameters.find(p => p.urlParam == 'le-spt-p');
    // if (spotLight) {
    //   spotLight.min = -minMax;
    //   spotLight.max = minMax;
    // }
    // let spotLightDistance = this.parameters.find(p => p.urlParam == 'le-spt-distance');
    // if (spotLight) {
    //   spotLightDistance.min = 0;
    //   spotLightDistance.max = minMax * 2;
    //   spotLightDistance.step = parseFloat((spotLightDistance.max / 30).toFixed(2));
    // }
    if (this.sceneLights && this.lightsSummarySent) {
      this.lightsSummarySent = true;
      this.postToChild('setLightsSummary', this.addIndexToLights(this.sceneLights));
    }
    // else
    this.postToChild('broadcastLightsSummary');
  }
  addIndexToLights(lights) {
    lights = this.utils.deepCopyByValue(lights);
    if (lights) {
      for (let i in lights) {
        if (lights[i]) lights[i].forEach(l => l.type = i);
      }
    }
    return lights;
  }
  setExposure(obj) {
    let exposureP = this.parameters.find(p => p.name == 'exposure');
    if (exposureP && obj.exposure) exposureP.default = obj.exposure;
  }
  // setLights(obj: any) {
  //   for (let i in obj.lightsDictionary) {
  //     for (let j in this.parameters) {
  //       if (this.parameters[j].options && this.parameters[j].options.type == i) {
  //         this.parameters[j].default = obj.lightsDictionary[i].intensity;
  //         this.parameters[j].color = '#' + obj.lightsDictionary[i].color.toString(16);
  //         if (obj.lightsDictionary[i].position && (this.parameters[j].urlParam == 'le-dir' || this.parameters[j].urlParam == 'le-spt')) {
  //           let pos = this.parameters.find(i => i.urlParam == this.parameters[j].urlParam + '-p');
  //           if (pos) {
  //             pos.value.x = obj.lightsDictionary[i].position.x;
  //             pos.value.y = obj.lightsDictionary[i].position.y;
  //             pos.value.z = obj.lightsDictionary[i].position.z;
  //           }
  //         }
  //       }
  //     }
  //   }
  // }
  setDistance(obj) {
    let minP = this.parameters.find(p => p.urlParam == 'min-distance');
    if (minP && obj.min) minP.default = obj.min;
    let maxP = this.parameters.find(p => p.urlParam == 'max-distance');
    if (maxP && obj.max) maxP.default = obj.max;
  }
  setMaterials(obj) {
    this.materials = obj.materials;
    this.refreshMaterials = false;
    if (this.materials && this.materials.length) {
      for (let i = 0; i < this.materials.length; i++) {
        this.initMaterialManipulations(i);
      }
    }
    this.onMaterialsTextures();
    this.updateMaterialManipulations();
    if (!this.materialManipulationsRevived) {
      this.materialManipulationsRevived = true;
      this.postToChild('broadcastMaterialManipulations');
    }
    this.broadcaster.next({
      key: 'setMaterials',
      data: this.materials
    });
    this._onSetMaterials.forEach(f => f(this.materials));
  }
  changeMeshMaterial(materialName, mesh) {
    if (materialName) {
      if (mesh.materialName == materialName) {
        // do nothing, no changes has been made
      } else {
        this.postToChild('setMaterialToMesh', {
          meshName: mesh.name,
          materialName: materialName
        });
      }
    } else {
      this.postToChild('setMaterialToMesh', {
        meshName: mesh.name
      });
    }
  }
  exportOBJ() {
    this.postToChild('broadcastOBJ');
    this.pixel.next({
      event_type: 'embeddedViewerExportOBJ'
    });
  }
  exportUSDZ() {
    if (this.scaleFactor && this.scaleFactor !== 1.0) this.setScale(this.scaleFactor, this.scaleFactor, this.scaleFactor);
    this.postToChild('broadcastUSDZ');
    this.pixel.next({
      event_type: 'embeddedViewerExportUSDZ'
    });
  }
  export(options = {}) {
    if (this.loading && !this.embed) return;
    this.loading = true;
    if (typeof options.meshCompress === 'boolean') this.meshCompress = options.meshCompress;
    if (options.imagesType || options.imagesCompressionFactor || options.maxTexturesSize || options.useOptipng) this.imageCompress = true;
    if (options.maxTexturesSize) this.maxTexturesSize = options.maxTexturesSize;
    if (options.maxDiffuseTexturesSize) this.maxDiffuseTexturesSize = options.maxDiffuseTexturesSize;
    if (options.maxNormalTexturesSize) this.maxNormalTexturesSize = options.maxNormalTexturesSize;
    if (options.maxMroTexturesSize) this.maxMroTexturesSize = options.maxMroTexturesSize;
    if (options.useOptipng) this.useOptipng = options.useOptipng;
    if (options.imagesType) this.imagesType = options.imagesType;
    if (options.normalImagesType) this.normalImagesType = options.normalImagesType;
    if (options.mroImagesType) this.mroImagesType = options.mroImagesType;
    if (this.scaleFactor && this.scaleFactor !== 1.0) this.setScale(this.scaleFactor, this.scaleFactor, this.scaleFactor);
    if (typeof options.simplify === 'number') this.simplifyGltf = true;
    if (typeof options.deleteUV2 === 'boolean') this.deleteUV2Gltf = options.deleteUV2;
    options.imagesCompressionFactor = options.imagesCompressionFactor || this.imagesCompressionFactor;
    this.postToChild('broadcastGLTF', {
      binary: this.embed ? this.binary : true,
      // trs: true,
      trs: false,
      onlyVisible: true,
      truncateDrawRange: false,
      embedImages: true,
      // animations: []
      forceIndices: true,
      forcePowerOfTwoTextures: true,
      imagesFileType: this.utils.getFileTypeBy(this.imagesType * 1),
      normalImagesFileType: this.utils.getFileTypeBy(this.normalImagesType * 1),
      mroImagesFileType: this.utils.getFileTypeBy(this.mroImagesType * 1),
      imagesCompressionFactor: options.imagesCompressionFactor,
      downloadFile: typeof options.downloadFile === 'boolean' ? options.downloadFile : this.embed,
      maxTexturesSize: this.maxTexturesSize,
      maxDiffuseTexturesSize: this.maxDiffuseTexturesSize,
      maxNormalTexturesSize: this.maxNormalTexturesSize,
      maxMroTexturesSize: this.maxMroTexturesSize,
      optipng: this.useOptipng,
      simplify: this.simplifyGltf ? 0 : null,
      deleteUV2: this.deleteUV2Gltf
    });
    // this.postToChild('broadcastGLTF');
    this.exportedQuery = this.utils.getParamsFromUrl(this.iframeModel.nativeElement.src);
    this.pixel.next({
      event_type: 'embeddedViewerExport'
    });
  }
  render(depth) {
    this.postToChild('prerender', {
      value: 2,
      options: {
        type: depth ? 'depth' : 'normal'
      }
    });
  }
  setScale(x, y, z) {
    this.postToChild('setScale', {
      x: x,
      y: y,
      z: z
    });
  }
  mergeVertices(tolerance) {
    this.postToChild('mergeVertices', {
      value: tolerance
    });
  }
  subdivision() {
    this.subdivisionProgress = true;
    this.postToChild('subdivision', this.subdivisionFactor);
  }
  subdivisionDone() {
    this.subdivisionProgress = false;
  }
  simplify() {
    this.simplifyProgress = true;
    this.postToChild('simplify', this.simplifyFactor);
  }
  simplifyDone(success) {
    this.simplifyProgress = false;
    if (!success) this.broadcaster.next({
      key: 'notifyUser',
      data: 'simplify failed'
    });
  }
  broadcastPolyTypeDistribution() {
    this.polyTypeProgress = true;
    this.postToChild('broadcastPolyTypeDistribution');
  }
  setPolyTypeDistribution(distribution) {
    this.polyTypeProgress = false;
    this._polyTypeDistribution = distribution;
  }
  restoreOriginalGeometries() {
    this.postToChild('restoreOriginalGeometries');
  }
  fixPosition() {
    let param = this.modelPivot;
    let vector = {
      x: null,
      y: null,
      z: null
    };
    let getPosForAxis = (axis, pos) => {
      const boundingBox = this.boundingBox;
      const mainMesh = this.mainMesh;
      switch (pos) {
        case AssetAdjustmentPosition.AFTER:
          {
            return -(boundingBox[axis] / 2) - mainMesh.center[axis] + mainMesh.position[axis];
          }
        case AssetAdjustmentPosition.CENTER:
          {
            return mainMesh.position[axis] - mainMesh.center[axis];
          }
        case AssetAdjustmentPosition.BEFORE:
          {
            return boundingBox[axis] / 2 - mainMesh.center[axis] + mainMesh.position[axis];
          }
      }
    };
    vector.x = getPosForAxis('x', param.value.x);
    vector.y = getPosForAxis('y', param.value.y);
    vector.z = getPosForAxis('z', param.value.z);
    this.postToChild('fixModelPivot', vector);
    this._meshChanged = true;
  }
  setPositionByVector(vector) {
    this.postToChild('fixModelPivot', vector);
    this._meshChanged = true;
  }
  fixRotation() {
    if (this.modelRotation.degree.x !== null) this.modelRotation.value.x = this.modelRotation.degree.x * (Math.PI / 180);
    if (this.modelRotation.degree.y !== null) this.modelRotation.value.y = this.modelRotation.degree.y * (Math.PI / 180);
    if (this.modelRotation.degree.z !== null) this.modelRotation.value.z = this.modelRotation.degree.z * (Math.PI / 180);
    this.postToChild('fixModelRotation', this.modelRotation.value);
    this._meshChanged = true;
  }
  // setPivot() {
  //   this.postToChild('setPivot', this.controlsPosition.value);
  // }
  setCameraPositionByPreset(pos) {
    if (!this.mainMesh || !this.boundingBox) return;
    let param = this.cameraPosition;
    param.value = {
      x: param.default,
      y: param.default,
      z: param.default
    };
    const size = this.boundingBox.size;
    switch (pos) {
      case AssetAdjustmentPosition.BEFORE:
        {
          param.value.x = -size;
          param.value.y = size / 4;
          break;
        }
      case AssetAdjustmentPosition.CENTER:
        {
          param.value.z = size;
          param.value.y = size / 4;
          break;
        }
      case AssetAdjustmentPosition.AFTER:
        {
          param.value.x = size;
          param.value.y = size / 4;
          break;
        }
      case AssetAdjustmentPosition.TOP:
        {
          param.value.y = size;
          break;
        }
      case AssetAdjustmentPosition.BOTTOM:
        {
          param.value.y = -size;
          break;
        }
    }
    this.setCameraPosition();
  }
  setCameraPosition(animationDuration = 1200) {
    let v = this.utils.deepCopyByValue(this.cameraPosition.value);
    v.animationDuration = animationDuration;
    this.postToChild('setCameraPosition', v);
  }
  setControlsProp(type, value) {
    this.postToChild('setControlsProp', {
      value,
      options: {
        type
      }
    });
  }
  // fixCameraRotation() {
  //   this.postToChild('fixCameraRotation', this.cameraRotation.value);
  // }
  initMaterialManipulations(currentMaterialIndex) {
    if (!this.materialManipulations) this.materialManipulations = {};
    if (!this.materials) return;
    if (!this.materialManipulations[this.materials[currentMaterialIndex].name]) {
      this.materialManipulations[this.materials[currentMaterialIndex].name] = {
        color: this.utils.getColor(this.materials[currentMaterialIndex].color),
        reflectivity: this.materials[currentMaterialIndex].reflectivity,
        refractionRatio: this.materials[currentMaterialIndex].refractionRatio,
        alphaTest: this.materials[currentMaterialIndex].alphaTest,
        transparent: this.materials[currentMaterialIndex].transparent,
        velvetIntensity: this.materials[currentMaterialIndex].velvetIntensity,
        clearCoat: this.materials[currentMaterialIndex].clearCoat,
        clearCoatRoughness: this.materials[currentMaterialIndex].clearCoatRoughness
        // castShadow: this.materials[currentMaterialIndex].castShadow,
        // receiveShadow: this.materials[currentMaterialIndex].receiveShadow
      };
    }
  }
  onMaterialChange() {
    this.initMaterialManipulations(this.currentMaterialIndex);
    if (this.showOutline) this.broadcastShowOutline();
  }
  onMaterialTypeChange(ignoreSnapshot = false) {
    if (this.materialManipulations[this.materials[this.currentMaterialIndex].name].type == 'MeshStandardMaterial') this.materialManipulationsChanges = true;
    this.postToChild('swapMaterialType', {
      name: this.materials[this.currentMaterialIndex].name,
      newType: this.materialManipulations[this.materials[this.currentMaterialIndex].name].type
    });
    setTimeout(() => {
      this.postToChild('broadcastMaterials');
      this.postToChild('broadcastTextures');
    }, 100);
    // if (!ignoreSnapshot) {
    //   let data = new SnapshotData();
    //   data.material = {
    //     materialManipulations: this.utils.deepCopyByValue(this.materialManipulations)
    //   } as MaterialSnapshot;
    //   this.takeSnapshot(SnapshotType.MATERIAL_TYPE, data);
    // }
    // }
  }
  setMaterialName(material, newName, ignoreSnapshot = false) {
    if (!newName || material.name == newName) return;
    this._materialChanged = true;
    let obj = {
      name: material.name,
      props: {
        name: newName
      }
    };
    this.postToChild('setMaterialProps', obj);
    this.postToChild('broadcastMaterials');
    // if (!ignoreSnapshot) {
    //   let data = new SnapshotData();
    //   data.material = {
    //     materialProps: {
    //       key: 'name',
    //       value: newName,
    //       material,
    //       mappingChanged: false
    //     },
    //     materials: this.utils.deepCopyByValue(this.materials),
    //   } as MaterialSnapshot;
    //   this.takeSnapshot(SnapshotType.MATERIAL, data);
    // }
  }
  setMaterialProps(material, key, value, mappingChanged = false, ignoreSnapshot = false) {
    if (mappingChanged) this._materialChanged = true;
    let obj = {
      name: material.name,
      props: {}
    };
    obj['props'][key] = value;
    this.postToChild('setMaterialProps', obj);
    // if (!ignoreSnapshot) {
    //   let data = new SnapshotData();
    //   data.material = {
    //     materialProps: {
    //       key,
    //       value,
    //       material,
    //       mappingChanged
    //     },
    //     materials: this.utils.deepCopyByValue(this.materials),
    //     mappingChanged
    //   } as MaterialSnapshot;
    //   this.takeSnapshot(SnapshotType.MATERIAL, data);
    // }
  }
  setTextureProps(texture, key, value, mappingChanged = false) {
    if (mappingChanged) this.mappingChanged = true;
    let obj = {
      name: texture.name,
      props: {}
    };
    obj['props'][key] = value;
    this.postToChild('setTextureProps', obj);
    // if (!ignoreSnapshot) {
    //   let data = new SnapshotData();
    //   data.textureProps = {
    //     key,
    //     value,
    //     mappingChanged
    //   } as TexturePropsSnapshot;
    //   this.takeSnapshot(SnapshotType.TEXTURE_PROPS, data);
    // }
  }
  deleteMesh(mesh) {
    this._meshChanged = true;
    let obj = {
      name: mesh.name
    };
    this.postToChild('deleteMesh', obj);
    this.getMeshesData();
    // this.postToChild('broadcastMaterials');
    // this.postToChild('broadcastTextures');
  }
  setMeshProps(mesh, key, value, mappingChanged = false, refreshMaterials = false, ignoreSnapshot = false) {
    if (mappingChanged) this._meshChanged = true;
    let obj = {
      name: mesh.name,
      props: {}
    };
    if (key === 'rotation') {
      //radians to degree
      mesh.rotation.x = mesh.rotationDegree.x * (Math.PI / 180);
      mesh.rotation.y = mesh.rotationDegree.y * (Math.PI / 180);
      mesh.rotation.z = mesh.rotationDegree.z * (Math.PI / 180);
      value = mesh.rotation;
    }
    obj['props'][key] = value;
    if (!this.meshesData[obj.name]) this.meshesData[obj.name] = {};
    // this.meshesData[value] = this.meshesData[obj.name];
    this.meshesData[obj.name][key] = value;
    if (key == 'name') {
      if (this.mainMesh && this.mainMesh.name == mesh.name) this.mainMesh[key] = value;
    } else if (AssetAdjustmentsService.MESH_MANIPULATIONS_RELEVANT_FIELDS.find(i => i === key)) {
      this.meshManipulationsChanges = true;
      if (!this.meshManipulations) this.meshManipulations = {};
      if (!this.meshManipulations[mesh.name]) this.meshManipulations[mesh.name] = {};
      this.meshManipulations[mesh.name][key] = value;
      // if (this.meshesData[mesh.name].ignoreBB) {
      //   this.meshManipulations[mesh.name] = {
      //     ignoreBB: this.meshesData[mesh.name].ignoreBB
      //   } as MeshData;
      // }
      // else {
      //   delete this.meshManipulations[mesh.name];
      // }
    }
    if (this.selectedMesh && this.selectedMesh.name == mesh.name) this.selectedMesh[key] = value;
    this.postToChild('setMeshProps', obj);
    // if (key === 'name')
    //   this.counter++;
    if (refreshMaterials) this.postToChild('broadcastMaterials');
    if (key == 'bloom') {
      this.onMeshBloomOptions();
      this.refreshNumOfBloomMeshes();
    }
    if (key == 'fur') {
      this.onMeshFurOptions();
      // this.refreshNumOfFurMeshes();
    }
    // if (!ignoreSnapshot) {
    //   let data = new SnapshotData();
    //   let mObj = {
    //     key,
    //     value,
    //     mesh,
    //     mainMesh: this.mainMesh,
    //     selectedMesh: this.selectedMesh,
    //     mappingChanged: mappingChanged,
    //     refreshMaterials: refreshMaterials,
    //   } as MeshSnapshot;
    //   data.mesh = mObj;
    //   this.takeSnapshot(SnapshotType.MESH, data);
    // }
    // if (refreshMeshes)
    //   this.postToChild('getMeshesData');
  }
  onMaterialPropChange(mappingChanged, ignoreSnapshot = false) {
    if (mappingChanged) this._materialChanged = true;
    let obj = {
      name: this.materials[this.currentMaterialIndex].name,
      props: {
        // "reflectivity": this.materialManipulations[this.materials[this.currentMaterialIndex].name].reflectivity
      }
    };
    let reflectivity = this.materialManipulations[this.materials[this.currentMaterialIndex].name].reflectivity;
    if (typeof reflectivity === 'number') {
      this.materialManipulationsChanges = true;
      obj['props']['reflectivity'] = reflectivity;
    }
    let opacity = this.materialManipulations[this.materials[this.currentMaterialIndex].name].opacity;
    if (typeof opacity === 'number') {
      this.materialManipulationsChanges = true;
      obj['props']['opacity'] = opacity;
    }
    let color = this.materialManipulations[this.materials[this.currentMaterialIndex].name].color;
    if (color) {
      obj['props']['color'] = color;
    }
    // let sheenColor = this.materialManipulations[this.materials[this.currentMaterialIndex].name].sheenColor;
    // if (sheenColor) {
    //   obj['props']['sheenColor'] = sheenColor;
    // }
    // let specularColor = this.materialManipulations[this.materials[this.currentMaterialIndex].name].specularColor;
    // if (specularColor) {
    //   obj['props']['specularColor'] = specularColor;
    // }
    // let specularIntensity = this.materialManipulations[this.materials[this.currentMaterialIndex].name].specularIntensity;
    // if (typeof specularIntensity === 'number') {
    //   obj['props']['specularIntensity'] = specularIntensity;
    // }
    // let sheenRoughness = this.materialManipulations[this.materials[this.currentMaterialIndex].name].sheenRoughness;
    // if (typeof sheenRoughness === 'number') {
    //   obj['props']['sheenRoughness'] = sheenRoughness;
    // }
    // let sheen = this.materialManipulations[this.materials[this.currentMaterialIndex].name].sheen;
    // if (typeof sheen === 'number') {
    //   obj['props']['sheen'] = sheen;
    // }
    let alphaTest = this.materialManipulations[this.materials[this.currentMaterialIndex].name].alphaTest;
    if (typeof alphaTest === 'number') {
      obj['props']['alphaTest'] = alphaTest;
    }
    let transparent = this.materialManipulations[this.materials[this.currentMaterialIndex].name].transparent;
    if (typeof transparent === 'boolean') {
      obj['props']['transparent'] = transparent;
    }
    // let castShadow = this.materialManipulations[this.materials[this.currentMaterialIndex].name].castShadow;
    // if (typeof castShadow === 'boolean') {
    //   obj['props']['castShadow'] = castShadow;
    //   this.materialManipulationsChanges = true;
    // }
    // let receiveShadow = this.materialManipulations[this.materials[this.currentMaterialIndex].name].receiveShadow;
    // if (typeof receiveShadow === 'boolean') {
    //   obj['props']['receiveShadow'] = receiveShadow;
    //   this.materialManipulationsChanges = true;
    // }
    let velvetIntensity = this.materialManipulations[this.materials[this.currentMaterialIndex].name].velvetIntensity;
    if (typeof velvetIntensity === 'number') {
      obj['props']['velvetIntensity'] = velvetIntensity;
      this.materialManipulationsChanges = true;
    }
    let clearCoat = this.materialManipulations[this.materials[this.currentMaterialIndex].name].clearCoat;
    if (typeof clearCoat === 'number') {
      obj['props']['clearCoat'] = clearCoat;
      this.materialManipulationsChanges = true;
    }
    let clearCoatRoughness = this.materialManipulations[this.materials[this.currentMaterialIndex].name].clearCoatRoughness;
    if (typeof clearCoatRoughness === 'number') {
      obj['props']['clearCoatRoughness'] = clearCoatRoughness;
      this.materialManipulationsChanges = true;
    }
    if (typeof clearCoatRoughness === 'number') {
      obj['props']['clearCoatRoughness'] = clearCoatRoughness;
      this.materialManipulationsChanges = true;
    }
    let refractionRatio = this.materialManipulations[this.materials[this.currentMaterialIndex].name].refractionRatio;
    if (typeof refractionRatio === 'number') {
      obj['props']['refractionRatio'] = refractionRatio;
      this.materialManipulationsChanges = true;
    }
    // let thickness = this.materialManipulations[this.materials[this.currentMaterialIndex].name].thickness;
    // if (typeof thickness === 'number') {
    //   obj['props']['thickness'] = thickness;
    //   this.materialManipulationsChanges = true;
    // }
    // let transmission = this.materialManipulations[this.materials[this.currentMaterialIndex].name].transmission;
    // if (typeof transmission === 'number') {
    //   obj['props']['transmission'] = transmission;
    //   this.materialManipulationsChanges = true;
    // }
    this.postToChild('setMaterialProps', obj);
    // if (!ignoreSnapshot) {
    //   let data = new SnapshotData();
    //   data.material = {
    //     mappingChanged: mappingChanged,
    //     materialManipulations: this.utils.deepCopyByValue(this.materialManipulations)
    //   } as MaterialSnapshot;
    //   this.takeSnapshot(SnapshotType.MATERIAL_MANIPULATION, data);
    // }
    // setTimeout(() => {
    //   this.postToChild('broadcastMaterials');
    //   this.postToChild('broadcastTextures');
    // }, 100);
  }
  broadcastShowOutline() {
    if (this.materials && this.materials[this.currentMaterialIndex]) {
      this.postToChild('setMaterialOutline', {
        material: this.materials[this.currentMaterialIndex],
        options: {
          visibleEdgeColor: '#64FFDA',
          hiddenEdgeColor: 'rgb(171, 171, 171)',
          edgeThickness: '1',
          // 'pulsePeriod': '2',
          edgeStrength: '10'
        }
      });
    }
  }
  resetDefaults() {
    // this.snapshotIndex = 0;
    this.simplifyFactor = 0.1;
    this.subdivisionFactor = 1;
    this.quantizeNormalBits = 15;
    this.quantizeTexcoordBits = 12;
    this.dracoCompressionLevel = 7;
    this.scaleFactor = 1.0;
    this.onPosition = [];
    this.meshCompress = false;
    this.imageCompress = false;
    this.mappingChanged = false;
    this._meshChanged = false;
    this._materialChanged = false;
    this.useOptipng = false;
    this.materialManipulationsChanges = false;
    this.meshManipulationsChanges = false;
    this.lightsChanges = false;
    this._childrenParentDictionary = {};
    this.animatedState = {};
    this.texturesAnimations = {};
    this.soloLights = {};
    this.materialManipulations = {};
    this.meshManipulations = {};
    this._numOfBloomMeshes = 0;
    this._numOfFurMeshes = 0;
    this._imageThresholdFixed = false;
    this.targetResourceType = null;
    this.exportedQuery = null;
    this.maxTexturesSize = null;
    this.maxDiffuseTexturesSize = null;
    this.maxNormalTexturesSize = null;
    this.maxMroTexturesSize = null;
    this._gltfValidationResponse = null;
    this._modelLoadError = null;
    this.imagesType = null;
    this.normalImagesType = null;
    this.mroImagesType = null;
    this.imagesCompressionFactor = null;
    this.simplifyGltf = null;
    this._polyCount = null;
    this._sceneHierarchy = null;
    this.meshesData = null;
    this.materials = null;
    this.sceneLights = null;
  }
  async broadcastPosition() {
    return new Promise((resolve, reject) => {
      this.onPosition.push(resolve);
      this.postToChild('broadcastPosition');
    });
  }
  toggleNoDistanceLimit(state) {
    this.postToChild('toggleNoDistanceLimit', state);
  }
  toggleNoControlsDamping(state) {
    this.postToChild('toggleNoControlsDamping', state);
  }
  // toggleDistance() {
  //   if (!this.parameters) return;
  //   let distance = this.parameters.filter(p => p.action == 'setDistance');
  //   distance.forEach(p => {
  //     if (this.isDistanceFree) {
  //       if (p.urlParam == 'max-distance')
  //         p.value = this.boundingBox ? (Math.max(this.boundingBox.size * 5, 30)) || 100000 : 100000;
  //       // p.value = Infinity;
  //       else
  //         p.value = 0.0001;
  //     }
  //     else {
  //       p.value = null;
  //     }
  //     this.changeValue(p);
  //   });
  // }
  setMeshesCastShadow(state) {
    this.postToChild('setMeshPropsRecursive', {
      key: 'castShadow',
      value: state
    });
    this.meshManipulationsChanges = true;
    this.syncMeshManipulations();
  }
  setMeshesReceiveShadow(state) {
    this.postToChild('setMeshPropsRecursive', {
      key: 'receiveShadow',
      value: state
    });
    this.meshManipulationsChanges = true;
    this.syncMeshManipulations();
  }
  onMeshBloomOptions(evt, field) {
    if (evt) this.meshBloomOptions[field] = evt.value;
    if (!this.meshBloomOptions.height) this.meshBloomOptions.height = -1;
    this.meshBloomOptions.width = this.meshBloomOptions.height;
    let meshNames = [];
    for (let mn in this.meshesData) {
      if (this.meshesData[mn].bloom) meshNames.push(mn);
    }
    this.postToChild('setBloomEffectOptions', {
      value: this.meshBloomOptions,
      meshNames
    });
  }
  onMeshFurOptions(evt, field) {
    if (evt) this.meshFurOptions[field] = evt.target.value;
    let meshNames = [];
    for (let mn in this.meshesData) {
      if (this.meshesData[mn].fur) meshNames.push(mn);
    }
    this.refreshNumOfFurMeshes();
    this.postToChild('toggleFur', {
      state: !!this.numOfFurMeshes,
      meshes: meshNames,
      options: this.meshFurOptions
    });
  }
  // setMeshBloom(mesh: MeshData, hasBloom: boolean) {
  //   if (hasBloom)
  //     mesh.bloom = this.meshBloomOptions;
  //   else
  //     delete mesh.bloom;
  //   this.setMeshProps(mesh, 'bloom', mesh.bloom);
  //   this.onMeshBloomOptions();
  // }
  // setMaterialsCastShadow(state: boolean) {
  //   this.postToChild('setMeshPropsRecursive', {
  //     key: 'castShadow',
  //     value: state
  //   });
  //   if (this.materials) {
  //     for (let i = 0; i < this.materials.length; i++) {
  //       this.initMaterialManipulations(i);
  //       this.materials[i].castShadow = state;
  //       this.materialManipulations[this.materials[i].name].castShadow = this.materials[i].castShadow;
  //     }
  //     // this.materials.forEach(m => {
  //     //   m.castShadow = state;
  //     //   this.materialManipulations[m.name].castShadow = m.castShadow;
  //     // });
  //   }
  //   this.materialManipulationsChanges = true;
  // }
  // setMaterialsReceiveShadow(state: boolean) {
  //   this.postToChild('setMeshPropsRecursive', {
  //     key: 'receiveShadow',
  //     value: state
  //   });
  //   if (this.materials) {
  //     for (let i = 0; i < this.materials.length; i++) {
  //       this.initMaterialManipulations(i);
  //       this.materials[i].receiveShadow = state;
  //       this.materialManipulations[this.materials[i].name].receiveShadow = this.materials[i].receiveShadow;
  //     }
  //   }
  //   this.materialManipulationsChanges = true;
  // }
  setRaycasterState(state, options) {
    this.postToChild('setRaycasterState', {
      value: state,
      options: options
    });
    if (!state) {
      this.selectedMesh = null;
      this.counter++;
    }
  }
  setProps(props) {
    let obj = {
      props: props
    };
    this.postToChild('setProps', obj);
  }
  bindSphereToLight(state, options = new BindSphereLightOptions()) {
    this.postToChild('bindSphereToLight', {
      value: state,
      options: options
    });
    if (!state) this.selectedLight = null;else this.selectedLight = options.type + '_' + (options.index || 0);
  }
  setTextureProp(texture, key, value) {
    texture[key] = value;
    let obj = {
      name: texture.name,
      props: {}
    };
    obj['props'][key] = value;
    this.postToChild('setTextureProps', obj);
  }
  getMaterialType(m) {
    if (m.type) return m.type;
    if (m.isMeshStandardMaterial) return 'MeshStandardMaterial';
    if (m.isMeshBasicMaterial) return 'MeshBasicMaterial';
    if (m.isMeshDepthMaterial) return 'MeshDepthMaterial';
    if (m.isMeshLambertMaterial) return 'MeshLambertMaterial';
    if (m.isMeshPhongMaterial) return 'MeshPhongMaterial';
    if (m.isMeshPhysicalMaterial) return 'MeshPhysicalMaterial';
    if (m.isMeshToonMaterial) return 'MeshToonMaterial';
    if (m.isPointsMaterial) return 'PointsMaterial';
    if (m.isSpriteMaterial) return 'SpriteMaterial';
  }
  setTexturesPropsByMaterial(material, key, value, mappingChanged = false) {
    let maps = this.noIntensity(this.keyValuePure(this.mapping), this.getMaterialType(material));
    maps.forEach(map => {
      if (material[map.key] && material[map.key].name) {
        if (mappingChanged) this.mappingChanged = true;
        this.setTextureProp(material[map.key], key, value);
      }
    });
  }
  hasImagesChanged() {
    return this._textureImageChanged || this.imageCompress || this.materialManipulationsChanges || this._imageThresholdFixed; // redundant? || !!this.imagesType || !!this.imagesCompressionFactor || !!this.maxTexturesSize || this.useOptipng;
  }
  // applyUrlParams(params: any) {
  //   for (let i in params) {
  //     let p = this.parameters.find(p => p.urlParam == i);
  //     if (p) {
  //       switch (p.type) {
  //         case AssetAdjustmentType.TOGGLE: {
  //           p.value = params[i] == 'true';
  //           break;
  //         }
  //         case AssetAdjustmentType.SLIDER: {
  //           p.value = parseFloat(params[i]);
  //           break;
  //         }
  //         case AssetAdjustmentType.VECTOR3: {
  //           let vec = params[i].split(',') as Array<string>;
  //           p.value = { x: vec[0], y: vec[1], z: vec[2] };
  //           break;
  //         }
  //         case AssetAdjustmentType.JOYSTICK: {
  //           p.value = { x: null, y: null, z: null };
  //           try {
  //             let vec = params[i].split(',');
  //             p.value = { x: this.utils.trimIfFloat(vec[0], p.round), y: this.utils.trimIfFloat(vec[1], p.round), z: this.utils.trimIfFloat(vec[2], p.round) };
  //           } catch (e) { }
  //           break;
  //         }
  //       }
  //     }
  //   }
  // }
  toggleMeshDimensions(meshName) {
    let exec = () => {
      return this.mhService.postToChild({
        action: 'toggleDimensionsAnnotations',
        value: {
          mesh: meshName
        },
        options: {
          active: true
        }
      }, this.iframeModel.nativeElement);
    };
    if (!exec()) {
      this.recoverIframeConnection();
      exec();
    }
  }
  setNormalizeScale(scale) {
    this.postToChild('setNormalizeScale', scale);
  }
  applyUrlParams(src = this.iframeModel.nativeElement.src) {
    let params = this.utils.getParamsFromUrl(src);
    for (let i in params) {
      let p = this.parameters.find(p => p.urlParam === i);
      if (p) {
        let e = {
          value: params[i]
        };
        switch (p.type) {
          case AssetAdjustmentType.TOGGLE:
            {
              e.value = params[i] == 'true';
              break;
            }
          case AssetAdjustmentType.SLIDER:
            {
              e.value = parseFloat(params[i]);
              break;
            }
          case AssetAdjustmentType.VECTOR3:
            {
              let vec = params[i].split(',');
              e.value = {
                x: vec[0],
                y: vec[1],
                z: vec[2]
              };
              break;
            }
          case AssetAdjustmentType.JOYSTICK:
            {
              e.value = {
                x: null,
                y: null,
                z: null
              };
              try {
                let vec = params[i].split(',');
                e.value = {
                  x: this.utils.trimIfFloat(vec[0], p.round),
                  y: this.utils.trimIfFloat(vec[1], p.round),
                  z: this.utils.trimIfFloat(vec[2], p.round)
                };
              } catch (e) {}
              break;
            }
        }
        this.changeValue(p, e);
      }
    }
  }
  async hasRedundantTextures(orgResolve) {
    return new Promise((resolve, reject) => {
      resolve = orgResolve || resolve;
      if (this.viewerType !== '3JS') {
        resolve(false);
        return;
      }
      if (!this.lightsChanges) {
        this.resolveAfterSetLights.push(this.hasRedundantTextures.bind(this, resolve));
        return;
      }
      if (!this.meshesData) {
        this.onGetMeshesData.push(this.hasRedundantTextures.bind(this, resolve));
        return;
      }
      let allLights = this.parameters.filter(p => p.isLight);
      if (!allLights.length) {
        resolve(false);
        return;
      }
      let castShadow = false;
      allLights.forEach(l => {
        let cs = l.children.find(c => c.name === 'cast shadow');
        if (cs && cs.value) {
          castShadow = true;
        }
      });
      if (!castShadow) {
        resolve(false);
        return;
      }
      let receiveShadow = false;
      if (this._meshesData) {
        for (let i in this.meshesData) {
          if (this.meshesData[i].receiveShadow) {
            receiveShadow = true;
            break;
          }
        }
      }
      if (!receiveShadow) {
        let plane = this.parameters.find(p => p.id == 'plane');
        if (plane.toggleState) {
          let planeR = this.parameters.find(p => p.id == 'plane-r');
          let planeP = this.parameters.find(p => p.id == 'plane-p');
          if (planeR.value || planeP.value) receiveShadow = true;
        }
      }
      resolve(!receiveShadow);
    });
  }
  // disableAllShadows() {
  //   let allLights = this.parameters.filter(p => p.isLight);
  //   allLights.forEach(l => {
  //     let cs = l.children.find(c => c.name === 'cast shadow');
  //     if (cs && cs.options && cs.options.castShadow) {
  //       cs.options.castShadow = false;
  //       this.changeValue(l);
  //     }
  //   });
  // }
  setControlsPosition(position) {
    return new Promise((resolve, reject) => {
      this.onControlsPositionEnd.push(resolve);
      this.postToChild('setControlsPosition', position);
    });
  }
  materializeMesh(meshName, config) {
    this._materialChanged = true;
    this.postToChild('materializeMesh', {
      name: meshName,
      config: config
    });
  }
  emulateTarget() {
    this.removeAllLights();
    let hdr = this.parameters.find(p => p.id == 'hdr'),
      hdrIntensity = this.parameters.find(p => p.id == 'hdr-intensity'),
      hdrBlur = this.parameters.find(p => p.id == 'hdr-blur'),
      hdrFormat = this.parameters.find(p => p.id == 'hdr-format'),
      probe = this.parameters.find(p => p.id == 'le-probe'),
      hdrbg = this.parameters.find(p => p.id == 'hdrbg'),
      hdrSkybox = this.parameters.find(p => p.id == 'hdr-skybox'),
      hdrRotation = this.parameters.find(p => p.id == 'hdr-rotation'),
      exp = this.parameters.find(p => p.id == 'exp'),
      fov = this.parameters.find(p => p.id == 'fov'),
      tone = this.parameters.find(p => p.id == 'tone-mapping');
    hdrIntensity.value = 1;
    this.changeValue(hdrIntensity);
    hdrBlur.value = false;
    this.changeValue(hdrBlur);
    hdrFormat.value = 'hdr';
    this.changeValue(hdrFormat);
    probe.value = false;
    this.changeValue(probe);
    hdrbg.value = false;
    this.changeValue(hdrbg);
    hdrSkybox.value = false;
    this.changeValue(hdrSkybox);
    hdrRotation.value = {
      x: 0,
      y: 0,
      z: 0
    };
    this.changeValue(hdrRotation);
    exp.value = 0.8;
    this.changeValue(exp);
    fov.value = 45;
    this.changeValue(fov);
    tone.value = 4; // ACES Filmic ToneMapping
    this.changeValue(tone);
    setTimeout(() => {
      hdr.value = 24;
      this.changeValue(hdr);
    });
  }
  broadcastDiffuseThreshold() {
    return new Promise((resolve, reject) => {
      this.onSetDiffuseThreshold.push(resolve);
      this.postToChild('broadcastDiffuseThreshold');
    });
  }
  fixImageThreshold(lowest, highest, mode) {
    this._imageThresholdFixed = true;
    return new Promise((resolve, reject) => {
      this.onFixImageThreshold.push(resolve);
      this.postToChild('fixImageThreshold', {
        lowest: lowest,
        highest: highest,
        mode: mode
      });
    });
  }
  adjustImageRGB(materialName, deltaRed, deltaGreen, deltaBlue, activeThreshold) {
    this._imageThresholdFixed = true;
    return new Promise((resolve, reject) => {
      this.onAdjustImageRGB.push(resolve);
      this.postToChild('adjustImageRGB', {
        materialName: materialName,
        deltaRed: deltaRed,
        deltaGreen: deltaGreen,
        deltaBlue: deltaBlue,
        activeThreshold: activeThreshold
      });
    });
  }
  revertImageRGB(materialName) {
    this._imageThresholdFixed = true;
    return new Promise((resolve, reject) => {
      this.onRevertImageRGB.push(resolve);
      this.postToChild('revertImageRGB', {
        materialName: materialName
      });
    });
  }
  flip(flipX, flipY) {
    this._meshChanged = true;
    this.postToChild('flip', {
      flipX: flipX,
      flipY: flipY
    });
  }
  async setTimeout(ms) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve();
      }, ms);
    });
  }
  async setAmazonPreset(preset) {
    this.removeAllLights();
    let hdri = {
      background: false,
      blur: false,
      format: 'hdr',
      lightProbe: 0,
      type: null,
      skybox: false,
      rotation: {
        x: 0,
        y: 0,
        z: 0
      }
    };
    switch (preset) {
      case AmazonPresets.AMBIENT:
        {
          hdri.intensity = 0;
          this.resolveAfterSetLights.push(() => {
            this.resolveAfterSetLights.push(() => {
              let l = this.parameters.find(p => p.isLight && p.name === 'Hemispheric' && p.index === 0);
              l.value = 1;
              l.options = {
                position: {
                  x: 0,
                  y: 1,
                  z: 0
                },
                type: 'HemisphericLight',
                index: 0
              };
              this.changeValue(l);
              l = this.parameters.find(p => p.isLight && p.name === 'Hemispheric' && p.index === 1);
              l.value = 1;
              l.options = {
                position: {
                  x: 0,
                  y: -1,
                  z: 0
                },
                type: 'HemisphericLight',
                index: 1
              };
              this.changeValue(l);
            });
            this.addLight('HemisphericLight');
          });
          this.addLight('HemisphericLight');
          // await this.refreshLightsSummary();
          // await this.setTimeout();
          // this.addLight('HemisphericLight');
          // await this.refreshLightsSummary();
          // await this.setTimeout();
          // let l = this.parameters.find(p => p.isLight && p.name === 'Hemispheric' && p.index === 0);
          // l.value = 1;
          // l.options = {
          //   position: { x: 0, y: 1, z: 0 },
          //   type: 'HemisphericLight',
          //   index: 0
          // };
          // this.changeValue(l);
          // l = this.parameters.find(p => p.isLight && p.name === 'Hemispheric' && p.index === 1);
          // l.value = 1;
          // l.options = {
          //   position: { x: 0, y: -1, z: 0 },
          //   type: 'HemisphericLight',
          //   index: 1
          // };
          // this.changeValue(l);
          // await this.refreshLightsSummary();
          break;
        }
      case AmazonPresets.CHROMATIC:
        {
          // hdri.intensity = 1.96;
          hdri.intensity = 1.6;
          hdri.type = 25;
          break;
        }
      case AmazonPresets.DIRECTIONAL:
        {
          // hdri.intensity = 1.96;
          hdri.intensity = 1.2;
          hdri.type = 26;
          break;
        }
      case AmazonPresets.NEUTRAL:
        {
          // hdri.intensity = 3;
          hdri.intensity = 2.4;
          hdri.type = 27;
          break;
        }
    }
    this.hdri = hdri;
    this.applyHDRI();
    let fov = this.parameters.find(p => p.id == 'fov');
    fov.value = 15;
    this.changeValue(fov);
    // this.setLightsByJson()
  }
  toggleCollision(collisionMode, color) {
    this.postToChild('toggleCollision', {
      value: collisionMode,
      color: color
    });
  }
  deleteCollision(position, count) {
    this.postToChild('spliceCollision', {
      value: position,
      count: count
    });
  }
  removeAllCollisions() {
    this.postToChild('removeAllCollisions');
  }
  toggleCollisionsVisibility(show) {
    this.postToChild('toggleCollisionsVisibility', {
      value: show
    });
  }
  deleteTexture(materialName, mapType) {
    const options = {
      map: mapType,
      remove: true
    };
    this.refreshMaterials = true;
    this.refreshTextures = true;
    this.postToChild('mapTextureToMaterial', {
      texture: null,
      material: materialName,
      options: options
    });
    if (mapType === 'envMap' && this.materialManipulations && this.materialManipulations[materialName]) {
      delete this.materialManipulations[materialName].envMap;
    }
    this.counter++;
    this.mappingChanged = true;
  }
  toggleXr(state, invokeWhenReady, showIcon) {
    this.mhService.postToChild({
      action: 'toggleXr',
      obj: {
        state: state,
        value: invokeWhenReady,
        showIcon: showIcon
      }
    }, this.iframeModel.nativeElement);
    // let obj = {
    //   action: 'toggleXr',
    //   value: value
    // } as any;
    // if (!this.mhService.postToChild(obj, this.iframeModel.nativeElement, options)) {
    // this.postToChild('toggleXr', {
    //   'state': state, 'value': invokeWhenReady, 'showIcon': showIcon
    // });
    // messager.sendToIframe('toggleXr', {
    //     'state': state,
    //     'value': invokeWhenReady,
    //     'showIcon': showIcon
    // }, true);
  }
  toggleFur(state, meshesNames, options) {
    this.mhService.postToChild({
      action: 'toggleFur',
      value: {
        state,
        meshes: meshesNames,
        options
      }
    }, this.iframeModel.nativeElement);
  }
  onDestroy() {
    if (this.iframeModel.nativeElement) this.mhService.removePostMessageEvent(this.mhService.getReferrerByUrl(this.iframeModel.nativeElement.src), this);
    this.frameEventsAttached = false;
    this.resetDefaults();
  }
  static ɵfac = function AssetAdjustmentsService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || AssetAdjustmentsService)(i0.ɵɵinject(i1.MessagesHandlerService), i0.ɵɵinject(EnumsService), i0.ɵɵinject(UtilsService));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: AssetAdjustmentsService,
    factory: AssetAdjustmentsService.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AssetAdjustmentsService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.MessagesHandlerService
  }, {
    type: EnumsService
  }, {
    type: UtilsService
  }], null);
})();

// import { AssetAdjustmentsFilterPipe } from 'src/app/asset-adjustments/asset-adjustments-filter.pipe';
class NgAssetAdjustmentsModule {
  static ɵfac = function NgAssetAdjustmentsModule_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || NgAssetAdjustmentsModule)();
  };
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: NgAssetAdjustmentsModule
  });
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgAssetAdjustmentsModule, [{
    type: NgModule,
    args: [{
      // declarations: [AssetAdjustmentsFilterPipe],
      imports: []
      // exports: [AssetAdjustmentsFilterPipe]
    }]
  }], null, null);
})();
class AssetCommunication {
  injectorInjected;
  _viewerSrc;
  utils;
  mhService;
  modelUrl;
  iframeModel;
  onViewerListening;
  onControlsPositionEnd;
  onCollisions = [];
  sanitizer;
  _eventEmitter;
  constructor(options, injectorInjected) {
    this.injectorInjected = injectorInjected;
    this.utils = this.injectorInjected.get(UtilsService);
    this.mhService = this.injectorInjected.get(MessagesHandlerService);
    this.sanitizer = this.injectorInjected.get(DomSanitizer);
    this.modelUrl = options.modelUrl;
    this.iframeModel = options.iframeModel;
    this._viewerSrc = this.sanitizer.bypassSecurityTrustResourceUrl(this.modelUrl);
    this.onViewerListening = [];
    this.onControlsPositionEnd = [];
    this._eventEmitter = new EventEmitter();
    this.mapUrl();
  }
  get eventEmitter() {
    return this._eventEmitter;
  }
  get viewerSrc() {
    return this._viewerSrc;
  }
  async mapUrl() {
    const obj = {
      actions: this.utils.getAllActions(),
      scope: this,
      onMessage: this.onMessage.bind(this)
    };
    this.mhService.onPostMessageEvent(obj, this.mhService.getReferrerByUrl(this.modelUrl));
    await this.waitForViewerListening();
    this.postToChild('broadcastViewerFullyLoaded');
  }
  changeUrl(url) {
    this.onViewerListening = [];
    this.onControlsPositionEnd = [];
    // this.removePostMessageEvent(this.modelUrl);
    this.modelUrl = url;
    this.mapUrl();
    this._viewerSrc = this.sanitizer.bypassSecurityTrustResourceUrl(this.modelUrl);
  }
  async waitForViewerListening() {
    return new Promise((resolve, reject) => {
      this.onViewerListening.push(() => {
        resolve();
      });
    });
  }
  async waitForCollisions() {
    return new Promise((resolve, reject) => {
      this.onCollisions.push(resolve);
    });
  }
  toggleCollisionsVisibility(show) {
    this.postToChild('toggleCollisionsVisibility', {
      'value': show
    });
  }
  removePostMessageEvent(url) {
    if (url) this.mhService.removePostMessageEvent(this.mhService.getReferrerByUrl(url), this);
  }
  onMessage(obj) {
    switch (obj.action) {
      case 'viewerListening':
        {
          this.onViewerListening.forEach(f => f());
          break;
        }
      case 'setCollisions':
        {
          this.onCollisions.forEach(f => f());
          break;
        }
      case 'onControlsPositionEnd':
        {
          this.onControlsPositionEnd.forEach(f => f());
          break;
        }
    }
    this._eventEmitter.next(obj);
  }
  postToChild(action, value, options = null, data = null, messageOptions = new PostToChildOptions$1(true)) {
    if (this.modelUrl && this.iframeModel) {
      let obj = {
        action,
        value,
        data,
        options
      };
      if (!this.mhService.postToChild(obj, this.iframeModel.nativeElement, messageOptions)) {
        this.recoverIframeConnection();
        this.mhService.postToChild(obj, this.iframeModel.nativeElement, messageOptions);
      }
    }
  }
  postAnyToChild(obj, messageOptions = new PostToChildOptions$1(true)) {
    if (this.modelUrl && this.iframeModel) {
      if (!this.mhService.postToChild(obj, this.iframeModel.nativeElement, messageOptions)) {
        this.recoverIframeConnection();
        this.mhService.postToChild(obj, this.iframeModel.nativeElement, messageOptions);
      }
    }
  }
  recoverIframeConnection() {
    if (this.iframeModel && this.iframeModel.nativeElement) this.iframeModel.nativeElement = document.querySelector(`iframe[src="${this.iframeModel.nativeElement.src}"]`);
  }
  materializeMesh(meshName, config) {
    this.postToChild('materializeMesh', {
      name: meshName,
      config: config
    });
  }
  toggleWireframe(state) {
    this.postToChild('toggleWireframe', state);
  }
  toggleShadedWireframe(state, color) {
    this.postAnyToChild({
      value: state,
      options: {
        color
      },
      action: 'toggleShadedWireframe'
    });
  }
  toggleDimensions(state, options) {
    const value = {};
    if (state && options) {
      if (typeof options.x === 'string') value.x = options.x;
      if (typeof options.y === 'string') value.y = options.y;
      if (typeof options.z === 'string') value.z = options.z;
    }
    if (!options) options = {};
    this.postAnyToChild({
      value,
      options: {
        dimensionsUnits: options.dimensionsUnits,
        color: options.color,
        active: state
      },
      action: 'toggleDimensionsAnnotations'
    });
  }
  setControlsPosition(pos, options) {
    return new Promise((resolve, reject) => {
      this.onControlsPositionEnd.push(resolve);
      this.postToChild('setControlsPosition', pos, null, options);
    });
  }
  setFOV(value, options) {
    this.postToChild('setFOV', value, {
      stabilize: options.stabilize,
      animate: options.animate,
      ignoreDistance: options.ignoreDistance,
      ignoreDistanceExceeded: options.ignoreDistanceExceeded
    });
  }
  setFocalLength(value, options) {
    this.postToChild('setFocalLength', value, {
      stabilize: options.stabilize,
      animate: options.animate,
      ignoreDistance: options.ignoreDistance,
      ignoreDistanceExceeded: options.ignoreDistanceExceeded
    });
  }
  broadcastSceneSummary() {
    this.postToChild('broadcastSceneSummary');
  }
  destroy() {
    this.removePostMessageEvent(this.modelUrl);
  }
}

/*
 * Public API Surface of asset-adjustments
 */

/**
 * Generated bundle index. Do not edit.
 */

export { AdjustmentsSourceChangesState, AmazonPresets, AnnotationType, AssetAdjustmentPosition, AssetAdjustmentSector, AssetAdjustmentType, AssetAdjustmentsService, AssetCommunication, BABYLON_LATEST_VERSION, BindSphereLightOptions, CLAMPING_MODE, CompressedFilesState, ConsoleMessageType, DimensionsUnits, EnumsService, GltfValidationSeverity, ImagesFileTypes, MAPS_DICTIONARY, MAX_PREVIEW_SIZE, MeshBloomOptions, MeshFurOptions, NgAssetAdjustmentsModule, NotificationType, PlaneOptions, PostToChildOptions, RaycasterType, RenderSide, RenderTechnology, RenderingEngine, SVGPostprocessingOptions, THREE_LATEST_VERSION, ThreeMinificationFilters, ThreeTextureWrap, TransparentSort, UtilsService };
