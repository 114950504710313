import { Component, OnInit, Input } from '@angular/core';
import { User } from '../user';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserDialog } from './user-dialog';
import { AuthService } from '../auth.service';
import { UtilsService } from '../../shared/utils.service';
import { RolesHelperService } from '../roles-helper.service';
import { RestService } from '../../communication/rest.service';
import { BroadcasterNotification, BroadcasterNotificationType } from '../../communication/broadcaster-notifications';
import { BroadcasterService } from 'ng-broadcaster';
import { GlobalsService } from 'src/app/shared/globals.service';

@Component({
    selector: 'app-user-card',
    templateUrl: './user-card.component.html',
    styleUrls: ['./user-card.component.scss'],
    standalone: false
})
export class UserCardComponent implements OnInit {
  @Input() user: User;
  @Input('use-dialog') useDialog: boolean;
  public showRetailersSwitch: boolean;
  constructor(
    private dialog: MatDialog,
    public auth: AuthService,
    private utils: UtilsService,
    public roles: RolesHelperService,
    private rest: RestService,
    private broadcaster: BroadcasterService,
    public globals: GlobalsService
  ) { }

  ngOnInit() {
    this.showRetailersSwitch = this.auth.user.id == this.user.id && this.auth.user.retailers_users.length > 1;
  }

  openDialog(): void {
    let userCopy = this.utils.deepCopyByValue(this.user) as User;
    this.auth.atachRolesToUser(userCopy);
    const dialogRef = this.dialog.open(UserDialog, {
      width: '500px',
      data: userCopy
    });

    dialogRef.afterClosed().subscribe((result: User) => {
      // console.log('The dialog was closed');
      // this.animal = result;
      if (result) {
        userCopy.avatar_url = result.avatar_url;
        userCopy.firstname = result.firstname;
        userCopy.lastname = result.lastname;
        userCopy.email = result.email;
        userCopy.users_roles = result.users_roles;
        this.user = userCopy;
      }
    });
  }

  logout() {
    this.auth.logout();
  }

  deleteUser() {
    if (!confirm('Are you sure you want to DELETE this user?')) return;
    const query = '/' + this.user.id
    this.rest.userProfile('delete', null, query).subscribe(
      () => {
        let data: BroadcasterNotification = {
          text: 'user deleted successfully',
          type: BroadcasterNotificationType.Success,
          action: 'OK'
        };
        this.broadcaster.broadcast('notifyUser', data); 
        this.broadcaster.broadcast('onUsersChange');
      },
      err => this.utils.httpErrorResponseHandler(err, 'failure deleting user')
    );
  }

  setRetailer(index: number) {
    this.auth.setRetailerIndex(index);
  }
}
