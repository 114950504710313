import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { BroadcasterService } from 'ng-broadcaster';
import { BroadcasterNotification, BroadcasterNotificationType } from 'src/app/communication/broadcaster-notifications';
import { RestService } from 'src/app/communication/rest.service';
import { KeyValueAnyPair } from 'src/app/shared/enums';
import { FileObject } from 'src/app/shared/file';
import { ResumableCDN, ResumableState, ResumableSubject } from 'src/app/shared/resumable';
import { ResumableUploadService } from 'src/app/shared/resumable-upload.service';
import { UtilsService } from 'src/app/shared/utils.service';
import { ProductsAttachment, ProductsAttachmentUI } from '../product';

@Component({
    selector: 'app-products-attachments',
    templateUrl: './products-attachments.component.html',
    styleUrls: ['./products-attachments.component.scss'],
    standalone: false
})
export class ProductsAttachmentsComponent implements OnChanges {
  @Input('attachments') attachments: Array<ProductsAttachmentUI>;
  @Input('product-id') productId: number;
  @Output('on-change') onChange: EventEmitter<Array<ProductsAttachment>>;
  public fileUploadsProgress: Map<FileObject, KeyValueAnyPair>;
  constructor(
    private resumableUpload: ResumableUploadService,
    private broadcaster: BroadcasterService,
    private rest: RestService,
    private utils: UtilsService
  ) {
    this.fileUploadsProgress = new Map<FileObject, KeyValueAnyPair>();
    this.onChange = new EventEmitter<Array<ProductsAttachment>>();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.attachments)
      this.addSuffix();
  }

  addSuffix() {
    if (this.attachments)
      this.attachments.forEach(a => {
        a.suffix = this.utils.getFileExtension(a.attachment);
      })
  }

  remove(index: number) {
    this.attachments.splice(index, 1);
    this.onUpdate();
  }

  onUpdate() {
    this.onChange.next(this.attachments);
    this.addSuffix();
  }

  onFilesChange(fileList: Array<FileObject>) {
    let totalVideoUploads = 0;
    let UploadFile = (file: FileObject) => {
      let sub = this.resumableUpload.sourceFiles(file.file);
      sub.subscribe((res: ResumableSubject) => {
        switch (res.state) {
          case ResumableState.FILE_PROGRESS: {
            const kvp = this.fileUploadsProgress.get(file);
            if (kvp)
              kvp.value = res.object.progress;
            break;
          }
          case ResumableState.ERROR: {
            console.warn(res);
            console.warn('Something went wrong during upload of a specific file, ERROR . . .');
            let data: BroadcasterNotification = {
              text: 'Connection was interrupted, please try again.',
              type: BroadcasterNotificationType.Error,
              action: 'OK'
            }
            this.broadcaster.broadcast('notifyUser', data);
            break;
          }
          case ResumableState.COMPLETE: {
            let payload = new ResumableCDN();
            payload.compress = true;
            payload.uploaded_file_name = `${file.name}.${file.suffix}`;
            payload.uploaded_file_url = res.object.message;
            this.rest.afterCdn('post', payload).subscribe(
              data => {
                this.attachments.push({
                  attachment: data['url'],
                  product_id: this.productId,
                  title: file.name
                } as ProductsAttachment);
                if (--totalVideoUploads == 0) {
                  setTimeout(() => {
                    this.fileUploadsProgress.clear();
                  }, 350);
                  this.onUpdate();
                }
              },
              err => this.utils.httpErrorResponseHandler(err, 'file upload failed')
            );
          }
        }
      });
    };
    if (fileList.length > 0) {
      fileList.forEach(file => {
        totalVideoUploads++;
        this.fileUploadsProgress.set(file, {
          key: file.name,
          value: 0
        });
        UploadFile(file);
      });
    }
  }
}
