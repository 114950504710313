import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { ProductAudit, ProductAuditUI, ProductAuditUIGroup, ProductAuditActionMethod, ProductData, ImageDialog, ProductsAttachment } from '../product';
import { Retailer } from '../../retailer/retailer';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ImageDialogComponent } from '../../shared/image-dialog/image-dialog.component';
import { ProductService } from '../product.service';
import { FeedbackService } from '../feedback.service';

@Component({
    selector: 'app-product-history',
    templateUrl: './product-history.component.html',
    styleUrls: ['./product-history.component.scss'],
    standalone: false
})
export class ProductHistoryComponent implements OnInit, OnChanges {
  @Input('products-audit') productsAudit: Array<ProductAudit>;
  @Input('products-data') productsData: Array<ProductData>;
  @Input('products-attachments') productsAttachments: Array<ProductsAttachment>;
  @Input('retailer') retailer: Retailer;
  public list: Array<ProductAuditUIGroup>;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private productService: ProductService,
    public feedbackService: FeedbackService,
  ) {
    this.list = [];
  }

  ngOnInit() {
    this.list = this.productService.mapAudit(this.retailer, this.productsAudit, this.productsData, this.productsAttachments);
  }

  ngOnChanges() {
    this.list = this.productService.mapAudit(this.retailer, this.productsAudit, this.productsData, this.productsAttachments);
  }

  onClick(item: ProductAuditUI) {
    if (item.onClickArgs) {
      switch (item.onClickArgs[0]) {
        case 'feedback': {
          this.router.navigate(['feedback', item.onClickArgs[1]], { relativeTo: this.route });
          break;
        }
        case 'attachment': {
          window.location.href = item.onClickArgs[1];
          break;
        }
        case 'data': {
          this.viewImage(item);
          break;
        }
        // case 'reject': {
        //   this.prService.onRejectFixed();
        //   break;
        // }
      }
    }
  }

  viewImage(imageData: ProductAuditUI) {
    const m = {
      url: imageData.url
    } as ImageDialog;
    this.dialog.open(ImageDialogComponent, {
      data: m
    });
  }
}
