import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GlobalsService } from 'src/app/shared/globals.service';
import { PlatgroundClass } from 'src/app/shared/globals';
import { AuthService } from '../auth.service';
import { GraphqlService } from 'src/app/communication/graphql.service';
import { RetailerFilterOptions } from 'src/app/retailer/retailer';

@Component({
    selector: 'app-payment-status',
    templateUrl: './payment-status.component.html',
    styleUrls: ['./payment-status.component.scss'],
    standalone: false
})
export class PaymentStatusComponent implements OnInit {
  public isSuccess: boolean;
  public budget: number;
  constructor(
    private activatedRoute: ActivatedRoute,
    private globals: GlobalsService,
    private auth: AuthService,
    private gql: GraphqlService
  ) {
    this.getBudget();
  }

  ngOnInit() {
    this.isSuccess = this.activatedRoute.snapshot.data['state'] as boolean;
    setTimeout(() => {
      this.globals.playgroundClass = PlatgroundClass[PlatgroundClass.SMALL].toLowerCase();
      this.globals.setHeaer('payment', this.isSuccess ? 'Successfully transferred' : 'Failure transferring funds');
    }, 0);
  }

  getBudget() {
    if (!this.auth.user) return;
    const options = {
      id: this.auth.user.retailers_users[this.auth.retailerIndex].retailer_id
    } as RetailerFilterOptions;
    this.gql.retailerForSettings(options).subscribe(
      res => this.budget = res.data.retailers.retailers_budget[0].amount
    );
  }
}
