import { Component, Input, OnInit } from '@angular/core';
import { Product, SingleProductQueryData } from '../product';
import { Retailer } from 'src/app/retailer/retailer';
import { RestService } from 'src/app/communication/rest.service';
import { Router } from '@angular/router';
import { ApolloQueryResult } from '@apollo/client/core';
import { ProductRequestService } from '../product-request.service';
import { ProductService } from '../product.service';
import { GraphqlService } from 'src/app/communication/graphql.service';
import { UtilsService } from 'src/app/shared/utils.service';
import { AuthService } from 'src/app/auth/auth.service';
import { MatSelectChange } from '@angular/material/select';

@Component({
    selector: 'app-duplicate-product-external',
    templateUrl: './duplicate-product-external.component.html',
    styleUrls: ['./duplicate-product-external.component.scss'],
    standalone: false
})
export class DuplicateProductExternalComponent implements OnInit {
  @Input('product') product: Product;
  public retailer: Retailer;
  public retailers: Array<Retailer>;
  constructor(
    private rest: RestService,
    private router: Router,
    public prService: ProductRequestService,
    private productService: ProductService,
    private gql: GraphqlService,
    private utils: UtilsService,
    private auth: AuthService
  ) { }

  ngOnInit(): void {
    this.retailers = [];
    this.auth.user.retailers_users.forEach(ru => {
      ru.retailers.forEach(r => {
        if (this.product.retailer_id !== r.id)
          this.retailers.push(r);
      });
    });
  }

  onRetailerChange(retailer: MatSelectChange) {
    if (retailer)
      this.retailer = retailer.value;
  }

  async duplicate() {
    if (!this.retailer) return;
    const query = '?rid=' + this.retailer.id;
    this.rest.productDuplicate('post', { id: this.product.id }, query).subscribe(
      (p: Product) => {
        this.gql.product(p.id).subscribe(
          (res: ApolloQueryResult<SingleProductQueryData>) => {
            this.prService.mapFromProduct(this.productService.getProductsFromProductQuery([res.data.products])[0])
            this.productService.editItem = this.prService.request;
            this.router.navigate(['/product', p.id]);
          },
          err => this.utils.httpErrorResponseHandler(err, 'failure fetching duplicating product')
        );
      },
      err => this.utils.httpErrorResponseHandler(err, 'failure duplicating product')
    );
  }
}