import { Component, Input, OnChanges } from '@angular/core';
import { RestService } from 'src/app/communication/rest.service';
import { BroadcasterService } from 'ng-broadcaster';
import { Category, RetailerFilterOptions } from '../retailer';
import { GraphqlService } from 'src/app/communication/graphql.service';
import { UtilsService } from 'src/app/shared/utils.service';
import { BroadcasterNotification, BroadcasterNotificationType } from 'src/app/communication/broadcaster-notifications';
import { ProductCategoriesService } from 'src/app/product-categories/product-categories.service';
import { ProductCategory } from 'src/app/product-categories/product-categories';
import { ProductProcessTypes } from 'src/app/product/product';

@Component({
    selector: 'app-retailer-categories',
    templateUrl: './retailer-categories.component.html',
    styleUrls: ['./retailer-categories.component.scss'],
    standalone: false
})
export class RetailerCategoriesComponent implements OnChanges {
  @Input('retailer-id') retailerId: number;
  @Input('config') categories: Array<Category>;
  public catToAdd: Category;
  public pCategories: Array<ProductCategory>;
  public activePanel: { [id: number]: { [id: number]: boolean } };
  public anglesP = 1;
  public categoriesP = 2;
  public processTypes = ProductProcessTypes;
  constructor(
    private rest: RestService,
    private broadcaster: BroadcasterService,
    private gql: GraphqlService,
    private utils: UtilsService,
    private productCategoriesService: ProductCategoriesService
  ) {
    this.catToAdd = {} as Category;
    this.productCategoriesService.getCategories().subscribe(
      (pCategories: Array<ProductCategory>) => this.pCategories = pCategories
    )
  }
  
  sort() {
    this.categories.sort((a: Category, b: Category) => b.id - a.id);
  }

  async ngOnChanges() {
    this.categories = this.utils.deepCopyByValue(this.categories);
    this.sort();
    this.catToAdd.retailer_id = this.retailerId;
    this.activePanel = {};
    for (let i = 0; i < this.categories.length; i++) {
      await this.utils.setTimeout();
      const c = this.categories[i];
      this.activePanel[c.id] = {};
      this.activePanel[c.id][this.anglesP] = false;
      this.activePanel[c.id][this.categoriesP] = false;
    }
  }

  save(c: Category) {
    const query = '?cid=' + c.id + '&rid=' + this.retailerId;
    this.rest.retailerCategories('put', c, query).subscribe(
      () => {
        let data: BroadcasterNotification = {
          text: 'categories successfully saved',
          type: BroadcasterNotificationType.Success,
          action: 'OK'
        };
        this.broadcaster.broadcast('notifyUser', data);
        this.refresh();
      },
      err => this.utils.httpErrorResponseHandler(err, 'failure saving categories')
    );
  }

  deleteCategory(c: Category) {
    if (!confirm('Are you sure you want to DELETE this category forever?')) return;
    const query = '/' + c.id + '?rid=' + this.retailerId;
    this.rest.retailerCategories('delete', null, query).subscribe(
      () => {
        let data: BroadcasterNotification = {
          text: 'category successfully deleted',
          type: BroadcasterNotificationType.Success,
          action: 'OK'
        };
        this.broadcaster.broadcast('notifyUser', data);
        this.refresh();
      },
      err => this.utils.httpErrorResponseHandler(err, 'failure deleting category')
    );
  }

  addNew() {
    if (!this.catToAdd.category_name_org_lang) return;
    const query = '?rid=' + this.retailerId;
    this.rest.retailerCategories('post', this.catToAdd, query).subscribe(
      () => {
        let data: BroadcasterNotification = {
          text: 'category successfully added',
          type: BroadcasterNotificationType.Success,
          action: 'OK'
        };
        this.broadcaster.broadcast('notifyUser', data);
        this.catToAdd.category_name_org_lang = '';
        this.refresh();
      },
      err => this.utils.httpErrorResponseHandler(err, 'failure adding category')
    );
  }

  refresh() {
    const options = {
      id: this.retailerId
    } as RetailerFilterOptions;
    this.gql.retailerForSettings(options).subscribe(
      res => {
        this.categories = this.utils.deepCopyByValue(res.data.retailers.retailers_categories);
        this.sort();
      },
      err => this.utils.httpErrorResponseHandler(err, 'failure refreshing categories')
    )
  }

  onOpen(cid: number, active: number) {
    this.activePanel[cid][active] = true;
  }

  onTextChange(c: Category, text: string) {
    c.text = text;
  }
}
