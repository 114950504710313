import { Component } from '@angular/core';

@Component({
    selector: 'app-or-separator',
    templateUrl: './or-separator.component.html',
    styleUrl: './or-separator.component.scss',
    standalone: false
})
export class OrSeparatorComponent {

}
