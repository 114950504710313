import * as i0 from '@angular/core';
import { Injectable } from '@angular/core';
class RolesManagerService {
  config;
  setConfig(config) {
    this.config = config;
  }
  getConfig() {
    return Object.assign({}, this.config);
  }
  // is user in role
  isUserInRole(userRoles, roleName) {
    if (!userRoles) return false;
    let role = userRoles.find(x => x.name == roleName);
    return role ? true : false;
  }
  addRoleToConfig(roleName) {
    this.config.roles.push({
      name: roleName
    });
  }
  addPermissionToConfig(permissionName) {
    this.config.permissions.push({
      description: permissionName
    });
  }
  getRoleById(roleId) {
    return this.config.roles.find(x => x.id == roleId);
  }
  getRoleByName(roleName) {
    return this.config.roles.find(x => x.name == roleName);
  }
  getPermissionByName(permissionDescription) {
    return this.config.permissions.find(x => x.description == permissionDescription);
  }
  doesRolesHasPermission(roles, permissionName) {
    if (!this.config) return false;
    for (let role of roles) {
      if (this.config.roles_permissions.find(x => (x.role_name ? x.role_name.toLowerCase() : x.role_name) == role.name.toLowerCase() && x.permission_description.toLowerCase() == (permissionName ? permissionName.toLowerCase() : permissionName))) return true;
    }
    return false;
  }
  isRoleInRoles(roles, roleName) {
    if (!this.config) return false;
    for (let role of roles) {
      if (roles.find(r => (r.name ? r.name.toLowerCase() : r.name) == roleName.toLowerCase())) return true;
    }
    return false;
  }
  getRolePermission(roleName, permissionName) {
    let rp = this.config.roles_permissions.find(x => x.role_name == roleName && x.permission_description == permissionName);
    return rp;
  }
  addRolePermission(roleName, permissionName) {
    let exist = this.getRolePermission(roleName, permissionName);
    if (exist) {
      return true;
    }
    let rp = {
      role_name: roleName,
      permission_description: permissionName,
      role_id: this.getRoleByName(roleName).id,
      permission_id: this.getPermissionByName(permissionName).id
    };
    this.config.roles_permissions.push(rp);
    return false;
  }
  deleteRolePermission(roleName, permissionName) {
    for (let i = 0; i < this.config.roles_permissions.length; i++) {
      if (this.config.roles_permissions[i].role_name == roleName && this.config.roles_permissions[i].permission_description == permissionName) {
        this.config.roles_permissions.splice(i, 1);
      }
    }
  }
  static ɵfac = function RolesManagerService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || RolesManagerService)();
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: RolesManagerService,
    factory: RolesManagerService.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(RolesManagerService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();

/*
 * Public API Surface of ng-role-based-access-control
 */

/**
 * Generated bundle index. Do not edit.
 */

export { RolesManagerService };
