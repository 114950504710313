import * as i0 from '@angular/core';
import { Injectable } from '@angular/core';
class StorageService {
  has(key) {
    return window.localStorage.hasOwnProperty(key);
  }
  set(key, value) {
    window.localStorage.setItem(key, JSON.stringify(value));
  }
  get(key) {
    let item = window.localStorage.getItem(key);
    try {
      return JSON.parse(item);
    } catch (e) {
      return item;
    }
  }
  clear() {
    window.localStorage.clear();
  }
  remove(key) {
    window.localStorage.removeItem(key);
  }
  isSupported() {
    let test = '_test';
    try {
      localStorage.setItem(test, test);
      localStorage.removeItem(test);
      return true;
    } catch (e) {
      return false;
    }
  }
  static ɵfac = function StorageService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || StorageService)();
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: StorageService,
    factory: StorageService.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(StorageService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();

/*
 * Public API Surface of ng-storage-service
 */

/**
 * Generated bundle index. Do not edit.
 */

export { StorageService };
