import { Component, OnInit, ChangeDetectorRef, Input, EventEmitter, Output, OnChanges, SimpleChanges } from '@angular/core';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlattener, MatTreeFlatDataSource } from '@angular/material/tree';
import { CollectionViewer, SelectionChange, SelectionModel } from '@angular/cdk/collections';
// import { BehaviorSubject } from 'rxjs';
import { CategoryNode, SelectedCategories, Category, SubCategory } from 'src/app/retailer/retailer';

@Component({
    selector: 'app-retailer-categories-tree',
    templateUrl: './retailer-categories-tree.component.html',
    styleUrls: ['./retailer-categories-tree.component.scss'],
    standalone: false
})
export class RetailerCategoriesTreeComponent implements OnInit, OnChanges {
  public levels: Map<CategoryNode, number>;
  public treeFlattener: MatTreeFlattener<CategoryNode, CategoryNode>;
  public treeControl: FlatTreeControl<CategoryNode>;
  public dataSource: MatTreeFlatDataSource<CategoryNode, CategoryNode>;
  @Output('on-change') onChange: EventEmitter<SelectedCategories>
  @Input() categories: Array<Category>;
  @Input('sub-categories') subCategories: Array<SubCategory>;
  @Input() restore: number;
  @Input('selected-categories') selectedCategories: Array<number>;
  @Input('selected-sub-categories') selectedSubCategories: Array<number>;
  constructor(
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.onChange = new EventEmitter<SelectedCategories>();
  }

  ngOnInit() {
    if (this.categories) {
      this.initCategories();
    }
  }

  initCategories() {
    this.selectedCategories = this.selectedCategories || [];
    this.selectedSubCategories = this.selectedSubCategories || [];
    this.levels = new Map<CategoryNode, number>();
    this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel,
      this.isExpandable, this.getChildren);
    this.treeControl = new FlatTreeControl<CategoryNode>(
      this.getLevel, this.isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
    this.dataSource.data = this.getNodes(this.categories);
    // this.changeDetectorRef.markForCheck();
  }

  ngOnChanges(changes: SimpleChanges) {
    for (let propName in changes) {
      if (propName == 'restore' && !changes[propName].firstChange) {
        this.clear();
      }
      else if (propName == 'categories' || propName == 'sub-categories') {
        this.initCategories();
      }
    }
  }

  clear() {
    this.selectedCategories = [];
    this.selectedSubCategories = [];
    this.dataSource.data = this.getNodes(this.categories);
    this.changeDetectorRef.markForCheck();
    this.onChange.next(this.getSelected(this.dataSource.data));
  }

  getChildrenByCategoryId(categories: Array<Category>, id: number): Array<CategoryNode> {
    let res = [];
    this.subCategories.forEach(sc => {
      if (sc.category_id == id) {
        let node = new CategoryNode(sc);
        if (this.selectedSubCategories.find(i => i == sc.id))
          this.checklistSelection.select(node);
        res.push(node);
      }

    });
    return res;
  }

  getNodes(categories: Array<Category>): Array<CategoryNode> {
    let data = [];
    categories.forEach(c => {

      let children = this.getChildrenByCategoryId(categories, c.id);

      let node = new CategoryNode(c, children);
      if (this.selectedCategories.find(i => i == c.id))
        // this.checklistSelection.select(node);
        node.checked = true;

      if (children.length) {
        let counter = 0;
        children.forEach(child => {
          if (this.selectedSubCategories.find(i => i == child.item.id)){
            child.checked = true;
            counter++;
          }
        });
        if(counter == children.length){
          node.checked = true;
        } else if( counter && counter < children.length){
          node.checked = true;
          node.indeterminate = true;
        }
      }
      data.push(node);
    });
    return data;
  }

  getLevel = (node: CategoryNode): number => {
    return this.levels.get(node) || 0;
  };

  isExpandable = (node: CategoryNode): boolean => {
    return node.children && node.children.value && node.children.value.length > 0;
  };

  getChildren = (node: CategoryNode) => {
    return node.children;
  };

  transformer = (node: CategoryNode, level: number) => {
    this.levels.set(node, level);
    return node;
  }

  hasChildren = (index: number, node: CategoryNode) => {
    return this.isExpandable(node);
  }

  checklistSelection = new SelectionModel<CategoryNode>(true /* multiple */);

  /** Toggle the game selection. Select/deselect all the descendants node */
  nodeSelectionToggle(node: CategoryNode ): void {


    if (node.indeterminate)
      node.checked = false;
    else
     node.checked = !node.checked;
    // this.checklistSelection.toggle(node);
    const descendants = this.treeControl.getDescendants(node);
    if ((!node.checked || node.indeterminate) && descendants.length) {
      if (node.indeterminate) {
        node.indeterminate = false;
        //   node.checked = false;
      }
      descendants.forEach(child => child.checked = false);

    } else if (node.checked && descendants.length) {
      descendants.forEach(child => child.checked = true);
    }

    var parent = this.getParentNode(node);
    if (parent) {
      const parentDescendants = this.treeControl.getDescendants(parent);
      if (parentDescendants.every(n => n.checked)) {
        parent.checked = true;
        parent.indeterminate = false;
      } else if (parentDescendants.every(n => !n.checked)) {
        parent.checked = false;
        parent.indeterminate = false;
      } else {
          parent.checked = true;
        parent.indeterminate = true;
      }
    }
 

    this.changeDetectorRef.markForCheck();
    this.onChange.next(this.getSelected(this.dataSource.data));


  }



  getParentNode(node: CategoryNode): CategoryNode {
    return this.dataSource.data.find(n => this.treeControl.getDescendants(n).some(c => c.item.id === node.item.id));
  }
 

  private getSelected(tree: Array<CategoryNode>): SelectedCategories {
    let res = new SelectedCategories();

    if (tree.length) {
      tree.forEach(node => {
        const descendants = this.treeControl.getDescendants(node);
        // let hasSelectedChildren = false;
        if (descendants.length) {
          descendants.forEach(child => {
            // if (this.checklistSelection.isSelected(child)) {
            if (child.checked) {
              // hasSelectedChildren = true;
              res.subCategories.push(child.item);
            }
          });
        }
        // if (hasSelectedChildren || this.checklistSelection.isSelected(node)) {
        if (!descendants.some(ch => ch.checked) && node.checked) {
          res.categories.push(node.item);
        }
      });
    }
    return res;
  }
}
