import { AfterContentInit, Component, ElementRef, EventEmitter, Injector, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { AssetCommunication, DimensionsOptions, PostMessage } from 'asset-adjustments';
import { MessagesHandlerService } from 'messages-handler';
import { Subscription } from 'rxjs';
import { ProductsResourcesAlignmentConfig } from 'src/app/product/product';
import { KeyValuePair } from '../enums';
import { UtilsService } from '../utils.service';

@Component({
    selector: 'app-asset-communication',
    templateUrl: './asset-communication.component.html',
    styleUrls: ['./asset-communication.component.scss'],
    standalone: false
})
export class AssetCommunicationComponent implements OnChanges, OnDestroy, AfterContentInit {
  public assetCommunication: AssetCommunication;
  private subs: Array<Subscription>;
  private frameID: string;
  private viewerFullyLoaded: boolean;
  @ViewChild('iframe', { static: true }) iframeModel: ElementRef;
  @Input('model-url') modelUrl: string;
  @Input('wireframe') wireframe: boolean;
  @Input('shaded-wireframe') shadedWireframe: boolean;
  @Input('emissive-intensity') emissiveIntensity: number;
  @Input('alignment') alignment: ProductsResourcesAlignmentConfig;
  @Input('post-message') postMessage: PostMessage;
  @Input('params-to-remove') paramsToRemove: Array<string>;
  @Input('params-to-add') paramsToAdd: Array<KeyValuePair>;
  @Input('broadcast-materials') broadcastMaterials: number;
  @Input('dimensions') dimensions: DimensionsOptions;
  @Output('on-viewer-message') onViewerMessage: EventEmitter<PostMessage>;
  constructor(
    private injector: Injector,
    private utils: UtilsService,
    private mhService: MessagesHandlerService
  ) {
    this.subs = [];
    this.onViewerMessage = new EventEmitter<PostMessage>();
  }

  ngAfterContentInit() {
    this.fixModelUrl();
    this.assetCommunication = new AssetCommunication({ iframeModel: this.iframeModel, modelUrl: this.modelUrl }, this.injector);
    this.subs.push(this.assetCommunication.eventEmitter.subscribe(this.onMessage.bind(this)));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.assetCommunication) {
      if (changes.modelUrl && changes.modelUrl.currentValue !== changes.modelUrl.previousValue) {
        this.fixModelUrl();
        this.assetCommunication.changeUrl(this.modelUrl);
      }
      if (changes.wireframe && changes.wireframe.currentValue !== changes.wireframe.previousValue) {
        this.assetCommunication.toggleWireframe(this.wireframe);
      }
      if (changes.shadedWireframe && changes.shadedWireframe.currentValue !== changes.shadedWireframe.previousValue) {
        this.assetCommunication.toggleShadedWireframe(this.shadedWireframe, '#ff0000');
      }
      if (changes.emissiveIntensity && changes.emissiveIntensity.currentValue !== changes.emissiveIntensity.previousValue && !changes.emissiveIntensity.firstChange) {
        this.setEmissiveIntensity(this.emissiveIntensity);
      }
      if (changes.alignment && changes.alignment.currentValue !== changes.alignment.previousValue) {
        this.setAlignment(this.alignment);
      }
      if (changes.dimensions && changes.dimensions.currentValue !== changes.dimensions.previousValue) {
        this.toggleDimensions(!!this.dimensions, this.dimensions);
      }
      if (changes.postMessage && !changes.postMessage.isFirstChange() && changes.postMessage.currentValue !== changes.postMessage.previousValue) {
        this.assetCommunication.postAnyToChild(this.postMessage);
      }
      if (this.viewerFullyLoaded) {
        if (changes.broadcastMaterials && changes.broadcastMaterials.currentValue !== changes.broadcastMaterials.previousValue) {
          this.assetCommunication.postAnyToChild({
            action: 'broadcastMaterials'
          });
        }
      }
    }
  }

  setAlignment(position: ProductsResourcesAlignmentConfig) {
    if (position) {
      // Sometimes we are using alignments from the OBJ which might have flawed dimensions.
      // We will leave the model in the center of the screen in terms of the position, we'll apply rotation, zoom and FOV.
      delete position.targetX;
      delete position.targetY;
      delete position.targetZ;
      let obj = {
        action: 'setControlsPosition',
        value: position
      } as any;
      this.assetCommunication.postAnyToChild(obj);
    }
  }

  setEmissiveIntensity(intensity: number) {
    let obj = {
      action: 'setEmissiveIntensity',
      value: intensity
    } as any;
    this.assetCommunication.postAnyToChild(obj);
  }

  fixModelUrl() {
    if (this.modelUrl) {
      if (this.paramsToRemove) {
        this.paramsToRemove.forEach(p => this.modelUrl = this.utils.setUrlParam(this.modelUrl, p, null));
      }
      if (this.paramsToAdd) {
        this.paramsToAdd.forEach(p => this.modelUrl = this.utils.setUrlParam(this.modelUrl, p.key, p.value));
      }
    }
    this.frameID = this.utils.getUrlParam(this.modelUrl, 'frame-id');
  }

  onMessage(obj: PostMessage) {
    if (obj.id && this.frameID && obj.id !== this.frameID)
      return;
    if (obj.action)
      this.onViewerMessage.next(obj);
    switch (obj.action) {
      case 'viewerFullyLoaded': {
        this.viewerFullyLoaded = true;
        if (this.shadedWireframe) {
          this.assetCommunication.toggleShadedWireframe(true, '#ff0000');
        }
        if (this.wireframe) {
          this.assetCommunication.toggleWireframe(true);
        }
        if (typeof this.emissiveIntensity === 'number')
          this.setEmissiveIntensity(this.emissiveIntensity);
        if (this.broadcastMaterials)
          this.assetCommunication.postAnyToChild({
            action: 'broadcastMaterials'
          });
        // this.assetCommunication.postAnyToChild({
        //   action: 'broadcastTextures'
        // });
        if (!this.hasLoadAnimation()) {
          setTimeout(() => {
            if (this.alignment)
              this.setAlignment(this.alignment);
          }, 1000);
        }
        if (this.dimensions) {
          this.toggleDimensions(true, this.dimensions);
        }
        break;
      }
      case 'onAnimateEnterEnd': {
        setTimeout(() => {
          if (this.alignment)
            this.setAlignment(this.alignment);
        }, 1000);
        break;
      }
      case 'setMaterials': {
        let hasEmissive = false;
        obj.data.materials.forEach(m => {
          if (m.emissiveMap)
            hasEmissive = true;
        });
        if (hasEmissive) {
          let em = {
            action: 'hasEmissive',
            data: true
          } as PostMessage;
          this.onViewerMessage.next(em);
        }
        break;
      }
    }
  }

  hasLoadAnimation() {
    return (!!!this.utils.getUrlParam(this.modelUrl, 'pv') || this.utils.getUrlParam(this.modelUrl, 'animate-enter') === 'false' || this.utils.getUrlParam(this.modelUrl, 'animate-enter') === '0');
  }

  // TODO use assetCommunication.toggleDimensions instead after updating to Angular 14 or above
  toggleDimensions(state: boolean, options: DimensionsOptions) {
    const value = {} as any;
    if (state && options) {
      if (typeof options.x === 'string')
        value.x = options.x;
      if (typeof options.y === 'string')
        value.y = options.y;
      if (typeof options.z === 'string')
        value.z = options.z;
    }
    if (!options)
      options = {};
    this.mhService.postToChild({ value , options: { dimensionsUnits: options.dimensionsUnits, color: options.color, active: state }, action: 'toggleDimensionsAnnotations' }, this.iframeModel.nativeElement);
    // this.postAnyToChild({ value , options: { dimensionsUnits: options.dimensionsUnits, color: options.color, active: state }, action: 'toggleDimensionsAnnotations' });
}

  ngOnDestroy() {
    if (this.assetCommunication)
      this.assetCommunication.destroy();
    this.subs.forEach(f => f.unsubscribe());
  }
}
