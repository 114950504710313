import { Component, OnInit } from '@angular/core';
import { Retailer } from '../../retailer/retailer';
import { RestService } from '../../communication/rest.service';
import { BroadcasterNotification, BroadcasterNotificationType } from '../../communication/broadcaster-notifications';
import { BroadcasterService } from 'ng-broadcaster';
import { GlobalsService } from '../../shared/globals.service';
import { UtilsService } from 'src/app/shared/utils.service';
import { ProductTag } from 'src/app/product/product';
import { MatChipInputEvent } from '@angular/material/chips';
import { ResumableUploadService } from 'src/app/shared/resumable-upload.service';
import { FileObject } from 'src/app/shared/file';

export enum ScraperSrcTypes {
    URL = 1,
    CSV = 2,
}

@Component({
    selector: 'app-mulitiple-scraper',
    templateUrl: './mulitiple-scraper.component.html',
    styleUrls: ['./mulitiple-scraper.component.scss'],
    standalone: false
})
export class MulitipleScraperComponent implements OnInit {
  public retailer: Retailer;
  public urls: Array<string>;
  public dueDate: Date;
  public urlsStr: string;
  public minDueDate: Date;
  public forceMeasurements: boolean;
  public visible: boolean;
  public useApi: boolean;
  public batchId: string;
  public products_tags: Array<ProductTag>;
  public batches = [];
  public selectedbatches = [];
  public scraperSrc = ScraperSrcTypes.URL;
  public csvFileName: string;
  public csvFileUrl: string;
  public scraperSrcTypes = ScraperSrcTypes;

  constructor(
    private rest: RestService,
    private broadcaster: BroadcasterService,
    private globals: GlobalsService,
    private utils: UtilsService,
    private resumableUpload: ResumableUploadService,
  ) {
    this.minDueDate = new Date();
    this.minDueDate.setMonth(this.minDueDate.getMonth() + 1);
    this.dueDate = null;
    this.useApi = false;
  }

  ngOnInit() {
    setTimeout(() => {
      this.globals.setHeaer('Scraper', '');
      this.globals.setBreadcrumbs();
      this.globals.playgroundClass = null;
    }, 0);
    this.products_tags = [];
  }

  onRetailerChange(retailer: Retailer) {
    if (retailer) {
      this.selectedbatches = [];
      this.retailer = retailer;
    }
  }


  setBatches(batches) {
    this.selectedbatches = batches;
  }


  scrape() {
    let query = '?scrape_only=false&rid=' + this.retailer.id;
    let batchesreq = [];
    let today = new Date();

    if (this.selectedbatches.length === 0) {
      let data: BroadcasterNotification = {
        text: 'Please select at least one batch.',
        type: BroadcasterNotificationType.Error,
        action: 'OK'
      }
      this.broadcaster.broadcast('notifyUser', data);
      return;
    }

    for (let i = 0; i < this.selectedbatches.length; i++) {
      const b = this.selectedbatches[i];
      if (new Date(b.end_at).getTime() < today.getTime()) {
        if (!confirm("Please note the batch end date has passed, are you sure that’s the batch you want to select?")) {
          return;
        }
      }
      batchesreq.push({ "batch_id": b.batch_id })
    }

    let obj = {
      urls: this.scraperSrc == this.scraperSrcTypes.URL ? this.urls : [],
      due_date: this.dueDate?this.dueDate.getTime():null,
      force_measurements: this.forceMeasurements,
      visible: this.visible ? 1 : 0,
      use_api: this.useApi,
      products_tags: this.products_tags,
      retailers_batches: batchesreq,
      csv_completion_file_url:  this.scraperSrc == this.scraperSrcTypes.CSV ? this.csvFileUrl : null
    } as any;

    if (this.scraperSrc == this.scraperSrcTypes.CSV) delete obj.urls;
    if (this.scraperSrc == this.scraperSrcTypes.URL) delete obj.csv_completion_file_url;
    this.rest.productScrape('post', obj, query).subscribe(
      () => {
        let data: BroadcasterNotification = {
          text: 'scraper initialized',
          type: BroadcasterNotificationType.Success,
          action: 'OK'
        }
        this.broadcaster.broadcast('notifyUser', data);
      },
      err => this.utils.httpErrorResponseHandler(err, 'scraper failure')
    );
  }

  removeTag(t: ProductTag, index: number) {
    // const index = this.products_tags.findIndex(t => t.tag == t.tag);
    if (index > -1)
      this.products_tags.splice(index, 1);
  }

  addTag(event: MatChipInputEvent): void {
    const input = event.input;
    const value = (event.value || '').trim();

    if (value) {
      if (!this.products_tags.find(t => t.tag == value)) {
        this.products_tags.push({
          tag: value
        });
      }
      else {
        let data: BroadcasterNotification = {
          text: 'tag already exists',
          type: BroadcasterNotificationType.Info,
          action: 'OK'
        }
        this.broadcaster.broadcast('notifyUser', data);
      }
    }

    // // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  parseUrls() {
    if (this.urlsStr) {
      this.urls = this.urlsStr.split('\n');
      // for (let i = 0; i < this.urls.length; i++) {
      //   if (!this.utils.isValidHttpUrl(this.urls[i]))
      //     this.urls.splice(i--, 1);
      // }
    }
    else {
        this.urls = null;
    }
  }

  isValid(): boolean {
    if (this.scraperSrc == this.scraperSrcTypes.CSV && !this.csvFileUrl && !this.batchId) return false;
    if (this.scraperSrc == this.scraperSrcTypes.URL && (!this.urls || this.urls.length < 1) && !this.batchId) return false;
    if (!this.retailer && !this.batchId) return false;
    return true;
  }
  clearDate(){
    this.dueDate = null;
  }

  async onFilesChange(file: Array<FileObject>) {

    const selectedFile = file[0];

    if (selectedFile.suffix !== 'csv') {
        let data: BroadcasterNotification = {
            text: `This file type not supported`,
            type: BroadcasterNotificationType.Error,
            action: 'OK',
            duration: 10000
        }
        return this.broadcaster.broadcast('notifyUser', data);
    }

    let fileURL = await this.resumableUpload.file(selectedFile.file);

    if (!fileURL) {
        let data: BroadcasterNotification = {
            text: `${selectedFile.file.name} upload failed`,
            type: BroadcasterNotificationType.Error,
            action: 'OK',
            duration: 10000
        }
        return this.broadcaster.broadcast('notifyUser', data);
    }

    this.csvFileName = selectedFile.file.name;
    this.csvFileUrl = fileURL;
  }

  removeCSVFile() {
    this.csvFileName = this.csvFileUrl = null;
  }


}
