import { Component, Input } from '@angular/core';
import { BroadcasterService } from 'ng-broadcaster';
import { BroadcasterNotification, BroadcasterNotificationType } from 'src/app/communication/broadcaster-notifications';
import { RestService } from 'src/app/communication/rest.service';
import { KeyValueAnyPair } from 'src/app/shared/enums';
import { UtilsService } from 'src/app/shared/utils.service';
import { RetailersViewerParam } from '../retailer';

@Component({
    selector: 'app-viewer-params',
    templateUrl: './viewer-params.component.html',
    styleUrls: ['./viewer-params.component.scss'],
    standalone: false
})
export class ViewerParamsComponent {
  @Input('config') config: Array<RetailersViewerParam>;
  @Input('retailer-id') retailerId: number;
  public keyVal: KeyValueAnyPair;
  public del: string;
  constructor(
    private rest: RestService,
    private utils: UtilsService,
    private broadcaster: BroadcasterService
  ) {
    this.keyVal = {
      key: null,
      value: null
    }
  }

  save() {
    if (!this.keyVal.key && !this.del) {
      alert('Please specify the name of the parameter first');
      return;
    }
    let payload = {
      viewer_params: {},
      remove_viewer_params: []
    }
    if (this.keyVal.key)
      payload.viewer_params[this.keyVal.key] = this.keyVal.value ? this.keyVal.value : null;
    if (this.del)
      payload.remove_viewer_params.push(this.del);
    this.rest.viewerParams('put', payload, `?rid=${this.retailerId}`).subscribe(
      res => {
        let data: BroadcasterNotification = {
          text: 'Viewer parameters was successfully updated',
          type: BroadcasterNotificationType.Success,
          action: 'OK'
        }
        this.broadcaster.broadcast('notifyUser', data);
        payload.remove_viewer_params.forEach(key => {
          this.removeFromConfig(key);
        });
        if (Object.keys(payload.viewer_params).length)
          this.config.push({
            viewer_param_key: Object.keys(payload.viewer_params)[0],
            viewer_param_value: payload.viewer_params[Object.keys(payload.viewer_params)[0]]
          });
        //   this.removeFromConfig(Object.keys(payload.viewer_params)[0]);
      },
      err => this.utils.httpErrorResponseHandler(err, 'failure updating parameters')
    );
  }

  remove(key: string) {
    if (!confirm(`Are you sure you want to delete ${key} from all the resources URL's?`)) return;
    let payload = {
      remove_viewer_params: [key]
    }
    this.rest.viewerParams('put', payload, `?rid=${this.retailerId}`).subscribe(
      res => {
        let data: BroadcasterNotification = {
          text: 'Viewer parameters was successfully updated',
          type: BroadcasterNotificationType.Success,
          action: 'OK'
        }
        this.broadcaster.broadcast('notifyUser', data);
        this.removeFromConfig(key);
      },
      err => this.utils.httpErrorResponseHandler(err, 'failure updating parameters')
    )
  }

  removeFromConfig(key: string) {
    for (let i = 0; i < this.config.length; i++) {
      if (key == this.config[i].viewer_param_key) {
        this.config.splice(i, 1);
        break;
      }
    }
  }
}
