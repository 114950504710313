import { Directive, ElementRef } from '@angular/core';
import { UtilsService } from './utils.service';

@Directive({
    selector: '[appClipboard]',
    standalone: false
})
export class ClipboardDirective {

  constructor(
    private elr: ElementRef,
    private utils: UtilsService) {
    this.elr.nativeElement.addEventListener('click', () => {
      this.utils.copyClipboard(this.elr.nativeElement.textContent);
    }, false);
  }

}
