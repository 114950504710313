import { Component, OnInit, Input, Output, ViewChild, ElementRef, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { UtilsService } from '../utils.service';

@Component({
    selector: 'app-simple-carousel-slider',
    templateUrl: './simple-carousel-slider.component.html',
    styleUrls: ['./simple-carousel-slider.component.scss'],
    standalone: false
})
export class SimpleCarouselSliderComponent implements OnInit {
  @Input() images: Array<string>;
  @Input() width: number;
  @Input() height: number;
  @Input('current-index') currentIndex: number;
  @Output('index-change') indexChange: EventEmitter<number>;
  @ViewChild('scroller') scroller: ElementRef;
  
  public total: number;
  public isRightNavDisabled: boolean;
  public translateX: number;
  public imm: boolean;
  constructor(
    private sanitizer: DomSanitizer,
    private utils: UtilsService,
    private cdr: ChangeDetectorRef
  ) {
    this.translateX = 0;
    this.indexChange = new EventEmitter<number>();
    this.isRightNavDisabled = false;
  }

  ngOnInit() {
    this.init();
  }

  init() {
    this.currentIndex = typeof this.currentIndex !== 'number' ? 0 : this.currentIndex;
    if (this.images)
      this.total = this.images.length;
    else
      this.total = 0;
    this.cdr.detectChanges();
    this.setRightNavDisabled();
    this.cdr.detectChanges();
  }

  // ngOnChanges(changes: SimpleChanges) {
  //   this.init();
  // }

  getBackground(image: string, isMain?: boolean) {
    return this.sanitizer.bypassSecurityTrustStyle(`url(${image})`);
  }

  navLeft() {
    let fdParent = this.utils.getFormattedDim(getComputedStyle(this.scroller.nativeElement.parentElement).width);
    this.translateX += fdParent.size;
    if (this.translateX > 0)
      this.translateX = 0;
    // this.scroller.nativeElement.style.transform = 'translateX(' + this.translateX + 'px)';
    this.setRightNavDisabled();
  }

  navRight() {
    let fdParent = this.utils.getFormattedDim(getComputedStyle(this.scroller.nativeElement.parentElement).width);
    let fd = this.utils.getFormattedDim(getComputedStyle(this.scroller.nativeElement).width);
    this.translateX -= fdParent.size;
    if (-this.translateX > (fd.size - fdParent.size))
      this.translateX = -(fd.size - fdParent.size);
    // this.scroller.nativeElement.style.transform = 'translateX(' + this.translateX + 'px)';
    this.setRightNavDisabled();
  }

  setIndex(index: number): void {
    if (this.currentIndex == index) return;
    this.currentIndex = index;
    this.indexChange.emit(this.currentIndex);
    this.setRightNavDisabled();
  }

  setRightNavDisabled(): boolean {
    if (!this.scroller) {
      this.isRightNavDisabled = true;
      return;
    }
    let fdParent = this.utils.getFormattedDim(getComputedStyle(this.scroller.nativeElement.parentElement).width);
    let fd = this.utils.getFormattedDim(getComputedStyle(this.scroller.nativeElement).width);
    if (fd && -this.translateX >= (fd.size - fdParent.size))
      this.isRightNavDisabled = true;
    else
      this.isRightNavDisabled = false;
  }
}
