import { Component } from "@angular/core";
import { User, UsersFilterOptions } from "../user";
import { GraphqlService } from "../../communication/graphql.service";
import { AuthService } from "../auth.service";
import { RestService } from "../../communication/rest.service";
import { BroadcasterService } from "ng-broadcaster";
import { BroadcasterNotification, BroadcasterNotificationType } from "../../communication/broadcaster-notifications";
import { MatDialogRef } from "@angular/material/dialog";
import { UtilsService } from "src/app/shared/utils.service";

@Component({
    selector: 'app-users-list-dialog',
    templateUrl: 'users-list-dialog.html',
    styleUrls: ['users-list-dialog.scss'],
    standalone: false
})
export class UsersListDialog {
    public users: Array<User>;
    public filter: string;
    constructor(
        public dialogRef: MatDialogRef<UsersListDialog>,
        private gql: GraphqlService,
        private auth: AuthService,
        private rest: RestService,
        private broadcaster: BroadcasterService,
        private utils: UtilsService
    ) {
        this.getUsers();
    }

    getUsers() {
        let options = {
            retailer_id: [this.auth.user.retailers_users[this.auth.retailerIndex].retailer_id]
        } as UsersFilterOptions;
        this.gql.users(options).subscribe(
            res => this.users = res.data.allUsers.rows
        )
    }

    getAcronyms(user: User): string {
        let res = '';
        if (user.firstname)
            res += user.firstname[0];
        if (user.lastname)
            res += user.lastname[0];
        return res;
    }

    remove(user: User) {
        if (!confirm('Are you sure you want to DELETE ' + user.firstname + ' ' + user.lastname + ' forever?')) return;
        this.rest.userProfile('delete', null, '/' + user.id).subscribe(
            res => {
                this.getUsers();
                let data: BroadcasterNotification = {
                    text: 'user deleted successfully',
                    type: BroadcasterNotificationType.Success,
                    action: 'OK'
                };
                this.broadcaster.broadcast('notifyUser', data);
            },
            err => this.utils.httpErrorResponseHandler(err, 'failure deleting user')
        )
    }

    close() {
        this.dialogRef.close();
    }
}