import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UtilsService } from 'src/app/shared/utils.service';
import { BroadcasterService } from 'ng-broadcaster';
import { BroadcasterNotification, BroadcasterNotificationType } from 'src/app/communication/broadcaster-notifications';
import { RestService } from 'src/app/communication/rest.service';

@Component({
    selector: 'app-qr-view',
    templateUrl: './qr-view.component.html',
    styleUrls: ['./qr-view.component.scss'],
    standalone: false
})
export class QrViewComponent implements OnInit, AfterViewInit {
 public popup;
  constructor(
    private rest: RestService,
    private broadcaster: BroadcasterService,
    private utils: UtilsService,
    public dialogRef: MatDialogRef<QrViewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {

    if(data.num === 1)
    this.popup = {
      title: "Can’t scan the QR code?",
      subtitle: "",
      list: ["Ensure you have an authentication app on your mobile phone, or scan to download one:",
      "Open the app on your phone and scan the code.",
       "Still having trouble? Contact your account manager."],
      text1:"",
      text2:"",
      button: "",
      action: () => this.dialogRef.close()
    }
    else if(data.num === 2)
    this.popup = {
      title: 'Didn’t get a code?',
      subtitle: '',
      list: ['Find the code on your authentication app.', `Didn’t find it? Get a <a class='qr-link color-link link pointer'>recovery link</a> to reset the QR code, or contact your account manager.`],
      text1: '',
      text2: '',
      button: '',
      action:  () => {
        const query = data.sso_token ? `/qr?sso_token=${data.sso_token}` : `/qr?email=${encodeURIComponent(data.email)}&password=${encodeURIComponent(data.password)}`;
        this.rest.userProfile('GET', null, query).subscribe(
          () => {
            const obj: BroadcasterNotification = {
              text: 'Recovery email has been sent to ' + data.email,
              type: BroadcasterNotificationType.Success,
              action: 'OK'
            };
            this.broadcaster.broadcast('notifyUser', obj);
          },
          (err: any) => {
            this.utils.getErrorMessage(err, 'failure to send recovery email');
          });
      }
    };
  }

  ngOnInit() {}

  ngAfterViewInit(){
    var ilink = document.querySelector('.qr-link');
    if(ilink){
      ilink.addEventListener('click',()=>{ this.onClick(); })
    }
  }

  onClick(){
    this.popup.action();
  }

  close(){
    this.dialogRef.close()
  }

}
