import { Component, OnInit, Input } from '@angular/core';
import { RetailersRendersAngles } from '../retailer';
import { RestService } from 'src/app/communication/rest.service';
import { UtilsService } from 'src/app/shared/utils.service';
import { BroadcasterService } from 'ng-broadcaster';
import { BroadcasterNotification, BroadcasterNotificationType } from 'src/app/communication/broadcaster-notifications';
import { FileObject } from 'src/app/shared/file';

@Component({
    selector: 'app-retailers-renders-angles',
    templateUrl: './retailers-renders-angles.component.html',
    styleUrls: ['./retailers-renders-angles.component.scss'],
    standalone: false
})
export class RetailersRendersAnglesComponent implements OnInit {
  @Input() config: Array<RetailersRendersAngles>;
  @Input('retailer-id') retailerId: number;
  @Input('category-id') categoryId: number;
  constructor(
    private rest: RestService,
    private utils: UtilsService,
    private broadcaster: BroadcasterService
  ) {
  }

  ngOnInit() {
    if (!this.config)
      this.config = [];
    else {
      this.config = this.utils.deepCopyByValue(this.config);
    }
    if (!this.config.length)
      this.config[0] = {} as RetailersRendersAngles;
  }

  save(index: number) {
    let query = this.config[index].id ? '/' + this.config[index].id : '';
    query += '?rid=' + this.retailerId;
    if (this.categoryId)
      this.config[index].category_id = this.categoryId;
    this.rest[this.categoryId ? 'retailerCategoriesRenderAngle' : 'retailerRenderAngle'](this.config[index].id ? 'put' : 'post', this.config[index], query).subscribe(
      (res: RetailersRendersAngles) => {
        let data: BroadcasterNotification = {
          text: 'renders settings updated successfully',
          type: BroadcasterNotificationType.Success,
          action: 'OK'
        }
        this.broadcaster.broadcast('notifyUser', data);
        this.config[index] = res;
      },
      err => this.utils.httpErrorResponseHandler(err, 'failure updating renders settings')
    );
  }

  onFilesChange(fileList: Array<FileObject>, index: number) {
    const fd = new FormData();
    fd.append('file', fileList[0].file);
    fd.append('compress', 'true');
    this.rest.afterCdn('post', fd).subscribe(
      data => this.config[index].screenshot = data.url,
      err => this.utils.httpErrorResponseHandler(err, 'failure uploading screenshot')
    );
  }

  deleteImage(index: number) {
    this.config[index].screenshot = null;
  }

  addAngle() {
    this.config.push({
      angle_x: null,
      angle_y: null,
      screenshot: null
    } as RetailersRendersAngles);
  }
}
