import { Pipe, PipeTransform } from '@angular/core';
import { KeyValuePair } from '../shared/enums';
import { AssetAdjustmentsService } from 'asset-adjustments';

@Pipe({
    name: 'noIntensity',
    standalone: false
})
export class NoIntensityPipe implements PipeTransform {

  constructor(private assetAdjustmentsService: AssetAdjustmentsService) {}

  transform(value: Array<KeyValuePair>, materialType: string, args?: any): Array<KeyValuePair> {
    return this.assetAdjustmentsService.noIntensity(value, materialType);
  }

}
