import { Component, forwardRef, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

const noop = () => {
};

@Component({
    selector: 'app-toggle-checkbox',
    templateUrl: './toggle-checkbox.component.html',
    styleUrls: ['./toggle-checkbox.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ToggleCheckboxComponent),
            multi: true
        }
    ],
    standalone: false
})
export class ToggleCheckboxComponent implements ControlValueAccessor, OnInit {
  private innerValue: boolean | number | null = false;
  @Input('disabled') disabled: boolean = false;
  @Input('on-val') onVal:boolean | number | null = true;
  @Input('off-val') offVal:boolean | number | null = false;
  @Input('strong-val-check') strongValCheck:boolean;
  @Output('on-change') onChange: EventEmitter<boolean | number | null>;
  constructor() {
    this.onChange = new EventEmitter<boolean>();
  }

  ngOnInit() {

  }

  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;

  //get accessor
  get value(): any {
    return this.innerValue;
  };

  //set accessor including call the onchange callback
  set value(v: any) {
    if (v !== this.getInnerValue()) {
      if (v)
        this.innerValue = this.onVal;
      else
        this.innerValue = this.offVal;
      this.onChangeCallback(this.innerValue);
      this.onChange.next(this.innerValue);
    }
  }

  getInnerValue() {
    if (this.innerValue == this.onVal)
      return true;
    return false;
  }

  //Set touched on blur
  onBlur() {
    this.onTouchedCallback();
  }

  writeValue(value: boolean): void {
    if (value !== this.innerValue) {
      this.innerValue = value;
    }
  }
  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
