import { ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

const noop = () => {
};

@Component({
    selector: 'app-round-checkbox',
    templateUrl: './round-checkbox.component.html',
    styleUrls: ['./round-checkbox.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => RoundCheckboxComponent),
            multi: true
        }
    ],
    standalone: false
})
export class RoundCheckboxComponent implements ControlValueAccessor {
  //The internal data model
  private innerValue: boolean = false;
  // private isDisabled: boolean = false;
  @Input() isSquare: boolean;
  @Input() disabled: boolean;
  @Input() text: string;
  @Input('disabled-mode') disabledMode: boolean;
  @Output() checked = new EventEmitter<boolean>();

  constructor(private cdr: ChangeDetectorRef) { }

  //Placeholders for the callbacks which are later providesd
  //by the Control Value Accessor
  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;

  //get accessor
  get value(): any {
    return this.innerValue;
  };

  //set accessor including call the onchange callback
  set value(v: any) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.onChangeCallback(v);
      this.checked.emit(this.innerValue);
    }
  }

  btnClick() {
    this.value = !this.value;
  }

  //Set touched on blur
  onBlur() {
    this.onTouchedCallback();
  }

  writeValue(value: boolean): void {
    if (value !== this.innerValue) {
      this.innerValue = value;
      this.cdr.detectChanges();
    }
  }
  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }
  // setDisabledState?(isDisabled: boolean): void {
  //   this.disabled = isDisabled;
  // }

  isRoundVisible(): boolean {
    if (this.disabled)
      return !this.disabledMode;
    return !this.value;
  }

  isIconVisible(): boolean {
    return this.value;
  }
}
