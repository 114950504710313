import { Component, OnInit } from '@angular/core';
import {
    Product,
    ProductFilterOptions,
    ProductQueryData,
} from 'src/app/product/product';
import { UtilsService } from '../utils.service';
import { ProductRequestService } from 'src/app/product/product-request.service';
import { DEFAULT_PREVIEW_DIM, FormatsType } from '../enums';
import { ResourceService } from '../resource.service';
import { GraphqlService } from 'src/app/communication/graphql.service';
import { ApolloQueryResult } from '@apollo/client/core';
import { RestService } from 'src/app/communication/rest.service';

@Component({
    selector: 'app-product-fixer',
    imports: [],
    templateUrl: './product-fixer.component.html',
    styleUrl: './product-fixer.component.scss'
})
export class ProductFixerComponent implements OnInit {
    public fixedResources: number;
    public checkedProducts: number;
    public batch: number;
    public img1: string;
    public img2: string;
    private batchSize: number;
    constructor(
        private utils: UtilsService,
        private prService: ProductRequestService,
        private gql: GraphqlService,
        private rest: RestService
    ) {
        this.fixedResources = 0;
        this.checkedProducts = 0;
        this.batchSize = 20;
        this.batch = 1;
        const b = parseInt(new URLSearchParams(location.search).get('batch'));
        if (b && !isNaN(b)) this.batch = b;
    }

    ngOnInit() {
        this.prService.retailerIndex = 0;
        this.fetch();
    }

    async fetch() {
        const options = {
            limit: this.batchSize,
            offset: this.batch * this.batchSize,
            viewer_resource_type: [FormatsType.GLB, FormatsType.glTF],
        } as ProductFilterOptions;
        let res = (await this.utils.observableToPromise(
            this.gql.fullProducts(options)
        )) as ApolloQueryResult<ProductQueryData>;
        this.fixAllProducts(
            this.utils.deepCopyByValue(res.data.allProducts.rows)
        );
    }

    async fixAllProducts(products: Array<Product>) {
        if (products.length) {
            for (let i = 0; i < products.length; i++) {
                await this.fixProduct(products[i]);
                this.checkedProducts++;
            }
            this.batch++;
            this.fetch();
        } else {
            console.log('Done!');
        }
    }

    async setRetailer(p: Product) {
        this.prService.request = p;
    }

    async fixProduct(p: Product) {
        this.setRetailer(p);
        const rs = new ResourceService(this.prService.utils);
        for (let i = 0; i < p.products_resources.length; i++) {
            if (
                (p.products_resources[i].viewer_resource_type ===
                    FormatsType.glTF ||
                    p.products_resources[i].viewer_resource_type ===
                        FormatsType.GLB) &&
                (await this.utils.isEmptyImage(
                    p.products_resources[i].resource_big
                ))
            ) {
                const settings = this.prService.getRetailerSettings();
                const options = {
                    width: settings.preload.width || DEFAULT_PREVIEW_DIM,
                    height: settings.preload.height || DEFAULT_PREVIEW_DIM,
                    compress: true,
                    create_images_by_tour: 'init_pos',
                };
                const preview = await rs.createPreview(
                    p.products_resources[i].resource_default,
                    options
                );
                if (preview) {
                    const newBig =
                        await this.prService.resumableUpload.getUrlFromBase64(
                            preview
                        );
                    this.img1 = newBig;
                    this.img2 = p.products_resources[i].resource_big;
                    console.log(p.products_resources[i].resource_big, newBig);
                    p.products_resources[i].resource_big = newBig;
                    this.fixedResources++;
                    await this.save(p);
                }
                // p.products_resources[i].resource_big =
                //     await this.prService.resumableUpload.getUrlFromBase64(
                //         await rs.createPreview(
                //             p.products_resources[i].resource_default,
                //             options
                //         )
                //     );
            }
        }
    }

    public save(item: Product) {
        return this.utils.observableToPromise(
            this.rest.product('put', item, `/${item.id}`)
        );
    }
}
