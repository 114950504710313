import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-self-profile',
    templateUrl: './self-profile.component.html',
    styleUrls: ['./self-profile.component.scss'],
    standalone: false
})
export class SelfProfileComponent implements OnInit {

  constructor(
    private auth: AuthService,
    private router: Router
  ) { }

  ngOnInit() {
    if (this.auth.user)
      this.router.navigateByUrl('settings/' + this.auth.user.id);
    else
      this.router.navigateByUrl('login');
  }

}
