import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ProductRequestService } from '../product-request.service';
import { FileObject } from '../../shared/file';
import { RestService } from '../../communication/rest.service';
import { BroadcasterNotification, BroadcasterNotificationType } from '../../communication/broadcaster-notifications';
import { BroadcasterService } from 'ng-broadcaster';
import { ProductData, ProductUrl, ProductTag, ProductResource, ProductResourceType, ResourceType, ImageDialog, MediaTag, ProductIdentifier, ProductsAttachment, ProductsRendersRequestStatus, ProductsRendersRequest, ExternalResourceDetails, ScrapedProduct } from '../product';
import { Category, RetailerBatch, RetailerFilterOptions, SubCategory, JobType, Retailer, RetailerQueryInner } from '../../retailer/retailer';
import { UtilsService } from '../../shared/utils.service';
import { EnumsService } from '../../shared/enums.service';
import { DEFAULT_3D_ICON, FormatsType, ImageFormatsType, KeyValuePair, RenderStatus } from '../../shared/enums';
import { ProductService } from '../product.service';
import { RolesHelperService } from '../../auth/roles-helper.service';
import { ResourceTypesService } from '../../shared/resource-types.service';
import { Subscription } from 'rxjs';
import { ImageDialogComponent } from '../../shared/image-dialog/image-dialog.component';
import { GraphqlService } from 'src/app/communication/graphql.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { MatChipInputEvent } from '@angular/material/chips';
import { ResumableUploadService } from 'src/app/shared/resumable-upload.service';
import { AuthService } from 'src/app/auth/auth.service';
import { EndpointsService } from 'src/app/communication/endpoints.service';
import { ArtistsJobsType, JobItem, JobsTypes } from 'src/app/retailer/job-types';
import { EndPoints } from '../../communication/endpoints';
import { ApiCallOptions } from 'src/app/shared/utils';
import { IP } from '../../shared/retailer-product-ip/retailer-product-ip.interface';
import { PreviewResourcesComponent } from '../preview-files/preview-resources.component';
import { DropZoneFile } from '../../shared/drop-zone/drop-zone.model';
import * as JSZip from 'jszip';
import { JSZipObject } from 'jszip';
import { ExternalResourceHandlerService } from './external-resource-handler.service';
import { GlobalsService } from 'src/app/shared/globals.service';
import { BodyService } from 'src/app/shared/body.service';
import { ApolloQueryResult } from '@apollo/client/core';

@Component({
    selector: 'app-product-settings',
    templateUrl: './product-settings.component.html',
    styleUrls: ['./product-settings.component.scss'],
    standalone: false
})
export class ProductSettingsComponent implements OnInit, OnDestroy {
  @ViewChild('scrapeUrl') scrapeUrl: ElementRef;
  @ViewChild('urlToAdd') urlToAdd: ElementRef;
  @ViewChild('catToAdd') catToAdd: ElementRef;
  @ViewChild('subCatToAdd') subCatToAdd: ElementRef;
  @ViewChild('renderBtn') renderBtn: ElementRef;
  @ViewChild('polyScroll') polyScroll: ElementRef;
  @ViewChild('addTagInput') addTagInput: ElementRef;
  @ViewChild('uploadsContainer') set uploadsContainer(element: ElementRef<HTMLDivElement>) {
    this.uploadsContainerRef = element;
  };

  public newCategoryMode: boolean;
  public newSubCategoryMode: boolean;
  public hasError: boolean;
  public currentFormat: KeyValuePair;
  public allFormats: Array<ResourceType>;
  public defaultFormats: Array<ProductResourceType>;
  public allDimUnits: Array<KeyValuePair>;
  public scrapeUrlError: boolean;
  public minDueDate: Date;
  public useApi: boolean;
  public formatToDownloads: Array<FormatsType>;
  public imagesFormatToDownloads: Array<ImageFormatsType>;
  public allImagesFormat: Array<KeyValuePair>;
  public allRenderStatuses: Array<KeyValuePair>;
  public jobType: number;
  public jobId: number;
  public jobTypeName: string;
  public isDueDateEnabled: boolean;
  public renderDelivered: number;
  public renderLive: boolean;
  public isDummy: boolean;
  private imagesToLoad: Array<FileObject>;
  private addCategoriesCounter: number;
  private subs: Array<Subscription>;
  public notAutoRender: boolean;
  public hasRenderResource: boolean;
  public canSetProductForExternalProvider: boolean;
  public priorities: Array<KeyValuePair>;
  public readonly uploadsAcceptedFormats = ['video/mp4', 'image/png', 'image/jpeg', 'application/x-zip-compressed', 'application/zip', '.glb', '.mp4', '.png', '.jpg', '.jpeg', '.zip', 'glb', 'mp4', 'png', 'jpg', 'jpeg', 'zip'];
  public dropZoneBackground = true;
  public status: Array<KeyValuePair>;
  private wasIAChangedByExternalProviderCheckbox: boolean;
  private uploadsContainerRef: ElementRef<HTMLDivElement>;
  public isImageFormatDisabled: boolean;
  private _selectedResourcesToDownload: ProductResource[];
  private readonly _supportedFileTypesForImageFormat = [FormatsType.FBX, FormatsType.OBJ, FormatsType.DAE];
  private hasIdentifierTypePoid: boolean;
  constructor(
    public prService: ProductRequestService,
    public rest: RestService,
    public broadcaster: BroadcasterService,
    public utils: UtilsService,
    private enums: EnumsService,
    public productService: ProductService,
    public roles: RolesHelperService,
    private cdr: ChangeDetectorRef,
    private resourceTypesService: ResourceTypesService,
    private dialog: MatDialog,
    private gql: GraphqlService,
    public resumableUpload: ResumableUploadService,
    private auth: AuthService,
    private endpoints: EndpointsService,
    private globals: GlobalsService,
    private body: BodyService
  ) {
    this.subs = [];
    this.useApi = false;
    this.minDueDate = new Date();
    this.minDueDate.setMonth(this.minDueDate.getMonth() + 1);
    this.newCategoryMode = false;
    this.newSubCategoryMode = false;
    this.allDimUnits = this.enums.getDimensionsUnits();
    this.allImagesFormat = this.enums.getImagesFormatsTypes();
    this.status = this.enums.getAllStatus();
    this.renderDelivered = ProductsRendersRequestStatus.DELIVERED;
    this.isImageFormatDisabled = true;

    this.addCategoriesCounter = -1;
    this.allFormats = this.resourceTypesService.getCachedTypes();
    this.priorities = this.enums.getPriorities();
    if (!this.allFormats) {
      this.subs.push(
        this.broadcaster.on('onResourceTypes').subscribe(
          () => this.allFormats = this.resourceTypesService.getCachedTypes(),
        ),
      );
    }
    this.subs.push(this.broadcaster.on('onRetailerIndex').subscribe(
      (retailerIndex: number) => {
        if (!this.prService.request.id) {
          let options = {
            id: this.auth.user.retailers_users[retailerIndex].retailer_id,
          } as RetailerFilterOptions;
          this.gql.retailerForSettings(options).subscribe(
            obj => this.prService.onRetailerChange(obj.data.retailers),
          );
          this.checkCanSetProductForExternalProvider();
        }
      },
    ));
    // this.subs.push(
    //   this.broadcaster.on('createAssetRequest').subscribe(
    //     () => this.prService.save.bind(this)
    //   )
    // );
    if (!this.prService.request.UI)
      this.prService.request.UI = this.productService.getProdutUI(this.prService.request, { improviseImageSet: false });
    this.setLastJob();
    this.hasIdentifierTypePoid = this.prService?.request?.products_identifiers?.some(x => x?.identifier_type?.toLowerCase()?.indexOf('poid') > -1);
  }

  get showDuplicate() {
    return this.prService.request.id && this.auth.user.retailers_users.length > 1 && this.roles.doesUserHasPermission('duplicate products');
  }

  ngOnInit() {
    this.checkErrors();
    if (this.prService.request.id) {
      this.prService.requiredFormats = this.resourceTypesService.mapProductResourceTypeToUi(this.prService.request.products_resources_types);
      this.renderLive = !!this.prService?.request?.products_renders_requests[this.prService.renderIndex]?.enabled;
      this.hasRenderResource = this.prService?.request?.products_resources?.some(r => r.resource_type !== MediaTag.MODEL);

    } else {
      if (this.prService.request.retailers[this.prService.retailerIndex] && this.prService.request.retailers[this.prService.retailerIndex]?.retailers_resources_types?.length)
        this.prService.requiredFormats = this.resourceTypesService.mapRetailerResourceTypeToUi(this.prService.request.retailers[this.prService.retailerIndex].retailers_resources_types);
      else {
        this.defaultFormats = this.resourceTypesService.getCachedDefaultTypes();
        if (!this.defaultFormats) {
          this.subs.push(
            this.broadcaster.on('onDefaultResourceTypes').subscribe(
              () => {
                this.defaultFormats = this.resourceTypesService.getCachedDefaultTypes();
                this.setDefaultFormats();
              },
            ),
          );
        } else
          this.setDefaultFormats();
      }
    }
    if (!this.prService.request.products_identifiers) this.prService.initIdentifiers();
    if (!this.prService.request.ip) {
      this.prService.request.ip = IP['Client’s IP'];
    }
    this.checkCanSetProductForExternalProvider();
  }



  public get currentRetailer(): Retailer {
    return this.prService.request.retailers[this.prService.retailerIndex];
  }

  setLastJob() {
    let ajr = this.prService.enabledResources[this.prService.currentResourceIndex]?.artists_jobs_resources;
    if (ajr) {
      let aji = ajr[0]?.artists_jobs_items;
      this.jobId = null;
      this.isDummy = false;
      if (aji) {
        this.jobId = aji[0]?.job_id;
        this.isDummy = aji[0]?.artists_jobs[0]?.is_dummy;
        this.setJobTypeName(aji[0]?.artists_jobs[0]?.artists_jobs_types);
      }
    }
  }

  setDefaultFormats() {
    this.prService.requiredFormats = [];
    this.defaultFormats.forEach(df => this.prService.requiredFormats.push(df.resources_types[0].id));
  }

  checkErrors() {
    this.hasError = (this.prService.request.products_data.length < ProductService.minImages) && !this.prService.request.external_provider;
  }

  upload(file: FileObject): Promise<string> {
    return new Promise((resolve: Function, reject: Function) => {
      this.resumableUpload.file(file.file).then((url: string) => {
        this.prService.editArrayValue('products_data', 'url', file.base64, url);
        resolve(url);
      }).catch(async err => {
        console.warn('failure uploading image, reverting to non-tus solution');
        try {
          console.log('non-tus solution succeeded', err);
          const url = await this.utils.uploadFile(file.file, false);
          this.prService.editArrayValue('products_data', 'url', file.base64, url);
          resolve(url);
        } catch (e) {
          this.utils.httpErrorResponseHandler(e, 'failure uploading image');
          if (this.prService.uploadingCount - 1 == 0) {
            this.onUploadDone();
          }
          else {
            this.prService.uploadingCount--;
          }
          reject();
        }
      }).finally(() => {
        this.onUploadDone();
      });
    });
  }

  onUploadDone() {
    if (--this.prService.uploadingCount == 0 && this.prService.saveAfterUpload) {
      this.prService.saveAfterUpload = false;
      this.prService.save();
    }
  }

  async onFilesChange(fileList: Array<FileObject>) {
    this.imagesToLoad = fileList;
    const validate = (file: FileObject) => {
      return (file.naturalHeight >= 300 || !file.naturalHeight) && (file.naturalWidth >= 300 || !file.naturalWidth);
    };
    if (this.imagesToLoad && this.imagesToLoad.length) {
      // This code is for uploading each file one-by-one
      // let hasImages = false;
      // this.imagesToLoad.forEach(file => {
      //   if (validate(file)) {
      //     hasImages = true;
      //     this.prService.request.products_data.push({ url: file.base64 });
      //   }
      // });
      // if (hasImages)
      //   this.globals.increaseNumOfRequestsInProgress();
      // for (let i = 0; i < this.imagesToLoad.length; i++) {
      //   const file = this.imagesToLoad[i];
      //   if (validate(file)) {
      //     this.prService.uploadingCount++;
      //     try {
      //       await this.upload(file);
      //     } catch (e) {}
      //   } else {
      //     const data: BroadcasterNotification = {
      //       text: `${file.name} is too small or type not supported - must be a JPEG or a PNG above 300X300 pixels`,
      //       type: BroadcasterNotificationType.Error,
      //       action: 'OK',
      //       duration: 10000
      //     }
      //     this.broadcaster.broadcast('notifyUser', data);
      //   }
      // }
      // if (hasImages)
      //   this.globals.decreaseNumOfRequestsInProgress();
      // This code is for uploading all file concurrently
      this.imagesToLoad.forEach(file => {
        if (validate(file)) {
          this.prService.uploadingCount++;
          this.upload(file);
          this.prService.request.products_data.push({ url: file.base64 });
        } else {
          const data: BroadcasterNotification = {
            text: `${file.name} is too small or type not supported - must be a JPEG or a PNG above 300X300 pixels`,
            type: BroadcasterNotificationType.Error,
            action: 'OK',
            duration: 10000
          }
          this.broadcaster.broadcast('notifyUser', data);
        }
      });
    }
    this.checkErrors();
  }

  private async getResourceDetailsFromZip(zipFiles: JSZipObject[]): Promise<ExternalResourceDetails[]> {
    // hierarchy GLB, GLTF, FBX, OBJ, MP4 PNG/JPEG
    const resourcesDetails: ExternalResourceDetails[] = [];
    const sortedTypes = ['glb', 'gltf', 'fbx', 'obj', 'mp4', 'png', 'jpeg', 'jpg', 'tif', 'tiff'];
    const resourceTypeDict = {
      'glb': FormatsType.GLB,
      'gltf': FormatsType.glTF,
      'fbx': FormatsType.FBX,
      'obj': FormatsType.OBJ,
      'mp4': FormatsType.MP4,
      'png': FormatsType.PNG,
      'jpg': FormatsType.JPG,
      'jpeg': FormatsType.JPG,
      'tif': FormatsType.TIFF,
      'tiff': FormatsType.TIFF
    };
    const fileExtensions = zipFiles.map(zipFile => this.utils.getFileNameExtension(zipFile.name));
    let fileIndex: number;

    for (const type of sortedTypes) {
      fileIndex = fileExtensions.indexOf(type);
      if (fileIndex > -1) {
        const resourceDetails: ExternalResourceDetails = {
          type: resourceTypeDict[type],
          name: this.utils.getFileName(zipFiles[fileIndex].name),
          extension: this.prService.getDisplayExtName(type),
          file: undefined,
          loading: true,
          isInFolder: false
        };
        if (resourceDetails.name.indexOf('/') > -1)
          resourceDetails.isInFolder = true;

        if (resourceDetails.type === FormatsType.MP4 ||
            resourceDetails.type === FormatsType.PNG ||
            resourceDetails.type === FormatsType.JPG) {
          resourceDetails.base64 = this.getBase64Prefix(resourceDetails.type) + await zipFiles[fileIndex].async('base64');
          resourceDetails.file = new File([await zipFiles[fileIndex].async('blob')], `${resourceDetails.name}.${type}`);
        } else {

        }

        resourcesDetails.push(resourceDetails);

        if (resourceDetails.type !== FormatsType.JPG &&
            resourceDetails.type !== FormatsType.PNG &&
            resourceDetails.type !== FormatsType.TIFF &&
            !resourceDetails.isInFolder) {
          break;
        }
        else if (resourceDetails.isInFolder && type === 'glb') {
          resourceDetails.file = new File([await zipFiles[fileIndex].async('blob')], `${resourceDetails.name}.${type}`);
          resourceDetails.isInFolder = false;
          break;
        }
      }
    }

    return resourcesDetails;
  }

  private getBase64Prefix(resourceType: FormatsType): string {
    switch (resourceType) {
      case FormatsType.PNG: {
        return 'data:image/png;base64,';
      }
      case FormatsType.JPG: {
        return 'data:image/jpeg;base64,';
      }
      case FormatsType.TIFF: {
        return 'data:image/tiff;base64,';
      }
      case FormatsType.MP4: {
        return 'data:video/mp4;base64,';
      }
      default: {
        return '';
      }
    }
  }

  private async getResourceDetails(dropZoneFile: DropZoneFile): Promise<ExternalResourceDetails[]> {
    return new Promise((resolve, reject) => {
      const extension = dropZoneFile.extension.replace('.', '').toLowerCase();
      const isZipFile = extension === 'zip';

      if (isZipFile) {
        JSZip.loadAsync(dropZoneFile.file).then(async (zip: JSZip) => {
          const files = Array.from(Object.values(zip.files));
          resolve(await this.getResourceDetailsFromZip(files));
        }).catch((err) => reject(err));
      } else {
        const type = extension === 'glb' ? FormatsType.GLB : extension === 'mp4' ? FormatsType.MP4 : extension === 'png' ? FormatsType.PNG : ((extension === 'tiff' || extension === 'tif') ? FormatsType.TIFF : FormatsType.JPG);
        const resourcesDetails: ExternalResourceDetails[] = [{
          type,
          name: dropZoneFile.name,
          base64: type === FormatsType.GLB ? DEFAULT_3D_ICON : dropZoneFile.base64,
          extension: this.prService.getDisplayExtName(extension),
          file: dropZoneFile.file,
          loading: true,
          isInFolder: false
        }];
        resolve(resourcesDetails);
      }
    });
  }

  fileNotAccepted() {
    this.utils.notifyUser({
      type: BroadcasterNotificationType.Error,
      text: 'unsupported file type'
    });
  }

  public async onExternalUploadFilesChange(dropZoneFile: DropZoneFile): Promise<void> {
    let resourcesDetails: ExternalResourceDetails[];

    try {
      resourcesDetails = await this.getResourceDetails(dropZoneFile);
    } catch (e) {
      console.error(e);
      return;
    }

    let uploaded = false;
    resourcesDetails.forEach(resource => {
      if (!resource.isInFolder) {
        uploaded = true;
        new ExternalResourceHandlerService(this.prService, resource, dropZoneFile).exec();
      }
    });
    if (!uploaded) {
      this.utils.notifyUser({
        type: BroadcasterNotificationType.Error,
        text: 'File should be in the root folder of the ZIP archive'
      });
    }
    // for (let i = 0; i < resourcesDetails.length; i++) {
    //   await new ExternalResourceHandlerService(this.prService, resourcesDetails[i], dropZoneFile).exec();
    // }
  }

  public async putResource(resource: ProductResource) {
    return await this.prService.putResource(resource);
  }

  public onIpChange(ip: IP): void {
    this.prService.request.ip = ip;
  }

  pin(index: number) {
    this.prService.request.products_data.map(pd => pd.sort_index = null);
    this.prService.request.products_data[index].sort_index = 0;
  }

  setBatches(batches: Array<RetailerBatch>) {
    setTimeout(() => {
      this.prService.request.products_batches = [];

      batches.forEach(b => {
        let br: RetailerBatch = {};
        br.batch_id = b.batch_id;
        br.end_at = b.end_at;
        if (b.id) {
          br.id = b.id;
        }
        this.prService.request.products_batches.push(br);
      });
    });
  }

  validateBatches() {
    return this.prService.request.products_batches.some(b => new Date(b.end_at) < new Date());
  }


  save() {
    if (!this.prService.uploadingCount) {
      this.prService.request.products_data.forEach(pd => {
        if (pd.url.indexOf('data:') === 0) {
          let mimeType = pd.url.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)[0];
          let file = {
            base64: pd.url,
            name: `product_image_${this.prService.uploadingCount++}`,
            suffix: mimeType.replace('image/', ''),
          } as FileObject;
          this.upload(file);
        }
      });
    }
    if (this.validateBatches()) {
      if (!confirm('Please note the batch end date has passed, are you sure that’s the batch you want to select?')) {
        return;
      }
    }

    if (!this.roles.isGodLoggedIn() && this.prService.request.products_batches.length === 0) {
      let data: BroadcasterNotification = {
        text: 'Please select at least one batch.',
        type: BroadcasterNotificationType.Error,
        action: 'OK',
      };
      this.broadcaster.broadcast('notifyUser', data);
      return;
    }

    this.prService.save();
  }

  delete() {
    if (!confirm('Are you sure you want to DELETE this product modeling request?')) return;
    this.prService.request.is_archived = true;
    this.prService.save();
  }

  restore() {
    if (!confirm('Are you sure you want to RESTORE this request?')) return;
    this.prService.request.is_archived = false;
    this.prService.save();
  }

  addUrl(url: string, clear = true) {
    if (!url) return;
    // if (this.prService.existArrayValue('products_urls', 'url', url)) {
    if (this.prService.request.products_urls.find(pu => pu.url == url)) {
      let data: BroadcasterNotification = {
        text: 'URL already exist',
        type: BroadcasterNotificationType.Info,
        action: 'OK',
      };
      this.broadcaster.broadcast('notifyUser', data);
      return;
    }
    ;
    // this.prService.setArrayValue('products_urls', { url: url });
    this.prService.request.products_urls.push({ url: url });
    if (clear)
      this.urlToAdd.nativeElement.value = '';
  }

  removeUrl(obj: ProductUrl) {
    if (obj.id)
      this.prService.removeArrayValue('products_urls', 'id', obj.id);
    else
      this.prService.removeArrayValue('products_urls', 'url', obj.url);
  }

  onAddUrlKeypress($event) {
    if (this.utils.isEnter($event.charCode) && this.urlToAdd.nativeElement.value) {
      this.addUrl(this.urlToAdd.nativeElement.value);
    }
  }

  createCategory(name: string, showError = true) {
    if (!name) return;
    const exist = this.prService.request.retailers[this.prService.retailerIndex].retailers_categories.find(c => c.category_name_org_lang == name);
    if (exist) {
      if (showError) {
        let data: BroadcasterNotification = {
          text: name + ' already exists',
          type: BroadcasterNotificationType.Info,
          action: 'OK',
        };
        this.broadcaster.broadcast('notifyUser', data);
      }
      this.prService.request.retailer_category_id = exist.id;
      return;
    }
    this.prService.request.category = name;
    this.prService.request.retailers[this.prService.retailerIndex].retailers_categories.push({
      category_name_org_lang: name,
      id: this.addCategoriesCounter,
    } as Category);

    this.setNewCategoryMode(false);
    if (this.catToAdd && this.catToAdd.nativeElement)
      this.catToAdd.nativeElement.value = '';
    this.prService.request.retailer_category_id = this.addCategoriesCounter--;
  }

  onAddCatKeypress($event) {
    if (this.utils.isEnter($event.charCode) && this.catToAdd.nativeElement.value) {
      this.createCategory(this.catToAdd.nativeElement.value);
    }
  }

  setNewCategoryMode(state: boolean) {
    if (state)
      setTimeout(() => {
        this.newCategoryMode = state;
        this.cdr.detectChanges();
        this.catToAdd.nativeElement.focus();
      }, 300);
    else
      this.newCategoryMode = state;
  }

  onCategoryChange(evt: MatSelectChange) {
    if (typeof evt.value !== 'number' && evt.source.panelOpen)
      this.setNewCategoryMode(true);
    this.prService.initRenderSettings(true, true);
    this.prService.setPrice();
  }

  createSubCategory(name: string, showError = true) {
    if (!name) return;
    const exist = this.prService.request.retailers[this.prService.retailerIndex].retailers_sub_categories.find(sc => sc.sub_category_name_org_lang == name);
    if (exist) {
      if (showError) {
        let data: BroadcasterNotification = {
          text: name + ' already exists',
          type: BroadcasterNotificationType.Info,
          action: 'OK',
        };
        this.broadcaster.broadcast('notifyUser', data);
      }
      this.prService.request.retailer_sub_category_id = exist.id;
      return;
    }
    this.prService.request.sub_category = name;
    this.prService.request.retailers[this.prService.retailerIndex].retailers_sub_categories.push({
      sub_category_name_org_lang: name,
      id: this.addCategoriesCounter,
    } as SubCategory);

    this.setNewSubCategoryMode(false);
    if (this.subCatToAdd && this.subCatToAdd.nativeElement)
      this.subCatToAdd.nativeElement.value = '';
    this.prService.request.retailer_sub_category_id = this.addCategoriesCounter--;
  }

  onAddSubCatKeypress($event) {
    if (this.utils.isEnter($event.charCode) && this.subCatToAdd.nativeElement.value) {
      this.createSubCategory(this.subCatToAdd.nativeElement.value);
    }
  }

  setNewSubCategoryMode(state: boolean) {
    if (state)
      setTimeout(() => {
        this.newSubCategoryMode = state;
        this.cdr.detectChanges();
        this.subCatToAdd.nativeElement.focus();
      }, 300);
    else
      this.newSubCategoryMode = state;
  }

  onSubCategoryChange(evt: MatSelectChange) {
    if (typeof evt.value !== 'number' && evt.source.panelOpen)
      this.setNewSubCategoryMode(true);
  }

  removeTag(t: ProductTag, index: number) {
    // const index = this.prService.request.products_tags.findIndex(t => t.tag == t.tag);
    if (index > -1)
      this.prService.request.products_tags.splice(index, 1);
  }

  addTag(event: MatChipInputEvent): void {
    const input = event.input;
    const value = (event.value || '').trim();

    if (value) {
      if (!this.prService.request.products_tags.find(t => t.tag == value)) {
        this.prService.request.products_tags.push({
          tag: value,
          confidence: 100,
        });
      } else {
        let data: BroadcasterNotification = {
          text: 'tag already exists',
          type: BroadcasterNotificationType.Info,
          action: 'OK',
        };
        this.broadcaster.broadcast('notifyUser', data);
      }
    }

    // // Reset the input value
    if (input) {
      input.value = '';
      setTimeout(this.onTagFocus.bind(this));
    }
  }

  // copyImage(imageData: ProductData): void {
  //   this.utils.copyClipboard(imageData.url);
  // }

  viewImage(imageData: ProductData) {
    const m = {
      title: this.prService.request.name_org_lang,
      url: imageData.url,
    } as ImageDialog;
    this.dialog.open(ImageDialogComponent, {
      // width: '500px',
      data: m,
    });
  }

  // viewImageURL(url: string, copyLink?: string) {
  //   const m = {
  //     title: 'Scan the QR with your mobile device for AR experience',
  //     url: url,
  //     copyLink
  //   } as ImageDialog;
  //   this.dialog.open(ImageDialogComponent, {
  //     data: m
  //   });
  // }

  deleteImage(imageData: ProductData, index: number): void {
    if (!confirm('Are you sure you want to DELETE this image forever?')) return;
    this.prService.request.products_data.splice(index, 1);
    this.checkErrors();
  }

  changeFormats(): void {
    this.prService.request.products_resources_types = this.resourceTypesService.mapUiToResourceType(this.prService.requiredFormats, this.allFormats, this.prService.request.products_resources_types, this.prService.request.id);
  }

  toggleEnabled(prefix: string, $event) {
    if (this.prService.request[prefix + 'enabled'] != 1)
      this.prService.request[prefix + 'enabled'] = 1;
    else
      this.prService.request[prefix + 'enabled'] = 0;
    if ($event)
      $event.preventDefault();
  }

  private async scrapeLocally(url: string) {
    if (this.roles.isSuperUserLoggedIn()) {
      const domain = this.utils.extractDomainFromURL(url);
      if (!this.prService.request.retailers[this.prService.retailerIndex].retailers_domains.find(d => d.retailer_domain == domain)) {
        this.utils.notifyUser({
          type: BroadcasterNotificationType.Error,
          text: 'Unsupported domain for selected retailer'
        });
        return;
      }
    }
    const mapProduct = (p: ScrapedProduct) => {
      this.prService.request.products_data = p.images.map(i => {
        return {
          url: i.url,
          sort_index: i.sort_index
        }
      });
      this.prService.request.products_urls = p.additional_urls.map(u => {
        return {
          url: u
        };
      });
      this.prService.request.products_urls.unshift({ url: p.pageUrl });
      if (p.products_tags)
        this.prService.request.products_tags = p.products_tags.map(t => {
          return {
            tag: t
          };
        });
      this.prService.request.products_identifiers = [{
        serial_number: p.sku,
        retailer_id: this.prService.request.retailers[this.prService.retailerIndex].id
      } as ProductIdentifier];
      this.prService.request.name_org_lang = p.title;
      if (typeof p.width === 'number')
        this.prService.request.width = p.width;
      if (typeof p.height === 'number')
        this.prService.request.height = p.height;
      if (typeof p.length === 'number')
        this.prService.request.length = p.length;
      if (typeof p.units === 'number')
        this.prService.request.units = p.units;
      if (p.category) {
        let currentCat = this.prService.request.retailers[this.prService.retailerIndex].retailers_categories.find(c => c.category_name_org_lang === p.category);
        if (currentCat)
          this.prService.request.retailer_category_id = currentCat.id;
        else
          this.createCategory(p.category);
      }
      if (p.sub_category) {
        let currentCat = this.prService.request.retailers[this.prService.retailerIndex].retailers_sub_categories.find(c => c.sub_category_name_org_lang === p.sub_category);
        if (currentCat)
          this.prService.request.retailer_sub_category_id = currentCat.id;
        else
          this.createSubCategory(p.sub_category);
      }
    };
    const options = {
      id: this.prService.request.retailers[0].id
    } as RetailerFilterOptions;
    const retailer = await this.utils.observableToPromise(this.gql.retailerForSettings(options)) as ApolloQueryResult<RetailerQueryInner>;
    let config = '{}';
    if (retailer.data.retailers.retailers_scrapers_configs[0])
      config = retailer.data.retailers.retailers_scrapers_configs[0].scraper_config || config;
    // if (typeof config === 'string')
    //   // config = JSON.parse(config);
    //   // config = this.utils.customParse(config);
    //   config = new Function(`return ${config}`);
    const p = await this.body.scrapeWithChromeExtension(url, config) as { products: Array<ScrapedProduct> };
    mapProduct(p.products[0]);
  }

  async scrape(url: string) {
    if (!url) {
      this.scrapeUrlError = true;
      return;
    }
    if (this.body.isScraperAvailable && confirm('Do you want to try and scrape locally?')) {
      this.scrapeLocally(url);
      return;
    }
    this.scrapeUrlError = false;
    if (!this.prService.request.retailers || !this.prService.request.retailers.length || !this.prService.request.retailers[this.prService.retailerIndex])
      console.error('no retailer to scrape for!');
    let query = '?scrape_only=true&rid=' + this.prService.request.retailers[this.prService.retailerIndex].id;
    let due_date = null;
    if (this.prService.request.due_date) {
      if (this.prService.request.due_date.getTime)
        due_date = this.prService.request.due_date.getTime();
      else
        due_date = new Date(this.prService.request.due_date).getTime();
    }
    let obj = { url: url, due_date: due_date, use_api: this.useApi };
    this.rest.productScrape('post', obj, query).subscribe(
      data => {
        this.prService.request = this.utils.deepCopyByValue(this.prService.request);
        // let poly = this.prService.request.products_polygon_specifications;
        Object.assign(this.prService.request, data);
        // this.prService.request.products_polygon_specifications = poly;
        this.createSubCategory(this.prService.request.sub_category, false);
        this.createCategory(this.prService.request.category, false);
        this.checkErrors();
        this.prService.setBudget();
        this.prService.setPrice();
        this.prService.initIdentifiers();
        this.prService.setHeader();
        this.prService.initRenderSettings(true);

      },
      err => this.utils.httpErrorResponseHandler(err, 'failure fetching data'),
    );
  }

  onEmbeddedChange(resource: ProductResource) {
    const related = {
      'resource_order': true,
      'resource_mobile_order': true,
      'resource_text': true,
      'resource_info': true,
      'resource_default': true,
      'resource_big': true,
      'resource_small': true,
    };
    for (let i in resource) {
      if (related[i])
        this.prService.enabledResources[this.prService.currentResourceIndex][i] = resource[i];
    }
    this.prService.enabledResources.forEach(er => {
      let r = this.prService.request.products_resources.find(r => r.id == er.id);
      Object.assign(r, er);
    });
  }

  public onDownloadResourcesChange(resources: ProductResource[]): void {
    this._selectedResourcesToDownload = resources;
    this.checkIfImageFormatDisabled();
  }

  private checkIfImageFormatDisabled() {
    this.isImageFormatDisabled = !this._selectedResourcesToDownload.every(resource => this._supportedFileTypesForImageFormat.includes(resource.viewer_resource_type));
  }

  public isResourcesToDownloadValid() {
    return !!this._selectedResourcesToDownload?.length;
  }

  onResourceChange(resource?: ProductResource) {
    if (resource) {
      Object.assign(this.prService.enabledResources[this.prService.currentResourceIndex], resource);
    }
    this.setLastJob();
  }

  onGifgen() {
    if (this.prService.gifgen) {
      if (this.prService.enabledResources.length - 1 == this.prService.currentResourceIndex)
        this.prService.save();
      else {
        this.prService.currentResourceIndex++;
        let last = true;
        for (let i = this.prService.currentResourceIndex; i < this.prService.enabledResources.length; i++) {
          if (this.prService.enabledResources[i].resource_type == MediaTag.MODEL && this.prService.enabledResources[i].resource_enabled) {
            last = false;
            break;
          }
        }
        if (last) {
          this.prService.save();
        } else {
          // do (this.prService.currentResourceIndex++)
          while (this.prService.enabledResources.length - 1 > this.prService.currentResourceIndex && (this.prService.enabledResources[this.prService.currentResourceIndex].resource_type != MediaTag.MODEL || this.prService.enabledResources[this.prService.currentResourceIndex].resource_enabled))
            this.prService.currentResourceIndex++;
        }
      }
    }
  }

  fixRejects() {
    if (!confirm(`Do you confirm fixing this issue?\r\n"${this.prService.request.products_reject_reasons[0] ? this.prService.request.products_reject_reasons[0].reason : 'unknown reason'}"`)) return;
    this.prService.onRejectFixed();
  }

  addAngle() {
    this.prService.addAngle();
    setTimeout(() => {
      if (this.renderBtn && this.renderBtn.nativeElement && this.renderBtn.nativeElement.scrollIntoView)
        this.renderBtn.nativeElement.scrollIntoView({ behavior: 'smooth' });
    }, 100);
  }

  addPoly() {
    this.prService.addPoly();
    setTimeout(() => {
      if (this.polyScroll && this.polyScroll.nativeElement && this.polyScroll.nativeElement.scrollIntoView)
        this.polyScroll.nativeElement.scrollIntoView({ behavior: 'smooth' });
    }, 100);
  }

  approve() {
    if (!confirm(`Are you sure you want to APPROVE this product?`)) return;
    this.prService.onApprove();
  }

  addIdentifier() {
    this.prService.request.products_identifiers.push({
      retailer_id: this.prService.request.retailers[this.prService.retailerIndex].id,
      product_id: this.prService.request.id,
      serial_number: this.prService.request.products_identifiers[0] ? this.prService.request.products_identifiers[0].serial_number : null,
    } as ProductIdentifier);
  }

  onAttachmentsCHange(arr: Array<ProductsAttachment>) {
    this.prService.request.products_attachments = arr;
  }

  public downloadResource(): void {
    const imagesFormatToDownloads: string[] = [];
    if (this.imagesFormatToDownloads) {
      this.imagesFormatToDownloads.forEach(f => {
        imagesFormatToDownloads.push(this.allImagesFormat.find(ff => ff.key == f).value.toLowerCase());
      });
    }
    this.productService.downloadResources(this._selectedResourcesToDownload, this.formatToDownloads, imagesFormatToDownloads);
  }

  goToTheJob(jobId) {
    if (jobId) {
      const url = `${this.endpoints.getEndpointDomain(EndPoints.CREATORS)}/jobs/job/${jobId}`;
      window.open(url, '_blank');
    }
  }

  approveRender() {
    let obj = this.prService.request.products_renders_requests;
    if (!obj || obj.length === 0) return;
    obj[this.prService.renderIndex].product_id = this.prService.request.id;
    let query = '/' + obj[this.prService.renderIndex].id + '?approve=true';

    function approveRenderApi(rsp) {
      if (rsp?.error) {
        return;
      } else if (rsp) {
        this.prService.request.status_id = rsp.status_id;
        this.prService.setBreadcrumbs();
      }
      this.rest.approveRender('put', obj, query).subscribe(
        (data: Array<ProductsRendersRequest>) => {
          if (data.length && data[0].status_id) {
            if (data[0].status_id === ProductsRendersRequestStatus.OFFLINE)
              this.prService.renderStatus = RenderStatus.APPROVED_OFFLINE;
            this.prService.request.products_renders_requests[this.prService.renderIndex].status_id = data[0].status_id;
          }
        },
        err => this.utils.httpErrorResponseHandler(err, 'failure fetching data'),
      );
    }

    let options = new ApiCallOptions();
    options.callback = approveRenderApi.bind(this);
    this.prService.saveProduct(options, true);
  }

  onRenderLiveChange(event: number) {
    let obj = this.prService.request.products_renders_requests;
    if (!obj || obj.length === 0) return;
    obj[this.prService.renderIndex].enabled = event;
    obj[this.prService.renderIndex].product_id = this.prService.request.id;
    let query = '/' + obj[this.prService.renderIndex].id;
    this.rest.approveRender('put', obj, query).subscribe(
      () => {
      },
      err => this.utils.httpErrorResponseHandler(err, 'failure setting render live'),
    );
  }

  compareObjects(o1: any, o2: any) {
    return (o1.type_id === o2.type_id);
  }

  createDummyOffer() {
    let payload = {
      'product_id': this.prService.request.id,
    };

    this.rest.dummy('post', payload, '/job').subscribe(
      (data: JobItem) => {
        this.jobId = data.id;
      //  this.jobType = data.job_type;
        this.isDummy = data.is_dummy;
        this.setJobTypeName(data.job_type);
      },
      err => this.utils.httpErrorResponseHandler(err, 'failure creating dummy job'),
    );
  }

  private setJobTypeName(job_type: Array<ArtistsJobsType>) {
    if (job_type?.length) {
      this.jobTypeName = '';
      for (let i = 0; i < job_type.length; i++) {
        const tid = job_type[i].type_id;
        this.jobTypeName += JobsTypes[tid].toLowerCase();
        if (i !== job_type.length - 1) {
          this.jobTypeName += ', ';
        }
      }
    }
  }

  ngOnDestroy() {
    if (this.subs.length)
      this.subs.forEach(s => s.unsubscribe());
  }

  public get externalProviderCheckboxDisabled(): boolean {
    return !!this.prService.request.id;
  }

  public onExternalProviderCheckboxChange(): void {
    this.checkErrors();
    const isNewProduct = !this.prService.request.id;
    if (this.prService.request.external_provider && !this.wasIAChangedByExternalProviderCheckbox && isNewProduct) {
      this.prService.request.ia = false;
      this.wasIAChangedByExternalProviderCheckbox = true;
    }
  }

  public onClear(): void {
    this.prService.clear();
    this.checkErrors();
  }

  private checkCanSetProductForExternalProvider(): void {
    this.canSetProductForExternalProvider = this.prService.request.external_provider || ((this.roles.isAdminLoggedIn() || this.roles.isSuperUserLoggedIn()) && this.currentRetailer.external_provider);
  }

  public onRetailerChange(retailer: Retailer): void {
    if (retailer)
      this.prService.onRetailerChange(retailer).then(() => {
        this.checkCanSetProductForExternalProvider();
      });
  }

  public onExternalResourceDelete(resource: ExternalResourceDetails): void {
    const index = this.prService.externalResources.indexOf(resource)
    if (index > -1) {
      this.prService.externalResources.splice(index, 1);
      const productResourceToDelete = this.prService.request.products_resources.find(productResource => productResource.id === resource.id);
      const previewItemIndex = this.prService.previewResourceItems.findIndex(previewItem => previewItem.src === resource.base64);

      if (productResourceToDelete) {
        productResourceToDelete.resource_enabled = 0;
      }

      if (previewItemIndex > -1) {
        this.prService.previewResourceItems.splice(previewItemIndex, 1);
      }
      if (!this.prService.externalResources.length)
        this.prService.isUploadExternalResourcesDirty = false
      else
        this.prService.isUploadExternalResourcesDirty = true;
    }
  }

  public onExternalResourcePreview(resource: ExternalResourceDetails): void {
    const resourceIndex = this.prService.externalResources.indexOf(resource);

    this.dialog.open(PreviewResourcesComponent, {
      data: {
        resources: this.prService.previewResourceItems,
        currentResource: this.prService.previewResourceItems[resourceIndex]
      },
      width: '600px',
      height: '700px'
    })
  }

  public async submitExternalFiles(): Promise<void> {
    if (confirm('Are you sure you want to submit these files?')) {
      this.prService.externalResources.forEach(exResource => {
        const productResource = this.prService.request.products_resources.find(resource => resource.id === exResource.id);
        if (productResource && this.prService.externalResourcesUploadsIDs.find(r => r === productResource.id)) {
          productResource.resource_enabled = 1;
        }
      });

      this.prService.externalResourcesUploadsIDs = [];

      this.prService.submitExternalFiles().subscribe({
        next: res => {
          const data: BroadcasterNotification = {
            text: `Files submitted`,
            type: BroadcasterNotificationType.Success,
            action: 'OK',
            duration: 5000
          }
          this.broadcaster.broadcast('notifyUser', data);
          this.prService.externalResources.forEach(exResource => {
            const productResource = this.prService.request.products_resources.find(resource => resource.id === exResource.id);
            if (productResource) {
              productResource.resource_enabled = 1;
            }
          });
          this.prService.request.products_resources.filter(resource => resource.resource_enabled === 1).forEach(enabledResource => {
            const externalResource = this.prService.externalResources.find(exResource => exResource.id === enabledResource.id);
            if (externalResource) {
              externalResource.submitted = true;
            }
          });
          this.prService.isUploadExternalResourcesDirty = false;
          this.prService.saveCB(this.prService.request);
          // this.prService.initResources();
        },
        error: err => {
          const data: BroadcasterNotification = {
            text: `Could not submit files`,
            type: BroadcasterNotificationType.Error,
            action: 'OK',
            duration: 5000
          }
          this.broadcaster.broadcast('notifyUser', data);
        }
      })
    }
  }

  public get submitExternalFilesDisabled(): boolean {
    return ((!this.prService.externalResources.length || this.prService.externalResourcesUploadsInProgress > 0) && !this.prService.isUploadExternalResourcesDirty) || !this.prService.isUploadExternalResourcesDirty;
  }

  public get isUploadDisabled(): boolean {
    return !!this.prService.request.ia && !this.prService.isSU;
  }

  public get showUploadsTab(): boolean {
    if (this.prService.isRetailersExternalProviderWithoutIA(this.currentRetailer.id))
      return this.prService.request.id && this.prService.request.external_provider;
    return this.prService.request.id && this.prService.request.external_provider && !this.prService.request.ia;
  }

  public get JobType(): typeof JobType {
    return JobType;
  }

  onTagFocus() {
    this.body.scrollToElement(this.addTagInput);
  }
}
