import { Component, OnInit, Input } from '@angular/core';
import { Retailer } from 'src/app/retailer/retailer';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { BudgetService } from '../budget.service';
import { BroadcasterService } from 'ng-broadcaster';
import { BroadcasterNotification, BroadcasterNotificationType } from 'src/app/communication/broadcaster-notifications';
import { ValidState } from 'src/app/shared/utils';
import { UtilsService } from 'src/app/shared/utils.service';

@Component({
    selector: 'app-add-funds',
    templateUrl: './add-funds.component.html',
    styleUrls: ['./add-funds.component.scss'],
    standalone: false
})
export class AddFundsComponent implements OnInit {
  @Input() retailer: Retailer;
  @Input('valid-state') validState: ValidState;
  public payForm: UntypedFormGroup;
  public wantToPay: boolean;
  public vat: number;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private budgetService: BudgetService,
    private broadcaster: BroadcasterService,
    private utils: UtilsService
  ) {
    this.vat = 1;
    this.payForm = this.formBuilder.group({
      amount: [null, Validators.required]
    });
  }

  ngOnInit() {
    this.vat = this.utils.getVATByCountryCode(this.retailer.country_code);
  }

  pay() {
    const res = this.budgetService.canPay(this.retailer);
    if (!res.state) {
      let data: BroadcasterNotification = {
        text: `First provide all of your company's missing mandatory fields - ${res.message}`,
        type: BroadcasterNotificationType.Error,
        action: 'OK'
      }
      this.broadcaster.broadcast('notifyUser', data);
      return;
    }
    if (this.validState && !this.validState.state) {
      let validState: BroadcasterNotification = {
        text: this.validState.message,
        type: BroadcasterNotificationType.Error,
        action: 'OK'
      }
      this.broadcaster.broadcast('notifyUser', validState);
      return;
    }
    if (!confirm(`Are you sure the following name "${this.retailer.name}" is valid for the receipt?`))
      return;
    if (this.payForm.valid)
      this.wantToPay = true;
  }
}
