import { Component, OnInit, ViewChild, OnDestroy, AfterViewInit, ElementRef } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { MatInput } from '@angular/material/input';
import { Sort } from '@angular/material/sort';
import { GlobalsService } from '../../shared/globals.service';
import { PlatgroundClass } from 'src/app/shared/globals';
import { RetailersQueryData } from '../../retailer/retailer';
import { ProductService } from 'src/app/product/product.service';
import { Subscription } from 'rxjs';
import { ApolloQueryResult } from '@apollo/client/core';
import { RolesHelperService } from 'src/app/auth/roles-helper.service';
import { EnumsService } from 'src/app/shared/enums.service';
import { UtilsService } from 'src/app/shared/utils.service';
import { ScrollHelper } from 'src/app/shared/scroll-helper';
import { ProductViewType } from '../product';

@Component({
    selector: 'app-products-manager',
    templateUrl: './products-manager.component.html',
    styleUrls: ['./products-manager.component.scss'],
    providers: [DecimalPipe],
    standalone: false
})
export class ProductsManagerComponent implements OnInit, OnDestroy, AfterViewInit {
  public viewType: number;
  private subs: Array<Subscription>;
  private ignoreDateChange: boolean;
  private scrollHelper: ScrollHelper;
  @ViewChild('scrollCards') public scrollCards: ElementRef;
  @ViewChild('scrollLines') public scrollLines: ElementRef;
  @ViewChild('pickerFromInput', {
    read: MatInput,
    static: false
  }) pickerFromInput: any;
  @ViewChild('pickerToInput', {
    read: MatInput,
    static: false
  }) pickerToInput: any;
  public dateFilter: any;
  public dateFilterSelectedValue: string;
  constructor(
    private activatedRoute: ActivatedRoute,
    private globals: GlobalsService,
    public productService: ProductService,
    public roles: RolesHelperService,
    private enums: EnumsService,
    private utils: UtilsService
  ) {
    this.ignoreDateChange = true;
    this.subs = [];
    this.productService.onProductsManagement = true;
  }

  ngOnInit() {
    let listResponse = this.activatedRoute.snapshot.data['products'].data.allProducts;
    const retailers = this.activatedRoute.snapshot.data['retailer'] as ApolloQueryResult<RetailersQueryData>;
    if (retailers && retailers.data.allRetailers && retailers.data.allRetailers.rows.length) {
      retailers.data.allRetailers.rows.forEach(r => this.productService.setRetailerToCache(r));
    }
    else {
      setTimeout(() => {
        this.productService.filter.controls.retailer_id.setValue([]);
      }, 0);
    }
    this.productService.items = this.productService.getProductsFromProductQuery(listResponse.rows);
    this.productService.selectedCount = 0;
    this.productService.count = listResponse.count as number;
    this.productService.allProductsSummary = listResponse.summary;
    this.productService.setSummary();
    this.productService.setProdutsUI();
    setTimeout(() => {
      this.globals.playgroundClass = PlatgroundClass[PlatgroundClass.SIDEBAR].toLowerCase();
      this.productService.setHeader();
    }, 0);
    this.ignoreDateChange = false;
    this.productService.updateSummary(listResponse);

    this.dateFilterSelectedValue = "created_at";
    if (this.productService.filter.controls?.date_range?.value) {
      this.dateFilterSelectedValue = this.productService.filter.controls.date_range.value;
    }
    this.dateFilter = this.enums.getDateFilters();
  }

  ngAfterViewInit() {
    this.initScrollHelper();
    this.setDatesFromFilter();
  }

  setDatesFromFilter() {
    let from = this.productService.filter.controls['date_from'].value;
    if (from)
      this.pickerFromInput.value = new Date(from * 1);
    let to = this.productService.filter.controls['date_to'].value;
    if (to)
      this.pickerToInput.value = new Date(to * 1);
  }

  dateKeyUp(e: KeyboardEvent) {
    if (e.code === 'Backspace') {
      this.pickerFromInput.value = null;
      this.pickerToInput.value = null;
      this.dateChange();
    }
  }

  dateChange() {
    if (this.ignoreDateChange) return;
    this.productService.ignoreChanges = true;
    this.productService.filter.controls['date_from'].setValue(this.pickerFromInput.value ? this.pickerFromInput.value.getTime() : null);

    this.productService.filter.controls['date_to'].setValue(this.pickerToInput.value ? this.pickerToInput.value.getTime() : null);
    this.productService.ignoreChanges = false;
    this.productService.filter.controls['date_range'].setValue(this.dateFilterSelectedValue);
  }

  sortData(sort: Sort) {
    this.productService.ignoreChanges = true;
    this.productService.filter.controls['order_by'].setValue(sort.active);
    this.productService.ignoreChanges = false;
    this.productService.filter.controls['is_desc'].setValue(sort.direction == 'desc');
  }

  fetchMore() {
    if (this.productService.count <= this.productService.items.length) return;
    this.productService.scrollOffset = this.productService.items.length;
    this.productService.searchByQuery();
  }

  onSelectModeChange() {
    this.productService.select();
  }

  dateSelectChange() {
    if (this.pickerFromInput.value != null || this.pickerToInput.value != null) {
      this.productService.filter.controls['date_range'].setValue(this.dateFilterSelectedValue);
    }
  }

  toggleReportGen() {
    this.productService.showBatchDownloader = false;
    this.productService.showReportGen = !this.productService.showReportGen;
  }

  toggleBatchDownloader() {
    let total = this.productService.totalSelected;
    let prodresourses = this.productService.selectedItems.filter(p => p.products_resources.length > 0).length;
    if (total === 0) {
      alert("Please select at least one product");
    } else if ((this.productService.isAllSelected && this.productService.resource_count === 0) ||
      (!this.productService.isAllSelected && prodresourses === 0)) {
      alert("Please note, the selected products don't have any models to download yet.");
    }
    else if (!this.productService.isAllSelected && prodresourses !== total) {
      if (confirm("Please note, only " + prodresourses + " of the selected products have available models.")) {
        this.showBatchDownloader();
      }
    } else /*if (confirm("Are you sure you want to download " + total + " assets?"))*/ {
      this.showBatchDownloader();
    }
  }

  showBatchDownloader() {
    this.productService.showReportGen = false;
    this.productService.showBatchDownloader = !this.productService.showBatchDownloader;
  }

  initScrollHelper() {
    if (this.productService.viewType === ProductViewType.GRID) {
      this.scrollCards = new ElementRef(document.getElementById('products-cards-list'));
      if (this.scrollHelper)
        this.scrollHelper.destroy();
      this.scrollHelper = new ScrollHelper(this.scrollCards.nativeElement);
      this.scrollHelper.onScrollThreshold.subscribe(this.fetchMore.bind(this));
    }
    else {
      this.scrollLines = new ElementRef(document.getElementById('products-lines-list'));
      if (this.scrollHelper)
        this.scrollHelper.destroy();
      this.scrollHelper = new ScrollHelper(this.scrollLines.nativeElement);
      this.scrollHelper.onScrollThreshold.subscribe(this.fetchMore.bind(this));
    }
  }

  setViewType(t: ProductViewType): void {
    this.productService.setViewType(t);
    setTimeout(this.initScrollHelper.bind(this));
  }

  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
    this.productService.clearRetailersCache();
    this.productService.scrollOffset = 0;
    this.productService.showReportGen = false;
    this.productService.showBatchDownloader = false;
    this.productService.onProductsManagement = false;
    this.scrollHelper.destroy();
  }
}
