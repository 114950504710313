import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { RetailersSpecification } from '../retailer';
import { RestService } from '../../communication/rest.service';
import { BroadcasterService } from 'ng-broadcaster';
import { UtilsService } from '../../shared/utils.service';
import { BroadcasterNotificationType, BroadcasterNotification } from '../../communication/broadcaster-notifications';
import { FileObject } from '../../shared/file';
import { ResumableUploadService } from 'src/app/shared/resumable-upload.service';
import { JobTypes } from '../job-types';
import { JobTypesService } from '../job-types.service';

@Component({
    selector: 'app-retailer-specs',
    templateUrl: './retailer-specs.component.html',
    styleUrls: ['./retailer-specs.component.scss'],
    standalone: false
})
export class RetailerSpecsComponent implements OnInit, OnChanges {
  @Input() config: Array<RetailersSpecification>;
  @Input('retailer-id') retailerId: number;
  public uploading: boolean;
  public jobTypes: Array<JobTypes>;
  constructor(
    private rest: RestService,
    private broadcaster: BroadcasterService,
    public utils: UtilsService,
    private resumableUpload: ResumableUploadService,
    private jobTypesService: JobTypesService
  ) {
    this.uploading = false;
    this.getJobTypes();
  }

  ngOnInit() {
    this.setDefaults();
  }

  ngOnChanges() {
    this.setDefaults();
  }

  setDefaults() {
    this.config = this.utils.deepCopyByValue(this.config) || [];
    this.sort();
  }

  sort() {
    this.config.sort((a: RetailersSpecification, b: RetailersSpecification) => b.id - a.id);
  }

  add() {
    this.config.push({
      retailer_id: this.retailerId
    } as RetailersSpecification);
  }

  remove(index: number) {
    this.config.splice(index, 1);
  }

  onTextChange(text: string, c: RetailersSpecification) {
    c.text = text;
  }

  async onFilesChange(fileList: Array<FileObject>, c: RetailersSpecification) {
    let file = fileList[0];
    if (!file) return;
    this.uploading = true;
    try {
      c.attachment = await this.resumableUpload.file(file.file);
      this.uploading = false;
    }
    catch (e) {
      this.utils.httpErrorResponseHandler(e, 'failure uploading file');
    }
  }

  save() {
    const query = '?rid=' + this.retailerId;
    this.rest.retailerSpec('put', this.config, query).subscribe(
      data => {
        this.config = data as Array<RetailersSpecification>;
        this.sort();
        let res: BroadcasterNotification = {
          text: 'specifications updated successfully',
          type: BroadcasterNotificationType.Success,
          action: 'OK'
        }
        this.broadcaster.broadcast('notifyUser', res);
      },
      err => this.utils.httpErrorResponseHandler(err, 'failure updating specifications')
    );
  }

  async getJobTypes() {
    this.jobTypes = await this.jobTypesService.getJobTypes();
  }
}
