import { Component, Input, OnChanges } from '@angular/core';

@Component({
    selector: 'app-retailer-embed-tag',
    templateUrl: './retailer-embed-tag.component.html',
    styleUrls: ['./retailer-embed-tag.component.scss'],
    standalone: false
})
export class RetailerEmbedTagComponent implements OnChanges {
  @Input('retailer-id') retailerId: number;
  public tag: string;
  constructor() { }

  ngOnChanges() {
    this.init();
  }

  init() {
    this.tag = `<script async type="text/javascript" src="//rc.hexa3d.io/retailer/integration?rid=${this.retailerId}"></script>`;
  }
}
