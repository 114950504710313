import { Component, OnInit, Input, OnDestroy, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { ProductResource, GifOptions, PreviewOptions } from '../product';
import { RetailersSettings, RetailersUISettings } from '../../retailer/retailer';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { UtilsService } from '../../shared/utils.service';
import { ProductService } from '../product.service';
import { Subscription } from 'rxjs';
import { RestService } from '../../communication/rest.service';
import { ProductRequestService } from '../product-request.service';
import {EndpointsService} from 'src/app/communication/endpoints.service';
import { AuthService } from 'src/app/auth/auth.service';
import { ResumableUploadService } from 'src/app/shared/resumable-upload.service';
import { CORS_TOKEN } from 'src/app/shared/utils';
import {EndPoints} from '../../communication/endpoints';
import { DEFAULT_GIF_DIM, DEFAULT_PREVIEW_DIM } from 'src/app/shared/enums';

@Component({
    selector: 'app-product-showcase',
    templateUrl: './product-showcase.component.html',
    styleUrls: ['./product-showcase.component.scss'],
    standalone: false
})
export class ProductShowcaseComponent implements OnInit, OnDestroy, OnChanges {
  @Input() resource: ProductResource;
  @Output('on-change') onChange: EventEmitter<ProductResource>;
  @Output('on-gifgen') onGifgen: EventEmitter<null>;
  // @Input('retailer-settings') retailerSettings: Array<RetailersSettings>;
  @Input('retailer-id') retailerId: number;
  private retailerSettings: Array<RetailersSettings>;
  private settings: RetailersUISettings;
  private onGifReadySub: Subscription;
  private onPreviewReadySub: Subscription;
  public genForm: UntypedFormGroup;
  public gifDownloadLink: string;
  public previewDownloadLink: string;
  // public videoDownloadLink: string;
  public isCreatePreview: boolean;
  public isCreateGif: boolean;
  // public isCreateVideo: boolean;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private utils: UtilsService,
    private productService: ProductService,
    private rest: RestService,
    private prService: ProductRequestService,
    private endpoints: EndpointsService,
    private auth: AuthService,
    private resumableUpload: ResumableUploadService
  ) {
    this.onChange = new EventEmitter<ProductResource>();
    this.onGifgen = new EventEmitter<null>();
    this.genForm = this.formBuilder.group({
      previewWidth: [DEFAULT_PREVIEW_DIM],
      previewHeight: [DEFAULT_PREVIEW_DIM],
      gifWidth: [DEFAULT_GIF_DIM],
      gifHeight: [DEFAULT_GIF_DIM]
    });
    this.onGifReadySub = this.productService.onGifReady.subscribe(
      async obj => {
        if (obj.image && obj.modelUrl &&
          this.utils.getParamFromURL(obj.modelUrl, 'load') == this.utils.getParamFromURL(this.resource.resource_default, 'load')) {
          try {
            this.resource.resource_small = await this.resumableUpload.file(this.utils.base64toFile(obj.image, 'small.gif'));
            this.isCreateGif = false;
            // this.gifDownloadLink = this.utils.getFileForDownload(this.resource.resource_small, this.resource.id + '_anumated.gif');
            this.setGifDownloadLink();
            this.onChange.next(this.resource);
            if (this.prService.gifgen) {
              this.onGifgen.next(null);
            }
          }
          catch (e) { }
          // let fileUploadRequest = {
          //   file: obj.image,
          //   filename: 'small.gif',
          //   compress: true
          // } as FileUploadRequest;
          // this.rest.cdn('post', fileUploadRequest).subscribe(
          //   data => {
          //     this.resource.resource_small = data['url'];
          //     this.isCreateGif = false;
          //     // this.gifDownloadLink = this.utils.getFileForDownload(this.resource.resource_small, this.resource.id + '_anumated.gif');
          //     this.setGifDownloadLink();
          //     this.onChange.next(this.resource);
          //     if (this.prService.gifgen) {
          //       this.onGifgen.next();
          //     }
          //   }
          // )
          // this.resource.resource_small = obj.image;
        }
      }
    );
    this.onPreviewReadySub = this.productService.onPreviewReady.subscribe(
      async obj => {
        if (obj.images && obj.modelUrl &&
          this.utils.getParamFromURL(obj.modelUrl, 'load') == this.utils.getParamFromURL(this.resource.resource_default, 'load')) {
          try {
            this.resource.resource_big = await this.resumableUpload.file(this.utils.base64toFile(obj.images[0], 'big.png'));
            this.isCreatePreview = false;
            this.previewDownloadLink = this.utils.getFileForDownload(this.resource.resource_big, this.resource.id + '_preview.png');
            this.setGifDownloadLink();
            this.onChange.next(this.resource);
          }
          catch (e) { }
          // let fileUploadRequest = {
          //   file: obj.images[0],
          //   filename: 'big.png',
          //   compress: true
          // } as FileUploadRequest;
          // this.rest.cdn('post', fileUploadRequest).subscribe(
          //   data => {
          //     this.resource.resource_big = data['url'];
          //     this.isCreatePreview = false;
          //     this.previewDownloadLink = this.utils.getFileForDownload(this.resource.resource_big, this.resource.id + '_preview.png');
          //     this.setGifDownloadLink();
          //     // this.gifDownloadLink = this.utils.getFileForDownload(this.resource.resource_small, this.resource.id + '_anumated.gif');
          //     this.onChange.next(this.resource);
          //   }
          // )
          // this.resource.resource_big = obj.images[0];
          // this.isCreatePreview = false;
        }
      }
    );
  }

  ngOnInit() {
    if (this.retailerSettings && this.retailerSettings.length) {
      this.onRetailerSettings();
      this.setDefaults();
    }
    else if (this.resource.product_id) {
      this.productService.getRetailerByRetailerId(
        this.retailerId || this.productService.getRetailerIdByResourceId(this.resource.product_id)
      ).subscribe(
        data => {
          this.retailerSettings = data.retailers_settings;
          if (this.retailerSettings && this.retailerSettings.length) {
            this.onRetailerSettings();
            this.setDefaults();
          }
        }
      )
    }
    this.setDefaults();
    // this.autoGif();
  }

  ngOnChanges(changes: SimpleChanges) {
    for (let propName in changes) {
      if (propName == 'resource')
        this.autoGif();
    }
  }

  setGifDownloadLink() {
    if (this.resource.resource_small) {
      this.gifDownloadLink = this.endpoints.getEndpointDomain(EndPoints.CMS) + '/product/resource/showcase/download/' +
        this.resource.id + '?token=' + this.auth.user.token + `${this.endpoints.isLocalhost() ? `&cors_override_key=${CORS_TOKEN}` : ''}`;
      // this.gifDownloadLink = this.utils.getFileForDownload(this.resource.resource_small, this.resource.id + '_anumated.gif');
    }
    else
      delete this.gifDownloadLink;
  }

  autoGif() {
    if (this.prService.gifgen) {
      if (this.prService.gifgenForce || !this.resource.resource_big) {
        this.createPreview(this.resource);
      }
      if (this.prService.gifgenForce || !this.resource.resource_small) {
        this.createGif(this.resource);
      }
    }
  }

  onRetailerSettings() {
    let rs = this.retailerSettings[0].settings;
    if (rs) {
      this.settings = JSON.parse(rs) as RetailersUISettings;
    }
  }

  setDefaults() {
    if (this.settings) {
      // this.previewWidth = (this.settings.preload && this.settings.preload.width) ? this.settings.preload.width : 500;
      // this.previewHeight = (this.settings.preload && this.settings.preload.height) ? this.settings.preload.height : 500;
      // this.gifWidth = (this.settings.gif && this.settings.gif.width) ? this.settings.gif.width : 200;
      // this.gifHeight = (this.settings.gif && this.settings.gif.height) ? this.settings.gif.height : 200;
      if (this.settings.preload) {
        if (this.settings.preload.width)
          this.genForm.controls['previewWidth'].setValue(this.settings.preload.width);
        if (this.settings.preload.height)
          this.genForm.controls['previewHeight'].setValue(this.settings.preload.height);
      }
      if (this.settings.gif) {
        if (this.settings.gif.width)
          this.genForm.controls['gifWidth'].setValue(this.settings.gif.width);
        if (this.settings.gif.height)
          this.genForm.controls['gifHeight'].setValue(this.settings.gif.height);
      }
    }
    if (this.resource) {
      this.setGifDownloadLink();
      // this.gifDownloadLink = this.utils.getFileForDownload(this.resource.resource_small, this.resource.id + '_anumated.gif');
      if (this.resource.resource_big)
        this.previewDownloadLink = this.utils.getFileForDownload(this.resource.resource_big, this.resource.id + '_preview.png');
      // if (this.resource.resource_video)
      //   this.videoDownloadLink = this.utils.getFileForDownload(this.resource.resource_video, this.resource.id + '_video.mp4');
    }
  }

  createPreview(resource: ProductResource) {
    if (!resource.resource_default) return;
    if (!this.genForm.controls['previewWidth'].value || !this.genForm.controls['previewHeight'].value) return;
    let options = {
      width: this.genForm.controls['previewWidth'].value,
      height: this.genForm.controls['previewHeight'].value,
      compress: true,
      create_images_by_tour: 'init_pos'
    } as PreviewOptions;
    this.productService.createPreview(resource.resource_default, options);
    // this.productService.createPreview('http://localhost:8000/public/index.html?load=/public/models/2117773971217038651/1979604609843866.fbx', options);
    this.isCreatePreview = true;
  }

  createGif(resource: ProductResource) {
    if (!resource.resource_default) return;
    if (!this.genForm.controls['gifWidth'].value || !this.genForm.controls['gifHeight'].value) return;
    let options = {
      ggwidth: this.genForm.controls['gifWidth'].value,
      ggheight: this.genForm.controls['gifHeight'].value
    } as GifOptions;
    this.productService.createGif(resource.resource_default, options);
    this.isCreateGif = true;
  }

  // createVideo(resource: ProductResource) {
  //   if (!resource.resource_small) return;
  //   this.rest.gifVideoConvert('post', {
  //     resource_id: resource.id
  //   }).subscribe((res: ProductResource) => {
  //     this.resource.resource_video = res.resource_video;
  //     this.videoDownloadLink = this.utils.getFileForDownload(this.resource.resource_video, this.resource.id + '_video.mp4');
  //     this.isCreateVideo = false;
  //   })
  //   this.isCreateVideo = true;
  // }

  ngOnDestroy() {
    this.onGifReadySub.unsubscribe();
    this.onPreviewReadySub.unsubscribe();
  }
}
