import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { RestService } from 'src/app/communication/rest.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { BroadcasterService } from 'ng-broadcaster';
import { RolesHelperService } from 'src/app/auth/roles-helper.service';
import { Subscription } from 'rxjs';
import { User } from 'src/app/auth/user';
import { BroadcasterNotification, BroadcasterNotificationType } from 'src/app/communication/broadcaster-notifications';
import { GlobalsService } from '../../shared/globals.service';
import { PlatgroundClass } from 'src/app/shared/globals';
import { NgForm } from '@angular/forms';
import { UtilsService } from 'src/app/shared/utils.service';

@Component({
    selector: 'app-recover-password',
    templateUrl: './recover-password.component.html',
    styleUrls: ['./recover-password.component.scss'],
    standalone: false
})
export class RecoverPasswordComponent implements OnInit, OnDestroy {
  private token: string;
  private rolesFetchedSubscribe: Subscription;
  public password: string;
  public msg: string;
  public inputType: string;
  @ViewChild('recoveryForm') recoveryForm: NgForm;
  constructor(
    private activatedRoute: ActivatedRoute, private rest: RestService,
    private auth: AuthService, private router: Router,
    private broadcaster: BroadcasterService, private rolesHelper: RolesHelperService,
    private globals: GlobalsService, private utils: UtilsService
  ) {
    this.inputType = 'password';
  }

  ngOnInit() {
    this.token = this.activatedRoute.snapshot.data['token'] as string;
    setTimeout(() => {
      this.globals.playgroundClass = PlatgroundClass[PlatgroundClass.SMALL].toLowerCase();
    }, 0);
  }

  toggleSP($event) {
    $event.preventDefault();
    if (this.inputType == 'password')
      this.inputType = 'text';
    else
      this.inputType = 'password';
  }

  changePassword() {
    if (!this.recoveryForm.valid) return;
    this.rest.userProfile('put', { password: this.password }, '?token=' + this.token).subscribe(
      // user => this.auth.onLoginSuccess(user as User),
      (user: User) => {
        user.token = this.token;
        this.auth.atachRolesToUser(user);
        this.auth.onLoginSuccess(user, false, false);
        // this.rolesFetchedSubscribe = this.broadcaster.on('rolesFetched').subscribe(() => {
        this.rolesFetchedSubscribe = this.rolesHelper.onRolesFetched.subscribe(() => {
          this.auth.onLoginSuccess(user, false);
          this.router.navigate(['/user-profile', user.id]);
          let data: BroadcasterNotification = {
            text: 'password updated',
            type: BroadcasterNotificationType.Success,
            action: 'OK'
          };
          this.broadcaster.broadcast('notifyUser', data);
          this.rolesFetchedSubscribe.unsubscribe();
        });
        this.rolesHelper.fetchRoles();
      },
      err => this.utils.httpErrorResponseHandler(err, 'failure setting new password')
    );
  }

  ngOnDestroy() {
    if (this.rolesFetchedSubscribe)
      this.rolesFetchedSubscribe.unsubscribe();
  }
}
