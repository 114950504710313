import { Component, OnInit, Input } from '@angular/core';
import { GlobalsService } from 'src/app/shared/globals.service';
import { AuthService } from '../auth.service';

@Component({
    selector: 'app-terms-view',
    templateUrl: './terms-view.component.html',
    styleUrls: ['./terms-view.component.scss'],
    standalone: false
})
export class TermsViewComponent implements OnInit {
  @Input() partial: boolean;
  constructor(
    private globals: GlobalsService,
    public auth: AuthService
  ) { }

  ngOnInit() {
    if (!this.partial)
      setTimeout(() => {
        this.globals.playgroundClass = null;
        // this.globals.setHeaer('Hexa Terms of Service', null);
      }, 0);
  }
}
