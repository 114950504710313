import { Component, OnInit } from '@angular/core';
import { Retailer } from 'src/app/retailer/retailer';
import { GraphqlService } from 'src/app/communication/graphql.service';
import { ScraperBatchFilterOptions, ScraperBatchRequest } from '../scraper';
import { BroadcasterNotification, BroadcasterNotificationType } from 'src/app/communication/broadcaster-notifications';
import { BroadcasterService } from 'ng-broadcaster';

@Component({
    selector: 'app-scraper-batches',
    templateUrl: './scraper-batches.component.html',
    styleUrls: ['./scraper-batches.component.scss'],
    standalone: false
})
export class ScraperBatchesComponent implements OnInit {
  public query: ScraperBatchFilterOptions;
  public items: Array<ScraperBatchRequest>;
  constructor(
    private gql: GraphqlService,
    private broadcaster: BroadcasterService
  ) {
    this.query = {} as ScraperBatchFilterOptions;
  }

  ngOnInit() {
  }

  onRetailerChange(retailer: Retailer) {
    if (retailer)
      this.query.retailer_id = retailer.id
  }

  getBatches() {
    this.gql.scraperBatch(this.query).subscribe(
      res => this.items = res.data.allProductsScraperBatchRequests.rows,
      err => {
        let data: BroadcasterNotification = {
          text: err._body ? err._body : 'failure fetching scraper batch items',
          type: BroadcasterNotificationType.Error,
          action: 'OK'
        };
        this.broadcaster.broadcast('notifyUser', data);
      }
    )
  }

  isValid(): boolean {
    return !!this.query.retailer_id;
  }
}
