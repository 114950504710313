import { Component, Input, forwardRef, Output, EventEmitter, ViewChild, ElementRef, AfterViewInit, OnChanges } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { ThreeVector3, Vector3PercentageValue } from 'asset-adjustments';

const noop = () => {
};

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => CoordinatesComponent),
  multi: true
};

@Component({
    selector: 'app-coordinates',
    templateUrl: './coordinates.component.html',
    styleUrls: ['./coordinates.component.scss'],
    providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR],
    standalone: false
})
export class CoordinatesComponent implements ControlValueAccessor, AfterViewInit, OnChanges {
  @Input() min: number;
  @Input() max: number;
  @Output('on-change') onChange: EventEmitter<Vector3PercentageValue>;
  @ViewChild('joystick') joystick: ElementRef;
  public style: any;
  public mouseDown: boolean;
  private defaultVal: Vector3PercentageValue;
  private innerValue: Vector3PercentageValue;
  private onChangeCallback: (_: any) => void = noop;
  private onTouchedCallback: () => void = noop;
  private init: boolean;
  constructor() {
    this.onChange = new EventEmitter<Vector3PercentageValue>();
    this.registerOnChange(() => {
      this.onChange.emit(this.value);
    });
  }

  ngAfterViewInit() {
    this.initialize();
  }

  ngOnChanges() {
    delete this.defaultVal;
    this.initialize();
  }

  initialize() {
    if (this.init || !this.value) return;
    this.init = true;
    if (typeof this.max !== 'number')
      this.max = 100;
    if (typeof this.min !== 'number')
      this.min = 0;
    this.getDefaultValue();
    this.setStyle();
    this.joystick.nativeElement.addEventListener('gestureend', this.onGestureEnd.bind(this), false);
  }

  //get accessor
  get value(): any {
    return this.innerValue;
  };

  //set accessor including call the onchange callback
  set value(v: any) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.onChangeCallback(v);
    }
  }

  writeValue(obj: Vector3PercentageValue): void {
    if (obj !== this.innerValue) {
      this.innerValue = obj;
    }
  }
  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  onValueChange() {
    const c = this.getDefaultValue();
    if (typeof this.value.x !== 'number' || isNaN(this.value.x))
      this.value.x = c.x;
    if (typeof this.value.y !== 'number' || isNaN(this.value.y))
      this.value.y = c.y;
    if (typeof this.value.z !== 'number' || isNaN(this.value.z))
      this.value.z = c.z;
    // if (typeof this.value.xv !== 'number')
    //   this.value.xv = this.getActualValue(c.x);
    // if (typeof this.value.yv !== 'number')
    //   this.value.yv = c.y;
    // if (typeof this.value.zv !== 'number')
    //   this.value.zv = this.getActualValue(c.z);
    // console.log(this.value.x);
    // this.value.xv = this.getActualValue(this.value.x);
    // console.log(this.value.x);
    // this.value.zv = this.getActualValue(this.value.z);
    // this.value.zy = this.value.y;
    this.onChange.emit(this.value);
    this.setStyle();
  }

  getDefaultValue(): ThreeVector3 {
    // const def = (this.max - this.min) / 2;
    // const def = 50;
    if (!this.defaultVal) {
      const def = this.getNormalizeValue(50);
      this.defaultVal = {
        x: def,
        y: def,
        z: def,
        xv: 50,
        yv: 50,
        zv: 50
      };
    }
    return this.defaultVal;
  }

  onMouseMove(event) {
    if (event.buttons === 1) {
      this.calcXZ(event);
    }
  }

  onClick(event) {
    this.calcXZ(event);
  }

  onMouseUp() {
    this.mouseDown = false;
  }

  onMouseDown() {
    this.mouseDown = true;
  }

  calcXZ(event) {
    let coords = this.value || this.getDefaultValue() as ThreeVector3;
    coords.xv = event.clientX - this.joystick.nativeElement.getBoundingClientRect().left;
    coords.zv = event.clientY - this.joystick.nativeElement.getBoundingClientRect().top;
    coords.xv = Math.min(Math.max(coords.xv, 0), 100);
    coords.zv = Math.min(Math.max(coords.zv, 0), 100);
    coords.x = this.getActualValue(coords.xv);
    coords.z = this.getActualValue(coords.zv);
    this.writeValue(coords);
    this.onValueChange();
  }

  onMouseWheel(event) {
    event.preventDefault();
    let coords = this.value || this.getDefaultValue() as ThreeVector3;
    if (event.wheelDelta / 120 > 0) {
      coords.y += this.max / 20;
    } else {
      coords.y -= this.max / 20;
    }
    coords.y = Math.min(this.max, Math.max(coords.y, this.min));
    this.writeValue(coords);
    this.onValueChange();
  }

  onGestureEnd(event) {
    event.preventDefault();
    let coords = this.value || this.getDefaultValue() as ThreeVector3;
    if (event.scale < 1.0) {
      coords.y += 1;
    }
    else if (event.scale > 1.0) {
      coords.y -= 1;
    }
    coords.y = Math.min(this.max, Math.max(coords.y, this.min));
    this.writeValue(coords);
    this.onValueChange();
  }

  getNormalizeValue(obtained: number) {
    var total = this.max + this.min;
    if (this.min < 0)
      total = this.max - this.min;
    return obtained * 100 / total;
  }

  getActualValue(obtained: number) {
    var total = this.max + this.min;
    if (this.min < 0)
      total = this.max - this.min;
    return total / 100 * (obtained - 50);
  }

  setStyle() {
    if (this.value) {
      let yv = Math.min(2.25, (1.5 * (this.getNormalizeValue(this.value.y / 100) + 1)));
      this.style = {
        // "top": this.getNormalizeValue(this.value.z) - 12 + 'px',
        // "left": this.getNormalizeValue(this.value.x) - 12 + 'px',
        // "transform": 'scale(' + (this.getNormalizeValue(this.value.y / 100) + 0.75) + ')'
        "top": (this.value.zv - 12) + 'px',
        "left": (this.value.xv - 12) + 'px',
        "transform": 'scale(' + yv + ')'
      }
    }
    else {
      this.style = {
        "top": '50px',
        "left": '50px',
        "transform": 'scale(1)'
      }
    }
  }
}
