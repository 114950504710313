import { Pipe, PipeTransform } from '@angular/core';
import { UtilsService } from './utils.service';

@Pipe({
    name: 'srcSuffix',
    standalone: false
})
export class SrcSuffixPipe implements PipeTransform {
  constructor(
    private utils: UtilsService
  ) {}

  transform(value: string, ...args: unknown[]): unknown {
    let suffixToAdd = args[0] as string,
      changeCDN = !!args[1];
    if (suffixToAdd) {
      suffixToAdd.split('&').forEach(p => {
        p = p.replace('?', '').trim();
        if (p) {
          const pArr = p.split('=');
          value = this.utils.setUrlParam(value, pArr[0], pArr[1]);
        }
      });
    }

    if (changeCDN)
      // value = value.replace('//cdn.hexa3d.io/', '//img-cdn.azureedge.net/');
      value = value.replace('//cdn.hexa3d.io/', '//himg-cdn.com/');
    return value;
  }

}
