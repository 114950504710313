<div fxLayout="row" fxLayoutAlign="space-around center" class="wrap" (click)="toggleQuickView($event)">
  <span fxFlex="100%" fxFlex.gt-sm="4">
    <app-round-checkbox [isSquare]="true" ngDefaultControl [(ngModel)]="item.UI.checked" (click)="productService.refreshSelectedCount()" [disabled]="productService.isAllSelected"
      [disabled-mode]="true" *ngIf="productService.currentPrimeStatusData && productService.currentPrimeStatusData.primeStatus != 0"></app-round-checkbox>
  </span>
  <span fxFlex="100%" fxFlex.gt-sm="12.5" class="text2 ellipsis"><ng-container *ngIf="item.products_identifiers[0]">{{item.products_identifiers[0].serial_number}}</ng-container></span>
  <span fxFlex="100%" fxFlex.gt-sm="24.5" class="text2 name ellipsis"  [matTooltip]="item.name_org_lang">{{item.name_org_lang}}</span>
  <span fxFlex="100%" fxFlex.gt-sm="9" class="text2 ellipsis">{{productService.getStatusById(item.status_id).value}}</span>
  <span fxFlex="100%" fxFlex.gt-sm="15.5" class="text2 ellipsis">
    <span *ngIf="item.retailers_categories?.length > 0">
      {{item.retailers_categories[0].category_name_org_lang}}
    </span>
  </span>
  <span fxFlex="100%" fxFlex.gt-sm="15.5" class="text2 ellipsis">
    <span *ngIf="item.retailers_sub_categories?.length > 0">
      {{item.retailers_sub_categories[0].sub_category_name_org_lang}}
    </span>
  </span>
  <span fxFlex="100%" fxFlex.gt-sm="8" class="text2 ellipsis">{{item.created_at | moment : 'format' : 'dd MMM yy'}}</span>
  <span fxFlex="100%" fxFlex.gt-sm="" class="more-options-wrap">
    <div class="more-options-inner">
      <span class="more-options orders" *ngIf="isOrders">
        <a mat-icon-button class="v-text-top" [routerLink]="['/product', item.id]">
          <i class="icon-wheel_setings icon-button-small"></i>
        </a>
        <a mat-icon-button [routerLink]="['./preview', item.id]" (click)="showRequest(item)" *ngIf="hasModel">
          <mat-icon aria-label="preview">
            remove_red_eye_outline
          </mat-icon>
        </a>
      </span>
      <span class="more-options" *ngIf="!isOrders">
        <!-- <button mat-icon-button (click)="toggleEnabled('')" class="v-text-top enabled-toggle def-dur" [ngClass]="{'contrast': item.enabled == 1}"> -->
        <i aria-label="enabled on desktop devices" class="icon-screen icon-button-small v-text-top enabled-toggle def-dur" [ngClass]="{'contrast': item.enabled == 1}"></i>
        <!-- </button> -->
        <!-- <button mat-icon-button (click)="toggleEnabled('mobile_')" class="v-text-top enabled-toggle def-dur" [ngClass]="{'contrast': item.mobile_enabled == 1}"> -->
        <i aria-label="enabled on mobile devices" class="icon-smartphone icon-button-small v-text-top enabled-toggle def-dur" [ngClass]="{'contrast': item.mobile_enabled == 1}"></i>
        <!-- </button> -->
        <a mat-icon-button class="no-toggle" [routerLink]="['/product', item.id]">
          <i class="icon-wheel_setings icon-button-small no-toggle"></i>
        </a>
      </span>
      <span class="more-options-btn">
        <button mat-icon-button class="float-op-dir">
          <mat-icon aria-label="more options">
            more_vert
          </mat-icon>
        </button>
      </span>
    </div>
  </span>
</div>
<app-product-quick-view *ngIf="item == productService.quickViewItem" [item]="item" [ngClass]="{'orders': isOrders, 'approved': !isOrders, 'exit': productService.quickViewClass == 'exit', 'enter': productService.quickViewClass == 'enter'}"></app-product-quick-view>