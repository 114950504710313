import * as i0 from '@angular/core';
import { Injectable } from '@angular/core';
class PostToChildOptions {
  stringify;
  constructor(stringify) {
    this.stringify = stringify;
  }
}
class MessagesHandlerService {
  expectedInterface = new Map();
  constructor() {
    self.addEventListener("message", event => {
      if (event.origin) {
        let refItems = this.expectedInterface.get(event.origin);
        if (!refItems) return;
        try {
          var msg = event.data;
          try {
            msg = JSON.parse(event.data);
          } catch (ex) {}
          for (let refItem of refItems) {
            if (refItem.actions.find(a => a == msg.action)) {
              if (refItem.invoke && typeof refItem.scope[refItem.invoke] === 'function') {
                if (refItem.options.apply) refItem.scope[refItem.invoke].apply(refItem.scope, [msg]);else refItem.scope[refItem.invoke](msg);
                if (refItem.options.invokeOnce) this.expectedInterface.delete(event.origin);
              }
              if (typeof refItem.onMessage === 'function') {
                refItem.onMessage(msg);
              }
            }
          }
        } catch (e) {}
      }
    }, false);
  }
  getReferrerByUrl(url) {
    var protocol = '';
    if (url.indexOf('http:') == 0) protocol = 'http:';else if (url.indexOf('https:') == 0) protocol = 'https:';else protocol = window.location.protocol;
    url = url.replace(protocol, '').replace('//', '');
    var indexOfSlash = url.indexOf('/');
    if (indexOfSlash > -1) url = url.substring(0, indexOfSlash);
    return protocol + '//' + url;
  }
  createPostMessageEvent(obj, referrer) {
    if (!this.expectedInterface.has(referrer)) this.expectedInterface.set(referrer, [obj]);else {
      let exist = this.expectedInterface.get(referrer);
      exist = exist.concat(obj);
      this.expectedInterface.set(referrer, exist);
    }
  }
  removePostMessageEvent(referrer, scope) {
    if (this.expectedInterface.has(referrer)) {
      let refItems = this.expectedInterface.get(referrer);
      let res = [];
      for (let refItem of refItems) {
        if (!refItem.scope == scope) res.push(refItem);
      }
      this.expectedInterface.set(referrer, res);
    }
  }
  onPostMessageEvent(obj, referrer) {
    obj.options = obj.options || {};
    this.createPostMessageEvent(obj, referrer);
  }
  postToChild(obj, frame, options = new PostToChildOptions(true)) {
    let iframe = null;
    if (typeof frame === 'string') iframe = document.querySelector(frame);else iframe = frame;
    if (!iframe || !iframe.contentWindow || !iframe.contentWindow.postMessage) {
      // console.warn('MessagesHandlerService.postToChild', 'iframe is undefined or not present in DOM');
      return false;
    }
    ;
    let referrer = this.getReferrerByUrl(iframe.getAttribute('src'));
    if (options.stringify) iframe.contentWindow.postMessage(JSON.stringify(obj), referrer);else iframe.contentWindow.postMessage(obj, referrer);
    return true;
  }
  static ɵfac = function MessagesHandlerService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || MessagesHandlerService)();
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: MessagesHandlerService,
    factory: MessagesHandlerService.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MessagesHandlerService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [], null);
})();

/*
 * Public API Surface of messages-handler
 */
// export * from './lib/messages-handler.module';
// export * from './lib/messages-handler';

/**
 * Generated bundle index. Do not edit.
 */

export { MessagesHandlerService, PostToChildOptions };
