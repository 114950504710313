import { Pipe, PipeTransform } from '@angular/core';
// import * as moment from 'moment';
import { format, formatDistanceToNow, formatDistance } from 'date-fns';

@Pipe({
  name: 'moment',
  standalone: false
})
export class MomentPipe implements PipeTransform {

  // transform(value: any, method: string, f: string, arg1: any): any {
  //   let date = Date.parse(value) as any;
  //   if (!isNaN(date)) {
  //     const momentAny = moment as any;
  //     switch (method) {
  //       case 'format': {
  //         return momentAny(date).format(f);
  //       }
  //       case 'fromNow': {
  //         let format = 'YYYY-MM-DD HH:mm:ss.SSS';
  //         // let format = 'YYYY-MM-DDTHH:mm:ss.SSZ';
  //         if (f)
  //           format = f;
  //         var date1 = momentAny(date).format(format);
  //         var stillUtc = moment.utc(date1).toDate();
  //         var local = momentAny(stillUtc).local().format(format);
  //         return momentAny(local).fromNow(arg1);
  //       }
  //       case 'fromNow2': {
  //         return momentAny(date).fromNow();
  //       }
  //       case 'toNow': {
  //         return momentAny(date).toNow(arg1);
  //       }
  //     }
  //     return momentAny(date).format("MMM Do YY"); //new Date(date).toUTCString();
  //   }
  //   return value;
  // }
  transform(value: any, method: string, f?: string, arg1?: any): any {
    const timestamp = Date.parse(value);
    if (!isNaN(timestamp)) {
      const date = new Date(timestamp);

      switch (method) {
        case 'format': {
          if (f) {
            try {
              return format(date, f);
            } catch (error) {
              console.error('Invalid date format string:', f);
              return value;
            }
          }
          // Default format if none provided
          return format(date, 'MMM d, yyyy');
        }

        case 'fromNow': {
          // `arg1` can be an options object for `formatDistanceToNow`
          const options = arg1 || { addSuffix: true };
          return formatDistanceToNow(date, options);
        }

        case 'fromNow2': {
          // Similar to 'fromNow' but without options
          return formatDistanceToNow(date, { addSuffix: true });
        }

        case 'toNow': {
          // `arg1` can be an options object for `formatDistance`
          const now = new Date();
          const options = arg1 || { addSuffix: true };
          return formatDistance(date, now, options);
        }

        default: {
          // Default fallback formatting
          return format(date, 'MMM d, yyyy');
        }
      }
    }

    // If the date is invalid, return the original value
    return value;
  }

}
