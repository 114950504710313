import { Component, Input, OnChanges, ChangeDetectionStrategy, SimpleChanges } from '@angular/core';
import { TexturedMaterial, KeyValuePair, EnumsService, ITextureAnimatorOptions, AssetAdjustmentsService, MapAdjustmentOption } from 'asset-adjustments';
import { MatSelectChange } from '@angular/material/select';

@Component({
    selector: 'app-texture-map',
    templateUrl: './texture-map.component.html',
    styleUrls: ['./texture-map.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class TextureMapComponent implements OnChanges {
  public counter: number;
  public currentTextureName: string;
  public currentVideoTextureSrc: string;
  public currentAnimatedTextureName: string;
  public currentImage: string;
  public manipulation: number;
  public manipulating: boolean;
  public minificationFilters: Array<KeyValuePair>;
  private hasInit: boolean;
  // @Input('textured-material') texturedMaterial: TexturedMaterial;
  // @Input('textures-with-images') texturesWithImages: { [id: string]: TexturedMaterial; };
  @Input('changes') changes: number;
  // @Input('images') images: Array<string>;
  @Input('type') type: string;
  @Input('mapping-changed') mappingChanged: boolean;
  @Input() intensity: { [id: string]: MapAdjustmentOption };
  @Input('image-manipulation') imageManipulation: boolean;
  // @Input('video-textures-srcs') videoTexturesSrcs: Array<string>;
  // @Output('on-texture-change') onTextureChange: EventEmitter<TexturedMaterial>;
  // @Output('on-texture-remove') onTextureRemove: EventEmitter<string>;
  // @Output('on-texture-restore') onTextureRestore: EventEmitter<string>;
  // @Output('on-material-props') onMaterialProps: EventEmitter<Array<any>>;
  // @Output('on-texture-props') onTextureProps: EventEmitter<Array<any>>;
  @Input('is-open') isOpen: boolean;

  constructor(
    private enums: EnumsService,
    public assetAdjustmentsService: AssetAdjustmentsService
  ) {
    this.counter = 0;
    // this.onTextureChange = new EventEmitter<TexturedMaterial>();
    // this.onTextureRemove = new EventEmitter<string>();
    // this.onTextureRestore = new EventEmitter<string>();
    // // this.onMaterialProps = new EventEmitter<Array<any>>();
    // this.onTextureProps = new EventEmitter<Array<any>>();
    this.minificationFilters = this.enums.getMinificationFilters();
  }

  init(force = false) {
    if (!force && this.hasInit) return;
    this.hasInit = true;
    this.setCurrentTextureName();
    this.setCurrentIntensity();
    this.setCurrentImage();
    this.setManipulation();
    this.setAnimatedTexture();
    // this.fixIntensity();
  }

  // ngOnInit() {
  //   this.setCurrentTextureName();
  //   this.setCurrentIntensity();
  //   this.setCurrentImage();
  //   this.setManipulation();
  //   this.setAnimatedTextureChange();
  // }

  async ngOnChanges(changes: SimpleChanges) {
    if (!this.isOpen)
      return;
    this.init();
    if (!this.assetAdjustmentsService.activeTextures) return;
    // let existingImages = Object.keys(this.assetAdjustmentsService.activeTextures).map(t => this.assetAdjustmentsService.activeTextures).map(t => t.src) as any;
    const existingImages = Object.values(this.assetAdjustmentsService.activeTextures).map(t => t.src);
    for (let i = 0; i < this.assetAdjustmentsService.texturesSrcs.length; i++) {
      if (!existingImages.find(s => s == this.assetAdjustmentsService.texturesSrcs[i])) {
        let texture = { src: this.assetAdjustmentsService.texturesSrcs[i], name: i.toString(), type: 'unmapped' } as any;
        this.assetAdjustmentsService.activeTextures[i.toString()] = texture;
        this.assetAdjustmentsService.texturesWithImages[i.toString()] = texture;
      }
    }
    await this.assetAdjustmentsService.createPreview();
    if (!this.assetAdjustmentsService.texturedMaterialsMap[this.type]) {
      this.assetAdjustmentsService.texturedMaterialsMap[this.type] = {
        type: this.type,
        intensity: this.assetAdjustmentsService.texturedMaterials[this.assetAdjustmentsService.materials[this.assetAdjustmentsService.currentMaterialIndex].name][this.type]?.intensity
      } as TexturedMaterial;
    }
    if (changes.changes) {
      this.counter++;
      // this.manipulating = false;
      setTimeout(() => this.manipulating = false);
    }
    this.init(true);
    // this.setCurrentTextureName();
    // this.setCurrentIntensity();
    // this.setCurrentImage();
    // this.setManipulation();
    // this.setAnimatedTextureChange();
  }

  setCurrentTextureName() {
    this.currentTextureName = null;
    this.currentVideoTextureSrc = null;
    if (this.assetAdjustmentsService.texturedMaterialsMap[this.type]) {
      for (let i in this.assetAdjustmentsService.activeTextures) {
        if (this.assetAdjustmentsService.texturedMaterialsMap[this.type].name == this.assetAdjustmentsService.activeTextures[i].name) {
          this.currentTextureName = this.assetAdjustmentsService.activeTextures[i].name;
          this.currentVideoTextureSrc = this.assetAdjustmentsService.texturedMaterialsMap[this.type].videoSrc;
          break;
        }
      }
    }
    else this.assetAdjustmentsService.texturedMaterialsMap[this.type] = {
      type: this.type
    } as TexturedMaterial;
    if (!this.currentTextureName)
      this.currentTextureName = `_${this.type}`;
  }

  setCurrentIntensity() {
    if (this.assetAdjustmentsService.activeTextures && this.assetAdjustmentsService.activeTextures[this.currentTextureName] && typeof this.assetAdjustmentsService.activeTextures[this.currentTextureName].intensity !== 'number' && this.intensity[this.type] && this.intensity[this.type].defaultValue) {
      this.assetAdjustmentsService.activeTextures[this.currentTextureName].intensity = this.intensity[this.type].defaultValue;
      if (this.type == 'normalMap' && this.assetAdjustmentsService.activeTextures[this.currentTextureName].normalScale)
        this.assetAdjustmentsService.activeTextures[this.currentTextureName].intensity = this.assetAdjustmentsService.activeTextures[this.currentTextureName].normalScale.x;
    }
  }

  setManipulation() {
    if (this.assetAdjustmentsService.activeTextures && this.assetAdjustmentsService.activeTextures[this.currentTextureName] && this.assetAdjustmentsService.activeTextures[this.currentTextureName].material) {
      switch (this.type) {
        case 'roughnessMap': {
          this.manipulation = this.getValOrDefault(this.assetAdjustmentsService.activeTextures[this.currentTextureName].material.roughnessManipulation, this.intensity[this.type].defaultManipulationValue);
          break;
        }
        case 'metalnessMap': {
          this.manipulation = this.getValOrDefault(this.assetAdjustmentsService.activeTextures[this.currentTextureName].material.metalnessManipulation, this.intensity[this.type].defaultManipulationValue);
          break;
        }
        case 'aoMap': {
          this.manipulation = this.getValOrDefault(this.assetAdjustmentsService.activeTextures[this.currentTextureName].material.aoManipulation, this.intensity[this.type].defaultManipulationValue);
          break;
        }
        case 'normalMap': {
          this.manipulation = this.getValOrDefault(this.assetAdjustmentsService.activeTextures[this.currentTextureName].material.normalManipulation, this.intensity[this.type].defaultManipulationValue);
          break;
        }
        case 'map': {
          this.manipulation = this.getValOrDefault(this.assetAdjustmentsService.activeTextures[this.currentTextureName].material.mapManipulation, this.intensity[this.type].defaultManipulationValue);
          break;
        }
        case 'emissiveMap': {
          this.manipulation = this.getValOrDefault(this.assetAdjustmentsService.activeTextures[this.currentTextureName].material.emissiveManipulation, this.intensity[this.type].defaultManipulationValue);
          break;
        }
      }
    }
  }

  getValOrDefault(val: number, def: number): number {
    if (typeof val === 'number')
      return val;
    return def;
  }

  minFilterChange() {
    let tt = this.assetAdjustmentsService.texturedMaterialsMap[this.type] as any;
    this.assetAdjustmentsService.setTextureProps(tt, 'minFilter', this.assetAdjustmentsService.texturedMaterialsMap[this.type].material[this.assetAdjustmentsService.texturedMaterialsMap[this.type].type].minFilter, true);
    // // this.onMaterialProps.emit([this.texturedMaterial, 'minFilter', this.texturedMaterial.material[this.texturedMaterial.type].minFilter])
    // this.onTextureProps.emit([this.texturedMaterial, 'minFilter', this.texturedMaterial.material[this.texturedMaterial.type].minFilter, true]);
  }

  textureChange(isVideo?: boolean) {
    this.manipulating = true;
    // this.activeTextures[this.currentTextureName].srcChange = true;
    // if (this.activeTextures[this.currentTextureName]) {
    //   this.activeTextures[this.currentTextureName].type = this.type;
    //   if (typeof this.texturedMaterial.intensity === 'number')
    //     this.activeTextures[this.currentTextureName].intensity = this.texturedMaterial.intensity;
    //   if (this.intensity[this.texturedMaterial.type].hasColor && this.texturedMaterial.color)
    //     this.activeTextures[this.currentTextureName].color = this.texturedMaterial.color;
    //   if (typeof this.texturedMaterial.flipY === 'boolean')
    //     this.activeTextures[this.currentTextureName].flipY = this.texturedMaterial.flipY;
    //   if (isVideo)
    //     this.activeTextures[this.currentTextureName].videoSrc = this.currentVideoTextureSrc;
    //   else
    //     delete this.activeTextures[this.currentTextureName].videoSrc;
    //   this.onTextureChange.emit(this.activeTextures[this.currentTextureName]);
    // }
    // else
    //   this.onTextureChange.emit(null);
    this.assetAdjustmentsService.activeTextures[this.currentTextureName].srcChange = true;
    if (this.assetAdjustmentsService.activeTextures[this.currentTextureName]) {
      this.assetAdjustmentsService.activeTextures[this.currentTextureName].type = this.type;
      if (typeof this.assetAdjustmentsService.texturedMaterialsMap[this.type].intensity === 'number')
        this.assetAdjustmentsService.activeTextures[this.currentTextureName].intensity = this.assetAdjustmentsService.texturedMaterialsMap[this.type].intensity;
      // if (typeof this.assetAdjustmentsService.texturedMaterialsMap[this.type].flipY === 'boolean' && this.assetAdjustmentsService.activeTextures[this.currentTextureName])
      //   this.assetAdjustmentsService.activeTextures[this.currentTextureName].flipY = this.assetAdjustmentsService.texturedMaterialsMap[this.type].flipY;
      if (isVideo)
        this.assetAdjustmentsService.activeTextures[this.currentTextureName].videoSrc = this.currentVideoTextureSrc;
      else
        delete this.assetAdjustmentsService.activeTextures[this.currentTextureName].videoSrc;
      if (this.assetAdjustmentsService.activeTextures)
        this.assetAdjustmentsService.activeTextures[this.currentTextureName].animatedTexture = this.assetAdjustmentsService.texturedMaterialsMap[this.type].animatedTexture;
      // this.onTextureChange.emit(this.activeTextures[this.currentTextureName]);
      this.assetAdjustmentsService.onTextureChange(this.assetAdjustmentsService.activeTextures[this.currentTextureName]);
    }
    else
      this.assetAdjustmentsService.onTextureChange(null);
    this.setCurrentImage();
  }

  clearTexture() {
    this.assetAdjustmentsService.deleteTexture(this.assetAdjustmentsService.materials[this.assetAdjustmentsService.currentMaterialIndex].name, this.type);
  }

  upscaleTexture() {
    const options = {
      'map': this.type,
      'upscaleFactor': 2
    } as any;
    this.assetAdjustmentsService.refreshMaterials = true;
    this.assetAdjustmentsService.refreshTextures = true;
    this.assetAdjustmentsService.postToChild('mapTextureToMaterial', {
      'texture': null, 'material': this.assetAdjustmentsService.materials[this.assetAdjustmentsService.currentMaterialIndex].name, "options": options
    });
    this.assetAdjustmentsService.counter++;
    this.assetAdjustmentsService.mappingChanged = true;
  }

  // restoreTexture() {
  //   // requestAnimationFrame(() => {
  //   //   requestAnimationFrame(() => {
  //   //     if (typeof this.texturedMaterial.intensity === 'number') {
  //   //       this.texturedMaterial.intensity = 1;
  //   //       this.intensityChange();
  //   //       requestAnimationFrame(() => {
  //   //         if (typeof this.texturedMaterial.intensity === 'number') {
  //   //           this.texturedMaterial.intensity = 1;
  //   //           this.intensityChange();
  //   //         }
  //   //       });
  //   //     }
  //   //   });
  //   // });
  //   if (typeof this.texturedMaterial.intensity === 'number')
  //     this.texturedMaterial.intensity = 1;
  //   this.onTextureRestore.emit(this.type);
  // }

  // setAnimatedTextureChange() {
  //   if (this.texturedMaterial)
  //     this.animatedTexture = !!this.texturedMaterial.animatedTexture;
  // }

  // animatedTextureChange() {
  //   if (this.texturedMaterial) {
  //     this.texturedMaterial.animatedTexture = this.animatedTexture ? {} as ITextureAnimatorOptions : null
  //     this.intensityChange(false);
  //   }
  // }

  intensityChange(manipulating: boolean) {
    this.manipulating = manipulating;
    // this.activeTextures[this.currentTextureName].type = this.type;
    if (!this.currentTextureName || !this.assetAdjustmentsService.activeTextures || !this.assetAdjustmentsService.activeTextures[this.currentTextureName])
      this.initTexturesWithImages();
    else {
      if (this.assetAdjustmentsService.texturedMaterialsMap[this.type].map) {
        if (this.assetAdjustmentsService.texturedMaterialsMap[this.type].type == 'roughnessMap')
          this.assetAdjustmentsService.texturedMaterialsMap[this.type].map['roughness'] = this.assetAdjustmentsService.texturedMaterialsMap[this.type].intensity;
        else if (this.assetAdjustmentsService.texturedMaterialsMap[this.type].type == 'metalnessMap')
          this.assetAdjustmentsService.texturedMaterialsMap[this.type].map['metalness'] = this.assetAdjustmentsService.texturedMaterialsMap[this.type].intensity;
        else if (this.assetAdjustmentsService.texturedMaterialsMap[this.type].type == 'emissiveMap')
          this.assetAdjustmentsService.texturedMaterialsMap[this.type].map['emissiveIntensity'] = this.assetAdjustmentsService.texturedMaterialsMap[this.type].intensity;
        else if (this.assetAdjustmentsService.texturedMaterialsMap[this.type].type == 'normalMap') {
          if (!this.assetAdjustmentsService.texturedMaterialsMap[this.type].map.normalScale)
            this.assetAdjustmentsService.texturedMaterialsMap[this.type].map.normalScale = { x: 1, y: 1 };
          this.assetAdjustmentsService.texturedMaterialsMap[this.type].map.normalScale.x = this.assetAdjustmentsService.texturedMaterialsMap[this.type].intensity;
          this.assetAdjustmentsService.texturedMaterialsMap[this.type].map.normalScale.y = this.assetAdjustmentsService.texturedMaterialsMap[this.type].intensity;
        }
        // else if (this.texturedMaterial.type == 'reflectivity')
        //   this.texturedMaterial.map['reflectivity'] = this.texturedMaterial.intensity;
        else
          this.assetAdjustmentsService.texturedMaterialsMap[this.type].map[this.type + 'Intensity'] = this.assetAdjustmentsService.texturedMaterialsMap[this.type].intensity;
      }
    }
    // const mapName = this.currentTextureName || this.type;
    let texture = null as TexturedMaterial;
    if (!this.currentTextureName || !this.assetAdjustmentsService.activeTextures[this.currentTextureName]) {
      texture = {
        type: this.type,
        intensity: this.assetAdjustmentsService.texturedMaterials[this.assetAdjustmentsService.materials[this.assetAdjustmentsService.currentMaterialIndex].name][this.type]?.intensity
      } as TexturedMaterial;
    }
    else
      texture = this.assetAdjustmentsService.activeTextures[this.currentTextureName];
    if (this.intensity[this.type].hasColor && this.assetAdjustmentsService.texturedMaterialsMap[this.type].color && this.currentTextureName)
      texture.color = this.assetAdjustmentsService.texturedMaterialsMap[this.type].color;
    // if (typeof this.assetAdjustmentsService.texturedMaterialsMap[this.type].flipY === 'boolean')
    //   this.assetAdjustmentsService.texturedMaterialsMap[this.type][this.currentTextureName].flipY = this.assetAdjustmentsService.texturedMaterialsMap[this.type].flipY;
    // this.assetAdjustmentsService.texturedMaterialsMap[this.type][this.currentTextureName].intensity = this.assetAdjustmentsService.texturedMaterialsMap[this.type].intensity;
    texture.intensity = this.assetAdjustmentsService.texturedMaterialsMap[this.type].intensity;
    // this.activeTextures[this.currentTextureName].manipulation = this.texturedMaterial.manipulation;
    switch (this.type) {
      case 'roughnessMap': {
        texture.roughnessManipulation = this.manipulation;
        break;
      }
      case 'metalnessMap': {
        texture.metalnessManipulation = this.manipulation;
        break;
      }
      case 'aoMap': {
        texture.aoManipulation = this.manipulation;
        break;
      }
      case 'normalMap': {
        texture.normalManipulation = this.manipulation;
        break;
      }
      case 'map': {
        texture.mapManipulation = this.manipulation;
        break;
      }
      case 'emissiveMap': {
        texture.emissiveManipulation = this.manipulation;
        break;
      }
    }
    texture.relatedType = this.type;
    // this.onTextureChange.emit(this.assetAdjustmentsService.texturedMaterialsMap[this.type][this.currentTextureName]);
    this.assetAdjustmentsService.onTextureChange(texture);
  }

  addToNormal(intensity: number, channel: number) {
    this.initTexturesWithImages();
    let obj = JSON.parse(JSON.stringify(this.assetAdjustmentsService.activeTextures[this.currentTextureName]));
    obj.channel = channel;
    obj.intensity = intensity;
    // this.onTextureChange.emit(obj);
    this.assetAdjustmentsService.onTextureChange(obj);
  }

  setCurrentImage() {
    this.initTexturesWithImages();
    this.currentImage = this.assetAdjustmentsService.activeTextures && this.assetAdjustmentsService.activeTextures[this.currentTextureName] ? this.assetAdjustmentsService.activeTextures[this.currentTextureName].src : null;
  }

  toggleFlipY() {
    this.assetAdjustmentsService.texturedMaterialsMap[this.type].toggleFlipY = true;
    this.assetAdjustmentsService.texturedMaterialsMap[this.type].flipY = !this.assetAdjustmentsService.texturedMaterialsMap[this.type].flipY;
    this.intensityChange(false);
    delete this.assetAdjustmentsService.texturedMaterialsMap[this.type].toggleFlipY;
  }

  initTexturesWithImages() {
    if (this.assetAdjustmentsService.activeTextures) {
      if (!this.assetAdjustmentsService.activeTextures[this.currentTextureName]) {
        if (this.assetAdjustmentsService.texturedMaterials[this.assetAdjustmentsService.materials[this.assetAdjustmentsService.currentMaterialIndex].name][this.type])
          this.assetAdjustmentsService.texturedMaterialsMap[this.type].intensity = this.assetAdjustmentsService.texturedMaterials[this.assetAdjustmentsService.materials[this.assetAdjustmentsService.currentMaterialIndex].name][this.type].intensity;
        else
          this.assetAdjustmentsService.texturedMaterialsMap[this.type].intensity = this.intensity[this.type].defaultIntensity
        this.assetAdjustmentsService.activeTextures[this.currentTextureName] = {
          type: this.type,
          intensity: this.assetAdjustmentsService.texturedMaterialsMap[this.type].intensity
        } as TexturedMaterial;
        this.setManipulation();
      }
    }
  }

  setAnimatedTexture() {
    if (this.assetAdjustmentsService.texturedMaterialsMap[this.type]) {
      // this.animatedTexture = !!this.assetAdjustmentsService.texturedMaterialsMap[this.type].animatedTexture;
      if (!this.assetAdjustmentsService.animatedState[this.assetAdjustmentsService.materials[this.assetAdjustmentsService.currentMaterialIndex].name])
        this.assetAdjustmentsService.animatedState[this.assetAdjustmentsService.materials[this.assetAdjustmentsService.currentMaterialIndex].name] = {};
      if (this.assetAdjustmentsService.texturedMaterialsMap[this.type] && this.assetAdjustmentsService.animatedState[this.assetAdjustmentsService.materials[this.assetAdjustmentsService.currentMaterialIndex].name][this.type]) {
        for (let i in this.assetAdjustmentsService.activeTextures) {
          if (this.assetAdjustmentsService.texturedMaterialsMap[this.type].animatedTexture) {
            if (this.assetAdjustmentsService.texturedMaterialsMap[this.type].animatedTexture.name == this.assetAdjustmentsService.activeTextures[i].name || this.assetAdjustmentsService.texturedMaterialsMap[this.type].animatedTexture.image == this.assetAdjustmentsService.activeTextures[i].src) {
              this.currentAnimatedTextureName = this.assetAdjustmentsService.activeTextures[i].name;
              this.assetAdjustmentsService.texturedMaterialsMap[this.type].animatedTexture.image = this.assetAdjustmentsService.activeTextures[i].src;
              break;
            }
          }
        }
      }
    }
  }

  animatedTextureChange(event: MatSelectChange) {
    if (this.assetAdjustmentsService.texturedMaterialsMap[this.type]) {
      this.assetAdjustmentsService.texturedMaterialsMap[this.type].animatedTexture = this.assetAdjustmentsService.animatedState[this.assetAdjustmentsService.materials[this.assetAdjustmentsService.currentMaterialIndex].name][this.type] ? {
        image: this.assetAdjustmentsService.activeTextures[event.value].src
      } as ITextureAnimatorOptions : null;
      // this.assetAdjustmentsService.texturedMaterialsMap[this.type].animatedTexture.image = this.assetAdjustmentsService.activeTextures[event.value].src;
      let currentTextureName = this.currentTextureName;
      this.currentTextureName = this.currentAnimatedTextureName;
      // this.texturedMaterial.srcChange = true;
      this.setAnimatedTexture();
      // this.intensityChange(false);
      this.textureChange();
      this.currentTextureName = currentTextureName;
    }
  }
}
