import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, AbstractControlOptions } from '@angular/forms';
import { AuthService } from 'src/app/auth/auth.service';
import { Credentials, User } from '../user';
import { TermsComponent } from 'src/app/auth/terms/terms.component';
import { MatDialog } from '@angular/material/dialog';
import { RestService } from 'src/app/communication/rest.service';
import { GlobalsService } from '../../shared/globals.service';
import { PlatgroundClass } from 'src/app/shared/globals';
import { BroadcasterNotification, BroadcasterNotificationType } from '../../communication/broadcaster-notifications';
import { BroadcasterService } from 'ng-broadcaster';
import { PrivacyComponent } from '../privacy/privacy.component';
import { UtilsService } from 'src/app/shared/utils.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { QrViewComponent } from '../qr-view/qr-view.component';
import { RecaptchaService } from 'src/app/shared/recaptcha.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    standalone: false
})
export class LoginComponent implements OnInit {
  public loginForm: UntypedFormGroup;
  public fpForm: UntypedFormGroup;
  public screen: string;
  public fPmeg: string;
  public qrImg;
  private token: string;
  public isShowPassword: boolean;
  public backStack: Array<string> = [];
  public enforceOldPass: boolean;
  //public screens = ['signup','login','forgotpassword','reset','update','qr','qrcode','mobile'];
  constructor(
    private formBuilder: UntypedFormBuilder,
    private auth: AuthService,
    private dialog: MatDialog,
    private rest: RestService,
    private globals: GlobalsService,
    private broadcaster: BroadcasterService,
    private sanitizer: DomSanitizer,
    private recaptchaService: RecaptchaService,
    private activatedRoute: ActivatedRoute,
    private utils: UtilsService
  ) {
    this.screen = this.activatedRoute.snapshot.url[0].path;
    this.backStack.push(this.screen);

    this.enforceOldPass = this.activatedRoute.snapshot.params.enforce === 'true';

    this.loginForm = this.formBuilder.group({
      email: [null, Validators.email],
      password: [null, Validators.required],
      confirm_password: null,
      old_password: (this.screen === 'reset' && this.enforceOldPass) ? [null, Validators.required] : null,
      rememberMe: true,
      code: null,
      name: ''
    }, { validator: this.checkPasswords.bind(this) } as AbstractControlOptions

    );

    let email = this.activatedRoute.snapshot.params.email;
    let name = this.activatedRoute.snapshot.params.name;
    this.token = this.activatedRoute.snapshot.params.token;
    this.loginForm.controls.email.setValue(email);
    if (name) {
      this.loginForm.controls.name.setValue(name);
    }

    this.fpForm = this.formBuilder.group({
      email: [null, Validators.email]
    });

    if (utils.isMobile.any() && (this.screen === 'signup' || this.screen === 'update')) {
      this.screen = 'mobile';
    }
    if (this.screen === 'reset' || this.screen === 'update' || this.screen === 'signup') {
      this.rest.userProfile('get', null, '/token' + '?token=' + this.token).subscribe(
        (result: User) => {
        },
        err => {
          let txt = 'This link is expired, please sign-in or start the reset process again.';
          if (this.screen === 'signup')
            txt = 'You are already signed-up, please sign-in.';

          this.token = null;
          this.screen = 'login';
          this.backStack = ['login'];
          let obj: BroadcasterNotification = {
            text: txt,
            type: BroadcasterNotificationType.Error,
            action: 'OK'
          };
          this.broadcaster.broadcast('notifyUser', obj);

        }
      );
    }
    else if (this.auth.ssoState) {
      if (this.auth.ssoState.sso_token) {
        if (this.auth.ssoState.qr_image) {
          this.screen = 'qr';
          this.setQrImg(this.auth.ssoState.qr_image);
        } else this.screen = 'qrcode';
      }
      else if (this.auth.ssoState.token) {
        this.onToken();
      }
    }
  }

  private setQrImg(src: string) {
    this.qrImg =
      src.indexOf('https://') === 0
        ? src
        : this.sanitizer.bypassSecurityTrustResourceUrl(
            `data:image/jpg;base64,${src}`
          );
  }

  async onToken() {
    this.auth.storeToken(this.auth.ssoState.token);
    this.auth.userProfile(this.auth.ssoState.user_id).subscribe((user: User) => {
      this.auth.user = user;
      this.auth.onLoginSuccess(this.auth.user);
    });
  }

  checkPasswords(group: UntypedFormGroup) {
    if (this.screen === 'login') {

      this.setDisabledState(true, group.controls['confirm_password']);
      return true;
    }
    return group.controls['password'].value == group.controls['confirm_password'].value ? null : { notSame: true };;
  }

  setDisabledState?(isDisabled: boolean, control): void {
    if (isDisabled == control.disabled) return;
    isDisabled ? control.disable() : control.enable();
    control.updateValueAndValidity();
  }

  ngOnInit() {
    setTimeout(() => {
      this.globals.playgroundClass = PlatgroundClass[PlatgroundClass.SMALL].toLowerCase();
    }, 0);
  }

  async login() {
    if (!this.loginForm.valid) return;
    let c = {
      email: this.loginForm.controls['email'].value,
      password: this.loginForm.controls['password'].value
    } as Credentials;

    this.recaptchaService.execute('login')
      .then(async (token: string) => {
        if (token)
          var result: User = await this.auth.login('PUT', c, '?g-recaptcha-response=' + token);
        if (result.qr) {
          this.screen = 'qr';
          this.setQrImg(result.qr);
        } else {
          this.screen = 'qrcode';
        }
        this.backStack.push(this.screen);
      });

  }

  signup() {
    if (!this.loginForm.valid) return;
    this.rest.userProfile('put', {
      password: this.loginForm.controls.password.value,
      old_password: this.loginForm.controls.old_password.value,
    }, '?token=' + this.token).subscribe(
      (user: User) => {
        if (user.token)
          this.token = user.token;
        this.login();
      },
      err => this.utils.httpErrorResponseHandler(err, 'failure setting new password')
    );
  }

  toggleFP(state: string) {
    if(this.screen === 'forgotpassword') return;
    if (this.screen != 'signup' && this.screen != 'reset')
      this.screen = state;
      this.backStack.push(state);
  }

  showTerms() {
    this.dialog.open(TermsComponent, {
    });
  }

  showPrivacy() {
    window.open('https://cms.hexa3d.io/privacy','_blank');
    // this.dialog.open(PrivacyComponent, {
    // });
  }

  qrPopup(num) {
    this.dialog.open(QrViewComponent, {
      width: '552px',
      //  height: '229px',
      data: {
        num: num ?? 1,
        email: this.loginForm.controls['email'].value,
        password: this.loginForm.controls['password'].value,
        sso_token: this.auth.ssoState?.sso_token
      }
    });
  }

  resetPassword() {
    if (!this.fpForm.valid) return;
    this.recaptchaService.execute('passwordRecovery')
      .then((token: string) => {
        if (this.fpForm.controls['email'].value && token)
          this.sendRecoveryMail(this.fpForm.controls['email'].value, token);
      });
  }

  sendRecoveryMail(email, token) {
    this.rest.passwordRecovery('?email=' + email + '&g-recaptcha-response=' + token).subscribe(
      () => this.recoveryNotification(),
      err => this.recoveryNotification()
    );
  }

  recoveryNotification() {
    this.fPmeg = 'Recovery e-mail has been sent, if we found an account associated with ' + this.fpForm.controls['email'].value;
    let obj: BroadcasterNotification = {
      text: this.fPmeg,
      type: BroadcasterNotificationType.Success,
      action: 'OK'
    };
    this.broadcaster.broadcast('notifyUser', obj);
  }

  confirmReset() {
    this.rest.userProfile('put', null, '/qr' + '?token=' + this.token).subscribe(
      (result: User) => {
        this.screen = 'qr';
        this.backStack.push(this.screen);
        this.setQrImg(result.qr);
      },
      err => this.utils.httpErrorResponseHandler(err, 'failure saving profile')
    );
  }

  enterCode() {
    this.screen = 'qrcode';
    this.backStack.push(this.screen);
  }

  async sendCode() {
    let payload = {
      mfa_code: this.loginForm.controls['code'].value.toString(),
      email: this.loginForm.controls['email'].value,
      password: this.loginForm.controls['password'].value,
      sso_token: this.auth.ssoState?.sso_token,
    }

    if (this.token) {
      this.rest.userProfile('post', payload, '/qr' + '?token=' + this.token).subscribe(
        (result: User) => {
          this.auth.atachRolesToUser(result);
          this.auth.onLoginSuccess(result as User, true, true, this.loginForm.controls['rememberMe'].value);
        },
        err => this.utils.httpErrorResponseHandler(err, 'failure saving profile')
      );
    } else {

      try {
        var result: User = await this.auth.login('POST', payload);
      } catch (err) {
        if (this.screen === 'qrcode')
          err = 'Code is not valid.';
        let data: BroadcasterNotification = {
          text: err,
          type: BroadcasterNotificationType.Error,
          action: 'OK'
        }
        this.broadcaster.broadcast('notifyUser', data);
        return;
      }
      this.auth.atachRolesToUser(result);
      this.auth.onLoginSuccess(result as User, true, true, this.loginForm.controls['rememberMe'].value);
    }
  }

  isPasswordsMatch(): boolean {
    this.loginForm.controls['password'].value == this.loginForm.controls['confirm_password'].value;
    return this.loginForm.controls['password'].value == this.loginForm.controls['confirm_password'].value;
  }

  toggShowPassword() {
    this.isShowPassword = !this.isShowPassword;
  }

  goBack() {
    let b = this.backStack;
    if (b.length > 0) {
      b.pop(); 
        this.screen = b[b.length - 1]; 
    }
  }

  // goto(s){
  //   this.screen = s;
  // }
}