import { Component, OnInit } from '@angular/core';
import { RestService } from '../../communication/rest.service';
import { BroadcasterService } from 'ng-broadcaster';
import { GlobalsService } from '../../shared/globals.service';
import { Retailer, RetailerFilterOptions } from '../../retailer/retailer';
import { User } from '../user';
import { GraphqlService } from '../../communication/graphql.service';

@Component({
    selector: 'app-users-management',
    templateUrl: './users-management.component.html',
    styleUrls: ['./users-management.component.scss'],
    standalone: false
})
export class UsersManagementComponent implements OnInit {
  public retailer: Retailer;
  public users: Array<User>;
  constructor(
    private rest: RestService,
    private broadcaster: BroadcasterService,
    private globals: GlobalsService,
    private gql: GraphqlService
  ) {
    this.users = [];
  }

  ngOnInit() {
    setTimeout(() => {
      this.globals.setHeaer('Users Management', 'select a retailer');
      this.globals.setBreadcrumbs();
      this.globals.playgroundClass = null;
    }, 0);
  }

  onRetailerChange(retailer: Retailer) {
    if (retailer) {
      this.retailer = retailer;
      this.globals.setHeaer('Users Management', this.retailer.name);
      this.globals.setBreadcrumbs();
      const options = {
        id: this.retailer.id
      } as RetailerFilterOptions;
      // let options = {};
      // options['id'] = this.retailer.id;
      this.gql.retailerForManage(options).subscribe(
        obj => {
          this.users = [];
          for (let ru of obj.data.retailers.retailers_users) {
            for (let u of ru.users) {
              this.users.push(u);
            }  
          }
        }
      )
    }
  }
}
