import { Component, Input, OnChanges } from '@angular/core';
import { RetailersRendersSettings } from '../retailer';
import { RestService } from 'src/app/communication/rest.service';
import { BroadcasterService } from 'ng-broadcaster';
import { BroadcasterNotification, BroadcasterNotificationType } from 'src/app/communication/broadcaster-notifications';
import { UtilsService } from 'src/app/shared/utils.service';
import { KeyValuePair } from 'src/app/shared/enums';
import { EnumsService } from 'src/app/shared/enums.service';

@Component({
    selector: 'app-retailers-renders-settings',
    templateUrl: './retailers-renders-settings.component.html',
    styleUrls: ['./retailers-renders-settings.component.scss'],
    standalone: false
})
export class RetailersRendersSettingsComponent implements OnChanges {
  @Input() config: Array<RetailersRendersSettings>;
  @Input('retailer-id') retailerId: number;
  public positions: Array<KeyValuePair>
  constructor(
    private rest: RestService,
    private utils: UtilsService,
    private broadcaster: BroadcasterService,
    private enums: EnumsService
  ) {
    this.positions = this.enums.getProductPositions();
  }

  ngOnChanges() {
    if (!this.config)
      this.config = [];
    else {
      this.config = this.utils.deepCopyByValue(this.config);
    }
    if (!this.config.length)
      this.config[0] = {} as RetailersRendersSettings;
  }

  save() {
    let query = '?rid=' + this.retailerId;
    this.rest.retailerRenderSetting('put', this.config[0], query).subscribe(
      (res: RetailersRendersSettings) => {
        let data: BroadcasterNotification = {
          text: 'renders settings updated successfully',
          type: BroadcasterNotificationType.Success,
          action: 'OK'
        }
        this.broadcaster.broadcast('notifyUser', data);
        this.config[0] = res;
      },
      err => this.utils.httpErrorResponseHandler(err, 'failure updating renders settings')
    );
  }
}
