import {Component, Inject, OnInit} from '@angular/core';
import {MAT_SNACK_BAR_DATA, MatSnackBarRef} from '@angular/material/snack-bar';

@Component({
    selector: 'app-dynamic-snack-bar',
    templateUrl: './dynamic-snack-bar.component.html',
    styleUrls: ['./dynamic-snack-bar.component.scss'],
    standalone: false
})
export class DynamicSnackBarComponent implements OnInit {
  public htmlTemplate: string;

  constructor( public snackBarRef: MatSnackBarRef<DynamicSnackBarComponent>,
               @Inject(MAT_SNACK_BAR_DATA) public data: any) { }

  ngOnInit(): void {
    this.htmlTemplate = this.data.htmlTemplate;
  }
}
