import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Retailer, Category, SelectedCategories, SubCategory, RetailersFilterOptions, RejectReason } from 'src/app/retailer/retailer';
import { ProductService } from '../product.service';
import { UtilsService } from '../../shared/utils.service';
import { RolesHelperService } from '../../auth/roles-helper.service';
import { ResourceType, ProductMassEdit, ResourceDimension, ProductsRendersRequestStatus } from '../product';
import { ResourceTypesService } from 'src/app/shared/resource-types.service';
import { Subscription } from 'rxjs';
import { BroadcasterService } from 'ng-broadcaster';
import { MatRadioChange } from '@angular/material/radio';
import { GraphqlService } from 'src/app/communication/graphql.service';
import { AuthService } from 'src/app/auth/auth.service';
import { KeyValuePair } from 'src/app/shared/enums';
import { EnumsService } from 'src/app/shared/enums.service';
import { RestService } from 'src/app/communication/rest.service';
import { BroadcasterNotification, BroadcasterNotificationType } from 'src/app/communication/broadcaster-notifications';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { RetailerBatchesComponent } from 'src/app/retailer/retailer-batches/retailer-batches.component';
import { FeedbackService } from '../feedback.service';
import { RejectReasonService } from 'src/app/shared/reject-reason.service';

@Component({
    selector: 'app-refine-results',
    templateUrl: './refine-results.component.html',
    styleUrls: ['./refine-results.component.scss'],
    standalone: false
})
export class RefineResultsComponent implements OnInit, OnDestroy {
  public uiBinds: any;
  public counter: number;
  public restoreTree: number;
  public categories: Array<Category>;
  public subCategories: Array<SubCategory>;
  public show: boolean;
  public allFormats: Array<ResourceType>;
  public allRejectReason: Array<RejectReason>;
  public isSU: boolean;
  public isAdmin: boolean;
  public canViewAllRetailers: boolean;
  public allDimUnits: Array<KeyValuePair>;
  public units: number;
  public length: number;
  public width: number;
  public height: number;
  public priorities: Array<KeyValuePair>;
  public prioritiesDictionary: { [id: number]: string };
  public retailerForBatch: Retailer;
  public userRatilers: Array<Retailer>;
  public resourceFormats: Array<ResourceType>;
  public renderStatuses: Array<KeyValuePair>;
  private subs: Array<Subscription>;
  @ViewChild('retBatchRef') retBatchRef: RetailerBatchesComponent;
  constructor(
    public productService: ProductService,
    private utils: UtilsService,
    public roles: RolesHelperService,
    public resourceTypesService: ResourceTypesService,
    private broadcaster: BroadcasterService,
    private gql: GraphqlService,
    private auth: AuthService,
    private enums: EnumsService,
    public feedbackService: FeedbackService,
    private rest: RestService,
    private rejectReasonService: RejectReasonService
  ) {
    this.subs = [];
    this.isSU = this.roles.isSuperUserOrObserverLoggedIn();
    this.isAdmin = this.roles.isAdminLoggedIn();
    this.canViewAllRetailers = this.roles.doesUserHasPermission('view all retailers');
    this.allDimUnits = this.enums.getDimensionsUnits();
    this.allFormats = this.resourceTypesService.getCachedTypes();
    if (!this.allFormats) {
      this.subs.push(
        this.broadcaster.on('onResourceTypes').subscribe(
          () => {
            this.allFormats = this.resourceTypesService.getCachedTypes();
            this.filter3d2dTypes();
          }
        )
      );
    }
    this.subs.push(
      this.broadcaster.on('onRetailerIndex').subscribe(
        () => {
          let options = {
            id: [this.auth.user.retailers_users[this.auth.retailerIndex].retailer_id]
          } as RetailersFilterOptions;
          this.gql.retailerForQuery(options).subscribe(
            obj => {
              this.categories = [];
              this.subCategories = [];
              this.onRetailerChange(obj.data.allRetailers.rows[0]);
            }
          )
        }
      )
    )
    this.show = false;
    this.uiBinds = {
      serial_number: {
        title: 'serial no.',
        value: null
      },
      tags: {
        title: 'tags',
        value: null
      },
      name: {
        title: 'name',
        value: null
      },
      url: {
        title: 'url',
        value: null
      }
    }
    this.priorities = this.enums.getPriorities();
    this.prioritiesDictionary = this.enums.getPrioritiesDictionary();
    this.counter = 0;
    this.restoreTree = 0;
    this.setRetailerBatches();
    this.getRejectReasons();
    this.renderStatuses = this.enums.getRenderStatuses();
  }

  async getRejectReasons() {
    this.allRejectReason = await this.rejectReasonService.getRejectReasons();
  }

  setRetailerBatches() {
    this.retailerForBatch = {} as Retailer;
    let retailer = this.productService.filter.controls.retailer_id?.value;
    if (retailer && retailer.length > 0) {
      this.retailerForBatch.id = retailer;
    } else {
      this.userRatilers = this.auth.user.retailers_users[this.auth.retailerIndex].retailers;
      this.retailerForBatch.id = this.userRatilers[0].id;
    }
  }

  async ngOnInit() {
    await this.feedbackService.getFeedbackTypes();
    this.setCategories();
  }

  filter3d2dTypes() { 
    if (this.allFormats)
      this.resourceFormats = this.allFormats;
  }

  onKeypress($event: any, field: string) {
    if (this.utils.isEnter($event.charCode) && this.uiBinds[field].value) {
      this.addToFilter(field, this.uiBinds[field].value);
    }
  }

  addToFilter(field: string, val: any) {
    this.productService.ignoreChanges = true;
    let current = this.productService.filter.controls[field].value;
    if (typeof current === 'undefined')
      current = [];
    if (current instanceof Array) {
      if (!current.find(x => x == val)) {
        val = String(val);
        if (val.indexOf('"') > -1)
          val = val.substring(0, val.indexOf('"'));
        current.push(String(val));
      }
    }
    this.productService.filter.controls[field].setValue(current);
    if (this.uiBinds[field].value)
      this.uiBinds[field].value = '';
    this.productService.searchByQuery(true);
    this.productService.ignoreChanges = false;
  }

  remove(field: string, val: any) {
    this.productService.ignoreChanges = true;
    let current = this.productService.filter.controls[field].value;
    if (current instanceof Array) {
      current.splice(current.indexOf(val), 1);
    }
    this.productService.filter.controls[field].setValue(current);
    this.counter++;
    this.productService.searchByQuery(true);
    this.productService.ignoreChanges = false;
    if (field == 'retailer_id') {
      delete this.productService.cachedRetailers[val];
      this.setCategories();
      this.clearCategories();

      this.retailerForBatch = {} as Retailer;
      let retailers = this.productService.filter.controls[field]?.value;
      if (retailers.length > 0) {
        this.retailerForBatch.id = retailers[0];
      }
      this.productService.filter.controls.batch_id.setValue([]);
    }
  }

  // removeRetailerCategories(rids?: Array<number>) {
  //   if (!rids) {
  //     rids = [];
  //     for (let i in this.productService.cachedRetailers) {
  //       rids.push(parseInt(i));
  //     }
  //   }
  //   for (let id of rids) {
  //     for (let i = 0; i < this.categories.length; i++) {
  //       if (this.categories[i].retailer_id == id)
  //         this.categories.splice(i, 1);
  //     }
  //     for (let i = 0; i < this.subCategories.length; i++) {
  //       if (this.subCategories[i].retailer_id == id)
  //         this.subCategories.splice(i, 1);
  //     }
  //   }
  // }

  clearRetailers() {
    this.productService.ignoreChanges = true;
    this.productService.filter.controls.retailer_id.setValue([]);
    this.productService.filter.controls.batch_id.setValue([]);
    this.productService.searchByQuery(true);
    this.productService.ignoreChanges = false;
    this.productService.cachedRetailers = {};
    this.setCategories();
    this.clearCategories();
  }

  clearCategories() {
    this.productService.ignoreChanges = true;
    this.productService.filter.controls.retailer_category_id.setValue([]);
    this.productService.ignoreChanges = false;
    this.productService.filter.controls.retailer_sub_category_id.setValue([]);
    this.restoreTree++;
  }

  setVal(field: string, val: any) {
    this.productService.filter.controls[field].setValue(val);
    this.counter++;
  }
 

  setCategories() {
    this.categories = this.productService.getRetailersCategories();
    this.subCategories = this.productService.getRetailersSubCategories();
  }

  onCategoriesChange(selected: SelectedCategories) {
    this.productService.ignoreChanges = true;
    this.productService.filter.controls['retailer_category_id'].setValue(selected.categories.map(c => c.id));
    this.productService.ignoreChanges = false;
    this.productService.filter.controls['retailer_sub_category_id'].setValue(selected.subCategories.map(c => c.id));
  }

  onRetailerChange(retailer: Retailer) {
    if (retailer) {
      retailer = this.utils.deepCopyByValue(retailer);
      this.productService.setRetailerToCache(retailer);
      this.categories = this.categories.concat(retailer.retailers_categories);
      this.subCategories = this.subCategories.concat(retailer.retailers_sub_categories);
      let retailers = this.productService.filter.controls.retailer_id.value;
      this.setRetailerBatches();
      if (!(retailers instanceof Array))
        retailers = [];
      if (retailers.filter(r => r == retailer.id).length > 0)
        return;
      retailers.push(retailer.id);
      this.productService.ignoreChanges = true;
      this.clearCategories();
      this.productService.filter.controls.retailer_id.setValue(retailers);

      this.retailerForBatch = { retailers_contracts: [] } as Retailer;
      this.retailerForBatch.id = retailers[0];
      this.retailerForBatch.retailers_contracts = retailer.retailers_contracts;

      this.productService.searchByQuery(true);
      this.productService.ignoreChanges = false;
    }
  }

  setBatches(e) {
    var ids = e.map(i => i.batch_id);
    this.productService.filter.controls.batch_id.setValue(ids);
  }

 


  
  addBatchesToProducts(e) {
    let today = new Date();
    let passedBatch = e.some(b => (new Date(b.end_at).getTime() < today.getTime()));
    if (passedBatch) {
      if (!confirm("Please note the batch end date has passed, are you sure that’s the batch you want to select?")) {
        this.retBatchRef.deselectAll();
        return;
      }
    }
     
    let ids = e.map(i => i.batch_id);
    // let selectedItems = this.productService.isAllSelected ? this.productService.items : this.productService.selectedItems;
    // let productids = selectedItems.map(p => p.id);

    let payload = {
      // "query": `{ allProducts(id: [${productids}]) { rows { id   name   created_at retailer_id }  summary    count   }}`,
      "query": `{ allProducts(${this.gql.getValues(this.productService.getWhere())}) { rows { id   name   created_at retailer_id }  summary    count   }}`,
      "batch_id": ids[0],
      "user_id": this.auth.user.id
    }
    this.rest.addBatchesToProduct('put', payload).subscribe(
      () => {
        let data: BroadcasterNotification = {
          text: 'products successfully assigned to the batches',
          type: BroadcasterNotificationType.Success,
          action: 'OK'
        }
        this.broadcaster.broadcast('notifyUser', data);
      },
      err => {
        this.utils.httpErrorResponseHandler(err, 'failure assign products to the batches')
      }
    );

  }

  applyDimensions() {
    let payload = {
      values: {
        width: this.width,
        height: this.height,
        length: this.length,
        units: this.units
      },
      where: this.productService.getWhere()
    } as ProductMassEdit;
    this.rest.product('put', payload).subscribe(
      () => {
        let data: BroadcasterNotification = {
          text: 'products dimensions updated successfully',
          type: BroadcasterNotificationType.Success,
          action: 'OK'
        }
        this.broadcaster.broadcast('notifyUser', data);
        // this.productService.unselectAll();
        // const onItemsChange = this.productService.onItemsChange.subscribe(
        //   () => {
        //     this.productService.refreshSummary();
        //     onItemsChange.unsubscribe();
        //   }
        // );
      },
      err => this.utils.httpErrorResponseHandler(err, 'failure updating products dimensions')
    );
  }

  toggleIA(state: MatButtonToggleChange) {
    let msg = 'Are you sure you want to ' + (state.value == true ? 'ENABLE ' : 'DISABLE ') + this.productService.totalSelected + ' products for internally approved?';
    if (!confirm(msg)) return;
    let p = {
      'ia': state.value
    };
    let payload = {
      values: p,
      where: this.productService.getWhere()
    } as ProductMassEdit;
    this.productService.saveProductMassEdit(payload);
  }

  toggleVisible(state: MatButtonToggleChange) {
    let msg = 'Are you sure you want to ' + (state.value == 1 ? 'ENABLE ' : 'DISABLE ') + this.productService.totalSelected + ' products for visibility?';
    if (!confirm(msg)) return;
    let p = {
      'visible': state.value
    };
    let payload = {
      values: p,
      where: this.productService.getWhere()
    } as ProductMassEdit;
    this.productService.saveProductMassEdit(payload);
  }

  togglePOC(state: MatButtonToggleChange) {
    let msg = 'Are you sure you want to ' + (state.value == 1 ? 'ENABLE ' : 'DISABLE ') + this.productService.totalSelected + ' products for Demo?';
    if (!confirm(msg)) return;
    let p = {
      'is_poc': state.value
    };
    let payload = {
      values: p,
      where: this.productService.getWhere()
    } as ProductMassEdit;
    this.productService.saveProductMassEdit(payload);
  }

  toggleAOT(state: MatButtonToggleChange) {
    let msg = 'Are you sure you want to ' + (state.value == 1 ? 'ENABLE ' : 'DISABLE ') + this.productService.totalSelected + ' products for Auto Open Texture?';
    if (!confirm(msg)) return;
    const p = {
      'auto_create_texture_offers': state.value
    };
    let payload = {
      values: p,
      where: this.productService.getWhere()
    } as ProductMassEdit;
    this.productService.saveProductMassEdit(payload);
  }

  onPriorityChange(evt: MatRadioChange) {
    let msg = `Are you sure you want to chenge all ${this.productService.totalSelected} selected products priority to ${this.prioritiesDictionary[evt.value]}?`;
    if (!confirm(msg)) return;
    let p = {
      'priority': evt.value
    };
    let payload = {
      values: p,
      where: this.productService.getWhere()
    } as ProductMassEdit;
    this.productService.saveProductMassEdit(payload);
  }

  toggleDP(evt: MatButtonToggleChange){  
    let p = {
      'priority_type': evt.value
    };
    let payload = {
      values: p,
      where: this.productService.getWhere()
    } as ProductMassEdit;
    this.productService.saveProductMassEdit(payload);
  }


  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe());
  }
}
