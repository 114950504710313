import { Component, OnInit, Input } from '@angular/core';
import { GlobalsService } from 'src/app/shared/globals.service';
import { AuthService } from '../auth.service';

@Component({
    selector: 'app-privacy-view',
    templateUrl: './privacy-view.component.html',
    styleUrls: ['./privacy-view.component.scss'],
    standalone: false
})
export class PrivacyViewComponent implements OnInit {
  @Input() partial: boolean;
  constructor(
    private globals: GlobalsService,
    public auth: AuthService
  ) { }

  ngOnInit() {
    if (!this.partial)
      setTimeout(() => {
        this.globals.playgroundClass = null;
        // this.globals.setHeaer('Privacy Notice', null);
      }, 0);
  }
}
