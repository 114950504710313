import { Component, OnInit, Input, Output, ViewChild, ElementRef, EventEmitter, OnDestroy, OnChanges, SimpleChanges, AfterViewInit } from '@angular/core';
import { ImageSet } from '../../product/product';
import { Subscription } from 'rxjs';
import { ElementDimensions } from '../utils';
import { SafeStyle, DomSanitizer } from '@angular/platform-browser';
import { CombineImagesService } from '../combine-images.service';

@Component({
    selector: 'app-image-hover-overlay',
    templateUrl: './image-hover-overlay.component.html',
    styleUrls: ['./image-hover-overlay.component.scss'],
    standalone: false
})
export class ImageHoverOverlayComponent implements AfterViewInit, OnChanges, OnDestroy {
  @Input('image-set') imageSet: ImageSet;
  @Input() height: string;
  @Input('create-fix') createFix: boolean;
  @Output('on-fixed-image') onFixedImage: EventEmitter<string>;
  @ViewChild('wrap') wrap: ElementRef;
  @ViewChild('img') img: ElementRef;
  @ViewChild('org') org: ElementRef;
  @ViewChild('inner') inner: ElementRef;
  private element: ElementRef;
  private lasrBigSrc: string;
  private onImagePortionSub: Subscription;
  public imgOrgDim: ElementDimensions;
  public imageHasLoaded: boolean;
  public preview: SafeStyle;
  public tsPreview: string;
  public fixPosition: boolean;
  public createingFix: boolean;
  public imageToSmall: boolean;
  constructor(
    private sanitizer: DomSanitizer,
    private combineImagesService: CombineImagesService
  ) {
    this.imgOrgDim = { width: 0, height: 0 };
    this.fixPosition = false;
    this.onFixedImage = new EventEmitter<string>();
    this.createingFix = false;
    this.imageToSmall = false;
  }

  ngAfterViewInit() {
    // this.element = this.wrap.nativeElement.parentElement;
    // if (!this.element)
    if (this.createFix) {
      this.onImagePortionSub = this.combineImagesService.onImagePortion.subscribe((cropImage) => {
        this.onFixedImage.emit(cropImage);
        this.createingFix = false;
      });
    }
    this.element = this.wrap;
    this.attachEvents();
  }

  ngOnChanges(changes: SimpleChanges) {
    for (let propName in changes) {
      if (propName == 'imageSet' && this.lasrBigSrc != this.imageSet.big) {
        this.lasrBigSrc = this.imageSet.big;
        this.imageHasLoaded = false;
        this.imageToSmall = false;
        let img = new Image();
        img.crossOrigin ='anonymous';
        this.setBackground();
        img.onload = () => {
          if (this.imageSet.big == img.src) {
            setTimeout(() => {
              requestAnimationFrame(() => {
                this.imageHasLoaded = true;
                this.setBackground();
              })
            }, 50);
            if (!this.checkMinImageSize(img))
              this.imageToSmall = true;
            else
              this.imageToSmall = false;
            this.setBackground();
          }
        }
        img.src = this.imageSet.big;
        this.setBackground();
      }
    }
  }

  setBackground() {
    let src = this.imageSet.small || this.imageSet.big;
    this.preview = this.sanitizer.bypassSecurityTrustStyle(`url(${src})`);
    if (this.imageToSmall)
      src = this.imageSet.big || this.imageSet.small;
    // this.tsPreview = this.sanitizer.bypassSecurityTrustStyle(`url(${src})`);
    this.tsPreview = src;
  }

  attachEvents() {
    this.element.nativeElement.addEventListener("mouseover", (e: MouseEvent) => {
      if (this.fixPosition) return;
      this.inner.nativeElement.classList.add('hover');

      this.imgOrgDim.width = this.org.nativeElement.offsetWidth;
      this.imgOrgDim.height = this.org.nativeElement.offsetHeight;

      this.img.nativeElement.style.width = this.img.nativeElement.naturalWidth + 'px';
      this.img.nativeElement.style.height = this.img.nativeElement.naturalHeight + 'px';
      // this.img.nativeElement.parentElement.style.visibility = '';
    }, false);

    this.element.nativeElement.addEventListener("mouseout", (e) => {
      if (this.fixPosition) return;
      this.inner.nativeElement.classList.remove('hover');
    }, false);

    this.element.nativeElement.addEventListener("mousemove", this.onMove.bind(this), false);
    this.element.nativeElement.addEventListener("touchmove", this.onMove.bind(this), false);
  }

  checkMinImageSize(img: any): boolean {
    // let bound = this.img.nativeElement.getBoundingClientRect();
    let parentBound = this.img.nativeElement.parentElement.getBoundingClientRect();
    if (img.naturalWidth < parentBound.width || img.naturalHeight < parentBound.height)
      return false;
    return true;
  }

  onMove(e: MouseEvent | any) {
    if (this.fixPosition) return;
    let x = e.clientX || e.offsetX || e.layerX;
    let y = e.clientY || e.offsetY || e.layerY;
    x = -(this.inner.nativeElement.getBoundingClientRect().left - x);
    y = -(this.inner.nativeElement.getBoundingClientRect().top - y);

    // for touch screens - need testing in all platphormes, brousers and OSs 
    // if (typeof x !== 'number') {
    //     if (!oe.touches) return;
    //     let oe = e.originalEvent ? e.originalEvent : e;
    //     x = oe.touches[0].clientX;
    //     y = oe.touches[0].clientY;
    //     if (!x) {
    //         x = oe.pageX;
    //         y = oe.pageY;
    //     }
    // }

    let yp = y / this.imgOrgDim.height;
    let xp = x / this.imgOrgDim.width;

    // let xRatio = org.offsetWidth / imgOrgDim.width;
    // let yRatio = org.offsetHeight / imgOrgDim.height;

    let totalY = this.img.nativeElement.naturalHeight - this.imgOrgDim.height;
    let totalX = this.img.nativeElement.naturalWidth - this.imgOrgDim.width;

    this.img.nativeElement.style.top = (-yp * totalY) + 'px';
    this.img.nativeElement.style.left = (-xp * totalX) + 'px';
  }

  toggleFix(e: MouseEvent) {
    this.fixPosition = !this.fixPosition;
    if (this.fixPosition && this.createFix) {
      this.createingFix = true;
      let img = new Image();
      img.crossOrigin ='anonymous';
      img.onload = () => {
        let bound = this.img.nativeElement.getBoundingClientRect();
        let parentBound = this.img.nativeElement.parentElement.getBoundingClientRect();
        this.combineImagesService.getImagePortion(this.img, parentBound.width, parentBound.height, -bound.left + parentBound.left, -bound.top + parentBound.top);
      };
      img.src = this.imageSet.big;
    }
    else if (this.createFix)
      this.onFixedImage.emit('');
  }

  ngOnDestroy() {
    if (this.onImagePortionSub)
      this.onImagePortionSub.unsubscribe();
  }

}
