
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ProductService } from '../product.service';
import { ActivatedRoute } from '@angular/router';
import { SingleProductQuery, Product, MediaTag, ProductProcessTypes, ProductResource, ResourceType } from '../product';
import { ProductRequestService } from '../product-request.service';
import { ApolloQueryResult } from '@apollo/client/core';
import { RetailerQueryInner } from '../../retailer/retailer';
import { RolesHelperService } from '../../auth/roles-helper.service';
import { UtilsService } from '../../shared/utils.service';
import { EnumsService } from 'src/app/shared/enums.service';
import { StorageService } from 'ng-storage-service';
import { BodyService } from 'src/app/shared/body.service';
import { GlobalsService } from 'src/app/shared/globals.service';
import { PlatgroundClass } from 'src/app/shared/globals';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { ProductSettingsComponent } from '../product-settings/product-settings.component';
import { FeedbackService } from '../feedback.service';
@Component({
    selector: 'app-product-request',
    templateUrl: './product-request.component.html',
    styleUrls: ['./product-request.component.scss'],
    standalone: false
})
export class ProductRequestComponent implements OnDestroy {
  @ViewChild(ProductSettingsComponent) productSettingsCmp: ProductSettingsComponent;
  public initialState: number;
  private autosaveInterval: any;
  private subs: Array<Subscription>;
  public processTypes = ProductProcessTypes;
  public hasModel: boolean;
  public prodLoaded: boolean;
  constructor(
    private activatedRoute: ActivatedRoute,
    public productService: ProductService,
    public prService: ProductRequestService,
    public roles: RolesHelperService,
    private utils: UtilsService,
    public enums: EnumsService,
    public storage: StorageService,
    private body: BodyService,
    private globals: GlobalsService,
    private auth: AuthService,
    public feedbackService: FeedbackService,
  ) {
    this.prService.externalResourcesCounter = 0;
    this.prService.uploadingCount = 0;
    this.subs = [];
    this.init();
  }

  async init() {
    await this.feedbackService.getFeedbackTypes();
    await this.initProduct();
    this.prodLoaded = true;
    this.prService.selectedResource = this.prService.enabledResources[this.prService.currentResourceIndex];
    if (this.prService?.selectedResource?.id)
      this.prService.preservedModelResource = this.prService.selectedResource.id;
    this.hasModel = this.productService.hasModel(this.prService.request, this.prService.isSU ? true : false);
    setTimeout(() => {
      this.prService.setHeader();
      this.globals.playgroundClass = PlatgroundClass[PlatgroundClass.OVERFLOW].toLowerCase();
    }, 0);
    this.prService.setAdjustmentsAvailable();
    this.prService.setRenderModeAvailable();
    this.prService.setDimensionsUnits();
    this.prService.initExternalResources();
  }

  private async initProduct() {
    let p = this.activatedRoute.snapshot.data['product'] as SingleProductQuery;
    if (p)
      this.prService.mapFromProduct(this.productService.getProductsFromProductQuery([p.data.products])[0]);
    else {
      let lastP = await this.prService.getDBValue(ProductRequestService.LAST_NEW_PRODUCT);

      if (lastP) {
        if (lastP.id) {
          this.prService.deleteDBValue(ProductRequestService.LAST_NEW_PRODUCT);
          this.prService.resetRequest();
        }
        else {
          if (this.prService.isSU || (!lastP.retailers || lastP.retailers[0])) {
            if (lastP.retailers[0] && !this.prService.isSU) {
              if (lastP.retailers[0].id === this.auth.user.retailers_users[this.auth.retailerIndex].id)
                this.prService.mapFromProduct(lastP);
              else
                this.prService.resetRequest();
            }
            else
              this.prService.mapFromProduct(lastP);
          }
          else {
            if (lastP.retailers[0].id === this.auth.user.retailers_users[this.auth.retailerIndex].id)
              this.prService.mapFromProduct(lastP);
            else
              this.prService.resetRequest();
          }
        }
      }
      else
        this.prService.resetRequest();
      let r = this.activatedRoute.snapshot.data['retailer'] as ApolloQueryResult<RetailerQueryInner>;
      if (r && r.data && r.data.retailers) {
        let existingIndex = this.prService.request.retailers.findIndex(retailer => retailer.id == r.data.retailers.id);
        if (existingIndex > -1)
          this.prService.request.retailers.splice(existingIndex, 1);
        this.prService.request.retailers = this.prService.request.retailers.concat(this.utils.deepCopyByValue(r.data.retailers));
        this.prService.onRetailersUpdate();
      }
      this.autosaveInterval = setInterval(this.autosave.bind(this), 5000);
    }
    this.productService.editItem = this.prService.request;
    let gifgen = this.activatedRoute.snapshot.data['gifgen'] as any;
    if (gifgen) {
      this.prService.gifgen = true;
      this.prService.gifgenForce = !!gifgen.gifgenForce;
      this.prService.currentResourceIndex = 0;
      while (this.prService.enabledResources.length - 1 == this.prService.currentResourceIndex && this.prService.enabledResources[this.prService.currentResourceIndex].resource_type != MediaTag.MODEL) {
        this.prService.currentResourceIndex++;
      }
    }
    this.prService.setPrice();
    this.prService.setBudget();
    this.body.bodyElement.nativeElement.classList.add('overflow-sm');
    // this.subs.push(this.broadcaster.on('esc').subscribe(() => {
    //   this.prService.clearIframeSrc();
    // }));
    // let retailer = this.activatedRoute.snapshot.data['retailer'] as RetailerQueryData;
    // if (retailer)
    //   this.prService.request.retailers = [this.utils.deepCopyByValue(retailer.data.retailers)];
   
  }

  autosave() {
    if (this.prService.request.id)
      clearInterval(this.autosaveInterval);
    else
      this.prService.setDBValue(ProductRequestService.LAST_NEW_PRODUCT, this.prService.request);
  }

  public onResourceChange(resource: ProductResource): void {
    const index = this.prService.enabledResources.indexOf(resource);
    this.prService.currentResourceIndex = index;
    this.prService.preservedModelResource = resource.id;
    this.prService.onResourceIndexChange();
    this.productSettingsCmp.onResourceChange();
  }

  ngOnDestroy() {
    delete this.productService.editItem;
    delete this.prService.gifgen;
    delete this.prService.gifgenForce;
    this.productService.clearRetailersCache();
    clearInterval(this.autosaveInterval);
    this.body.bodyElement.nativeElement.classList.remove('overflow-sm');
    this.globals.playgroundClass = null;
    this.subs.forEach(s => s.unsubscribe());
  }
}
